import React from "react";
import ReactPaginate from 'react-paginate';
import {decode as base64_decode} from 'base-64';
import queryString from 'query-string';
import { ReactSession } from 'react-client-session';

import { Helpers } from "../../config/Helpers";

import ModalProduct from "../../components/ModalProduct";

class Wishlist extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            categoryData: [],
            productData: [],
            offset: 0,
            perPage: 10,
            currentPage: 0,
            pageNumList: [10,25,50,100,150,200],
            sortData: ['Featured','Price: Low to High','Price: High to Low'],
            sortDataValue: 'Featured',
            setModal: '',
            filterCategories: '',
            search: '',
            orderType: (ReactSession.get("order_type")) ? ReactSession.get("order_type") : 'Grosir',
            branchCode: (ReactSession.get("branch_code")) ? ReactSession.get("branch_code") : 'CBNG-18-020001',
            urlParam: queryString.parse(window.location.search)
        };
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    componentDidMount() {
        this.getGoodsType();
        if(this.state.urlParam.search){
            this.setState({
                search: this.state.urlParam.search,
            }, () => {
                this.getProduct();
            });
        }else{
            this.getProduct();
        }
    }

    getGoodsType = () => {
        Helpers.getGoodsType().then(res => {
            this.setState({ categoryData: res });
        })
    };

    getProduct = () => {
        Helpers.getWishlist(this.state.sessUserCode,this.state.filterCategories,this.state.branchCode).then(res => {
            this.setState({ productData: res });

            let sortData = res;
            if(this.state.search !== ''){
                sortData = sortData.filter(item => {
                    const textData = this.state.search.toUpperCase();

                    return Object.keys(item).some(key => ((item[key]) ? item[key] : '').toString().toUpperCase().search(textData) !== -1);
                });
            }
            if(this.state.sortDataValue == 'Price: High to Low'){
                sortData = sortData.sort(function(a,b) {
                    const val_a = parseInt(a.HargaGrosirFormatTerkecil.replaceAll('.',''));
                    const val_b = parseInt(b.HargaGrosirFormatTerkecil.replaceAll('.',''));

                    return (val_a < val_b) ? 1 : ((val_b < val_a) ? -1 : 0);
                });
            }
            if(this.state.sortDataValue == 'Price: Low to High'){
                sortData = sortData.sort(function(a,b) {
                    const val_a = parseInt(a.HargaGrosirFormatTerkecil.replaceAll('.',''));
                    const val_b = parseInt(b.HargaGrosirFormatTerkecil.replaceAll('.',''));

                    return (val_a > val_b) ? 1 : ((val_b > val_a) ? -1 : 0);
                });
            }

            const slice = sortData.slice(this.state.offset, this.state.offset + this.state.perPage)
            const postData = slice.map((object, i) => {
                return <div className="col-lg-1-5 col-md-4 col-6 col-sm-6">
                    <div className="product-cart-wrap mb-30">
                        <div className="product-img-action-wrap">
                            <div className="product-img product-img-zoom">
                                <a href="#">
                                    <img className="default-img"
                                         src={object.img_url} alt=""/>
                                    <img className="hover-img"
                                         src={object.img_url} alt=""/>
                                </a>
                            </div>
                        </div>
                        <div className="product-content-wrap">
                            <div className="product-category">
                                <p style={{'font-size': '10px', 'line-height': '13px', 'margin-top': '5px', 'height': '26px'}}>{object.JenisBarang}</p>
                            </div>
                            <h2 style={{'font-size': '12px', 'height': '44px'}}><a href="#">{object.NamaBarangShort}</a></h2>
                            <div>
                                <span className="font-small text-muted" style={{'font-size': '11px'}}>
                                    Unit: <a href="vendor-details-1.html">{object.UnitBarangTerkecil}</a>
                                </span>
                            </div>
                            <div className="product-card-bottom" style={{'margin-top': 'unset'}}>
                                {
                                    this.state.orderType == 'Grosir' && (
                                        <div className="product-price">
                                            <span style={{'font-size': '15px'}}>{object.HargaGrosirFormatTerkecil} </span>
                                            <span style={{'font-size': '12px'}} className="old-price">{object.HargaRetailFormatTerkecil}</span>
                                        </div>
                                    )
                                }
                                {
                                    this.state.orderType == 'Retail' && (
                                        <div className="product-price">
                                            <span style={{'font-size': '15px'}}>{object.HargaRetailFormatTerkecil} </span>
                                        </div>
                                    )
                                }

                            </div>

                            <a className="btn btn-small w-100 hover-up mt-5" onClick={() => {this.handleProductClick(object)}}>
                                <i className="fi-rs-shopping-cart mr-5"></i>Add
                            </a>
                        </div>
                    </div>
                </div>
            })

            this.setState({
                pageCount: Math.ceil(sortData.length / this.state.perPage),
                postData
            })
        })
    };

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.getProduct()
        });

    };

    handlePageNumClick = (num) => {
        this.setState({
            currentPage: 0,
            offset: 0,
            perPage: num,
        }, () => {
            this.getProduct()
        });
    };

    handleSortByClick = (sortBy) => {
        this.setState({
            currentPage: 0,
            offset: 0,
            sortDataValue: sortBy
        }, () => {
            this.getProduct()
        });
    };

    handleProductClick = (object) => {
        ReactSession.set("product_id", object.KodeBarang);
        ReactSession.set("product_name", object.NamaBarang);
        ReactSession.set("product_category", object.JenisBarangShort);
        ReactSession.set("product_image", object.img_url);

        this.setState({
            setModal: ''
        }, () => {
            this.setState({
                setModal: <ModalProduct modalRef={true}/>
            })
        })
    };

    handleCategoriesClick = (categories) => {
        this.setState({
            currentPage: 0,
            offset: 0,
            filterCategories: categories,
        }, () => {
            this.getProduct()
        });
    };

    handleSearch = (e) => {
        e.preventDefault();

        this.setState({
            currentPage: 0,
            offset: 0
        }, () => {
            this.getProduct()
        });
    };

    render() {
        return (
            <>
                { this.state.setModal }

                <main className="main">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <a href="./" rel="nofollow"><i className="fi-rs-home mr-5"></i>Beranda</a>
                                <span></span> Akun<span></span> Wishlist
                            </div>
                        </div>
                    </div>
                    <section className="popular-categories section-padding">
                        <div className="container wow animate__animated animate__fadeIn">
                            <div className="section-title">
                                <div className="title">
                                    <h3>Produk Kategori</h3>
                                </div>
                                <div className="slider-arrow slider-arrow-2 flex-right carausel-10-columns-arrow"
                                     id="carausel-10-columns-arrows"></div>
                            </div>
                            <div className="carausel-10-columns-cover position-relative">
                                <div className="carausel-10-columns" id="carausel-10-columns">
                                    {
                                        this.state.categoryData.map((object, i) => {
                                            return <div className={'card-2 ' + ((i%2 == 1) ? 'bg-9' : 'bg-11') + ' wow animate__animated animate__fadeInUp'}
                                                        data-wow-delay=".1s">
                                                <figure className="img-hover-scale overflow-hidden">
                                                    <a onClick={() => {this.handleCategoriesClick(object.KodeJenisBarang)}}><img src={object.img_url}/></a>
                                                </figure>
                                                <h6 style={{'font-size': '14px'}}>
                                                    <a onClick={() => {this.handleCategoriesClick(object.KodeJenisBarang)}}>{object.JenisBarang}</a>
                                                </h6>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="container mb-30">
                        <div className="archive-header-2 text-center">
                            <div className="row">
                                <div className="col-lg-5 mx-auto">
                                    <div className="sidebar-widget-2 widget_search mb-50">
                                        <div className="search-form">
                                            <form method="post" onSubmit={(e) => {this.handleSearch(e)}}>
                                                <input type="text" placeholder="Cari produk ..." value={this.state.search} onChange={e => this.setState({search: e.target.value})}/>
                                                <button type="submit"><i className="fi-rs-search"></i></button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="shop-product-fillter">
                                    <div className="totall-product">
                                        {/*<p>We found <strong className="text-brand">29</strong> items for you!</p>*/}
                                    </div>
                                    <div className="sort-by-product-area">
                                        <div className="sort-by-cover mr-10">
                                            <div className="sort-by-product-wrap">
                                                <div className="sort-by">
                                                    <span><i className="fi-rs-apps"></i>Show:</span>
                                                </div>
                                                <div className="sort-by-dropdown-wrap">
                                                    <span> {this.state.perPage} <i className="fi-rs-angle-small-down"></i></span>
                                                </div>
                                            </div>
                                            <div className="sort-by-dropdown">
                                                <ul>
                                                    {
                                                        this.state.pageNumList.map((object, i) => {
                                                            return <li><a className={(object == this.state.perPage) ? 'active': ''} href="#" onClick={() => {this.handlePageNumClick(object)}}>{object}</a></li>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="sort-by-cover">
                                            <div className="sort-by-product-wrap">
                                                <div className="sort-by">
                                                    <span><i className="fi-rs-apps-sort"></i>Sort by:</span>
                                                </div>
                                                <div className="sort-by-dropdown-wrap">
                                                    <span> Featured <i className="fi-rs-angle-small-down"></i></span>
                                                </div>
                                            </div>
                                            <div className="sort-by-dropdown">
                                                <ul>
                                                    {
                                                        this.state.sortData.map((object, i) => {
                                                            return <li><a className={(object == this.state.sortDataValue) ? 'active': ''} href="#" onClick={() => {this.handleSortByClick(object)}}>{object}</a></li>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row product-grid">
                                    {this.state.postData}
                                </div>
                                <div className="pagination-area mt-10 mb-40">
                                    <nav aria-label="Page navigation example">
                                        <ReactPaginate
                                            previousLabel={<i className="fi-rs-arrow-small-left"></i>}
                                            previousClassName={"page-item"}
                                            previousLinkClassName={"page-link"}
                                            nextLabel={<i className="fi-rs-arrow-small-right"></i>}
                                            nextClassName={"page-item"}
                                            nextLinkClassName={"page-link"}
                                            breakLabel={"..."}
                                            breakClassName={"page-link dot"}
                                            pageCount={this.state.pageCount}
                                            marginPagesDisplayed={1}
                                            pageRangeDisplayed={1}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={"pagination justify-content-start"}
                                            activeClassName={"page-item active"}
                                            activeLinkClassName={"page-link"}
                                            pageClassName={"page-item"}
                                            pageLinkClassName={"page-link"}/>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default Wishlist;