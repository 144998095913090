import React from "react";
import {Helpers} from "../../../config/Helpers";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import {ReactSession} from "react-client-session";
import queryString from "query-string";
import ReactPaginate from 'react-paginate';
import $ from "jquery";
import {Connection} from "../../../config/Connection";
import {toast} from "react-toastify";
import Select from 'react-select';

class Shopping extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            sessStore: (ReactSession.get("sess_order_store")) ? ReactSession.get("sess_order_store") : '',
            sessMPBuyerId: (ReactSession.get("sess_mp_buyerid")) ? ReactSession.get("sess_mp_buyerid") : '',
            branchCode: (ReactSession.get("branch_code")) ? ReactSession.get("branch_code") : 'CBNG-18-020001',
            offset: 0,
            perPage: 10,
            currentPage: 0,
            pageNumList: [10,25,50,100,150,200],
            search: '',
            urlParam: queryString.parse(window.location.search),
            productData: [],
            productDataAll: [],
            productDataSlice: [],
            total_items: 0,
            total_orders: 'Rp 0',
            dataSort: [
                {value: '', label: 'Featured'},
                {value: 'asc', label: 'Name Ascending'},
                {value: 'desc', label: 'Name Descending'}
            ],
            dataPagesNum: [
                {value: 10, label: 'Show 10'},
                {value: 25, label: 'Show 25'},
                {value: 50, label: 'Show 50'},
                {value: 100, label: 'Show 100'}
            ],
            dataJenisBarang: [],
            jenis: '',
            sortDataValue: ''
        };
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    componentDidMount() {
        this.getAllData();
    }

    getAllData = () => {
        this.getGoodsType();
    };

    getGoodsType = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getGoodsType().then(res => {
            var arr = res.map(function(a) {
                return {value: a.KodeJenisBarang, label: a.JenisBarang};
            });

            this.setState({ dataJenisBarang: arr });

            this.getSummary(true);
        })
    };

    getSummary = (load) => {
        $(".loader-wrapper").css("display", "");
        Helpers.getMPOrderTempSummary(this.state.sessMPBuyerId,'','','','').then(res => {
            let obj = res[0];
            this.setState({
                total_items: obj.total_items,
                total_orders: obj.total_orders_format
            });

            if(load){
                this.getData(true);
            }else{
                $(".loader-wrapper").css("display", "none");
            }
        });
    };

    getData = (change) => {
        if(this.state.productData.length > 0){
            if(change){
                $(".loader-wrapper").css("display", "");
                Helpers.getMPProductsDisplay(this.state.sessMPBuyerId,'','','', this.state.jenis).then(res => {
                    $(".loader-wrapper").css("display", "none");

                    this.setState({
                        productData: res
                    }, () => {
                        this.prepareProduct(res);
                    });
                });
            }else{
                this.prepareProduct(this.state.productData);
            }
        }else{
            $(".loader-wrapper").css("display", "");
            Helpers.getMPProductsDisplay(this.state.sessMPBuyerId,'','','', this.state.jenis).then(res => {
                $(".loader-wrapper").css("display", "none");

                this.setState({
                    productData: res
                }, () => {
                    this.prepareProduct(res);
                });
            });
        }
    };

    prepareProduct = (res) => {
        let sortData = res;
        if(this.state.search !== ''){
            sortData = sortData.filter(item => {
                const textData = this.state.search.toUpperCase();

                return Object.keys(item).some(key => ((item[key]) ? item[key] : '').toString().toUpperCase().search(textData) !== -1);
            });
        }
        if(this.state.sortDataValue == 'desc'){
            sortData = sortData.sort(function(a,b) {
                const val_a = a.name.toUpperCase();
                const val_b = b.name.toUpperCase();

                return (val_a < val_b) ? 1 : ((val_b < val_a) ? -1 : 0);
            });
        }
        if(this.state.sortDataValue == 'asc'){
            sortData = sortData.sort(function(a,b) {
                const val_a = a.name.toUpperCase();
                const val_b = b.name.toUpperCase();

                return (val_a > val_b) ? 1 : ((val_b > val_a) ? -1 : 0);
            });
        }
        const slice = sortData.slice(this.state.offset, this.state.offset + this.state.perPage);
        this.setState({
            productDataAll: sortData,
            productDataSlice: slice
        }, () => {
            this.prepareData(sortData,slice);
        });
    };

    prepareData = (sortData,slice) => {
        const postData = slice.map((object, i) => {
            return <div className="col">
                <div className="card card-product-grid">
                    <a href="#" className="img-wrap"> <img src={object.url_photo}
                                                           alt="Product"/> </a>
                    <div className="info-wrap">
                        <a href="#" className="title" style={{'height': '63px'}}>{object.name}</a>
                        <div style={{'display': 'flex'}}>
                            <div className="price mb-2" style={{'width': '100%'}}>{object.price_active_format}</div>
                            <div className="price mb-2" style={{'width': '100%', 'text-align': 'right'}}>{object.unit_label}</div>
                        </div>
                        <div style={{'display': 'flex'}}>
                            <input type="number" className="form-control" min={0} max={object.stocks}
                                   style={{'margin-right': '10px', 'width': '40%'}}
                                   value={this.state.productDataSlice[i].qty_order}
                                   onChange={event => this.handleChangeQty(event,i)}/>
                            <a className="btn btn-sm font-sm rounded btn-brand"
                               onClick={() => {this.handleSubmit(i)}}> Add to cart </a>
                        </div>
                    </div>
                </div>
            </div>
        });

        this.setState({
            pageCount: Math.ceil(sortData.length / this.state.perPage),
            postData
        });
    };

    handleChangeQty = (e,index) => {
        const some_array = [...this.state.productDataSlice]
        some_array[index].qty_order = e.target.value;
        this.setState({productDataSlice: some_array}, () => {
            this.prepareData(this.state.productDataAll,this.state.productDataSlice);
        });
    };

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.getData(false)
        });
    };

    handleChange = (event,state) => {
        this.setState({
            currentPage: 0,
            offset: 0,
            [state]: (state === 'search') ? event.target.value : event.value
        }, () => {
            if(state === 'jenis'){
                this.getData(true);
            }else{
                this.getData(false);
            }
        });
    };

    handleReset = () => {
        this.setState({
            jenis: '',
            search: '',
            sortDataValue: '',
            perPage: 10
        }, () => {
            this.getData(true);
        });
    };

    handleSubmit = (i) => {
        $(".loader-wrapper").css("display", "");
        Helpers.manageMPOrderTemp(
            this.state.sessUserCode,
            this.state.sessMPBuyerId,
            this.state.productDataSlice[i].kode_unit,
            this.state.productDataSlice[i].qty_order
        ).then(res => {
            if(res.length > 0){
                if(res[0].alert == 'success'){
                    this.getSummary(false);
                }else{
                    $(".loader-wrapper").css("display", "none");
                }

                toast[res[0].alert](res[0].message);
            }else{
                toast.error(Helpers.MSG_ERROR);
            }
        })
    };

    render() {
        return (
            <>
                <section className="content-main">
                    <div className="content-header">
                        <div>
                            <h2 className="content-title card-title">Shopping</h2>
                            <p>Find everthing you need here</p>
                        </div>
                        <div>
                            <article className="icontext">
                                    <a href="shopping-details"><span className="icon icon-sm rounded-circle bg-info-light"><i
                                        className="text-info material-icons md-shopping_basket"></i></span></a>
                                <div className="text">
                                    <h6 className="mb-1 card-title">Total Belanja</h6>
                                    <span>{this.state.total_orders}</span>
                                    <span className="text-sm">From {this.state.total_items} items</span>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="card mb-4">
                        <header className="card-header">
                            <div className="row gx-3">
                                <div className="col-lg-4">
                                    <input type="text" placeholder="Search..." className="form-control"
                                           value={this.state.search} onChange={event => this.handleChange(event,'search')}/>
                                </div>
                                <div className="col-lg-2">
                                    <Select
                                        options={this.state.dataJenisBarang}
                                        value={this.state.dataJenisBarang.filter((item) => item.value === this.state.jenis)}
                                        onChange={event => this.handleChange(event,'jenis')} />
                                </div>
                                <div className="col-lg-2">
                                    <Select
                                        options={this.state.dataSort}
                                        value={this.state.dataSort.filter((item) => item.value === this.state.sortDataValue)}
                                        onChange={event => this.handleChange(event,'sortDataValue')} />
                                </div>
                                <div className="col-lg-2">
                                    <Select
                                        options={this.state.dataPagesNum}
                                        value={this.state.dataPagesNum.filter((item) => item.value === this.state.perPage)}
                                        onChange={event => this.handleChange(event,'perPage')} />
                                </div>
                                <div className="col-lg-2">
                                    <a className="btn btn-orange btn-sm rounded" onClick={() => {this.handleReset()}}>Reset Filter</a>
                                </div>
                            </div>
                        </header>
                        <div className="card-body">
                            <div
                                className="row gx-3 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-xl-4 row-cols-xxl-5">
                                {this.state.postData}
                            </div>
                        </div>
                    </div>

                    <div className="pagination-area mt-30 mb-50">
                        <nav aria-label="Page navigation example">
                            <ReactPaginate
                                previousLabel={<i className="fi-rs-arrow-small-left"></i>}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextLabel={<i className="fi-rs-arrow-small-right"></i>}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakLabel={"..."}
                                breakClassName={"page-link dot"}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={1}
                                onPageChange={this.handlePageClick}
                                containerClassName={"pagination justify-content-start"}
                                activeClassName={"page-item active"}
                                activeLinkClassName={"page-link"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}/>
                        </nav>
                    </div>
                </section>
            </>
        );
    }
}

export default Shopping;