import React from "react";
import ReactPaginate from 'react-paginate';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { ReactSession } from 'react-client-session';

import { Helpers } from "../../config/Helpers";
import {toast} from "react-toastify";
import queryString from "query-string";

class ChatDetails extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            urlParam: queryString.parse(window.location.search),
            search: '',
            komplainID: '',
            reply: ''
        };
    }

    componentDidMount() {
        if(!this.state.sessUserCode){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.getKomplainDetail();
        }
    }

    getKomplainDetail = () => {
        this.setState({
            komplainID: (this.state.urlParam.trxid) ? base64_decode(this.state.urlParam.trxid) : ''
        }, () => {
            Helpers.getKomplainDetail(this.state.komplainID).then(res => {
                let sortData = res;
                if(this.state.search !== ''){
                    sortData = sortData.filter(item => {
                        const textData = this.state.search.toUpperCase();

                        return Object.keys(item).some(key => ((item[key]) ? item[key] : '').toString().toUpperCase().search(textData) !== -1);
                    });
                }

                const postData = sortData.map((object, i) => {
                    let elements = '';
                    if(object.KodeUserPengirim !== this.state.sessUserCode){
                        elements = <div className="msg left-msg">
                                <div
                                    className="msg-img"
                                    style={{backgroundImage: `url(./assets/imgs/chat/admin.png)`}}
                                />

                                <div className="msg-bubble">
                                    <div className="msg-info">
                                        <div className="msg-info-name">{object.KodeUserPengirim}</div>
                                        <div className="msg-info-time">{object.CreatedOnDetail}</div>
                                    </div>

                                    <div className="msg-text">
                                        {object.Pertanyaan}
                                    </div>
                                </div>
                            </div>
                    }else{
                        elements = <div className="msg right-msg">
                            <div
                                className="msg-img"
                                style={{backgroundImage: `url(./assets/imgs/chat/user.png)`}}
                            />

                            <div className="msg-bubble">
                                <div className="msg-info">
                                    <div className="msg-info-name">{object.KodeUserPengirim}</div>
                                    <div className="msg-info-time">{object.CreatedOnDetail}</div>
                                </div>

                                <div className="msg-text">
                                    {object.Pertanyaan}
                                </div>
                            </div>
                        </div>
                    }
                    return elements
                })

                this.setState({
                    postData
                })
            })
        });
    };

    handleSearch = (e) => {
        e.preventDefault();

        this.getKomplainDetail();
    };

    handleSubmit = (e) => {
        e.preventDefault();

        Helpers.createKomplain(
            this.state.komplainID,
            this.state.sessUserCode,
            'Admin',
            '',
            this.state.reply,
            'reply'
        ).then(res => {
            if(res.length > 0){
                if(res[0].alert == 'success'){
                    this.setState({
                        reply: ''
                    });

                    this.getKomplainDetail();
                }

                toast[res[0].alert](res[0].message);
            }else{
                toast.error(Helpers.MSG_ERROR);
            }
        })
    };

    render() {
        return (
            <>
                <main className="main pages mb-80">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <a href="./" rel="nofollow"><i className="fi-rs-home mr-5"></i>Beranda</a>
                                <span></span> Chat<span></span> Detail Percakapan
                            </div>
                        </div>
                    </div>
                    <div className="page-content pt-20">
                        <div className="container">
                            <div className="archive-header-2 text-center">
                                <div className="row">
                                    <div className="col-lg-5 mx-auto">
                                        <div className="sidebar-widget-2 widget_search mb-15">
                                            <div className="search-form">
                                                <form method="post" onSubmit={(e) => {this.handleSearch(e)}}>
                                                    <input type="text" placeholder="Cari pesan bantuan ..." value={this.state.search} onChange={e => this.setState({search: e.target.value})}/>
                                                    <button type="submit"><i className="fi-rs-search"></i></button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-5 m-auto">
                                    <section className="msger">
                                        <header className="msger-header">
                                            <div className="msger-header-title">
                                                <i className="fas fa-comment-alt" /> No. Bantuan: {this.state.komplainID}
                                            </div>
                                            <div className="msger-header-options">
                                                <a style={{'color': '#F47626'}} href={'./chat'}>Kembali</a>
                                            </div>
                                        </header>

                                        <main className="msger-chat">
                                            {this.state.postData}
                                        </main>

                                        <form className="msger-inputarea"  method="post" onSubmit={(e) => {this.handleSubmit(e)}}>
                                            <input value={this.state.reply} onChange={e => this.setState({reply: e.target.value})}
                                                   placeholder="Enter your message..."
                                                   type="text" required
                                                   className="msger-input"/>
                                            <button type="submit" className="msger-send-btn">Send</button>
                                        </form>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default ChatDetails;