import React from "react";
import {ReactSession} from "react-client-session";
import $ from "jquery";
import {Helpers} from "../../../config/Helpers";
import queryString from "query-string";
import {MDBDataTableV5} from "mdbreact";

class Orders extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            urlActive: window.location.pathname,
            sessMPBuyerId: (ReactSession.get("sess_mp_buyerid")) ? ReactSession.get("sess_mp_buyerid") : '',
            productData: [],
            dataStatus: [
                {value: 'all', label: 'All'},
                {value: 'draft', label: 'Waiting Payment'},
                {value: 'paid', label: 'Waiting Confirmation'},
                {value: 'process', label: 'Order Processed'},
                {value: 'sent', label: 'Order Sent'},
                {value: 'received', label: 'Order Received'},
                {value: 'approved', label: 'Order Finished'}
            ],
            statusDataValue: ''
        };
    }

    componentDidMount() {
        this.getOrders();
    }

    getOrders = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getMPBuyerOrder('',this.state.sessMPBuyerId,'all').then(res => {
            $(".loader-wrapper").css("display", "none");
            this.setState({ productData: res });
        })
    };

    selectData = (id) => {
        window.location.href = 'orders-details?kode_order=' + id;
    };

    render() {
        return (
            <>
                <section className="content-main">
                    <div className="content-header">
                        <div>
                            <h2 className="content-title card-title">Order List</h2>
                            <p>Find your order here</p>
                        </div>
                    </div>
                    <div className="card mb-4">
                        <div className="card-body">
                            <MDBDataTableV5
                                hover
                                fullPagination
                                entriesOptions={[10, 25, 50, 100]}
                                entries={10}
                                data={
                                    {
                                        columns: [
                                            {
                                                label: 'ID Order',
                                                field: 'kode_order',
                                            },
                                            {
                                                label: 'Date',
                                                field: 'date_created',
                                            },
                                            {
                                                label: 'Total Items',
                                                field: 'total_items',
                                            },
                                            {
                                                label: 'Total Orders',
                                                field: 'total_payment'
                                            },
                                            {
                                                label: 'Payment Channel',
                                                field: 'payment_channel',
                                            },
                                            {
                                                label: 'Status',
                                                field: 'status_view',
                                            },
                                            {
                                                label: 'Action',
                                                field: 'action',
                                            }
                                        ],
                                        rows: this.state.productData.map((row) => {
                                            return {
                                                ...row,
                                                status_view: (
                                                    <span className={'badge rounded-pill alert-success'}>{row.status_upper}</span>
                                                ),
                                                action: (
                                                    <div className="dropdown">
                                                        <a href="#" data-bs-toggle="dropdown"
                                                           className="btn btn-brand rounded btn-sm font-sm"> <i
                                                            className="material-icons md-more_horiz"></i> </a>
                                                        <div className="dropdown-menu">
                                                            <a className="dropdown-item" onClick={() => {this.selectData(row.kode_order)}}>View Details</a>
                                                            {
                                                                row.status === 'draft' && (
                                                                    <a className="dropdown-item text-danger" onClick={() => {this.setArchive(row.kode_product,'delete')}}>Continue Payment</a>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                ),
                                            };
                                        }),
                                    }
                                } />
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Orders;