import React from "react";
import ReactPaginate from 'react-paginate';
import {encode as base64_encode} from 'base-64';
import { ReactSession } from 'react-client-session';

import { Helpers } from "../../config/Helpers";
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";

class HistoryOrder extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',

            perPage: 10,

            offsetFinish: 0,
            currentPageFinish: 0,

            offsetCancel: 0,
            currentPageCancel: 0,

            pageNumList: [10,25,50,100,150,200],
            sortData: ['Featured','ID Ascending','ID Descending'],
            search: ''
        };
        this.handlePageClickFinish = this.handlePageClickFinish.bind(this);
        this.handlePageClickCancel = this.handlePageClickCancel.bind(this);
    }

    componentDidMount() {
        if(!this.state.sessUserCode){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.getData();
        }
    }

    getData = () => {
        this.getDataFinish();
        this.getDataCancel();
    }

    getDataFinish = () => {
        Helpers.getListOrder('no','',this.state.sessUserCode,'','').then(res => {
            let sortData = res.filter(item => item.status_overall == 'Finished' );
            if(this.state.search !== ''){
                sortData = sortData.filter(item => {
                    const textData = this.state.search.toUpperCase();

                    return Object.keys(item).some(key => ((item[key]) ? item[key] : '').toString().toUpperCase().search(textData) !== -1);
                });
            }
            if(this.state.sortDataValue == 'ID Descending'){
                sortData = sortData.sort(function(a,b) {
                    const val_a = a.KodeTransaksiOrder.toUpperCase();
                    const val_b = b.KodeTransaksiOrder.toUpperCase();

                    return (val_a < val_b) ? 1 : ((val_b < val_a) ? -1 : 0);
                });
            }
            if(this.state.sortDataValue == 'ID Ascending'){
                sortData = sortData.sort(function(a,b) {
                    const val_a = a.KodeTransaksiOrder.toUpperCase();
                    const val_b = b.KodeTransaksiOrder.toUpperCase();

                    return (val_a > val_b) ? 1 : ((val_b > val_a) ? -1 : 0);
                });
            }

            const slice = sortData.slice(this.state.offsetFinish, this.state.offsetFinish + this.state.perPage)
            const postDataFinish = slice.map((object, i) => {
                return <div className="col-lg-3 col-md-6 col-12 col-sm-6">
                    <div className="vendor-wrap mb-40">
                        <div className="vendor-img-action-wrap" style={{'padding': 'unset'}}>
                            <div className="vendor-img">
                                <table style={{'margin-bottom': 'unset'}}>
                                    <tr>
                                        <td>
                                            <img className="default-img" src={object.DisplayOrder} style={{width: '75px'}}/>
                                        </td>
                                        <td>
                                            <div className="d-flex justify-content-between align-items-end mt-25">
                                                <div>
                                                    <div className="product-category">
                                                        <span className="text-muted" style={{'font-size': '11px'}}>{object.date_d + ' ' + object.date_m + ' ' + object.date_y + ' ' + object.date_t}</span>
                                                    </div>
                                                    <h4 className="mb-5" style={{'font-size': '15px'}}><a style={{'color': '#000'}}>{object.KodeTransaksiOrder}</a>
                                                    </h4>
                                                    <div className="product-category">
                                                        <span className="text-muted" style={{'font-size': '11px'}}>{object.NamaWarung}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div className="product-badges product-badges-position product-badges-mrg">
                                <span className="best">
                                    {
                                        (object.status_overall == 'Waiting Pickup' && object.TipePengiriman == 'Jasa Kurir') ? 'Waiting Delivery' : object.status_overall
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="vendor-content-wrap" style={{'padding': '10px 20px 10px 20px'}}>
                            <div className="d-flex justify-content-between align-items-center">
                                <h4 style={{'font-size': '15px'}}>
                                    <a style={{'color': '#000'}}>Rp {object.TotalHargaOrder} ({object.TotalItem} items)</a>
                                </h4>

                                <div>
                                    <a href={'./account-history-order-details?trxid=' + base64_encode(object.KodeTransaksiOrder)} className="btn btn-xs">View Order <i className="fi-rs-arrow-small-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            })

            this.setState({
                pageCountFinish: Math.ceil(sortData.length / this.state.perPage),
                postDataFinish
            })
        })
    };

    getDataCancel = () => {
        Helpers.getListOrder('no','',this.state.sessUserCode,'','').then(res => {
            let sortData = res.filter(item => item.status_overall == 'Order Canceled' );
            if(this.state.search !== ''){
                sortData = sortData.filter(item => {
                    const textData = this.state.search.toUpperCase();

                    return Object.keys(item).some(key => ((item[key]) ? item[key] : '').toString().toUpperCase().search(textData) !== -1);
                });
            }
            if(this.state.sortDataValue == 'ID Descending'){
                sortData = sortData.sort(function(a,b) {
                    const val_a = a.KodeTransaksiOrder.toUpperCase();
                    const val_b = b.KodeTransaksiOrder.toUpperCase();

                    return (val_a < val_b) ? 1 : ((val_b < val_a) ? -1 : 0);
                });
            }
            if(this.state.sortDataValue == 'ID Ascending'){
                sortData = sortData.sort(function(a,b) {
                    const val_a = a.KodeTransaksiOrder.toUpperCase();
                    const val_b = b.KodeTransaksiOrder.toUpperCase();

                    return (val_a > val_b) ? 1 : ((val_b > val_a) ? -1 : 0);
                });
            }

            const slice = sortData.slice(this.state.offsetCancel, this.state.offsetCancel + this.state.perPage)
            const postDataCancel = slice.map((object, i) => {
                return <div className="col-lg-3 col-md-6 col-12 col-sm-6">
                    <div className="vendor-wrap mb-40">
                        <div className="vendor-img-action-wrap" style={{'padding': 'unset'}}>
                            <div className="vendor-img">
                                <table style={{'margin-bottom': 'unset'}}>
                                    <tr>
                                        <td>
                                            <img className="default-img" src={object.DisplayOrder} style={{width: '75px'}}/>
                                        </td>
                                        <td>
                                            <div className="d-flex justify-content-between align-items-end mt-25">
                                                <div>
                                                    <div className="product-category">
                                                        <span className="text-muted" style={{'font-size': '11px'}}>{object.date_d + ' ' + object.date_m + ' ' + object.date_y + ' ' + object.date_t}</span>
                                                    </div>
                                                    <h4 className="mb-5" style={{'font-size': '15px'}}><a style={{'color': '#000'}}>{object.KodeTransaksiOrder}</a>
                                                    </h4>
                                                    <div className="product-category">
                                                        <span className="text-muted" style={{'font-size': '11px'}}>{object.NamaWarung}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div className="product-badges product-badges-position product-badges-mrg">
                                <span className="best">
                                    {
                                        (object.status_overall == 'Waiting Pickup' && object.TipePengiriman == 'Jasa Kurir') ? 'Waiting Delivery' : object.status_overall
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="vendor-content-wrap" style={{'padding': '10px 20px 10px 20px'}}>
                            <div className="d-flex justify-content-between align-items-center">
                                <h4 style={{'font-size': '15px'}}>
                                    <a style={{'color': '#000'}}>Rp {object.TotalHargaOrder} ({object.TotalItem} items)</a>
                                </h4>

                                <div>
                                    <a href={'./account-history-order-details?trxid=' + base64_encode(object.KodeTransaksiOrder)} className="btn btn-xs">View Order <i className="fi-rs-arrow-small-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            })

            this.setState({
                pageCountCancel: Math.ceil(sortData.length / this.state.perPage),
                postDataCancel
            })
        })
    };

    handlePageClickFinish = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPageFinish: selectedPage,
            offsetFinish: offset
        }, () => {
            this.getDataFinish()
        });
    };

    handlePageClickCancel = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPageCancel: selectedPage,
            offsetCancel: offset
        }, () => {
            this.getDataCancel()
        });
    };

    handlePageNumClick = (num) => {
        this.setState({
            currentPageFinish: 0,
            offsetFinish: 0,
            currentPageCancel: 0,
            offsetCancel: 0,
            perPage: num,
        }, () => {
            this.getData()
        });
    };

    handleSortByClick = (sortBy) => {
        this.setState({
            currentPageFinish: 0,
            offsetFinish: 0,
            currentPageCancel: 0,
            offsetCancel: 0,
            sortDataValue: sortBy
        }, () => {
            this.getData()
        });
    };

    handleSearch = (e) => {
        e.preventDefault();

        this.setState({
            currentPageFinish: 0,
            offsetFinish: 0,
            currentPageCancel: 0,
            offsetCancel: 0,
        }, () => {
            this.getData()
        });
    };

    render() {
        return (
            <>
                <main className="main pages mb-80">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <a href="./" rel="nofollow"><i className="fi-rs-home mr-5"></i>Beranda</a>
                                <span></span> Akun <span></span> History Order
                            </div>
                        </div>
                    </div>
                    <div className="page-content pt-20">
                        <div className="container">
                            <div className="archive-header-2 text-center">
                                <div className="row">
                                    <div className="col-lg-5 mx-auto">
                                        <div className="sidebar-widget-2 widget_search mb-50">
                                            <div className="search-form">
                                                <form method="post" onSubmit={(e) => {this.handleSearch(e)}}>
                                                    <input type="text" placeholder="Cari riwayat ..." value={this.state.search} onChange={e => this.setState({search: e.target.value})}/>
                                                    <button type="submit"><i className="fi-rs-search"></i></button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-lg-8 mx-auto">
                                    <div className="shop-product-fillter">
                                        <div className="totall-product">
                                            <a href={'./account-history'} className="btn btn-sm">Kembali</a>
                                        </div>
                                        <div className="sort-by-product-area">
                                            <div className="sort-by-cover mr-10">
                                                <div className="sort-by-product-wrap">
                                                    <div className="sort-by">
                                                        <span><i className="fi-rs-apps"></i>Show:</span>
                                                    </div>
                                                    <div className="sort-by-dropdown-wrap">
                                                        <span> {this.state.perPage} <i className="fi-rs-angle-small-down"></i></span>
                                                    </div>
                                                </div>
                                                <div className="sort-by-dropdown">
                                                    <ul>
                                                        {
                                                            this.state.pageNumList.map((object, i) => {
                                                                return <li><a className={(object == this.state.perPage) ? 'active': ''} onClick={() => {this.handlePageNumClick(object)}}>{object}</a></li>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="sort-by-cover">
                                                <div className="sort-by-product-wrap">
                                                    <div className="sort-by">
                                                        <span><i className="fi-rs-apps-sort"></i>Sort by:</span>
                                                    </div>
                                                    <div className="sort-by-dropdown-wrap">
                                                        <span> Featured <i className="fi-rs-angle-small-down"></i></span>
                                                    </div>
                                                </div>
                                                <div className="sort-by-dropdown">
                                                    <ul>
                                                        {
                                                            this.state.sortData.map((object, i) => {
                                                                return <li><a className={(object == this.state.sortDataValue) ? 'active': ''} onClick={() => {this.handleSortByClick(object)}}>{object}</a></li>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="product-tabs section-padding position-relative">
                            <div className="container">
                                <div className="section-title style-2 wow animate__animated animate__fadeIn">
                                    <h3>Riwayat Order</h3>
                                    <ul className="nav nav-tabs links" id="myTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="nav-tab-one" data-bs-toggle="tab"
                                                    data-bs-target="#tab-one" type="button" role="tab"
                                                    aria-controls="tab-one" aria-selected="true">Order Selesai
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="nav-tab-two" data-bs-toggle="tab"
                                                    data-bs-target="#tab-two" type="button" role="tab"
                                                    aria-controls="tab-two" aria-selected="false">Order Dibatalkan
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="tab-one" role="tabpanel"
                                         aria-labelledby="tab-one">
                                        <div className="row vendor-grid">
                                            {this.state.postDataFinish}
                                        </div>

                                        <div className="pagination-area mt-20 mb-20">
                                            <nav aria-label="Page navigation example">
                                                <ReactPaginate
                                                    previousLabel={<i className="fi-rs-arrow-small-left"></i>}
                                                    previousClassName={"page-item"}
                                                    previousLinkClassName={"page-link"}
                                                    nextLabel={<i className="fi-rs-arrow-small-right"></i>}
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={"page-link"}
                                                    breakLabel={"..."}
                                                    breakClassName={"page-link dot"}
                                                    pageCount={this.state.pageCountFinish}
                                                    marginPagesDisplayed={1}
                                                    pageRangeDisplayed={1}
                                                    onPageChange={this.handlePageClickFinish}
                                                    containerClassName={"pagination justify-content-start"}
                                                    activeClassName={"page-item active"}
                                                    activeLinkClassName={"page-link"}
                                                    pageClassName={"page-item"}
                                                    pageLinkClassName={"page-link"}/>
                                            </nav>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="tab-two" role="tabpanel"
                                         aria-labelledby="tab-two">
                                        <div className="row vendor-grid">
                                            {this.state.postDataCancel}
                                        </div>

                                        <div className="pagination-area mt-20 mb-20">
                                            <nav aria-label="Page navigation example">
                                                <ReactPaginate
                                                    previousLabel={<i className="fi-rs-arrow-small-left"></i>}
                                                    previousClassName={"page-item"}
                                                    previousLinkClassName={"page-link"}
                                                    nextLabel={<i className="fi-rs-arrow-small-right"></i>}
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={"page-link"}
                                                    breakLabel={"..."}
                                                    breakClassName={"page-link dot"}
                                                    pageCount={this.state.pageCountCancel}
                                                    marginPagesDisplayed={1}
                                                    pageRangeDisplayed={1}
                                                    onPageChange={this.handlePageClickCancel}
                                                    containerClassName={"pagination justify-content-start"}
                                                    activeClassName={"page-item active"}
                                                    activeLinkClassName={"page-link"}
                                                    pageClassName={"page-item"}
                                                    pageLinkClassName={"page-link"}/>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>
            </>
        );
    }
}

export default HistoryOrder;