import React from "react";
import {ReactSession} from "react-client-session";
import {encode as base64_encode} from "base-64";
import {Helpers} from "../../../config/Helpers";
import Select from 'react-select';

import {Connection} from "../../../config/Connection";
import $ from "jquery";
import {toast} from "react-toastify";

class Account extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            urlActive: window.location.pathname,
            sessMPSupplierId: (ReactSession.get("sess_mp_supplierid")) ? ReactSession.get("sess_mp_supplierid") : '',
            sessMPSupplierStatus: (ReactSession.get("sess_mp_supplierstatus")) ? ReactSession.get("sess_mp_supplierstatus") : '',

            tabActive: 'general',
            dataBank: [],

            locPinPoint: '',
            locDistance: '',
            locDistanceDisplay: '',
            locProvinsi: '',
            locKota: '',
            locKecamatan: '',
            locDesa: '',
            locDetails: '',
            locPostalCode: '',

            supplier_type: '',
            supplier_name: '',
            business_type: '',
            company_name: '',
            company_email: '',
            company_phone: '',
            company_website: '',
            fullname: '',
            position: '',
            phone: '',
            products: '',
            others_products: '',
            description: '',

            doc_ktp: '',
            doc_npwp: '',
            doc_akta_pendirian: '',
            doc_akta_perubahan: '',
            doc_skt: '',
            doc_sppkp: '',
            doc_siup: '',
            doc_tdp: '',
            doc_skd: '',
            doc_nib: '',

            bank_code: '',
            bank_name: '',
            bank_number: ''
        }
    }

    componentDidMount() {
        this.getBank();
    }

    checkSupplier = () => {
        Helpers.getMPSupplier('',this.state.sessMPSupplierId,'Aktif').then(res => {
            $(".loader-wrapper").css("display", "none");
            if(res.length > 0){
                const r = res[0];

                this.setState({
                    locPinPoint: r.pinpoint,
                    locProvinsi: r.province,
                    locKota: r.city,
                    locKecamatan: r.subcity,
                    locDesa: r.district,
                    locDetails: r.address,
                    locPostalCode: r.postal_code,
                    supplier_type: r.supplier_type_name,
                    supplier_name: r.supplier_name,
                    business_type: r.business_type_name,
                    company_name: r.company_name,
                    company_email: r.company_email,
                    company_phone: r.company_phone,
                    company_website: r.company_website,
                    fullname: r.fullname,
                    position: r.position_name,
                    phone: r.phone,
                    products: r.products,
                    others_products: r.others_products,
                    description: r.description,
                    bank_code: r.bank_code,
                    bank_name: r.bank_name,
                    bank_number: r.bank_number,
                    doc_ktp: r.doc_ktp,
                    doc_npwp: r.doc_npwp,
                    doc_akta_pendirian: r.doc_akta_pendirian,
                    doc_akta_perubahan: r.doc_akta_perubahan,
                    doc_skt: r.doc_skt,
                    doc_sppkp: r.doc_sppkp,
                    doc_siup: r.doc_siup,
                    doc_tdp: r.doc_tdp,
                    doc_skd: r.doc_skd,
                    doc_nib: r.doc_nib,
                });
            }
        })
    };

    getBank = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getBank().then(res => {
            if(res){
                this.setState({
                    dataBank: res.map(function(item) {
                        return {
                            value: item.KodeBank,
                            label: item.Fullname
                        };
                    })
                });
            }

            this.checkSupplier();
        })
    };

    handleChange = (event,state) => {
        this.setState({ [state]: event.value });
    };

    handleTab = (state) => {
        this.setState({
            tabActive: state
        });
    };

    handleSubmit = () => {
        $(".loader-wrapper").css("display", "");

        var now = new Date();
        var time_epoch = now.getTime();

        var data_post = new FormData();
        if($("#doc_ktp").get(0) !== undefined){
            var doc_ktp = $("#doc_ktp").get(0).files;
            if (doc_ktp.length > 0) {
                data_post.append("doc_ktp", doc_ktp[0]);
            }
        }
        if($("#doc_npwp").get(0) !== undefined){
            var doc_npwp = $("#doc_npwp").get(0).files;
            if (doc_npwp.length > 0) {
                data_post.append("doc_npwp", doc_npwp[0]);
            }
        }
        if($("#doc_akta_pendirian").get(0) !== undefined){
            var doc_akta_pendirian = $("#doc_akta_pendirian").get(0).files;
            if (doc_akta_pendirian.length > 0) {
                data_post.append("doc_akta_pendirian", doc_akta_pendirian[0]);
            }
        }
        if($("#doc_akta_perubahan").get(0) !== undefined){
            var doc_akta_perubahan = $("#doc_akta_perubahan").get(0).files;
            if (doc_akta_perubahan.length > 0) {
                data_post.append("doc_akta_perubahan", doc_akta_perubahan[0]);
            }
        }
        if($("#doc_skt").get(0) !== undefined){
            var doc_skt = $("#doc_skt").get(0).files;
            if (doc_skt.length > 0) {
                data_post.append("doc_skt", doc_skt[0]);
            }
        }
        if($("#doc_sppkp").get(0) !== undefined){
            var doc_sppkp = $("#doc_sppkp").get(0).files;
            if (doc_sppkp.length > 0) {
                data_post.append("doc_sppkp", doc_sppkp[0]);
            }
        }
        if($("#doc_siup").get(0) !== undefined){
            var doc_siup = $("#doc_siup").get(0).files;
            if (doc_siup.length > 0) {
                data_post.append("doc_siup", doc_siup[0]);
            }
        }
        if($("#doc_tdp").get(0) !== undefined){
            var doc_tdp = $("#doc_tdp").get(0).files;
            if (doc_tdp.length > 0) {
                data_post.append("doc_tdp", doc_tdp[0]);
            }
        }
        if($("#doc_skd").get(0) !== undefined){
            var doc_skd = $("#doc_skd").get(0).files;
            if (doc_skd.length > 0) {
                data_post.append("doc_skd", doc_skd[0]);
            }
        }
        if($("#doc_nib").get(0) !== undefined){
            var doc_nib = $("#doc_nib").get(0).files;
            if (doc_nib.length > 0) {
                data_post.append("doc_nib", doc_nib[0]);
            }
        }

        const param =
            "kode=" + this.state.sessUserCode +
            "&kode_supplier=" + this.state.sessMPSupplierId +
            "&bank_code=" + this.state.bank_code +
            "&bank_name=" + encodeURIComponent(this.state.bank_name) +
            "&bank_number=" + this.state.bank_number +
            "&epoch=" + time_epoch;

        fetch(Connection.API_URL + 'ManageMPSupplierCompletion?' + param, {
            method: 'POST',
            body: data_post
        }).then((res) => res.json()).then((res) => {
            if(res.length > 0){
                if(res[0].alert == 'success'){
                    setTimeout(function() {
                        $(".loader-wrapper").css("display", "none");
                        window.location.href = 'account';
                    }, 2000);
                }

                toast[res[0].alert](res[0].message);
            }else{
                toast.error(Helpers.MSG_ERROR);
            }
        });
    };

    render() {
        return (
            <>
                <section className="content-main">
                    <div className="content-header">
                        <div>
                            <h2 className="content-title card-title">Account</h2>
                            <p>Your account status: <span className="badge rounded-pill alert-success">{(this.state.sessMPSupplierStatus === 'Waiting') ? 'Waiting for verification': this.state.sessMPSupplierStatus}</span></p>
                        </div>
                        {
                            (this.state.sessMPSupplierStatus !== 'Approved') && (
                                <div>
                                    <a className="btn btn-primary btn-sm rounded" onClick={() => {this.handleSubmit()}}>Save Changes</a>
                                </div>
                            )
                        }
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="row gx-5">
                                <aside className="col-lg-3 border-end">
                                    <nav className="nav nav-pills flex-lg-column mb-4">
                                        <a className={(this.state.tabActive === 'general') ? 'nav-link active' : 'nav-link'}
                                           href="javascript:void(0);" onClick={() => {this.handleTab('general')}}>General</a>
                                        <a className={(this.state.tabActive === 'bank') ? 'nav-link active' : 'nav-link'}
                                           href="javascript:void(0);" onClick={() => {this.handleTab('bank')}}>Bank Account</a>
                                        <a className={(this.state.tabActive === 'documents') ? 'nav-link active' : 'nav-link'}
                                           href="javascript:void(0);" onClick={() => {this.handleTab('documents')}}>Documents</a>
                                    </nav>
                                </aside>
                                <div className="col-lg-9">
                                    <section className="content-body p-xl-4">
                                        {
                                            (this.state.tabActive === 'general') && (
                                                <>
                                                    <h5 className="mb-2">Company Information</h5>
                                                    <div className="mb-2">
                                                        <label className="form-label">Supplier Type</label>
                                                        <input type="text" placeholder="Type here" className="form-control" required readOnly={true}
                                                               value={this.state.supplier_type} onChange={e => this.setState({supplier_type: e.target.value})}/>
                                                    </div>
                                                    <div className="mb-2">
                                                        <label className="form-label">Supplier Name</label>
                                                        <input type="text" placeholder="Type here" className="form-control" required readOnly={true}
                                                               value={this.state.supplier_name} onChange={e => this.setState({supplier_name: e.target.value})}/>
                                                    </div>
                                                    <div className="mb-2">
                                                        <label className="form-label">Business Type</label>
                                                        <input type="text" placeholder="Type here" className="form-control" required readOnly={true}
                                                               value={this.state.business_type} onChange={e => this.setState({business_type: e.target.value})}/>
                                                    </div>
                                                    <div className="mb-2">
                                                        <label className="form-label">Company Name</label>
                                                        <input type="text" placeholder="Type here" className="form-control" required readOnly={true}
                                                               value={this.state.company_name} onChange={e => this.setState({company_name: e.target.value})}/>
                                                    </div>
                                                    <div className="mb-2">
                                                        <label className="form-label">Provinsi</label>
                                                        <input type="text" placeholder="Type here" className="form-control" required readOnly={true}
                                                               value={this.state.locProvinsi} onChange={e => this.setState({locProvinsi: e.target.value})}/>
                                                    </div>
                                                    <div className="mb-2">
                                                        <label className="form-label">Kota/Kabupaten</label>
                                                        <input type="text" placeholder="Type here" className="form-control" required readOnly={true}
                                                               value={this.state.locKota} onChange={e => this.setState({locKota: e.target.value})}/>
                                                    </div>
                                                    <div className="mb-2">
                                                        <label className="form-label">Kecamatan</label>
                                                        <input type="text" placeholder="Type here" className="form-control" required readOnly={true}
                                                               value={this.state.locKecamatan} onChange={e => this.setState({locKecamatan: e.target.value})}/>
                                                    </div>
                                                    <div className="mb-2">
                                                        <label className="form-label">Kelurahan</label>
                                                        <input type="text" placeholder="Type here" className="form-control" required readOnly={true}
                                                               value={this.state.locDesa} onChange={e => this.setState({locDesa: e.target.value})}/>
                                                    </div>
                                                    <div className="mb-2 mt-2">
                                                        <label className="form-label">Postal Code</label>
                                                        <input type="text" placeholder="Type here" className="form-control" required readOnly={true}
                                                               value={this.state.locPostalCode} onChange={e => this.setState({locPostalCode: e.target.value})}/>
                                                    </div>
                                                    <div className="mb-2">
                                                        <label className="form-label">Details Address</label>
                                                        <textarea className="form-control" rows="3" required readOnly={true}
                                                                  value={this.state.locDetails} onChange={e => this.setState({locDetails: e.target.value})} />
                                                    </div>
                                                    <div className="mb-2">
                                                        <label className="form-label">Company Email</label>
                                                        <input type="email" placeholder="Type here" className="form-control" required readOnly={true}
                                                               value={this.state.company_email} onChange={e => this.setState({company_email: e.target.value})}/>
                                                    </div>
                                                    <div className="mb-2">
                                                        <label className="form-label">Company Phone Number</label>
                                                        <input type="number" placeholder="Type here" className="form-control" required readOnly={true}
                                                               value={this.state.company_phone} onChange={e => this.setState({company_phone: e.target.value})}/>
                                                    </div>
                                                    <div className="mb-2">
                                                        <label className="form-label">Website (optional)</label>
                                                        <input type="text" placeholder="Type here" className="form-control" readOnly={true}
                                                               value={this.state.company_website} onChange={e => this.setState({company_website: e.target.value})}/>
                                                    </div>

                                                    <h5 className="mb-2 mt-15">Contact Information</h5>
                                                    <div className="mb-2">
                                                        <label className="form-label">Full Name</label>
                                                        <input type="text" placeholder="Type here" className="form-control" required readOnly={true}
                                                               value={this.state.fullname} onChange={e => this.setState({fullname: e.target.value})}/>
                                                    </div>
                                                    <div className="mb-2">
                                                        <label className="form-label">Position in Company</label>
                                                        <input type="text" placeholder="Type here" className="form-control" required readOnly={true}
                                                               value={this.state.position} onChange={e => this.setState({position: e.target.value})}/>
                                                    </div>
                                                    <div className="mb-2">
                                                        <label className="form-label">Mobile Number</label>
                                                        <input type="number" placeholder="Type here" className="form-control" required readOnly={true}
                                                               value={this.state.phone} onChange={e => this.setState({phone: e.target.value})}/>
                                                    </div>

                                                    <h5 className="mb-2 mt-15">What products does your company offer</h5>
                                                    <div className="mb-2">
                                                        <input type="text" placeholder="Type here" className="form-control" required readOnly={true}
                                                               value={this.state.products} onChange={e => this.setState({products: e.target.value})}/>
                                                        <span className="form-check-label">Others</span>
                                                        <input type="text" placeholder="Product/Service Description" className="form-control" readOnly={(this.state.isOther) ? false : true}
                                                               value={this.state.others_products} onChange={e => this.setState({others_products: e.target.value})}/>
                                                    </div>

                                                    <h5 className="mb-2 mt-15">Write a brief description about your business</h5>
                                                    <div className="mb-2">
                                                        <textarea className="form-control" rows="3" required readOnly={true}
                                                                  value={this.state.description} onChange={e => this.setState({description: e.target.value})} />
                                                    </div>
                                                </>
                                            )
                                        }
                                        {
                                            (this.state.tabActive === 'bank') && (
                                                <>
                                                    <h5 className="mb-2">Bank Account</h5>
                                                    <div className="mb-2">
                                                        <label className="form-label">Bank Name</label>
                                                        <Select
                                                            options={this.state.dataBank}
                                                            value={this.state.dataBank.filter((item) => item.value === this.state.bank_code)}
                                                            onChange={event => this.handleChange(event,'bank_code')} />
                                                    </div>
                                                    <div className="mb-2">
                                                        <label className="form-label">Account Number</label>
                                                        <input type="number" placeholder="Type here" className="form-control" required
                                                               value={this.state.bank_number} onChange={e => this.setState({bank_number: e.target.value})}/>
                                                    </div>
                                                    <div className="mb-2">
                                                        <label className="form-label">Account Name</label>
                                                        <input type="text" placeholder="Type here" className="form-control" required
                                                               value={this.state.bank_name} onChange={e => this.setState({bank_name: e.target.value})}/>
                                                    </div>
                                                </>
                                            )
                                        }
                                        {
                                            (this.state.tabActive === 'documents') && (
                                                <>
                                                    <h5 className="mb-2">Documents</h5>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <div className="mb-2">
                                                                <label className="form-label">Kartu Tanda Penduduk</label><br/>
                                                                {
                                                                    this.state.doc_ktp && (
                                                                        <img src={Connection.SVC_URL + this.state.doc_ktp} alt="" width={100}/>
                                                                    )
                                                                }
                                                                {
                                                                    !this.state.doc_ktp && (
                                                                        <img src="/assets_admin/imgs/theme/upload.svg" alt="" width={100}/>
                                                                    )
                                                                }
                                                                <input type="file" name="doc_ktp" id="doc_ktp" className="form-control" accept="image/png, image/jpeg" />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="mb-2">
                                                                <label className="form-label">Nomor Pokok Wajib Pajak</label><br/>
                                                                {
                                                                    this.state.doc_npwp && (
                                                                        <img src={Connection.SVC_URL + this.state.doc_npwp} alt="" width={100}/>
                                                                    )
                                                                }
                                                                {
                                                                    !this.state.doc_npwp && (
                                                                        <img src="/assets_admin/imgs/theme/upload.svg" alt="" width={100}/>
                                                                    )
                                                                }
                                                                <input type="file" name="doc_npwp" id="doc_npwp" className="form-control" accept="image/png, image/jpeg" />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="mb-2">
                                                                <label className="form-label">Akta Pendirian</label><br/>
                                                                {
                                                                    this.state.doc_akta_pendirian && (
                                                                        <img src={Connection.SVC_URL + this.state.doc_akta_pendirian} alt="" width={100}/>
                                                                    )
                                                                }
                                                                {
                                                                    !this.state.doc_akta_pendirian && (
                                                                        <img src="/assets_admin/imgs/theme/upload.svg" alt="" width={100}/>
                                                                    )
                                                                }
                                                                <input type="file" name="doc_akta_pendirian" id="doc_akta_pendirian" className="form-control" accept="image/png, image/jpeg" />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="mb-2">
                                                                <label className="form-label">Akta Perubahan Terakhir</label><br/>
                                                                {
                                                                    this.state.doc_akta_perubahan && (
                                                                        <img src={Connection.SVC_URL + this.state.doc_akta_perubahan} alt="" width={100}/>
                                                                    )
                                                                }
                                                                {
                                                                    !this.state.doc_akta_perubahan && (
                                                                        <img src="/assets_admin/imgs/theme/upload.svg" alt="" width={100}/>
                                                                    )
                                                                }
                                                                <input type="file" name="doc_akta_perubahan" id="doc_akta_perubahan" className="form-control" accept="image/png, image/jpeg" />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="mb-2">
                                                                <label className="form-label">Surat Keterangan Terdaftar</label><br/>
                                                                {
                                                                    this.state.doc_skt && (
                                                                        <img src={Connection.SVC_URL + this.state.doc_skt} alt="" width={100}/>
                                                                    )
                                                                }
                                                                {
                                                                    !this.state.doc_skt && (
                                                                        <img src="/assets_admin/imgs/theme/upload.svg" alt="" width={100}/>
                                                                    )
                                                                }
                                                                <input type="file" name="doc_skt" id="doc_skt" className="form-control" accept="image/png, image/jpeg" />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="mb-2">
                                                                <label className="form-label">Surat Pengukuhan Pengusaha Kena Pajak</label><br/>
                                                                {
                                                                    this.state.doc_sppkp && (
                                                                        <img src={Connection.SVC_URL + this.state.doc_sppkp} alt="" width={100}/>
                                                                    )
                                                                }
                                                                {
                                                                    !this.state.doc_sppkp && (
                                                                        <img src="/assets_admin/imgs/theme/upload.svg" alt="" width={100}/>
                                                                    )
                                                                }
                                                                <input type="file" name="doc_sppkp" id="doc_sppkp" className="form-control" accept="image/png, image/jpeg" />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="mb-2">
                                                                <label className="form-label">Surat Ijin Usaha Perdagangan</label><br/>
                                                                {
                                                                    this.state.doc_siup && (
                                                                        <img src={Connection.SVC_URL + this.state.doc_siup} alt="" width={100}/>
                                                                    )
                                                                }
                                                                {
                                                                    !this.state.doc_siup && (
                                                                        <img src="/assets_admin/imgs/theme/upload.svg" alt="" width={100}/>
                                                                    )
                                                                }
                                                                <input type="file" name="doc_siup" id="doc_siup" className="form-control" accept="image/png, image/jpeg" />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="mb-2">
                                                                <label className="form-label">Tanda Daftar Perusahaan</label><br/>
                                                                {
                                                                    this.state.doc_tdp && (
                                                                        <img src={Connection.SVC_URL + this.state.doc_tdp} alt="" width={100}/>
                                                                    )
                                                                }
                                                                {
                                                                    !this.state.doc_tdp && (
                                                                        <img src="/assets_admin/imgs/theme/upload.svg" alt="" width={100}/>
                                                                    )
                                                                }
                                                                <input type="file" name="doc_tdp" id="doc_tdp" className="form-control" accept="image/png, image/jpeg" />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="mb-2">
                                                                <label className="form-label">Surat Keterangan Domisili</label><br/>
                                                                {
                                                                    this.state.doc_skd && (
                                                                        <img src={Connection.SVC_URL + this.state.doc_skd} alt="" width={100}/>
                                                                    )
                                                                }
                                                                {
                                                                    !this.state.doc_skd && (
                                                                        <img src="/assets_admin/imgs/theme/upload.svg" alt="" width={100}/>
                                                                    )
                                                                }
                                                                <input type="file" name="doc_skd" id="doc_skd" className="form-control" accept="image/png, image/jpeg" />
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className="mb-2">
                                                                <label className="form-label">Nomor Induk Berusaha</label><br/>
                                                                {
                                                                    this.state.doc_nib && (
                                                                        <img src={Connection.SVC_URL + this.state.doc_nib} alt="" width={100}/>
                                                                    )
                                                                }
                                                                {
                                                                    !this.state.doc_nib && (
                                                                        <img src="/assets_admin/imgs/theme/upload.svg" alt="" width={100}/>
                                                                    )
                                                                }
                                                                <input type="file" name="doc_nib" id="doc_nib" className="form-control" accept="image/png, image/jpeg" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Account;