import React from "react";

import queryString from "query-string";
import {isMobile} from "react-device-detect";
import {Helpers} from "../../config/Helpers";
import $ from "jquery";
import Select from 'react-select';
import moment from "moment";
import 'react-html5-camera-photo/build/css/index.css';
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import {encode as base64_encode} from "base-64";
import {toast} from "react-toastify";

class KoinWorksApply extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            urlParam: queryString.parse(window.location.search),
            isModalOpen: false,
            isModalConfirmOpen: false,
            isFrontCamera: false,
            cameraTakeFor: '',
            dataGender: [],
            dataMaritalStatus: [],
            dataReligion: [],
            dataEducation: [],
            dataOtherSourceOfIncome: [],
            dataMyPosition: [],
            dataTerritory: [],
            dataSubDistrict: [],
            dataDistrict: [],
            dataCity: [],
            dataProvince: [],
            dataCountry: [],
            dataHomeOwnershipStatus: [],
            dataRelationshipWithBorrower: [],
            dataType: [],
            dataNumberOfEmployee: [],
            dataCategory: [],
            month1: moment().subtract(3, 'months').format('YYYY-MM'),
            month2: moment().subtract(2, 'months').format('YYYY-MM'),
            month3: moment().subtract(1, 'months').format('YYYY-MM'),
            monthlyRevenues1: 0,
            monthlyRevenues2: 0,
            monthlyRevenues3: 0,
            monthlyTransaction1: 0,
            monthlyTransaction2: 0,
            monthlyTransaction3: 0,
            kodeMarketing: '',
            hasLiveHere: 0,
            firstAccountCreatedAt: moment(new Date()).utc().format('YYYY-MM-DD'),
            ktpImage: '',
            ktpWithSelfieImage: ''
        }
    }

    componentDidMount() {
        window.location.href = './404';
        /*this.setState({
            kodeMarketing: (this.state.urlParam.kode) ? this.state.urlParam.kode : '',
        }, () => {
            if(!this.state.kodeMarketing){
                window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
            }else{
                if(isMobile) {
                    $("#mobile-header").css("display", "none");
                    $("#mobile-footer").css("display", "none");
                }

                this.prepareData();
                this.koinWorksGetListRevenue();
                this.koinWorksGetListTransaction();
            }
        });*/
    }

    koinWorksGetListRevenue = () => {
        Helpers.koinWorksGetListRevenue(this.state.kodeMarketing).then(res => {
            if(res.length > 0){
                this.setState({
                    monthlyRevenues1: (typeof res[0] !== 'undefined') ? res[0].total : 0,
                    monthlyRevenues2: (typeof res[1] !== 'undefined') ? res[1].total : 0,
                    monthlyRevenues3: (typeof res[2] !== 'undefined') ? res[2].total : 0
                });
            }
        })
    };

    koinWorksGetListTransaction = () => {
        Helpers.koinWorksGetListTransaction(this.state.kodeMarketing).then(res => {
            if(res.length > 0){
                this.setState({
                    monthlyTransaction1: (typeof res[0] !== 'undefined') ? res[0].total : 0,
                    monthlyTransaction2: (typeof res[1] !== 'undefined') ? res[1].total : 0,
                    monthlyTransaction3: (typeof res[2] !== 'undefined') ? res[2].total : 0
                });
            }
        })
    };

    prepareData = () => {
        this.getMasterData('Master_KoinWorks_Gender','','dataGender','id','name');
        this.getMasterData('Master_KoinWorks_MaritalStatus','','dataMaritalStatus','id','name');
        this.getMasterData('Master_KoinWorks_Religion','','dataReligion','id','name');
        this.getMasterData('Master_KoinWorks_Education','','dataEducation','id','name');
        this.getMasterData('Master_KoinWorks_OtherSourceIncome','','dataOtherSourceOfIncome','id','name');
        this.getMasterData('Master_KoinWorks_Position','','dataMyPosition','id','name');
        this.getMasterData('Master_Wilayah_Indonesia','','dataTerritory','kode','name','jenis');
        this.getMasterData('Master_Wilayah_Negara','','dataCountry','id','name');
        this.getMasterData('Master_KoinWorks_HomeOwnershipStatus','','dataHomeOwnershipStatus','id','name');
        this.getMasterData('Master_KoinWorks_RelationshipWithBorrower','','dataRelationshipWithBorrower','id','name');
        this.getMasterData('Master_KoinWorks_BusinessType','','dataType','id','name');
        this.getMasterData('Master_KoinWorks_NumberOfEmployee','','dataNumberOfEmployee','id','name');
        this.getMasterData('Master_KoinWorks_Category','','dataCategory','id','name');
    }

    getMasterData = (table,id,state,value,label,additional) => {
        Helpers.getMasterData(table,id).then(res => {
            if(res){
                this.setState({
                    [state]: res.map(function(item) {
                        return {
                            additional: (additional) ? item[additional] : null,
                            value: item[value],
                            label: (label instanceof Array) ? label.map(function(val, index) {
                                let setVal = (val.includes("|")) ? item[val.split("|")[0]][val.split("|")[1]].trim() : item[val].trim();
                                return (
                                    index === label.length-1
                                ) ? setVal : setVal + ' - ';
                            }) : item[label].trim()
                        };
                    })
                }, () => {
                    if(table === 'Master_Wilayah_Indonesia'){
                        this.setState({
                            dataProvince: this.state[state].filter(item => {
                                return item.additional === 'provinsi';
                            }),
                        });
                    }
                });
            }
        })
    };

    handleChange = (event,state) => {
        this.setState({ [state]: event.value, [state+'Label']: event.label });

        if(state === 'province'){
            this.setState({
                dataCity: this.state.dataTerritory.filter(item => {
                    return item.additional === 'kota' && item.value.substring(0,2) === event.value;
                }),
            });
        }else if(state === 'city'){
            this.setState({
                dataDistrict: this.state.dataTerritory.filter(item => {
                    return item.additional === 'kecamatan' && item.value.substring(0,5) === event.value;
                }),
            });
        }
    };

    toggleCheckbox = (state) => {
        this.setState({ [state]: (this.state[state] === 0) ? 1 : 0 });
    };

    handleModal = (usedFor) => {
        this.setState({ isModalOpen: !this.state.isModalOpen, cameraTakeFor: usedFor });
    };

    handleModalConfirm = () => {
        this.setState({ isModalConfirmOpen: !this.state.isModalConfirmOpen });
    };

    handleRotateCamera = () => {
        this.setState({ isFrontCamera: !this.state.isFrontCamera });
    };

    handleTakePhoto = (dataUri) => {
        let cameraTakeFor = this.state.cameraTakeFor;
        this.handleModal('');

        const name = this.state.kodeMarketing + '_' + moment().unix() + '.jpg';

        if(dataUri){
            Helpers.firebaseUploadFile(dataUri,name,this.state.cameraTakeFor).then(res => {
                if(cameraTakeFor === 'ktpImage'){
                    $("#image-ktp").attr('src', dataUri);
                    this.setState({ ktpImage: res });
                }else if(cameraTakeFor === 'ktpWithSelfieImage'){
                    $("#image-selfie").attr('src', dataUri);
                    this.setState({ ktpWithSelfieImage: res });
                }
            });
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();

        if(this.state.ktpImage && this.state.ktpWithSelfieImage){
            this.handleModalConfirm();
        }else{
            toast.error('Anda belum melampirkan foto KTP atau foto selfie dengan KTP');
        }
    };

    handleConfirm = () => {
        this.handleModalConfirm();

        let setObj = {
                limit: 50000000,
                firstAccountCreatedAt: this.state.firstAccountCreatedAt,
                monthlyRevenues: {
                    [parseInt(this.state.month1.replace('-', ''))]: parseInt(this.state.monthlyRevenues1),
                    [parseInt(this.state.month2.replace('-', ''))]: parseInt(this.state.monthlyRevenues2),
                    [parseInt(this.state.month3.replace('-', ''))]: parseInt(this.state.monthlyRevenues3)
                },
                monthlyTransactions: {
                    [parseInt(this.state.month1.replace('-', ''))]: parseInt(this.state.monthlyTransaction1),
                    [parseInt(this.state.month2.replace('-', ''))]: parseInt(this.state.monthlyTransaction2),
                    [parseInt(this.state.month3.replace('-', ''))]: parseInt(this.state.monthlyTransaction3)
                },
                personal: {
                    fullname: this.state.fullname,
                    ktpNIK: this.state.ktpNIK,
                    ktpImage: this.state.ktpImage,
                    ktpWithSelfieImage: this.state.ktpWithSelfieImage,
                    familyCardImage: "",
                    gender: this.state.genderLabel,
                    placeOfBirth: this.state.placeOfBirth,
                    dateOfBirth: this.state.dateOfBirth,
                    maritalStatus: this.state.maritalStatusLabel,
                    religion: this.state.religionLabel,
                    education: this.state.educationLabel,
                    ocupation: this.state.ocupation,
                    totalOtherIncome: "",
                    otherSourceOfIncome: "",
                    netSalary: parseInt(this.state.netSalary),
                    myPosition: this.state.myPositionLabel,
                    address: {
                        address: this.state.address,
                        subDistrict: this.state.subDistrict,
                        district: this.state.districtLabel,
                        city: this.state.cityLabel,
                        province: this.state.provinceLabel,
                        country: this.state.countryLabel,
                        postalCode: this.state.postalCode,
                        hasLivedHere: (this.state.hasLiveHere) ? true : false,
                        homeOwnershipStatus: this.state.homeOwnershipStatusLabel,
                        durationOfStay: this.state.durationOfStay
                    },
                    spouse: {
                        ktpNIK: "",
                        ktpImage: "",
                        fullName: "",
                        email: "",
                        phoneArea: "",
                        phoneNumber: ""
                    }
                },
                business: {
                    type: this.state.typeLabel,
                    name: this.state.name,
                    description: this.state.description,
                    website: "",
                    email: this.state.email,
                    phoneArea: this.state.phoneAreaBusiness,
                    phoneNumber: this.state.phoneNumberBusiness,
                    ownerFullname: this.state.ownerFullname,
                    npwpImage: "",
                    licenseImage: "",
                    averageSales: parseInt(this.state.averageSales),
                    establisedIn: this.state.establisedIn,
                    numberOfEmployee: this.state.numberOfEmployeeLabel,
                    bankStatementImage: "",
                    myPosition: "",
                    ownershipPercentage: 0,
                    relationshipWithBorrower: "",
                    siupNumber: "",
                    category: this.state.categoryLabel,
                    address: {
                        address: this.state.addressBusiness,
                        subDistrict: this.state.subDistrictBusiness,
                        district: this.state.districtBusiness,
                        city: "",
                        province: "",
                        country: "",
                        postalCode: this.state.postalCodeBusiness,
                        hasLivedHere: false,
                        homeOwnershipStatus: "",
                        durationOfStay: ""
                    }
                }
            }
        ;

        /*let setObj = {
            limit: 50000000,
            personal: {
                fullname: this.state.fullname,
                ktpNIK: this.state.ktpNIK,
                ktpImage: this.state.ktpImage,
                ktpWithSelfieImage: this.state.ktpWithSelfieImage,
                gender: this.state.genderLabel,
                placeOfBirth: this.state.placeOfBirth,
                dateOfBirth: this.state.dateOfBirth,
                maritalStatus: this.state.maritalStatusLabel,
                religion: this.state.religionLabel,
                education: this.state.educationLabel,
                ocupation: this.state.ocupation,
                //totalOtherIncome: this.state.totalOtherIncome,
                //otherSourceOfIncome: this.state.otherSourceOfIncome,
                netSalary: parseInt(this.state.netSalary),
                myPosition: this.state.myPositionLabel,
                address: {
                    address: this.state.address,
                    postalCode:this.state.postalCode,
                    subDistrict: this.state.subDistrict,
                    district: this.state.districtLabel,
                    city: this.state.cityLabel,
                    province: this.state.provinceLabel,
                    country: this.state.countryLabel,
                    hasLiveHere: (this.state.hasLiveHere) ? true : false,
                    homeOwnershipStatus: this.state.homeOwnershipStatusLabel,
                    durationOfStay: this.state.durationOfStay,
                },
                spouse: {
                    relationshipWithBorrower: this.state.relationshipWithBorrowerLabel,
                    KtpImage: this.state.KtpImage,
                    phoneArea: this.state.phoneArea,
                    phoneNumber: this.state.phoneNumber,
                },
            },
            business: {
                type: this.state.typeLabel,
                name: this.state.name,
                description: this.state.description,
                //website: this.state.website,
                email: this.state.email,
                phoneArea: this.state.phoneArea,
                phoneNumber: this.state.phoneNumber,
                ownerFullname: this.state.ownerFullname,
                //npwpImage: this.state.npwpImage,
                averageSales: parseInt(this.state.averageSales),
                establisedIn: this.state.establisedIn,
                numberOfEmployee: this.state.numberOfEmployeeLabel,
                //bankStatementImage: this.state.bankStatementImage,
                ownershipPercentage: parseInt(this.state.ownershipPercentage),
                category: this.state.categoryLabel,
                address: {
                    address: this.state.addressBusiness,
                    postalCode: this.state.postalCodeBusiness,
                    subDistrict: this.state.subDistrictBusiness,
                    district: this.state.districtBusiness,
                },
            },
            firstAccountCreatedAt: this.state.firstAccountCreatedAt,
            monthlyRevenues: {
                [parseInt(this.state.month1.replace('-',''))]: parseInt(this.state.monthlyRevenues1),
                [parseInt(this.state.month2.replace('-',''))]: parseInt(this.state.monthlyRevenues2),
                [parseInt(this.state.month3.replace('-',''))]: parseInt(this.state.monthlyRevenues3),
            },
        };*/

        $("#preloader-active-x").css("display", "");
        Helpers.koinWorksRequestLimit(
            this.state.kodeMarketing,
            JSON.stringify(setObj)
        ).then(res => {
            if(res.length > 0){
                if(res[0].alert == 'success'){
                    setTimeout(() => {
                        //window.location.href = './koinworks-apply-otp?kode=' + this.state.kodeMarketing;
                        window.location.href = './response-page';
                    }, 1000);
                }

                toast[res[0].alert](res[0].message);
            }else{
                toast.error(Helpers.MSG_ERROR);
            }

            $("#preloader-active-x").css("display", "none");
        })
    }

    render() {
        return (
            <>

                <Modal
                    size={'lg'}
                    isOpen={this.state.isModalOpen}
                    toggle={() => {this.handleModal('')}}>
                    <ModalBody style={{'padding': 'unset'}}>
                        <Camera
                            imageType = {IMAGE_TYPES.JPG}
                            idealFacingMode = {(this.state.isFrontCamera) ? FACING_MODES.USER : FACING_MODES.ENVIRONMENT}
                            onTakePhoto = { (dataUri) => { this.handleTakePhoto(dataUri); } }
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => {this.handleRotateCamera()}}>
                            Rotate Camera
                        </Button>
                        <Button onClick={() => {this.handleModal('')}}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>

                <Modal
                    size={'lg'}
                    isOpen={this.state.isModalConfirmOpen}
                    toggle={() => {this.handleModalConfirm()}}>
                    <ModalBody>
                        <h5>Do you want to submit this data?</h5>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => {this.handleConfirm()}}>
                            Submit
                        </Button>
                        <Button onClick={() => {this.handleModalConfirm()}}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>

                <main className="main pages mb-80" style={{'display': 'none'}}>
                    <div className="page-content pt-10">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 m-auto">
                                    <div className="login_wrap widget-taber-content background-white">
                                        <div className="padding_eight_all bg-white">
                                            <form method="post" onSubmit={(e) => {this.handleSubmit(e)}}>
                                                <div className="heading_s1 mb-20">
                                                    <h5 className="mb-10">Data Personal</h5>
                                                    <div className="divider-text-center"></div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Full Name</label>
                                                    <input required type="text" value={this.state.fullname} onChange={e => this.setState({fullname: e.target.value})}
                                                           className="form-input-custom"
                                                           placeholder="e.g. John Doe"/>
                                                </div>
                                                <div className="form-group">
                                                    <label>NIK</label>
                                                    <input required type="number" value={this.state.ktpNIK} onChange={e => this.setState({ktpNIK: e.target.value})}
                                                           className="form-input-custom"
                                                           placeholder="e.g. 1111111111111111"/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Image KTP</label>
                                                    <img id="image-ktp" src={'./assets/imgs/banner/takeimage.jpg'} onClick={() => {this.handleModal('ktpImage')}} />
                                                </div>
                                                <div className="form-group">
                                                    <label>Selfie With KTP</label>
                                                    <img id="image-selfie" src={'./assets/imgs/banner/takeimage.jpg'} onClick={() => {this.handleModal('ktpWithSelfieImage')}} />
                                                </div>
                                                <div className="form-group">
                                                    <label>Gender</label>
                                                    <Select
                                                        options={this.state.dataGender}
                                                        value={this.state.dataGender.filter((item) => item.value === this.state.gender)}
                                                        onChange={event => this.handleChange(event,'gender')} />
                                                </div>
                                                <div className="form-group">
                                                    <label>Place Of Birth</label>
                                                    <input required type="text" value={this.state.placeOfBirth} onChange={e => this.setState({placeOfBirth: e.target.value})}
                                                           className="form-input-custom"
                                                           placeholder="e.g. Jakarta"/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Date Of Birth</label>
                                                    <input required type="date" value={this.state.dateOfBirth} onChange={e => this.setState({dateOfBirth: e.target.value})}
                                                           className="form-input-custom"
                                                           placeholder="Choose date"/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Marital Status</label>
                                                    <Select
                                                        options={this.state.dataMaritalStatus}
                                                        value={this.state.dataMaritalStatus.filter((item) => item.value === this.state.maritalStatus)}
                                                        onChange={event => this.handleChange(event,'maritalStatus')} />
                                                </div>
                                                <div className="form-group">
                                                    <label>Religion</label>
                                                    <Select
                                                        options={this.state.dataReligion}
                                                        value={this.state.dataReligion.filter((item) => item.value === this.state.religion)}
                                                        onChange={event => this.handleChange(event,'religion')} />
                                                </div>
                                                <div className="form-group">
                                                    <label>Education</label>
                                                    <Select
                                                        options={this.state.dataEducation}
                                                        value={this.state.dataEducation.filter((item) => item.value === this.state.education)}
                                                        onChange={event => this.handleChange(event,'education')} />
                                                </div>
                                                <div className="form-group">
                                                    <label>Ocupation</label>
                                                    <input required type="text" value={this.state.ocupation} onChange={e => this.setState({ocupation: e.target.value})}
                                                           className="form-input-custom"
                                                           placeholder="e.g. Karyawan Swasta"/>
                                                </div>
                                                {/*<div className="form-group">*/}
                                                    {/*<label>Total Other Income (optional)</label>*/}
                                                    {/*<input type="number" value={this.state.totalOtherIncome} onChange={e => this.setState({totalOtherIncome: e.target.value})}*/}
                                                           {/*className="form-input-custom"*/}
                                                           {/*placeholder="Total Other Income *"/>*/}
                                                {/*</div>*/}
                                                {/*<div className="form-group">*/}
                                                    {/*<label>Other Source Of Income (optional)</label>*/}
                                                    {/*<Select*/}
                                                        {/*options={this.state.dataOtherSourceOfIncome}*/}
                                                        {/*value={this.state.dataOtherSourceOfIncome.filter((item) => item.value === this.state.otherSourceOfIncome)}*/}
                                                        {/*onChange={event => this.handleChange(event,'otherSourceOfIncome')} />*/}
                                                {/*</div>*/}
                                                <div className="form-group">
                                                    <label>Net Salary</label>
                                                    <input required type="number" value={this.state.netSalary} onChange={e => this.setState({netSalary: e.target.value})}
                                                           className="form-input-custom"
                                                           placeholder="e.g. 1000000"/>
                                                </div>
                                                <div className="form-group">
                                                    <label>My Position</label>
                                                    <Select
                                                        options={this.state.dataMyPosition}
                                                        value={this.state.dataMyPosition.filter((item) => item.value === this.state.myPosition)}
                                                        onChange={event => this.handleChange(event,'myPosition')} />
                                                </div>
                                                <div className="heading_s1 mb-20">
                                                    <h5 className="mb-10">Data Residence</h5>
                                                    <div className="divider-text-center"></div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Address</label>
                                                    <textarea className="form-control" rows="3" value={this.state.address} onChange={e => this.setState({address: e.target.value})}></textarea>
                                                </div>

                                                <div className="form-group">
                                                    <label>Country</label>
                                                    <Select
                                                        options={this.state.dataCountry}
                                                        value={this.state.dataCountry.filter((item) => item.value === this.state.country)}
                                                        onChange={event => this.handleChange(event,'country')} />
                                                </div>
                                                <div className="form-group">
                                                    <label>Province</label>
                                                    <Select
                                                        options={this.state.dataProvince}
                                                        value={this.state.dataProvince.filter((item) => item.value === this.state.province)}
                                                        onChange={event => this.handleChange(event,'province')} />
                                                </div>
                                                <div className="form-group">
                                                    <label>City</label>
                                                    <Select
                                                        options={this.state.dataCity}
                                                        value={this.state.dataCity.filter((item) => item.value === this.state.city)}
                                                        onChange={event => this.handleChange(event,'city')} />
                                                </div>
                                                <div className="form-group">
                                                    <label>District</label>
                                                    <Select
                                                        options={this.state.dataDistrict}
                                                        value={this.state.dataDistrict.filter((item) => item.value === this.state.district)}
                                                        onChange={event => this.handleChange(event,'district')} />
                                                </div>
                                                <div className="form-group">
                                                    <label>Sub District</label>
                                                    <input required type="text" value={this.state.subDistrict} onChange={e => this.setState({subDistrict: e.target.value})}
                                                           className="form-input-custom"
                                                           placeholder="e.g. Karet Kuningan"/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Postal Code</label>
                                                    <input required type="number" value={this.state.postalCode} onChange={e => this.setState({postalCode: e.target.value})}
                                                           classN
                                                           ame="form-input-custom"
                                                           placeholder="e.g. 12345"/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Has Live Here</label>
                                                    <div>
                                                        <input type="checkbox"
                                                               checked={(this.state.hasLiveHere === 1) ? true : false}
                                                               onClick={() => {this.toggleCheckbox('hasLiveHere')}}
                                                               className="checkbox-custom"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Home Ownership Status</label>
                                                    <Select
                                                        options={this.state.dataHomeOwnershipStatus}
                                                        value={this.state.dataHomeOwnershipStatus.filter((item) => item.value === this.state.homeOwnershipStatus)}
                                                        onChange={event => this.handleChange(event,'homeOwnershipStatus')} />
                                                </div>
                                                <div className="form-group">
                                                    <label>Duration Of Stay (year)</label>
                                                    <input required type="number" value={this.state.durationOfStay} onChange={e => this.setState({durationOfStay: e.target.value})}
                                                    className="form-input-custom"
                                                    placeholder="e.g. 3"/>
                                                </div>
                                                {/*<div className="heading_s1 mb-20">*/}
                                                    {/*<h5 className="mb-10">Data Spouse</h5>*/}
                                                    {/*<div className="divider-text-center"></div>*/}
                                                {/*</div>*/}
                                                {/*<div className="form-group">*/}
                                                    {/*<label>Duration Of Stay (year)</label>*/}
                                                    {/*<input required type="number" value={this.state.durationOfStay} onChange={e => this.setState({durationOfStay: e.target.value})}*/}
                                                           {/*className="form-input-custom"*/}
                                                           {/*placeholder="e.g. 3"/>*/}
                                                {/*</div>*/}
                                                {/*<div className="form-group">*/}
                                                    {/*<label>Relationship With Borrower</label>*/}
                                                    {/*<Select*/}
                                                        {/*options={this.state.dataRelationshipWithBorrower}*/}
                                                        {/*value={this.state.dataRelationshipWithBorrower.filter((item) => item.value === this.state.relationshipWithBorrower)}*/}
                                                        {/*onChange={event => this.handleChange(event,'relationshipWithBorrower')} />*/}
                                                {/*</div>*/}
                                                {/*<div className="form-group">*/}
                                                    {/*<label>KTP Spouse</label>*/}
                                                    {/*<input required type="text" value={this.state.KtpImageSpouse} onChange={e => this.setState({KtpImageSpouse: e.target.value})}*/}
                                                           {/*className="form-input-custom"*/}
                                                           {/*placeholder="e.g. 1111111111111111"/>*/}
                                                {/*</div>*/}
                                                {/*<div className="form-group">*/}
                                                    {/*<label>Phone Area</label>*/}
                                                    {/*<input required type="number" value={this.state.phoneArea} onChange={e => this.setState({phoneArea: e.target.value})}*/}
                                                           {/*className="form-input-custom"*/}
                                                           {/*placeholder="e.g. 62"/>*/}
                                                {/*</div>*/}
                                                {/*<div className="form-group">*/}
                                                    {/*<label>Phone Number</label>*/}
                                                    {/*<input required type="number" value={this.state.phoneNumber} onChange={e => this.setState({phoneNumber: e.target.value})}*/}
                                                           {/*className="form-input-custom"*/}
                                                           {/*placeholder="e.g. 81234567890"/>*/}
                                                {/*</div>*/}
                                                <div className="heading_s1 mb-20">
                                                    <h5 className="mb-10">Data Business</h5>
                                                    <div className="divider-text-center"></div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Type</label>
                                                    <Select
                                                        options={this.state.dataType}
                                                        value={this.state.dataType.filter((item) => item.value === this.state.type)}
                                                        onChange={event => this.handleChange(event,'type')} />
                                                </div>
                                                <div className="form-group">
                                                    <label>Name</label>
                                                    <input required type="text" value={this.state.name} onChange={e => this.setState({name: e.target.value})}
                                                           className="form-input-custom"
                                                           placeholder="e.g. Warung Setiabudi"/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Description</label>
                                                    <input required type="text" value={this.state.description} onChange={e => this.setState({description: e.target.value})}
                                                           className="form-input-custom"
                                                           placeholder="Business Description"/>
                                                </div>
                                                {/*<div className="form-group">*/}
                                                    {/*<label>Website (optional)</label>*/}
                                                    {/*<input type="text" value={this.state.website} onChange={e => this.setState({website: e.target.value})}*/}
                                                           {/*className="form-input-custom"*/}
                                                           {/*placeholder="Website *"/>*/}
                                                {/*</div>*/}
                                                <div className="form-group">
                                                    <label>Email</label>
                                                    <input required type="email" value={this.state.email} onChange={e => this.setState({email: e.target.value})}
                                                           className="form-input-custom"
                                                           placeholder="e.g. johndoe@example.com"/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Phone Area</label>
                                                    <input required type="number" value={this.state.phoneAreaBusiness} onChange={e => this.setState({phoneAreaBusiness: e.target.value})}
                                                           className="form-input-custom"
                                                           placeholder="e.g. 62"/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Phone Number</label>
                                                    <input required type="number" value={this.state.phoneNumberBusiness} onChange={e => this.setState({phoneNumberBusiness: e.target.value})}
                                                           className="form-input-custom"
                                                           placeholder="e.g. 81234567890"/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Owner Fullname</label>
                                                    <input required type="text" value={this.state.ownerFullname} onChange={e => this.setState({ownerFullname: e.target.value})}
                                                           className="form-input-custom"
                                                           placeholder="e.g. John Doe"/>
                                                </div>
                                                {/*<div className="form-group">*/}
                                                    {/*<label>NPWP Image (optional)</label>*/}
                                                    {/*<input type="text" value={this.state.npwpImage} onChange={e => this.setState({npwpImage: e.target.value})}*/}
                                                           {/*className="form-input-custom"*/}
                                                           {/*placeholder="NPWP Image *"/>*/}
                                                {/*</div>*/}
                                                <div className="form-group">
                                                    <label>Average Sales</label>
                                                    <input required type="number" value={this.state.averageSales} onChange={e => this.setState({averageSales: e.target.value})}
                                                           className="form-input-custom"
                                                           placeholder="e.g. 1000000"/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Establised In</label>
                                                    <input required type="date" value={this.state.establisedIn} onChange={e => this.setState({establisedIn: e.target.value})}
                                                           className="form-input-custom"
                                                           placeholder="Choose date"/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Number Of Employee</label>
                                                    <Select
                                                        options={this.state.dataNumberOfEmployee}
                                                        value={this.state.dataNumberOfEmployee.filter((item) => item.value === this.state.numberOfEmployee)}
                                                        onChange={event => this.handleChange(event,'numberOfEmployee')} />
                                                </div>
                                                {/*<div className="form-group">*/}
                                                    {/*<label>Bank Statement Image</label>*/}
                                                    {/*<input required type="text" value={this.state.bankStatementImage} onChange={e => this.setState({bankStatementImage: e.target.value})}*/}
                                                           {/*className="form-input-custom"*/}
                                                           {/*placeholder="Bank Statement Image *"/>*/}
                                                {/*</div>*/}
                                                {/*<div className="form-group">*/}
                                                    {/*<label>Ownership Percentage</label>*/}
                                                    {/*<input required type="number" value={this.state.ownershipPercentage} onChange={e => this.setState({ownershipPercentage: e.target.value})}*/}
                                                           {/*className="form-input-custom"*/}
                                                           {/*placeholder="e.g. 100"/>*/}
                                                {/*</div>*/}
                                                <div className="form-group">
                                                    <label>Category</label>
                                                    <Select
                                                        options={this.state.dataCategory}
                                                        value={this.state.dataCategory.filter((item) => item.value === this.state.category)}
                                                        onChange={event => this.handleChange(event,'category')} />
                                                </div>
                                                <div className="form-group">
                                                    <label>Address</label>
                                                    <textarea className="form-control" rows="3" value={this.state.addressBusiness} onChange={e => this.setState({addressBusiness: e.target.value})}></textarea>
                                                </div>
                                                <div className="form-group">
                                                    <label>District</label>
                                                    <input required type="text" value={this.state.districtBusiness} onChange={e => this.setState({districtBusiness: e.target.value})}
                                                           className="form-input-custom"
                                                           placeholder="e.g. Setiabudi"/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Sub District</label>
                                                    <input required type="text" value={this.state.subDistrictBusiness} onChange={e => this.setState({subDistrictBusiness: e.target.value})}
                                                           className="form-input-custom"
                                                           placeholder="e.g. Karet Kuningan"/>
                                                </div>
                                                <div className="form-group">
                                                    <label>Postal Code</label>
                                                    <input required type="number" value={this.state.postalCodeBusiness} onChange={e => this.setState({postalCodeBusiness: e.target.value})}
                                                           className="form-input-custom"
                                                           placeholder="e.g. 12345"/>
                                                </div>
                                                <div className="form-group" style={{'display': 'none'}}>
                                                    <label>Monthly Revenues Last 3 Months</label>
                                                    <input required type="number" value={this.state.monthlyRevenues1} onChange={e => this.setState({monthlyRevenues1: e.target.value})}
                                                           className="form-input-custom mb-2"
                                                           placeholder={'Revenue for month '+this.state.month1} />
                                                    <input required type="number" value={this.state.monthlyRevenues2} onChange={e => this.setState({monthlyRevenues2: e.target.value})}
                                                           className="form-input-custom mb-2"
                                                           placeholder={'Revenue for month '+this.state.month2}/>
                                                    <input required type="number" value={this.state.monthlyRevenues3} onChange={e => this.setState({monthlyRevenues3: e.target.value})}
                                                           className="form-input-custom mb-2"
                                                           placeholder={'Revenue for month '+this.state.month3}/>
                                                </div>
                                                <div className="form-group">
                                                    <button type="submit"
                                                            className="btn btn-heading btn-block hover-up"
                                                            name="login">Submit
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default KoinWorksApply;