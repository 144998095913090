import React from "react";
import {Connection} from "../config/Connection";

class About extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (
            <>
                <main className="main pages">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <a href="./" rel="nofollow"><i className="fi-rs-home mr-5"></i> Beranda</a>
                                <span></span> Tentang Waroq
                            </div>
                        </div>
                    </div>
                    <section className="hero-3 position-relative align-items" style={{backgroundImage: `url(https://waroq.co.id/WRQBACKEND/Images/default/back4.png)`, height: '570px'}}>
                        <h2 className="mb-30 text-center" style={{color: 'white'}}>TENTANG WAROQ</h2>
                        <div className="row" style={{'--bs-gutter-x': 'unset'}}>
                            <div className="col-xl-10 col-lg-12 m-auto">
                                <div className="row">
                                    <div className="col-sm-6 mb-15" align="center">
                                        <h3 className="mb-15" style={{color: 'white'}}>VISI</h3>
                                        <p className="pr-15 pl-15" align="justify" style={{color: 'white'}}>
                                            Warung kelontong adalah salah satu usaha yang banyak dimiliki masyarakat indonesia sehingga perlu dijaga dan ditingkatkan eksistensinya. WAROQ akan membawa perubahan ekonomi masyarakat indonesia dengan memberdayakan lahan usaha skala kecil bersaing sehat dengan pasar modal
                                        </p>
                                    </div>
                                    <div className="col-sm-6 mb-15" align="center">
                                        <h3 className="mb-15" style={{color: 'white'}}>MISI</h3>
                                        <p className="pr-15 pl-15" align="justify" style={{color: 'white'}}>
                                            WAROQ memberikan edukasi akan inovasi dan teknologi modern, serta mengembangkan bisnis warung kelontong agar mampu bersaing dengan pasar modern yang sudah ada.
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                    <div className="page-content pt-50">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-10 col-lg-12 m-auto">
                                    <section className="row mb-75">
                                        <div className="col-lg-6">
                                            <img src={Connection.IMG_SRC_BACKEND + 'default/paari.jpg'} alt=""
                                                 className="border-radius-15 mb-md-3 mb-lg-0 mb-sm-4"/>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="pl-25">
                                                <h2 className="mb-30">FOUNDER</h2>
                                                <h5 className="mb-15">ARY SISWANTO</h5>
                                                <p className="mb-15" align="justify">Sebagai Founder Waroq (Warung Baroqah), Ary Siswanto yang lahir di Kota Surabaya, 14 April 1978 ini pernah mengenyam ilmu di Sekolah Tinggi Ilmu Bahasa Asing Satya Widya, Surabaya. Dengan latar belakang keluarga yang berasal dari kalangan pahlawan Indonesia yaitu Bapak Kasmin, dimana beliau sebagai salah satu anggota TNI Angkatan Laut Republik Indonesia yang sudah berjuang untuk Indonesia dan di anugerahi Piagam Bintang Gerilya oleh Presiden RI pertama yaitu Ir. Soekarno, telah menumbuhkan semangat nasionalisme yang tinggi di dalam keluarga dan lingkungannya.</p>
                                                <p className="mb-15" align="justify">Walaupun lahir dari keluarga pahlawan, tidak serta merta membuat kehidupan Ary Siswanto dan keluarganya hidup berkecukupan. Sejak ditinggal oleh Ayahnya, sang Ibu harus berusaha untuk menghidupi empat anaknya dengan membuka warung kelontong. Dari usaha warung tersebut itulah Ary dan keluarga mampu bertahan hidup sehingga ia menjadi sangat berempati kepada setiap orang yang hidupnya berkekurangan.</p>
                                                <p className="mb-15" align="justify">Pembelajaran hidup yang dirasakan membuat Ary Siswanto tergerak untuk membawa sebuah perubahan besar bagi seluruh masyarakat Indonesia khususnya masyarakat dengan ekonomi menengah kebawah yang masih berkelut dengan warung kelontong. Sebagai Founder Waroq (Warung Baroqah), Ary Siswanto terpanggil untuk mensejahterakan para pemilik warung kelontong yang semakin terpuruk adanya persaingan antara warung modern atau minimarket.</p>
                                                <p className="mb-50" align="justify">Tidak hanya berhenti pada Waroq saja, Ary Siswanto juga aktif dalam filantropis dan kegiatan sosial seperti membantu para korban bencana alam. Selain itu ia juga memimpin sebuah usaha yang bergerak di bidang financial yaitu BBX Koperasi Primer dimana saat ini sudah memiliki anggota sebanyak 1.250 orang dan semakin bertambah setiap harinya.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="row about-count mb-50" style={{backgroundImage: `url(https://waroq.co.id/WRQBACKEND/Images/default/bg1.png)`}}>
                                                <center>
                                                    <h3 className="mb-30" style={{color: 'white'}}>BAGIAN DARI KELUARGA PAHLAWAN INDONESIA</h3>
                                                    <img src={'https://waroq.co.id/WRQBACKEND/Images/default/keluargaA.png'} style={{width: '486px'}} />
                                                </center>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="carausel-3-columns-cover position-relative">
                                                <div id="carausel-3-columns-arrows"></div>
                                                <div className="carausel-3-columns" id="carausel-3-columns">
                                                    <img src="https://waroq.co.id/WRQBACKEND/Images/default/A1.jpg" alt=""/>
                                                    <img src="https://waroq.co.id/WRQBACKEND/Images/default/A2.jpg" alt=""/>
                                                    <img src="https://waroq.co.id/WRQBACKEND/Images/default/A3.jpg" alt=""/>
                                                    <img src="https://waroq.co.id/WRQBACKEND/Images/default/A4.jpg" alt=""/>
                                                    <img src="https://waroq.co.id/WRQBACKEND/Images/default/A5.jpg" alt=""/>
                                                    <img src="https://waroq.co.id/WRQBACKEND/Images/default/A6.jpg" alt=""/>
                                                    <img src="https://waroq.co.id/WRQBACKEND/Images/default/A7.jpg" alt=""/>
                                                    <img src="https://waroq.co.id/WRQBACKEND/Images/default/A8.jpg" alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    {/*<section className="row mb-50">*/}
                                        {/*<div className="col-lg-6">*/}
                                            {/*<img src="https://waroq.co.id/WRQBACKEND/Images/default/paronald.jpg" alt=""*/}
                                                 {/*className="border-radius-15 mb-md-3 mb-lg-0 mb-sm-4"/>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-lg-6">*/}
                                            {/*<div className="pl-25">*/}
                                                {/*<h2 className="mb-30">CO-FOUNDER</h2>*/}
                                                {/*<h5 className="mb-15">RONALD KUMALAPUTRA</h5>*/}
                                                {/*<p className="mb-15" align="justify">Ingin membawa sebuah perubahan pada lapisan ekonomi masyarakat Indonesia khususnya menengah kebawah adalah impian seorang Co-founder WAROQ, Ronald Kumalaputra. Perkembangan perekonomian Indonesia yang cenderung stabil pada tahun 2018 ternyata tidak seluruhnya dirasakan oleh masyarakat Indonesia.</p>*/}
                                                {/*<p className="mb-15" align="justify">Dengan bekal pengalaman di bidang keuangan kurang lebih 16 tahun dan memegang posisi terkemuka di berbagai perusahaan Mayapada Group sebagai Pendiri, Presiden Direktur & Presiden Komisaris untuk perusahaan di bidang bisnis seperti ritel, real estate, keuangan, teknologi dan media, Ronald Kumalaputra CEO.</p>*/}
                                                {/*<p className="mb-15" align="justify">Founder WAROQ percaya pengalamannya akan sangat membantu para pemilik warung dalam memperbaiki kinerja bisnis warung kelontong yang dijalankan secara tradisional berubah menjadi modern. Pada situasi ini, Ronald Kumalaputra memiliki panggilan hati untuk membantu dan membawa perubahan bagi sekitar khususnya bagi masyarakat dengan usaha bisnis skala kecil agar usaha yang mereka jalankan tidak kalah oleh pelayanan minimarket.</p>*/}
                                                {/*<p className="mb-50" align="justify">Komitmen dan panggilan hati ini membuahkan sebuah langkah baru yang bergerak di bidang teknologi ritel berwujudkan WAROQ. Hadirnya WAROQ (Warung Baroqah) di harapkan akan meningkatkan, memperbaiki kondisi perekonomian dari skala usaha kecil, juga membantu para pemilik warung kelontong agar mampu menghadapi situasi perekonomian offline menuju online yang semakin viral.</p>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-lg-12">*/}
                                            {/*<div className="row about-count mb-50" style={{backgroundImage: `url(https://waroq.co.id/WRQBACKEND/Images/default/bg2.png)`}}>*/}
                                                {/*<center>*/}
                                                    {/*<h3 className="mb-30">BAGIAN DARI KELUARGA TERPANDANG DI INDONESIA</h3>*/}
                                                    {/*<img src={'https://waroq.co.id/WRQBACKEND/Images/default/keluargaronald.png'} style={{width: '486px'}} />*/}
                                                {/*</center>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-lg-12">*/}
                                            {/*<div className="carausel-3-columns-cover position-relative">*/}
                                                {/*<div id="carausel-3a-columns-arrows"></div>*/}
                                                {/*<div className="carausel-3-columns" id="carausel-3a-columns">*/}
                                                    {/*<img src="https://waroq.co.id/WRQBACKEND/Images/photos/JOKOWI.jpg" alt=""/>*/}
                                                    {/*<img src="https://waroq.co.id/WRQBACKEND/Images/photos/JK.jpg" alt=""/>*/}
                                                    {/*<img src="https://waroq.co.id/WRQBACKEND/Images/photos/bill.jpg" alt=""/>*/}
                                                    {/*<img src="https://waroq.co.id/WRQBACKEND/Images/photos/bj.jpg" alt=""/>*/}
                                                    {/*<img src="https://waroq.co.id/WRQBACKEND/Images/photos/al.jpg" alt=""/>*/}
                                                    {/*<img src="https://waroq.co.id/WRQBACKEND/Images/photos/ad.jpg" alt=""/>*/}
                                                    {/*<img src="https://waroq.co.id/WRQBACKEND/Images/photos/ht.jpg" alt=""/>*/}
                                                    {/*<img src="https://waroq.co.id/WRQBACKEND/Images/photos/tito.jpg" alt=""/>*/}
                                                    {/*<img src="https://waroq.co.id/WRQBACKEND/Images/photos/tni.jpg" alt=""/>*/}
                                                    {/*<img src="https://waroq.co.id/WRQBACKEND/Images/photos/wir.jpg" alt=""/>*/}
                                                    {/*<img src="https://waroq.co.id/WRQBACKEND/Images/photos/mentri4.jpg" alt=""/>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                    {/*</section>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="hero-3 position-relative align-items" style={{backgroundImage: `url(https://waroq.co.id/WRQBACKEND/Images/default/bgrad1.png)`}}>
                        <img src={'https://waroq.co.id/WRQBACKEND/Images/default/baner1.png'} style={{width: '225px', height: '225px'}} />
                    </section>
                    <div className="page-content pt-50">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-10 col-lg-12 m-auto">
                                    <section className="text-center mb-50">
                                        <h2 className="style-3 mb-40">SATU APLIKASI UNTUK SEMUA</h2>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 mb-24">
                                                <div className="featured-card">
                                                    <img src="https://waroq.co.id/WRQBACKEND/Images/default/keuangan.png" alt=""/>
                                                    <h4>Keuangan</h4>
                                                    <p align="justify">Anggota dapat memanfaatkan fitur ini sebagai mesin kasir yang multifungsi dengan demikian pengaturan keuangan lebih rapi dan tercatat.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 mb-24">
                                                <div className="featured-card">
                                                    <img src="https://waroq.co.id/WRQBACKEND/Images/default/stock.png" alt=""/>
                                                    <h4>Stok Barang</h4>
                                                    <p align="justify">Anggota dapat mengetahui stock barang (quantity) dan ragam produk yang dimiliki, mengetahui harga produk.</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 mb-24">
                                                <div className="featured-card">
                                                    <img src="https://waroq.co.id/WRQBACKEND/Images/default/orderonline.png" alt=""/>
                                                    <h4>Order Online</h4>
                                                    <p align="justify">Anggota dapat langsung melakukan pemesanan barang secara online sesuai dengan yang dibutuhkan.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="hero-3 position-relative align-items backdrop-about" style={{backgroundImage: `url(https://waroq.co.id/WRQBACKEND/Images/default/back4.png)`}}>
                        <h2 className="mb-30 mt-50 text-center" style={{color: 'white'}}>KEUNTUNGAN MENJADI MEMBER WAROQ</h2>
                        <div className="row" style={{'--bs-gutter-x': 'unset'}}>
                            <div className="col-xl-10 col-lg-12 m-auto">
                                <section className="text-center mb-50">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 mb-24">
                                            <img src="https://waroq.co.id/WRQBACKEND/Images/default/MODAL.png" alt="" style={{width: '100px', height: '100px'}}/>
                                            <h4 style={{color: 'white'}}>MODAL</h4>
                                            <p align="justify" style={{color: 'white', 'padding-left': '15px', 'padding-right': '15px'}}>Member WAROQ dapat mengajukan tambahan modal usaha melalui BBX KOPERASI, yang merupakan bagian dari grup WAROQ.</p>
                                        </div>
                                        <div className="col-lg-4 col-md-6 mb-24">
                                            <img src="https://waroq.co.id/WRQBACKEND/Images/default/pemugaran.png" alt="" style={{width: '100px', height: '100px'}}/>
                                            <h4 style={{color: 'white'}}>PEMUGARAN</h4>
                                            <p align="justify" style={{color: 'white', 'padding-left': '15px', 'padding-right': '15px'}}>Dapatkan promosi branding dan endorsment, dari sponsor yang bekerjasama dengan WAROQ.</p>
                                        </div>
                                        <div className="col-lg-4 col-md-6 mb-24">
                                            <img src="https://waroq.co.id/WRQBACKEND/Images/default/diskon.png" alt="" style={{width: '100px', height: '100px'}}/>
                                            <h4 style={{color: 'white'}}>DISKON</h4>
                                            <p align="justify" style={{color: 'white', 'padding-left': '15px', 'padding-right': '15px'}}>Dapatkan diskon sampai dengan 50% berbagai macam produk dengan menggunakan BBX COIN.</p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </section>
                    <div className="page-content pt-50">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-10 col-lg-12 m-auto">
                                    <section className="text-center mb-50">
                                        <h2 className="style-3 mb-40">TESTIMONI MEMBER WAROQ</h2>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 mb-24">
                                                <div className="featured-card">
                                                    <img src="https://waroq.co.id/WRQBACKEND/Images/default/testi1.png" alt=""/>
                                                    <p align="justify">"awal saya tahu waroq dari teman sesama pemilik warung. Di dalam hati saya, saya bertanya mana ada pembelian barang dengan harga yang murah sekali bahkan diantar langsung barangnya. Teman saya memberitahu saya bahwa dia bermitra dengan WAROQ dan saya berusaha untuk mencari tau dan pada akhirnya saya sudah bermitra dengan WAROQ selama 6 bulan. Melalui bermitra dengan WAROQ, penghasilan saya jadi bertambah, sehingga saat ini saya bisa memiliki tabungan lebih."</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 mb-24">
                                                <div className="featured-card">
                                                    <img src="https://waroq.co.id/WRQBACKEND/Images/default/testi2.png" alt=""/>
                                                    <p align="justify">"WAROQ hadir membuka kesempatan agar usaha-usaha kecil seperti warung mampu bersaing dengan usaha-usaha yang lebih besar. Pak Surahman salah satu mitra waroq merasa pemenuhan kebutuhannya lebih terbantu setelah bergabung dengan WAROQ, Pak Surahman juga yakin bahwa WAROQ merupakan cara Tuhan memberikan rezeki pada keluarganya."</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 mb-24">
                                                <div className="featured-card">
                                                    <img src="https://waroq.co.id/WRQBACKEND/Images/default/testi3.png" alt=""/>
                                                    <p align="justify">"Menjadi pedagang adalah cita-cita Pak Kaslim bersama WAROQ Pak Kaslim mampu meraih cita-citanya tersebut. Meskipun masih ada plus-minusnya, laki-laki beranak 3 ini jadi merasa terbantu."</p>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="hero-3 position-relative align-items backdrop-about-large" style={{backgroundImage: `url(https://waroq.co.id/WRQBACKEND/Images/default/back4.png)`}}>
                        <h2 className="mb-30 mt-50 text-center" style={{color: 'white'}}>KEUNTUNGAN MENJADI MITRA WAROQ</h2>
                        <div className="row" style={{'--bs-gutter-x': 'unset'}}>
                            <div className="col-xl-10 col-lg-12 m-auto">
                                <section className="text-center mb-50">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 mb-24">
                                            <div className="featured-card" style={{'padding': '20px 30px', 'margin-left': '15px', 'margin-right': '15px', height: '435px'}}>
                                                <img src="https://waroq.co.id/WRQBACKEND/Images/WAROQ/placement.png" alt=""/>
                                                <h4>PLACEMENT</h4>
                                                <p align="justify">Produk akan di distribusikan pada Waroq yang Anda tentukan. Kami akan memberikan briefing produk knowledge kepada anggota Waroq untuk memprioritaskan penjualan produk Anda.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 mb-24">
                                            <div className="featured-card" style={{'padding': '20px 30px', 'margin-left': '15px', 'margin-right': '15px', height: '435px'}}>
                                                <img src="https://waroq.co.id/WRQBACKEND/Images/WAROQ/branding.png" alt=""/>
                                                <h4>BRANDING</h4>
                                                <p align="justify">Ketika produk di distribusikan, kami akan melakukan branding promosi produk Anda pada media yang telah di sediakan dan memastikan pemasangan telah sesuai dengan standarnya.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 mb-24">
                                            <div className="featured-card" style={{'padding': '20px 30px', 'margin-left': '15px', 'margin-right': '15px', height: '435px'}}>
                                                <img src="https://waroq.co.id/WRQBACKEND/Images/WAROQ/report.png" alt=""/>
                                                <h4>CONTROL & REPORT</h4>
                                                <p align="justify">Kontrol kunjungan dan kembali memberikan produk knowledge ke anggota Waroq setiap 2 minggu sekali, mengecek/resupply kesediaan produk dalam keadaan sesuai standar. Report diberikan kepada Mitra Waroq setiap satu bulan sekali (sesuai permintaan).</p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </section>
                    <div className="page-content pt-50">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-10 col-lg-12 m-auto">
                                    <section className="text-center mb-50">
                                        <h2 className="style-3 mb-40">OUR VIDEO</h2>
                                        <iframe className="embed-responsive-item"
                                                src='https://www.youtube.com/embed/CC7dDho8zfE' frameBorder="0"
                                                allowFullScreen style={{width: '100%', height: '100vh'}} />
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default About;