import React from "react";
import {ReactSession} from "react-client-session";
import {encode as base64_encode} from "base-64";
import {Helpers} from "../../../config/Helpers";
import Select from 'react-select';

import {Connection} from "../../../config/Connection";
import $ from "jquery";
import {toast} from "react-toastify";
import AutoComplete from "../../RegisterBuyer";

class Account extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            urlActive: window.location.pathname,
            sessMPBuyerId: (ReactSession.get("sess_mp_buyerid")) ? ReactSession.get("sess_mp_buyerid") : '',

            buyer_type: '',
            buyer_type_name: '',
            buyer_name: '',
            business_type: '',
            business_type_name: '',
            company_name: '',
            company_email: '',
            company_phone: '',
            company_website: '',
            fullname: '',
            position: '',
            position_name: '',
            phone: '',
            finance_email: '',
            finance_npwp: '',
            finance_phone: '',
            products: '',
            others_products: '',
            description: '',
            postal_code: '',
            district: '',
            subcity: '',
            city: '',
            province: '',
            address: '',
            pinpoint: '',
            coordinates: '',
            user_waroq: '',
            id_provinsi: '',
            provinsi: '',
            id_kota: '',
            kota: '',
            id_kecamatan: '',
            kecamatan: '',
            id_kelurahan: '',
            kelurahan: ''
        }
    }

    componentDidMount() {
        this.checkBuyer();
    }

    checkBuyer = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getMPBuyer('',this.state.sessMPBuyerId,'Aktif').then(res => {
            $(".loader-wrapper").css("display", "none");
            if(res.length > 0){
                const r = res[0];

                this.setState({
                    buyer_type: r.buyer_type,
                    buyer_type_name: r.buyer_type_name,
                    buyer_name: r.buyer_name,
                    business_type: r.business_type,
                    business_type_name: r.business_type_name,
                    company_name: r.company_name,
                    company_email: r.company_email,
                    company_phone: r.company_phone,
                    company_website: r.company_website,
                    fullname: r.fullname,
                    position: r.position,
                    position_name: r.position_name,
                    phone: r.phone,
                    finance_email: r.finance_email,
                    finance_npwp: r.finance_npwp,
                    finance_phone: r.finance_phone,
                    products: r.products,
                    others_products: r.others_products,
                    description: r.description,
                    postal_code: r.postal_code,
                    district: r.district,
                    subcity: r.subcity,
                    city: r.city,
                    province: r.province,
                    address: r.address,
                    pinpoint: r.pinpoint,
                    coordinates: r.coordinates,
                    user_waroq: r.user_waroq,
                    id_provinsi: r.id_provinsi,
                    provinsi: r.provinsi,
                    id_kota: r.id_kota,
                    kota: r.kota,
                    id_kecamatan: r.id_kecamatan,
                    kecamatan: r.kecamatan,
                    id_kelurahan: r.id_kelurahan,
                    kelurahan: r.kelurahan
                });
            }
        })
    };

    render() {
        return (
            <>
                <section className="content-main">
                    <div className="content-header">
                        <div>
                            <h2 className="content-title card-title">Account</h2>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="row gx-5">
                                <div className="col-lg-12">
                                    <section className="content-body p-xl-4">
                                        <h5 className="mb-2">Business Information</h5>
                                        <div className="mb-2">
                                            <label className="form-label">Buyer Type</label>
                                            <input type="text" placeholder="Type here" className="form-control" required readOnly={true}
                                                   value={this.state.buyer_type_name} onChange={e => this.setState({buyer_type_name: e.target.value})}/>
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">Buyer Name</label>
                                            <input type="text" placeholder="Type here" className="form-control" required readOnly={true}
                                                   value={this.state.buyer_name} onChange={e => this.setState({buyer_name: e.target.value})}/>
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">Business Type</label>
                                            <input type="text" placeholder="Type here" className="form-control" required readOnly={true}
                                                   value={this.state.business_type_name} onChange={e => this.setState({business_type_name: e.target.value})}/>
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">Company Name</label>
                                            <input type="text" placeholder="Type here" className="form-control" required readOnly={true}
                                                   value={this.state.company_name} onChange={e => this.setState({company_name: e.target.value})}/>
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">Provinsi</label>
                                            <input type="text" placeholder="Type here" className="form-control" readOnly={true}
                                                   value={this.state.provinsi} onChange={e => this.setState({provinsi: e.target.value})}/>
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">Kota/Kabupaten</label>
                                            <input type="text" placeholder="Type here" className="form-control" readOnly={true}
                                                   value={this.state.kota} onChange={e => this.setState({kota: e.target.value})}/>
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">Kecamatan</label>
                                            <input type="text" placeholder="Type here" className="form-control" readOnly={true}
                                                   value={this.state.kecamatan} onChange={e => this.setState({kecamatan: e.target.value})}/>
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">Kelurahan</label>
                                            <input type="text" placeholder="Type here" className="form-control" readOnly={true}
                                                   value={this.state.kelurahan} onChange={e => this.setState({kelurahan: e.target.value})}/>
                                        </div>
                                        <div className="mb-2 mt-2">
                                            <label className="form-label">Postal Code</label>
                                            <input type="text" placeholder="Type here" className="form-control" required readOnly={true}
                                                   value={this.state.postal_code} onChange={e => this.setState({postal_code: e.target.value})}/>
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">Details Address</label>
                                            <textarea className="form-control" rows="3" required readOnly={true}
                                                      value={this.state.address} onChange={e => this.setState({address: e.target.value})} />
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">Company Email</label>
                                            <input type="email" placeholder="Type here" className="form-control" required readOnly={true}
                                                   value={this.state.company_email} onChange={e => this.setState({company_email: e.target.value})}/>
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">Company Phone Number</label>
                                            <input type="number" placeholder="Type here" className="form-control" required readOnly={true}
                                                   value={this.state.company_phone} onChange={e => this.setState({company_phone: e.target.value})}/>
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">Website (optional)</label>
                                            <input type="text" placeholder="Type here" className="form-control" readOnly={true}
                                                   value={this.state.company_website} onChange={e => this.setState({company_website: e.target.value})}/>
                                        </div>

                                        <h5 className="mb-2 mt-15">Finance Information</h5>
                                        <div className="mb-2">
                                            <label className="form-label">Finance Email</label>
                                            <input type="email" placeholder="Type here" className="form-control" required readOnly={true}
                                                   value={this.state.finance_email} onChange={e => this.setState({finance_email: e.target.value})}/>
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">NPWP</label>
                                            <input type="number" placeholder="Type here" className="form-control" required readOnly={true}
                                                   value={this.state.finance_npwp} onChange={e => this.setState({finance_npwp: e.target.value})}/>
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">Phone Number</label>
                                            <input type="number" placeholder="Type here" className="form-control" required readOnly={true}
                                                   value={this.state.finance_phone} onChange={e => this.setState({finance_phone: e.target.value})}/>
                                        </div>

                                        <h5 className="mb-2 mt-15">Contact Information</h5>
                                        <div className="mb-2">
                                            <label className="form-label">Full Name</label>
                                            <input type="text" placeholder="Type here" className="form-control" required readOnly={true}
                                                   value={this.state.fullname} onChange={e => this.setState({fullname: e.target.value})}/>
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">Position in Company</label>
                                            <input type="text" placeholder="Type here" className="form-control" required readOnly={true}
                                                   value={this.state.position_name} onChange={e => this.setState({position_name: e.target.value})}/>
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">Mobile Number</label>
                                            <input type="number" placeholder="Type here" className="form-control" required readOnly={true}
                                                   value={this.state.phone} onChange={e => this.setState({phone: e.target.value})}/>
                                        </div>

                                        <h5 className="mb-2 mt-15">What products does your business need</h5>
                                        <div className="mb-2">
                                            <input type="text" placeholder="Type here" className="form-control" required readOnly={true}
                                                   value={this.state.products} onChange={e => this.setState({products: e.target.value})}/>
                                            <span className="form-check-label">Others</span>
                                            <input type="text" placeholder="Product/Service Description" className="form-control" readOnly={(this.state.isOther) ? false : true}
                                                   value={this.state.others_products} onChange={e => this.setState({others_products: e.target.value})}/>
                                        </div>

                                        <h5 className="mb-2 mt-15">Write a brief description about your business</h5>
                                        <div className="mb-2">
                                            <textarea className="form-control" rows="3" required readOnly={true}
                                                      value={this.state.description} onChange={e => this.setState({description: e.target.value})} />
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Account;