import React from "react";
import {ReactSession} from "react-client-session";
import {encode as base64_encode} from "base-64";
import {Helpers} from "../../../config/Helpers";
import $ from "jquery";

class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            urlActive: window.location.pathname,
            sessMPSupplierId: (ReactSession.get("sess_mp_supplierid")) ? ReactSession.get("sess_mp_supplierid") : '',
            sessMPSupplierStatus: (ReactSession.get("sess_mp_supplierstatus")) ? ReactSession.get("sess_mp_supplierstatus") : '',

            total_orders: '0',
            total_orders_values: '0',
            orders_active: '0',
            orders_active_values: '0',
            orders_finished: '0',
            orders_finished_values: '0',
            orders_waiting_to_send: '0'
        }
    }

    componentDidMount() {
        this.checkSupplier();
    }

    checkSupplier = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getMPSupplierSummary(this.state.sessMPSupplierId).then(res => {
            $(".loader-wrapper").css("display", "none");
            if(res.length > 0){
                const r = res[0];

                this.setState({
                    total_orders: r.total_orders,
                    total_orders_values: r.total_orders_values,
                    orders_active: r.orders_active,
                    orders_active_values: r.orders_active_values,
                    orders_finished: r.orders_finished,
                    orders_finished_values: r.orders_finished_values,
                    orders_waiting_to_send: r.orders_waiting_to_send
                });
            }
        })
    };

    render() {
        return (
            <>
                <section className="content-main">
                    <div className="content-header">
                        <div>
                            <h2 className="content-title card-title">Dashboard</h2>
                            <p>Whole data about your business here</p>
                        </div>
                    </div>
                    {
                        (this.state.sessMPSupplierStatus !== 'Approved') && (
                            <div className="row mt-60">
                                <div className="col-sm-12">
                                    <div className="w-50 mx-auto text-center">
                                        <h3 className="mt-40 mb-15">Oops! Your account not verified</h3>
                                        <p>Since your account has not been verified, some menus such as products and orders are disabled, please fill in your information here...</p>
                                        <a href="/marketplace/supplier/account" className="btn btn-primary mt-4">Go to account</a>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        (this.state.sessMPSupplierStatus === 'Approved') && (
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="card card-body mb-4">
                                        <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-primary-light"><i
                                        className="text-primary material-icons md-shopping_basket"></i></span>
                                            <div className="text">
                                                <h6 className="mb-1 card-title">Total Orders</h6>
                                                <span>{this.state.total_orders}</span>
                                                <span className="text-sm"> All status include </span>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="card card-body mb-4">
                                        <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-success-light"><i
                                        className="text-success material-icons md-notifications_active"></i></span>
                                            <div className="text">
                                                <h6 className="mb-1 card-title">Orders Active</h6>
                                                <span>{this.state.orders_active}</span>
                                                <span className="text-sm"> Active orders only </span>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="card card-body mb-4">
                                        <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-warning-light"><i
                                        className="text-warning material-icons md-local_shipping"></i></span>
                                            <div className="text">
                                                <h6 className="mb-1 card-title">Waiting to Send</h6>
                                                <span>{this.state.orders_waiting_to_send}</span>
                                                <span className="text-sm"> Need to deliver product </span>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="card card-body mb-4">
                                        <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-info-light"><i
                                        className="text-info material-icons md-receipt"></i></span>
                                            <div className="text">
                                                <h6 className="mb-1 card-title">Order Finished</h6>
                                                <span>{this.state.orders_finished}</span>
                                                <span className="text-sm"> Payment received </span>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card card-body mb-4">
                                        <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-primary-light"><i
                                        className="text-primary material-icons md-monetization_on"></i></span>
                                            <div className="text">
                                                <h6 className="mb-1 card-title">Total Orders Values</h6>
                                                <span>Rp {this.state.total_orders_values}</span>
                                                <span className="text-sm"> All status include </span>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card card-body mb-4">
                                        <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-success-light"><i
                                        className="text-success material-icons md-monetization_on"></i></span>
                                            <div className="text">
                                                <h6 className="mb-1 card-title">Orders Active Values</h6>
                                                <span>Rp {this.state.orders_active_values}</span>
                                                <span className="text-sm"> Active orders only </span>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="card card-body mb-4">
                                        <article className="icontext">
                                    <span className="icon icon-sm rounded-circle bg-info-light"><i
                                        className="text-info material-icons md-monetization_on"></i></span>
                                            <div className="text">
                                                <h6 className="mb-1 card-title">Order Finished Values</h6>
                                                <span>Rp {this.state.orders_finished_values}</span>
                                                <span className="text-sm"> Payment received </span>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </section>
            </>
        );
    }
}

export default Dashboard;