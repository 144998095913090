import React from "react";

import queryString from "query-string";
import {isMobile} from "react-device-detect";
import {Helpers} from "../../config/Helpers";
import $ from "jquery";
import Select from 'react-select';
import moment from "moment";
import 'react-html5-camera-photo/build/css/index.css';
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import {encode as base64_encode} from "base-64";
import {toast} from "react-toastify";
import OtpInput from 'react-otp-input';
import ReactPaginate from 'react-paginate';

class KoinWorksLoan extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            urlParam: queryString.parse(window.location.search),
            kodeMarketing: '',
            offset: 0,
            perPage: 10,
            currentPage: 0,
            pageNumList: [10,25,50,100,150,200],
            sortData: ['Featured','ID Ascending','ID Descending'],
            search: ''
        }
    }

    componentDidMount() {
        window.location.href = './404';
        /*this.setState({
            kodeMarketing: (this.state.urlParam.kode) ? this.state.urlParam.kode : ''
        }, () => {
            this.getData();
        });

        if(isMobile) {
            $("#mobile-header").css("display", "none");
            $("#mobile-footer").css("display", "none");
        }*/
    }

    getData = () => {
        Helpers.koinWorksGetListLoan(this.state.kodeMarketing).then(res => {
            let sortData = res;
            if(this.state.search !== ''){
                sortData = sortData.filter(item => {
                    const textData = this.state.search.toUpperCase();

                    return Object.keys(item).some(key => ((item[key]) ? item[key] : '').toString().toUpperCase().search(textData) !== -1);
                });
            }
            if(this.state.sortDataValue == 'ID Descending'){
                sortData = sortData.sort(function(a,b) {
                    const val_a = a.LoanID.toUpperCase();
                    const val_b = b.LoanID.toUpperCase();

                    return (val_a < val_b) ? 1 : ((val_b < val_a) ? -1 : 0);
                });
            }
            if(this.state.sortDataValue == 'ID Ascending'){
                sortData = sortData.sort(function(a,b) {
                    const val_a = a.LoanID.toUpperCase();
                    const val_b = b.LoanID.toUpperCase();

                    return (val_a > val_b) ? 1 : ((val_b > val_a) ? -1 : 0);
                });
            }

            const slice = sortData.slice(this.state.offset, this.state.offset + this.state.perPage)
            const postData = slice.map((object, i) => {
                return <div className="col-lg-3 col-md-6 col-12 col-sm-6">
                    <div className="vendor-wrap mb-40">
                        <div className="vendor-img-action-wrap" style={{'padding': 'unset'}}>
                            <div className="vendor-img">
                                <table style={{'margin-bottom': 'unset'}}>
                                    <tr>
                                        <td>
                                            <div className="d-flex justify-content-between align-items-end mt-25">
                                                <div>
                                                    <div className="product-category">
                                                        <span className="text-muted" style={{'font-size': '11px'}}>{object.date_d + ' ' + object.date_m + ' ' + object.date_y + ' ' + object.date_t}</span>
                                                    </div>
                                                    <h4 className="mb-5" style={{'font-size': '15px'}}><a style={{'color': '#000'}}>{object.KodeKoinWorksOrder}</a>
                                                    </h4>
                                                    <div className="product-category">
                                                        <span className="text-muted" style={{'font-size': '11px'}}>Order: {object.KodeTransaksiOrder}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div className="product-badges product-badges-position product-badges-mrg">
                                <span className="best">
                                    Status: {object.StatusLabel}
                                </span>
                            </div>
                        </div>
                        <div className="vendor-content-wrap" style={{'padding': '10px 20px 10px 20px'}}>
                            <div className="d-flex justify-content-between align-items-center">
                                <h4 style={{'font-size': '15px'}}>
                                    <a style={{'color': '#000'}}>Rp {object.AmountDisplay} ({object.Tenor} Hari)</a>
                                </h4>

                                <div>
                                    {
                                        object.StatusCode === '3b6b220b-2019-4279-8343-cbe6167f7574' && (
                                            <a href={'./koinworks-loan-details?loanid=' + base64_encode(object.LoanID) + '&kode='+this.state.kodeMarketing} className="btn btn-xs">View Bill <i className="fi-rs-arrow-small-right"></i></a>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            })

            this.setState({
                pageCount: Math.ceil(sortData.length / this.state.perPage),
                postData
            })
        })
    };

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.getData()
        });
    };

    handlePageNumClick = (num) => {
        this.setState({
            currentPage: 0,
            offset: 0,
            perPage: num,
        }, () => {
            this.getData()
        });
    };

    handleSortByClick = (sortBy) => {
        this.setState({
            currentPage: 0,
            offset: 0,
            sortDataValue: sortBy
        }, () => {
            this.getData()
        });
    };

    handleSearch = (e) => {
        e.preventDefault();

        this.setState({
            currentPage: 0,
            offset: 0
        }, () => {
            this.getData()
        });
    };

    render() {
        return (
            <>
                <main className="main pages mb-80" style={{'display': 'none'}}>
                    <div className="page-content pt-20">
                        <div className="container">
                            <div className="archive-header-2 text-center">
                                <div className="row">
                                    <div className="col-lg-5 mx-auto">
                                        <div className="sidebar-widget-2 widget_search mb-50">
                                            <div className="search-form">
                                                <form method="post" onSubmit={(e) => {this.handleSearch(e)}}>
                                                    <input type="text" placeholder="Cari data tagihan ..." value={this.state.search} onChange={e => this.setState({search: e.target.value})}/>
                                                    <button type="submit"><i className="fi-rs-search"></i></button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-lg-8 mx-auto">
                                    <div className="shop-product-fillter">
                                        <div className="totall-product">
                                            {/*<a href={'./account-history'} className="btn btn-sm">Kembali</a>*/}
                                        </div>
                                        <div className="sort-by-product-area">
                                            <div className="sort-by-cover mr-10">
                                                <div className="sort-by-product-wrap">
                                                    <div className="sort-by">
                                                        <span><i className="fi-rs-apps"></i>Show:</span>
                                                    </div>
                                                    <div className="sort-by-dropdown-wrap">
                                                        <span> {this.state.perPage} <i className="fi-rs-angle-small-down"></i></span>
                                                    </div>
                                                </div>
                                                <div className="sort-by-dropdown">
                                                    <ul>
                                                        {
                                                            this.state.pageNumList.map((object, i) => {
                                                                return <li><a className={(object == this.state.perPage) ? 'active': ''} onClick={() => {this.handlePageNumClick(object)}}>{object}</a></li>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="sort-by-cover">
                                                <div className="sort-by-product-wrap">
                                                    <div className="sort-by">
                                                        <span><i className="fi-rs-apps-sort"></i>Sort by:</span>
                                                    </div>
                                                    <div className="sort-by-dropdown-wrap">
                                                        <span> Featured <i className="fi-rs-angle-small-down"></i></span>
                                                    </div>
                                                </div>
                                                <div className="sort-by-dropdown">
                                                    <ul>
                                                        {
                                                            this.state.sortData.map((object, i) => {
                                                                return <li><a className={(object == this.state.sortDataValue) ? 'active': ''} onClick={() => {this.handleSortByClick(object)}}>{object}</a></li>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row vendor-grid">
                                {this.state.postData}
                            </div>

                            <div className="pagination-area mt-20 mb-20">
                                <nav aria-label="Page navigation example">
                                    <ReactPaginate
                                        previousLabel={<i className="fi-rs-arrow-small-left"></i>}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextLabel={<i className="fi-rs-arrow-small-right"></i>}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakLabel={"..."}
                                        breakClassName={"page-link dot"}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={1}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={"pagination justify-content-start"}
                                        activeClassName={"page-item active"}
                                        activeLinkClassName={"page-link"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}/>
                                </nav>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default KoinWorksLoan;