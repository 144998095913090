import React from "react";
import $ from "jquery";
import {Helpers} from "../../../config/Helpers";
import {ReactSession} from "react-client-session";
import queryString from "query-string";
import {Connection} from "../../../config/Connection";
import {toast} from "react-toastify";
import ReactToPrint from 'react-to-print';

class OrderDetails extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            urlActive: window.location.pathname,
            urlParam: queryString.parse(window.location.search),
            sessMPBuyerId: (ReactSession.get("sess_mp_buyerid")) ? ReactSession.get("sess_mp_buyerid") : '',
            kode_order: '',
            kode_buyer: '',
            kode_payment_gateway: '',
            total_order: '',
            potongan_komisi: '',
            jasa_kirim: '',
            biaya_admin: '',
            total_bayar: '',
            total_berat: '',
            tipe_pengiriman: '',
            jarak_pengiriman: '',
            metode_pembayaran: '',
            status_pembayaran: '',
            phone: '',
            postal_code: '',
            district: '',
            subcity: '',
            city: '',
            province: '',
            address: '',
            coordinates: '',
            note: '',
            status: '',
            created_by: '',
            created_on: '',
            modified_by: '',
            modified_on: '',
            status_aktif: '',
            buyer_name: '',
            company_name: '',
            company_phone: '',
            company_email: '',
            payment_channel: '',
            payment_logo: '',
            province_name: '',
            city_name: '',
            subcity_name: '',
            district_name: '',
            date_created: '',
            biaya_admin_format: '',
            total_order_format: '',
            total_payment: '',
            total_items: '',
            status_upper: ''
        };
        this.componentRef = React.createRef();
    }

    componentDidMount() {
        const kode_order = (this.state.urlParam.kode_order) ? this.state.urlParam.kode_order : '';

        this.setState({
            kode_order: kode_order
        }, () => {
            this.getOrders();
        });
    }

    getOrders = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getMPBuyerOrder(this.state.kode_order,this.state.sessMPBuyerId,'all').then(res => {
            if(res){
                let r = res[0];
                this.setState({
                    kode_buyer: r.kode_buyer,
                    kode_payment_gateway: r.kode_payment_gateway,
                    total_order: r.total_order,
                    potongan_komisi: r.potongan_komisi,
                    jasa_kirim: r.jasa_kirim,
                    biaya_admin: r.biaya_admin,
                    total_bayar: r.total_bayar,
                    total_berat: r.total_berat,
                    tipe_pengiriman: r.tipe_pengiriman,
                    jarak_pengiriman: r.jarak_pengiriman,
                    metode_pembayaran: r.metode_pembayaran,
                    status_pembayaran: r.status_pembayaran,
                    phone: r.phone,
                    postal_code: r.postal_code,
                    district: r.district,
                    subcity: r.subcity,
                    city: r.city,
                    province: r.province,
                    address: r.address,
                    coordinates: r.coordinates,
                    note: r.note,
                    status: r.status,
                    created_by: r.created_by,
                    created_on: r.created_on,
                    modified_by: r.modified_by,
                    modified_on: r.modified_on,
                    status_aktif: r.status_aktif,
                    buyer_name: r.buyer_name,
                    company_name: r.company_name,
                    company_phone: r.company_phone,
                    company_email: r.company_email,
                    payment_channel: r.payment_channel,
                    payment_logo: r.payment_logo,
                    province_name: r.province_name,
                    city_name: r.city_name,
                    subcity_name: r.subcity_name,
                    district_name: r.district_name,
                    date_created: r.date_created,
                    biaya_admin_format: r.biaya_admin_format,
                    total_order_format: r.total_order_format,
                    total_payment: r.total_payment,
                    total_items: r.total_items,
                    status_upper: r.status_upper
                });
            }

            this.getData();
        })
    };

    getData = () => {
        Helpers.getMPBuyerOrderDetails(this.state.kode_order,'').then(res => {
            $(".loader-wrapper").css("display", "none");
            const postData = res.map((object, i) => {
                return <tr>
                    <td>
                        <a className="itemside" href="#">
                            <div className="left">
                                <img src={object.img_url} width="40" height="40"
                                     className="img-xs" alt="Item"/>
                            </div>
                            <div className="info">{object.product}</div>
                        </a>
                    </td>
                    <td>{object.status_overall}</td>
                    <td>{object.unit}</td>
                    <td className="text-end">{object.price_waroq_format}</td>
                    <td className="text-end">{object.qty}</td>
                    <td className="text-end">{object.total_waroq_format}</td>
                </tr>
            });

            this.setState({
                postData
            });
        })
    };

    saveMPStatusOrder = (status) => {
        $(".loader-wrapper").css("display", "");

        var now = new Date();
        var time_epoch = now.getTime();

        var data_post = new FormData();

        if(status === 'received'){
            if($("#doc_order").get(0) !== undefined){
                var doc_order = $("#doc_order").get(0).files;
                if (doc_order.length > 0) {
                    data_post.append("doc_order", doc_order[0]);
                }
            }
        }

        const param =
            "kode_user=" + this.state.sessMPBuyerId +
            "&kode_order=" + this.state.kode_order +
            "&status=" + status +
            "&epoch=" + time_epoch;

        fetch(Connection.API_URL + 'SaveMPStatusOrder?' + param, {
            method: 'POST',
            body: data_post
        }).then((res) => res.json()).then((res) => {
            $(".loader-wrapper").css("display", "none");
            if(res.length > 0){
                if(res[0].alert == 'success'){
                    this.getOrders();
                }

                toast[res[0].alert](res[0].message);
            }else{
                toast.error(Helpers.MSG_ERROR);
            }
        });
    };

    render() {
        return (
            <>
                <section className="content-main">
                    <div className="content-header">
                        <div>
                            <h2 className="content-title card-title">Order detail</h2>
                            <p>Details for Order ID: {this.state.kode_order}</p>
                        </div>
                    </div>
                    <ReactToPrint
                        trigger={() => {
                            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                            // to the root node of the returned component as it will be overwritten.
                            return <a className="btn btn-secondary print ms-2 mb-3" href="#">
                                <i className="icon material-icons md-print mr-5"></i> Download Invoice
                            </a>;
                        }}
                        content={() => this.componentRef}
                    />
                    <div className="card" ref={el => (this.componentRef = el)}>
                        <header className="card-header">
                            <div className="row align-items-center">
                                <div className="col-lg-4 col-md-4 mb-lg-0 mb-15">
                                    <div style={{'display': 'flex'}}>
                                        <i className="material-icons md-calendar_today mr-5"></i> <b>{this.state.date_created}</b>
                                    </div>
                                    <small className="text-muted">Order ID: {this.state.kode_order}</small>
                                </div>
                                <div className="col-lg-8 col-md-8 ms-auto text-md-end">
                                    {
                                        (this.state.status_upper === 'order sent') && (
                                            <>
                                                <input type="file" name="doc_order" id="doc_order" className="form-control mr-5" accept="image/png, image/jpeg"
                                                       style={{'width': '225px', 'display': 'inline'}} />
                                                <a className="btn btn-primary" onClick={() => {this.saveMPStatusOrder('received')}}>Confirm Received</a>
                                            </>
                                        )
                                    }
                                    {/*<a className="btn btn-secondary print ms-2" href="#">*/}
                                        {/*<i className="icon material-icons md-print"></i>*/}
                                    {/*</a>*/}
                                </div>
                            </div>
                        </header>
                        <div className="card-body">
                            <div className="row mb-50 mt-20 order-info-wrap">
                                <div className="col-md-4">
                                    <article className="icontext align-items-start">
                                    <span className="icon icon-sm rounded-circle bg-primary-light">
                                        <i className="text-primary material-icons md-person"></i>
                                    </span>
                                        <div className="text">
                                            <h6 className="mb-1">Customer</h6>
                                            <p className="mb-1">
                                                {this.state.company_name} <br/>
                                                {this.state.company_email} <br/>
                                                {this.state.phone}
                                            </p>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-4">
                                    <article className="icontext align-items-start">
                                    <span className="icon icon-sm rounded-circle bg-primary-light">
                                        <i className="text-primary material-icons md-local_shipping"></i>
                                    </span>
                                        <div className="text">
                                            <h6 className="mb-1">Order info</h6>
                                            <p className="mb-1">
                                                Payment Method: {this.state.metode_pembayaran} <br/>
                                                Payment Status: {this.state.status_pembayaran} <br/>
                                                Order Status: <span className="badge rounded-pill alert-success"
                                                                    style={{'font-size': 'unset', 'display': 'unset'}}>{this.state.status_upper}</span>
                                            </p>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-4">
                                    <article className="icontext align-items-start">
                                    <span className="icon icon-sm rounded-circle bg-primary-light">
                                        <i className="text-primary material-icons md-place"></i>
                                    </span>
                                        <div className="text">
                                            <h6 className="mb-1">Deliver to</h6>
                                            <p className="mb-1">
                                                City: {this.state.city_name} <br/>
                                                {this.state.address} <br/>
                                                Kodepos {this.state.postal_code}
                                            </p>
                                        </div>
                                    </article>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th width="40%">Product</th>
                                                <th width="15%">Status</th>
                                                <th width="10%">Unit</th>
                                                <th width="15%">Price</th>
                                                <th width="5%">Qty</th>
                                                <th width="15%" className="text-end">Total</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.postData}
                                            <tr>
                                                <td colSpan="7">
                                                    <article className="float-end">
                                                        <dl className="dlist">
                                                            <dt>Subtotal:</dt>
                                                            <dd>{this.state.total_order_format}</dd>
                                                        </dl>
                                                        <dl className="dlist">
                                                            <dt>Payment charge:</dt>
                                                            <dd>{this.state.biaya_admin_format}</dd>
                                                        </dl>
                                                        <dl className="dlist">
                                                            <dt>Grand total:</dt>
                                                            <dd><b className="h5">{this.state.total_payment}</b></dd>
                                                        </dl>
                                                    </article>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="box shadow-sm bg-light">
                                        <h6 className="mb-15">Payment info</h6>
                                        <div style={{'display': 'flex'}}>
                                            <img src={this.state.payment_logo} height="30"/>
                                            <label className="ml-5 mt-1">{this.state.payment_channel}</label>
                                        </div>
                                    </div>
                                    <div className="h-25 pt-4">
                                        <div className="mb-3">
                                            <label>Notes</label>
                                            <textarea className="form-control" name="notes" id="notes"
                                                      placeholder="Type some note" readOnly={true}
                                                      value={this.state.note} onChange={e => this.setState({note: e.target.value})}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default OrderDetails;