import React from "react";

import queryString from "query-string";
import {isMobile} from "react-device-detect";
import {Helpers} from "../../config/Helpers";
import {Connection} from "../../config/Connection";
import $ from "jquery";
import Select from 'react-select';
import moment from "moment";
import 'react-html5-camera-photo/build/css/index.css';
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import {decode as base64_decode, encode as base64_encode} from "base-64";
import {toast} from "react-toastify";
import OtpInput from 'react-otp-input';
import ReactPaginate from 'react-paginate';

class KoinWorksLoanDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            urlParam: queryString.parse(window.location.search),
            kodeMarketing: '',
            loanID: '',
            dayPastDue: 0,
            dueDate: "",
            interest: 0,
            lateFee: 0,
            loanStatus: "",
            paymentDate: "",
            principal: 0,
            totalOutstanding: 0,
            userID: 0
        }
    }

    componentDidMount() {
        window.location.href = './404';
        /*this.setState({
            loanID: (this.state.urlParam.loanid) ? base64_decode(this.state.urlParam.loanid) : '',
            kodeMarketing: (this.state.urlParam.kode) ? this.state.urlParam.kode : ''
        }, () => {
            this.getData();
        });

        if(isMobile) {
            $("#mobile-header").css("display", "none");
            $("#mobile-footer").css("display", "none");
        }*/
    }

    getData = () => {
        $("#preloader-active-x").css("display", "");
        Helpers.koinWorksGetListLoanDetails(this.state.loanID).then(res => {
            if(res.status === 200){
                this.setState({
                    dayPastDue: res.data.loanDetails.dayPastDue,
                    dueDate: res.data.loanDetails.dueDate,
                    interest: res.data.loanDetails.interest,
                    lateFee: res.data.loanDetails.lateFee,
                    loanStatus: res.data.loanDetails.loanStatus,
                    paymentDate: res.data.loanDetails.paymentDate,
                    principal: res.data.loanDetails.principal,
                    totalOutstanding: res.data.loanDetails.totalOutstanding,
                    userID: res.data.loanDetails.userID
                });

                const generatePaymentMethod = res.data.repaymentDetails.map((object, i) => {
                    return <tr>
                        <td className="image product-thumbnail">
                            <img src={Connection.IMG_SRC_BANK + object.bankName.toLowerCase() + '.png'} alt="#"/>
                        </td>
                        <td>
                            <h6 className="text-brand text-align-left" style={{'vertical-align': 'unset'}}>{object.bankName}</h6>
                            <h6 className="w-160 mb-5 text-align-left">
                                <a href="javascript:void(0);" className="text-heading" onClick={() => {this.handleCopyVA(object.virtualAccountNumber)}}>{object.virtualAccountNumber}</a>
                            </h6>
                        </td>
                    </tr>
                })

                this.setState({
                    generatePaymentMethod
                })
            }else{
                toast.error(res.message.id);
            }

            $("#preloader-active-x").css("display", "none");
        })
    };

    handleCopyVA = (va) => {
        navigator.clipboard.writeText(va);
        toast.success("Virtual Account berhasil di copy");
    };

    render() {
        return (
            <>
                <main className="main pages mb-80" style={{'display': 'none'}}>
                    <div className="container mb-80 mt-15">
                        <div className="row">
                            <div className="col-lg-6 m-auto">
                                <div className="border p-md-4 cart-totals ml-30 mb-15">
                                    <div className="totall-product mb-10">
                                        <a href={'./koinworks-loan?kode='+this.state.kodeMarketing} className="btn btn-sm">Kembali</a>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table no-border">
                                            <tbody>
                                            <tr>
                                                <td className="cart_total_label">
                                                    <h6 className="text-muted text-align-left">Due Date</h6>
                                                </td>
                                                <td className="cart_total_amount">
                                                    <h5 className="text-brand text-end">{this.state.dueDate.substring(0,10)}</h5></td>
                                            </tr>
                                            <tr>
                                                <td className="cart_total_label">
                                                    <h6 className="text-muted text-align-left">Day Past Due</h6>
                                                </td>
                                                <td className="cart_total_amount">
                                                    <h5 className="text-brand text-end">{this.state.dayPastDue}</h5></td>
                                            </tr>
                                            <tr>
                                                <td className="cart_total_label">
                                                    <h6 className="text-muted text-align-left">Payment Date</h6>
                                                </td>
                                                <td className="cart_total_amount">
                                                    <h5 className="text-brand text-end">{this.state.paymentDate}</h5></td>
                                            </tr>
                                            <tr>
                                                <td scope="col" colSpan="2">
                                                    <div className="divider-2 mt-10 mb-10"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="cart_total_label">
                                                    <h6 className="text-muted text-align-left">Principal</h6>
                                                </td>
                                                <td className="cart_total_amount">
                                                    <h4 className="text-brand text-end">{parseInt(this.state.principal).toLocaleString(undefined, {maximumFractionDigits: 0}).toString().replaceAll(',','.')}</h4>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="cart_total_label">
                                                    <h6 className="text-muted text-align-left">Interest</h6>
                                                </td>
                                                <td className="cart_total_amount">
                                                    <h6 className="text-heading text-end">{parseInt(this.state.interest).toLocaleString(undefined, {maximumFractionDigits: 0}).toString().replaceAll(',','.')}</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="cart_total_label">
                                                    <h6 className="text-muted text-align-left">Late Fee</h6>
                                                </td>
                                                <td className="cart_total_amount">
                                                    <h6 className="text-heading text-end">{parseInt(this.state.lateFee).toLocaleString(undefined, {maximumFractionDigits: 0}).toString().replaceAll(',','.')}</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td scope="col" colSpan="2">
                                                    <div className="divider-2 mt-10 mb-10"></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="cart_total_label">
                                                    <h6 className="text-muted text-align-left">Total Outstanding</h6>
                                                </td>
                                                <td className="cart_total_amount">
                                                    <h4 className="text-brand text-end">{parseInt(this.state.totalOutstanding).toLocaleString(undefined, {maximumFractionDigits: 0}).toString().replaceAll(',','.')}</h4>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="border p-md-4 cart-totals ml-30 mb-15">
                                    <div className="table-responsive">
                                        <table className="table no-border">
                                            <tbody>
                                            <tr>
                                                <td className="cart_total_label" colSpan={3}>
                                                    <h6 className="text-muted">Metode Pembayaran</h6>
                                                </td>
                                            </tr>
                                            {
                                                this.state.generatePaymentMethod
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default KoinWorksLoanDetails;