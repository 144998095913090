import React from "react";
import {ReactSession} from "react-client-session";
import queryString from "query-string";
import {toast} from "react-toastify";
import {decode as base64_decode, encode as base64_encode} from "base-64";

import { Connection } from "../../config/Connection";
import { Helpers } from "../../config/Helpers";
import GoogleMap from '../../components/GoogleMap';
import AutoComplete from "../../components/AutoComplete";

const Marker = props => {
    return <>
        <div className="pin"></div>
        <div className="pulse"></div>
    </>
}

class New extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            sessUserType: (ReactSession.get("sess_usertype")) ? ReactSession.get("sess_usertype") : '',
            urlParam: queryString.parse(window.location.search),

            branchCode: (ReactSession.get("branch_code")) ? ReactSession.get("branch_code") : 'CBNG-18-020001',
            mapCenter: {
                lat: -6.213110657838351,
                lng: 106.82010690652227
            },
            mapZoom: 15,
            mapApiLoaded: false,
            mapInstance: null,
            mapApi: null,
            places: [],

            locID: '',
            locPinPoint: '',
            locDistance: '',
            locDistanceDisplay: '',
            locProvinsi: '',
            locKota: '',
            locKecamatan: '',
            locDesa: '',
            locDetails: '',
            locLabel: '',
            locPenerima: '',
            locPhone: '',
            locCommand: ''
        }
    }

    componentDidMount() {
        if(!this.state.sessUserCode){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.getLocationDetail();
        }
    }

    getLocationDetail = () => {
        this.setState({
            locID: (this.state.urlParam.trxid) ? base64_decode(this.state.urlParam.trxid) : '',
            locCommand: (this.state.urlParam.action) ? this.state.urlParam.action : ''
        }, () => {
            if(this.state.locID && this.state.locCommand == 'update'){
                Helpers.getListLokasiPengiriman(this.state.locID,'', this.state.branchCode).then(res => {
                    const result = res[0];

                    this.setState({
                        mapCenter: {
                            lat: parseFloat(result.Latitude),
                            lng: parseFloat(result.Longitude)
                        },

                        locPinPoint: result.PinPoint,
                        locDistance: result.Jarak,
                        locProvinsi: result.Provinsi,
                        locKota: result.Kota,
                        locKecamatan: result.Kecamatan,
                        locDesa: result.Desa,
                        locDetails: result.Detail,
                        locLabel: result.Nama,
                        locPenerima: result.Penerima,
                        locPhone: result.NoPonsel
                    }, () => {
                        this.googleLocationDetails();
                    });
                })
            }else{
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        this.setState({
                            mapCenter: {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude
                            }
                        }, () => {
                            this.googleLocationDetails();
                        });
                    });
                }
            }
        });
    };

    googleLocationDetails = () => {
        const latlng = this.state.mapCenter.lat + ',' + this.state.mapCenter.lng;

        Helpers.googleLocationDetails(latlng).then(res => {
            this.defaultPlace(res.results[0]);
        });
    };

    handleGenerateMaps = () => {
        const generateMaps = <GoogleMap
            defaultZoom={this.state.mapZoom}
            defaultCenter={this.state.mapCenter}
            bootstrapURLKeys={{
                key: Connection.MAPS_API_KEY,
                libraries: ['places', 'geometry'],
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}>
            <Marker lat={parseFloat(this.state.mapCenter.lat)} lng={parseFloat(this.state.mapCenter.lng)} />
        </GoogleMap>

        this.setState({
            generateMaps
        });
    };

    apiHasLoaded = (map, maps) => {
        this.setState({
            mapApiLoaded: true,
            mapInstance: map,
            mapApi: maps,
        });
    };

    defaultPlace = (place) => {
        this.setState({
            places: [place],
            mapCenter: {
                lat: place.geometry.location.lat,
                lng: place.geometry.location.lng
            },
            locPinPoint: place.formatted_address,
            locProvinsi: place.address_components.find(x => x.types[0] === 'administrative_area_level_1').long_name,
            locKota: place.address_components.find(x => x.types[0] === 'administrative_area_level_2').long_name,
            locKecamatan: place.address_components.find(x => x.types[0] === 'administrative_area_level_3').long_name,
            locDesa: place.address_components.find(x => x.types[0] === 'administrative_area_level_4').long_name
        }, () => {
            this.countDistance();
        });
    };

    addPlace = (place) => {
        this.setState({
            places: [place],
            mapCenter: {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            },
            locPinPoint: place.formatted_address,
            locProvinsi: place.address_components.find(x => x.types[0] === 'administrative_area_level_1').long_name,
            locKota: place.address_components.find(x => x.types[0] === 'administrative_area_level_2').long_name,
            locKecamatan: place.address_components.find(x => x.types[0] === 'administrative_area_level_3').long_name,
            locDesa: place.address_components.find(x => x.types[0] === 'administrative_area_level_4').long_name
        }, () => {
            this.countDistance();
        });
    };

    countDistance = () => {
        const destination = this.state.mapCenter.lat + ',' + this.state.mapCenter.lng
        Helpers.countDistance(destination,this.state.branchCode).then(res => {
            this.setState({
                //locDistance: res.rows[0].elements[0].distance.value,
                //locDistanceDisplay: res.rows[0].elements[0].distance.text + ' / ' + res.rows[0].elements[0].duration.text,

                locDistance: res[0].distanceValue,
                locDistanceDisplay: res[0].distanceText + ' / ' + res[0].durationText,
            }, () => {
                this.handleGenerateMaps();
            });
        })
    };

    handleSubmit = (e) => {
        e.preventDefault();

        Helpers.saveLokasiPengiriman(
            this.state.sessEmail,
            this.state.locID,
            this.state.sessStore,
            this.state.sessUserType,
            this.state.locLabel,
            this.state.locPenerima,
            this.state.locPhone,
            this.state.locDetails,
            this.state.locPinPoint,
            this.state.locProvinsi,
            this.state.locKota,
            this.state.locKecamatan,
            this.state.locDesa,
            this.state.mapCenter.lat,
            this.state.mapCenter.lng,
            this.state.locDistance,
            '',
            this.state.locCommand
        ).then(res => {
            if(res.length > 0){
                if(res[0].alert == 'success'){
                    this.setState({
                        offset: 0,
                        currentPage: 0,
                        search: ''
                    });

                    setTimeout(function() {
                        window.location.href = './location';
                    }, 1000);
                }

                toast[res[0].alert](res[0].message);
            }else{
                toast.error(Helpers.MSG_ERROR);
            }
        })
    };

    render() {
        return (
            <>
                <main className="main pages mb-80">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <a href="./" rel="nofollow"><i className="fi-rs-home mr-5"></i>Beranda</a>
                                <span></span> Lokasi<span></span> Manage
                            </div>
                        </div>
                    </div>
                    <div className="page-content pt-20">
                        <div className="container">
                            {
                                this.state.mapApiLoaded && (
                                    <AutoComplete map={this.state.mapInstance} mapApi={this.state.mapApi} addplace={this.addPlace} />
                                )
                            }
                            <div style={{ height: '100vh', width: '100%' }}>
                                {this.state.generateMaps}
                            </div>
                            <div className="row">
                                <div className="col-lg-5 m-auto">
                                    <form className="contact-form-style mt-30 mb-30"
                                          method="post" onSubmit={(e) => {this.handleSubmit(e)}}>
                                        <div className="input-style mb-20">
                                            <label>Alamat (Berdasarkan Pin Point)</label>
                                            <textarea value={this.state.locPinPoint} onChange={e => this.setState({locPinPoint: e.target.value})}
                                                      placeholder="Cari atau arahkan pin sesuai lokasi anda"
                                                      readOnly={true} required />
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Estimasi Jarak & Waktu Tempuh Dari Gudang</label>
                                            <input value={this.state.locDistanceDisplay} onChange={e => this.setState({locDistanceDisplay: e.target.value})}
                                                   placeholder=""
                                                   type="text" readOnly={true} required/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Provinsi</label>
                                            <input value={this.state.locProvinsi} onChange={e => this.setState({locProvinsi: e.target.value})}
                                                   placeholder=""
                                                   type="text" readOnly={true} required/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Kota</label>
                                            <input value={this.state.locKota} onChange={e => this.setState({locKota: e.target.value})}
                                                   placeholder=""
                                                   type="text" readOnly={true} required/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Kecamatan</label>
                                            <input value={this.state.locKecamatan} onChange={e => this.setState({locKecamatan: e.target.value})}
                                                   placeholder=""
                                                   type="text" readOnly={true} required/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Desa</label>
                                            <input value={this.state.locDesa} onChange={e => this.setState({locDesa: e.target.value})}
                                                   placeholder=""
                                                   type="text" readOnly={true} required/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Detail Alamat</label>
                                            <input value={this.state.locDetails} onChange={e => this.setState({locDetails: e.target.value})}
                                                   placeholder="e.g. Gang Sukasenang, Nomor 14, Rumah Warna Putih"
                                                   type="text" required/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Simpan Alamat Sebagai</label>
                                            <input value={this.state.locLabel} onChange={e => this.setState({locLabel: e.target.value})}
                                                   placeholder="e.g. Rumah"
                                                   type="text" required/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Nama Penerima</label>
                                            <input value={this.state.locPenerima} onChange={e => this.setState({locPenerima: e.target.value})}
                                                   placeholder="Masukkan Nama Penerima"
                                                   type="text" required/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Nomor Handphone Penerima</label>
                                            <input value={this.state.locPhone} onChange={e => this.setState({locPhone: e.target.value})}
                                                   min={0}
                                                   placeholder="Masukkan Nomor Handphone Penerima"
                                                   type="number" required/>
                                        </div>
                                        <button className="submit submit-auto-width"
                                                type="submit">Submit
                                        </button>
                                        <a className="btn btn-outline" style={{'color': '#F47626'}} href={'./location'}>Kembali
                                        </a>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default New;