import React from "react";
import {encode as base64_encode} from 'base-64';
import { ReactSession } from 'react-client-session';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from '../../App';
import Footer from "../Footer";
import {Helpers} from "../../config/Helpers";
import $ from "jquery";

class Header extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            urlActive: window.location.pathname
        }
    }

    componentDidMount() {
        if(!this.state.sessUserCode){
            window.location.href = '/login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.checkBuyer();
        }
    }

    checkBuyer = () => {
        //$(".loader-wrapper").css("display", "");
        Helpers.getMPBuyer(this.state.sessUserCode,'','Aktif').then(res => {
            //$(".loader-wrapper").css("display", "none");
            if(res.length <= 0){
                ReactSession.set("sess_mp_buyerid", '');

                window.location.href = '/marketplace';
            }else{
                const r = res[0];

                ReactSession.set("sess_mp_buyerid", r.kode_buyer);

                this.setState({
                    sessMPBuyerId: r.kode_buyer
                });
            }
        })
    };

    render() {
        return (
            <>
                <ToastContainer />
                <div className="screen-overlay"></div>

                <div className="loader-wrapper" style={{'display': 'none'}}>
                    <div className="loader"></div>
                </div>

                <aside className="navbar-aside" id="offcanvas_aside">
                    <div className="aside-top">
                        <a href="/" className="brand-wrap">
                            <img src="/assets/imgs/theme/waroq-logo.png" className="logo" alt="Nest Dashboard"/>
                        </a>
                        <div>
                            <button className="btn btn-icon btn-aside-minimize"><i
                                className="text-muted material-icons md-menu_open"></i></button>
                        </div>
                    </div>
                    <nav>
                        <ul className="menu-aside">
                            <li className={(this.state.urlActive === '/marketplace/buyer/dashboard' || this.state.urlActive === '/marketplace/buyer') ? 'menu-item active' : 'menu-item'}>
                                <a className="menu-link" href="/marketplace/buyer/dashboard">
                                    <i className="icon material-icons md-home"></i>
                                    <span className="text">Dashboard</span>
                                </a>
                            </li>
                            <li className={(this.state.urlActive === '/marketplace/buyer/shopping') ? 'menu-item active' : 'menu-item'}>
                                <a className="menu-link" href="/marketplace/buyer/shopping">
                                    <i className="icon material-icons md-shopping_bag"></i>
                                    <span className="text">Shopping</span>
                                </a>
                            </li>
                            <li className={(this.state.urlActive === '/marketplace/buyer/orders') ? 'menu-item active' : 'menu-item'}>
                                <a className="menu-link" href="/marketplace/buyer/orders">
                                    <i className="icon material-icons md-shopping_cart"></i>
                                    <span className="text">Orders</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </aside>
                <main className="main-wrap">
                    <header className="main-header navbar">
                        <div className="col-search">
                        </div>
                        <div className="col-nav">
                            <button className="btn btn-icon btn-mobile me-auto" data-trigger="#offcanvas_aside"><i
                                className="material-icons md-apps"></i></button>
                            <ul className="nav">
                                <li className="nav-item">
                                    <a className="nav-link btn-icon darkmode" href="#"> <i
                                        className="material-icons md-nights_stay"></i> </a>
                                </li>
                                <li className="dropdown nav-item">
                                    <a className="dropdown-toggle" data-bs-toggle="dropdown" href="#"
                                       id="dropdownAccount" aria-expanded="false"> <img
                                        className="img-xs rounded-circle" src="/assets_admin/imgs/people/avatar-new.png"
                                        alt="User"/></a>
                                    <div className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownAccount">
                                        <a className="dropdown-item" href="/marketplace/buyer/account"><i
                                            className="material-icons md-perm_identity"></i>Account</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item text-danger" href="#"><i
                                            className="material-icons md-exit_to_app"></i>Logout</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </header>
                    <App/>
                    <Footer/>
                </main>
            </>
        );
    }
}

export default Header;