import React from "react";
import {encode as base64_encode} from 'base-64';
import { ReactSession } from 'react-client-session';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from "query-string";
import Select from 'react-select';
import $ from 'jquery';

import {Helpers} from "../config/Helpers";
import {Connection} from "../config/Connection";
import GoogleMap from '../components/GoogleMap';
import AutoComplete from "../components/AutoComplete";

const Marker = props => {
    return <>
        <div className="pin"></div>
        <div className="pulse"></div>
    </>
}

class RegisterSupplier extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            urlParam: queryString.parse(window.location.search),

            dataSupplierType: [],
            dataBusinessType: [],
            dataPosition: [],

            mapCenter: {
                lat: -6.213110657838351,
                lng: 106.82010690652227
            },
            mapZoom: 15,
            mapApiLoaded: false,
            mapInstance: null,
            mapApi: null,
            places: [],

            locPinPoint: '',
            locDistance: '',
            locDistanceDisplay: '',
            locProvinsi: '',
            locKota: '',
            locKecamatan: '',
            locDesa: '',
            locDetails: '',
            locPostalCode: '',

            supplier_type: '',
            supplier_name: '',
            business_type: '',
            company_name: '',
            company_email: '',
            company_phone: '',
            company_website: '',
            fullname: '',
            position: '',
            phone: '',
            products: '',
            others_products: '',
            description: '',

            isCheckAll: false,
            isMakanan: false,
            isKebutuhanDapur: false,
            isMinuman: false,
            isKebutuhanHotel: false,
            isFurniture: false,
            isJasa: false,
            isEngineer: false,
            isSembako: false,
            isIT: false,
            isOtomotif: false,
            isOther: false,

            arrProducts: [
                {name: 'Makanan', state: 'isMakanan'},
                {name: 'Kebutuhan Dapur', state: 'isKebutuhanDapur'},
                {name: 'Minuman', state: 'isMinuman'},
                {name: 'Kebutuhan Hotel', state: 'isKebutuhanHotel'},
                {name: 'Furniture', state: 'isFurniture'},
                {name: 'Jasa', state: 'isJasa'},
                {name: 'Engineer', state: 'isEngineer'},
                {name: 'Sembako', state: 'isSembako'},
                {name: 'IT', state: 'isIT'},
                {name: 'Otomotif', state: 'isOtomotif'},
                {name: 'Other', state: 'isOther'}
            ]
        }
    }

    componentDidMount() {
        if(!this.state.sessUserCode){
            window.location.href = '/login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.checkSupplier();
        }
    }

    checkSupplier = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getMPSupplier(this.state.sessUserCode,'','Aktif').then(res => {
            if(res.length > 0){
                window.location.href = 'supplier';
            }else{
                this.prepareData();

                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        this.setState({
                            mapCenter: {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude
                            }
                        }, () => {
                            this.googleLocationDetails();
                        });
                    });
                }
            }
        })
    };

    prepareData = () => {
        this.getMasterData('Master_MP_Supplier_Type','','dataSupplierType','id','name');
        this.getMasterData('Master_MP_Business_Type','','dataBusinessType','id','name');
        this.getMasterData('Master_MP_Position','','dataPosition','id','name');
    };

    getMasterData = (table,id,state,value,label,additional) => {
        Helpers.getMasterData(table,id).then(res => {
            if(res){
                this.setState({
                    [state]: res.map(function(item) {
                        return {
                            additional: (additional) ? item[additional] : null,
                            value: item[value],
                            label: (label instanceof Array) ? label.map(function(val, index) {
                                let setVal = (val.includes("|")) ? item[val.split("|")[0]][val.split("|")[1]].trim() : item[val].trim();
                                return (
                                    index === label.length-1
                                ) ? setVal : setVal + ' - ';
                            }) : item[label].trim()
                        };
                    })
                }, () => {
                    if(table === 'Master_Wilayah_Indonesia'){
                        this.setState({
                            dataProvince: this.state[state].filter(item => {
                                return item.additional === 'provinsi';
                            }),
                        });
                    }
                });
            }
        })
    };

    googleLocationDetails = () => {
        const latlng = this.state.mapCenter.lat + ',' + this.state.mapCenter.lng;

        Helpers.googleLocationDetails(latlng).then(res => {
            this.defaultPlace(res.results[0]);
        });
    };

    handleGenerateMaps = () => {
        const generateMaps = <GoogleMap
            defaultZoom={this.state.mapZoom}
            defaultCenter={this.state.mapCenter}
            bootstrapURLKeys={{
                key: Connection.MAPS_API_KEY,
                libraries: ['places', 'geometry'],
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}>
            <Marker lat={parseFloat(this.state.mapCenter.lat)} lng={parseFloat(this.state.mapCenter.lng)} />
        </GoogleMap>

        this.setState({
            generateMaps
        });
    };

    apiHasLoaded = (map, maps) => {
        this.setState({
            mapApiLoaded: true,
            mapInstance: map,
            mapApi: maps,
        });
    };

    defaultPlace = (place) => {
        this.setState({
            places: [place],
            mapCenter: {
                lat: place.geometry.location.lat,
                lng: place.geometry.location.lng
            },
            locPinPoint: place.formatted_address,
            locProvinsi: place.address_components.find(x => x.types[0] === 'administrative_area_level_1').long_name,
            locKota: place.address_components.find(x => x.types[0] === 'administrative_area_level_2').long_name,
            locKecamatan: place.address_components.find(x => x.types[0] === 'administrative_area_level_3').long_name,
            locDesa: place.address_components.find(x => x.types[0] === 'administrative_area_level_4').long_name,
            locPostalCode: place.address_components.find(x => x.types[0] === 'postal_code').long_name
        }, () => {
            this.countDistance();
        });
    };

    addPlace = (place) => {
        console.log(place);
        this.setState({
            places: [place],
            mapCenter: {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            },
            locPinPoint: place.formatted_address,
            locProvinsi: place.address_components.find(x => x.types[0] === 'administrative_area_level_1').long_name,
            locKota: place.address_components.find(x => x.types[0] === 'administrative_area_level_2').long_name,
            locKecamatan: place.address_components.find(x => x.types[0] === 'administrative_area_level_3').long_name,
            locDesa: place.address_components.find(x => x.types[0] === 'administrative_area_level_4').long_name,
            locPostalCode: place.address_components.find(x => x.types[0] === 'postal_code').long_name
        }, () => {
            this.countDistance();
        });
    };

    countDistance = () => {
        const destination = this.state.mapCenter.lat + ',' + this.state.mapCenter.lng
        Helpers.countDistance(destination,this.state.branchCode).then(res => {
            $(".loader-wrapper").css("display", "none");
            this.setState({
                locDistance: res[0].distanceValue,
                locDistanceDisplay: res[0].distanceText + ' / ' + res[0].durationText,
            }, () => {
                this.handleGenerateMaps();
            });
        })
    };

    handleChange = (event,state) => {
        this.setState({ [state]: event.value });
    };

    handleChecked = (state) => {
        if(state === 'isCheckAll'){
            if(this.state.isCheckAll){
                this.setState({
                    isCheckAll: false,
                    isMakanan: false,
                    isKebutuhanDapur: false,
                    isMinuman: false,
                    isKebutuhanHotel: false,
                    isFurniture: false,
                    isJasa: false,
                    isEngineer: false,
                    isSembako: false,
                    isIT: false,
                    isOtomotif: false,
                    isOther: false
                });
            }else{
                this.setState({
                    isCheckAll: true,
                    isMakanan: true,
                    isKebutuhanDapur: true,
                    isMinuman: true,
                    isKebutuhanHotel: true,
                    isFurniture: true,
                    isJasa: true,
                    isEngineer: true,
                    isSembako: true,
                    isIT: true,
                    isOtomotif: true
                });
            }
        }else{
            this.setState({
                [state]: !this.state[state]
            });
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();

        $(".loader-wrapper").css("display", "");

        const latlng = this.state.mapCenter.lat + ',' + this.state.mapCenter.lng;
        let getProduct = '';
        for(var i=0; i<this.state.arrProducts.length; i++){
            if(this.state[this.state.arrProducts[i].state]){
                getProduct = (getProduct) ? getProduct+','+this.state.arrProducts[i].name : this.state.arrProducts[i].name;
            }
        }

        Helpers.manageMPSupplier(
            this.state.sessUserCode,
            this.state.sessUserCode,
            '',
            this.state.supplier_type,
            encodeURIComponent(this.state.supplier_name),
            this.state.business_type,
            encodeURIComponent(this.state.company_name),
            this.state.company_email,
            this.state.company_phone,
            this.state.company_website,
            encodeURIComponent(this.state.fullname),
            this.state.position,
            this.state.phone,
            getProduct,
            encodeURIComponent(this.state.others_products),
            encodeURIComponent(this.state.description),
            this.state.locPostalCode,
            this.state.locDesa,
            this.state.locKecamatan,
            this.state.locKota,
            this.state.locProvinsi,
            encodeURIComponent(this.state.locDetails),
            this.state.locPinPoint,
            latlng,
            'insert'
        ).then(res => {
            $(".loader-wrapper").css("display", "none");

            if(res.length > 0){
                if(res[0].alert == 'success'){
                    setTimeout(function() {
                        window.location.href = 'supplier';
                    }, 1000);
                }

                toast[res[0].alert](res[0].message);
            }else{
                toast.error(Helpers.MSG_ERROR);
            }
        })
    };

    render() {
        return (
            <>
                <ToastContainer />

                <div className="loader-wrapper" style={{'display': 'none'}}>
                    <div className="loader"></div>
                </div>

                <main>
                    <header className="main-header style-2 navbar">
                        <div className="col-brand">
                            <a href="/marketplace" className="brand-wrap">
                                <img src="/assets/imgs/theme/waroq-logo.png" className="logo" alt="Nest Dashboard"/>
                            </a>
                        </div>
                    </header>
                    <section className="content-main">
                        <div className="card">
                            <div className="card-body">
                                <form method="post" onSubmit={(e) => {this.handleSubmit(e)}}>
                                    <h3 className="mb-15">Supplier Registration</h3>

                                    <h5 className="mb-2">Company Information</h5>
                                    <div className="mb-2">
                                        <label className="form-label">Supplier Type</label>
                                        <Select
                                            options={this.state.dataSupplierType}
                                            value={this.state.dataSupplierType.filter((item) => item.value === this.state.supplier_type)}
                                            onChange={event => this.handleChange(event,'supplier_type')} />
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label">Supplier Name</label>
                                        <input type="text" placeholder="Type here" className="form-control" required
                                               value={this.state.supplier_name} onChange={e => this.setState({supplier_name: e.target.value})}/>
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label">Business Type</label>
                                        <Select
                                            options={this.state.dataBusinessType}
                                            value={this.state.dataBusinessType.filter((item) => item.value === this.state.business_type)}
                                            onChange={event => this.handleChange(event,'business_type')} />
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label">Company Name</label>
                                        <input type="text" placeholder="Type here" className="form-control" required
                                               value={this.state.company_name} onChange={e => this.setState({company_name: e.target.value})}/>
                                    </div>
                                    <div>
                                        {
                                            this.state.mapApiLoaded && (
                                                <AutoComplete map={this.state.mapInstance} mapApi={this.state.mapApi} addplace={this.addPlace} />
                                            )
                                        }
                                        <div style={{ height: '100vh', width: '100%' }}>
                                            {this.state.generateMaps}
                                        </div>
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label">Provinsi</label>
                                        <input type="text" placeholder="Type here" className="form-control" required readOnly={true}
                                               value={this.state.locProvinsi} onChange={e => this.setState({locProvinsi: e.target.value})}/>
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label">Kota/Kabupaten</label>
                                        <input type="text" placeholder="Type here" className="form-control" required readOnly={true}
                                               value={this.state.locKota} onChange={e => this.setState({locKota: e.target.value})}/>
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label">Kecamatan</label>
                                        <input type="text" placeholder="Type here" className="form-control" required readOnly={true}
                                               value={this.state.locKecamatan} onChange={e => this.setState({locKecamatan: e.target.value})}/>
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label">Kelurahan</label>
                                        <input type="text" placeholder="Type here" className="form-control" required readOnly={true}
                                               value={this.state.locDesa} onChange={e => this.setState({locDesa: e.target.value})}/>
                                    </div>
                                    <div className="mb-2 mt-2">
                                        <label className="form-label">Postal Code</label>
                                        <input type="text" placeholder="Type here" className="form-control" required
                                               value={this.state.locPostalCode} onChange={e => this.setState({locPostalCode: e.target.value})}/>
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label">Details Address</label>
                                        <textarea className="form-control" rows="3" required
                                                  value={this.state.locDetails} onChange={e => this.setState({locDetails: e.target.value})} />
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label">Company Email</label>
                                        <input type="email" placeholder="Type here" className="form-control" required
                                               value={this.state.company_email} onChange={e => this.setState({company_email: e.target.value})}/>
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label">Company Phone Number</label>
                                        <input type="number" placeholder="Type here" className="form-control" required
                                               value={this.state.company_phone} onChange={e => this.setState({company_phone: e.target.value})}/>
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label">Website (optional)</label>
                                        <input type="text" placeholder="Type here" className="form-control"
                                               value={this.state.company_website} onChange={e => this.setState({company_website: e.target.value})}/>
                                    </div>

                                    <h5 className="mb-2 mt-15">Contact Information</h5>
                                    <div className="mb-2">
                                        <label className="form-label">Full Name</label>
                                        <input type="text" placeholder="Type here" className="form-control" required
                                               value={this.state.fullname} onChange={e => this.setState({fullname: e.target.value})}/>
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label">Position in Company</label>
                                        <Select
                                            options={this.state.dataPosition}
                                            value={this.state.dataPosition.filter((item) => item.value === this.state.position)}
                                            onChange={event => this.handleChange(event,'position')} />
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label">Mobile Number</label>
                                        <input type="number" placeholder="Type here" className="form-control" required
                                               value={this.state.phone} onChange={e => this.setState({phone: e.target.value})}/>
                                    </div>

                                    <h5 className="mb-2 mt-15">What products does your company offer</h5>
                                    <div className="mb-2">
                                        <label className="form-check">
                                            <input type="checkbox" className="form-check-input"
                                                   checked={this.state.isCheckAll}
                                                   onClick={() => {this.handleChecked('isCheckAll')}}/>
                                            <span className="form-check-label">Check all that applies</span>
                                        </label>
                                        <div className="row ml-15 mb-2">
                                            <div className="col-lg-6">
                                                <label className="form-check mt-1">
                                                    <input type="checkbox" className="form-check-input"
                                                           checked={this.state.isMakanan}
                                                           onClick={() => {this.handleChecked('isMakanan')}}/>
                                                    <span className="form-check-label">Makanan</span>
                                                </label>
                                                <label className="form-check mt-1">
                                                    <input type="checkbox" className="form-check-input"
                                                           checked={this.state.isKebutuhanDapur}
                                                           onClick={() => {this.handleChecked('isKebutuhanDapur')}}/>
                                                    <span className="form-check-label">Kebutuhan Dapur</span>
                                                </label>
                                                <label className="form-check mt-1">
                                                    <input type="checkbox" className="form-check-input"
                                                           checked={this.state.isMinuman}
                                                           onClick={() => {this.handleChecked('isMinuman')}}/>
                                                    <span className="form-check-label">Minuman</span>
                                                </label>
                                                <label className="form-check mt-1">
                                                    <input type="checkbox" className="form-check-input"
                                                           checked={this.state.isKebutuhanHotel}
                                                           onClick={() => {this.handleChecked('isKebutuhanHotel')}}/>
                                                    <span className="form-check-label">Kebutuhan Hotel</span>
                                                </label>
                                                <label className="form-check mt-1">
                                                    <input type="checkbox" className="form-check-input"
                                                           checked={this.state.isFurniture}
                                                           onClick={() => {this.handleChecked('isFurniture')}}/>
                                                    <span className="form-check-label">Furniture</span>
                                                </label>
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="form-check mt-1">
                                                    <input type="checkbox" className="form-check-input"
                                                           checked={this.state.isJasa}
                                                           onClick={() => {this.handleChecked('isJasa')}}/>
                                                    <span className="form-check-label">Jasa</span>
                                                </label>
                                                <label className="form-check mt-1">
                                                    <input type="checkbox" className="form-check-input"
                                                           checked={this.state.isEngineer}
                                                           onClick={() => {this.handleChecked('isEngineer')}}/>
                                                    <span className="form-check-label">Engineer</span>
                                                </label>
                                                <label className="form-check mt-1">
                                                    <input type="checkbox" className="form-check-input"
                                                           checked={this.state.isSembako}
                                                           onClick={() => {this.handleChecked('isSembako')}}/>
                                                    <span className="form-check-label">Sembako</span>
                                                </label>
                                                <label className="form-check mt-1">
                                                    <input type="checkbox" className="form-check-input"
                                                           checked={this.state.isIT}
                                                           onClick={() => {this.handleChecked('isIT')}}/>
                                                    <span className="form-check-label">IT</span>
                                                </label>
                                                <label className="form-check mt-1">
                                                    <input type="checkbox" className="form-check-input"
                                                           checked={this.state.isOtomotif}
                                                           onClick={() => {this.handleChecked('isOtomotif')}}/>
                                                    <span className="form-check-label">Otomotif</span>
                                                </label>
                                            </div>
                                        </div>
                                        <label className="form-check mb-2">
                                            <input type="checkbox" className="form-check-input"
                                                   checked={this.state.isOther}
                                                   onClick={() => {this.handleChecked('isOther')}}/>
                                            <span className="form-check-label">Other</span>
                                        </label>
                                        <input type="text" placeholder="Product/Service Description" className="form-control" readOnly={(this.state.isOther) ? false : true}
                                               value={this.state.others_products} onChange={e => this.setState({others_products: e.target.value})}/>
                                    </div>

                                    <h5 className="mb-2 mt-15">Write a brief description about your business</h5>
                                    <div className="mb-2">
                                        <textarea className="form-control" rows="3" required
                                                  value={this.state.description} onChange={e => this.setState({description: e.target.value})} />
                                    </div>

                                    <button className="btn btn-primary mt-15" type="submit">Submit</button>
                                </form>
                            </div>
                        </div>
                    </section>
                </main>
            </>
        );
    }
}

export default RegisterSupplier;