import React from "react";
import {encode as base64_encode} from 'base-64';
import { ReactSession } from 'react-client-session';

class History extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : ''
        };
    }

    componentDidMount() {
        if(!this.state.sessUserCode){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }
    }

    render() {
        return (
            <>
                <main className="main pages mb-80">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <a href="./" rel="nofollow"><i className="fi-rs-home mr-5"></i>Home</a>
                                <span></span> Akun <span></span> Riwayat Transaksi
                            </div>
                        </div>
                    </div>
                    <div className="page-content pt-10">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 m-auto">
                                    <div className="banner-img wow animate__animated animate__fadeInUp w-100" data-wow-delay="0" style={{backgroundImage: `url(./assets/imgs/background/v2_bg_orange.jpeg)`}}>
                                        <center>
                                            <img src={'./assets/imgs/icon/v2_ic_history_all_white.png'} width={100}/><br/>
                                            <label style={{'color': '#FFFFFF'}}>Silahkan pilih jenis riwayat transaksi di bawah ini</label><br/>
                                            <a href="./account-history-oder" className="btn btn-xs mb-15 mt-15" style={{'background-color': '#FBBC17', 'color': '#F47626', 'width': '152px', 'display': 'inline-block'}}>ORDER</a><br/>
                                            <a href="./account-history-withdrawal" className="btn btn-xs mb-15" style={{'background-color': '#FBBC17', 'color': '#F47626', 'width': '152px', 'display': 'inline-block'}}>PENARIKAN POINT</a><br/>
                                            <a href="./account-history-voucher" className="btn btn-xs mb-15" style={{'background-color': '#FBBC17', 'color': '#F47626', 'width': '152px', 'display': 'inline-block'}}>TRANSFER SALDO</a>
                                        </center>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default History;