import React from "react";
import ReactPaginate from 'react-paginate';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { ReactSession } from 'react-client-session';

import { Helpers } from "../../config/Helpers";
import {toast} from "react-toastify";
import queryString from "query-string";

class List extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            sessStore: (ReactSession.get("sess_order_store")) ? ReactSession.get("sess_order_store") : '',
            branchCode: (ReactSession.get("branch_code")) ? ReactSession.get("branch_code") : 'CBNG-18-020001',
            offset: 0,
            perPage: 10,
            currentPage: 0,
            pageNumList: [10,25,50,100,150,200],
            sortData: ['Featured','Name Ascending','Name Descending'],
            search: '',
            urlParam: queryString.parse(window.location.search),
        };
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    componentDidMount() {
        if(!this.state.sessUserCode){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            const kodeOrder = (this.state.urlParam.trxid) ? base64_decode(this.state.urlParam.trxid) : '';
            const type = (this.state.urlParam.type) ? this.state.urlParam.type : '';

            this.setState({
                kodeTransaksiOrder: kodeOrder,
                type: type
            }, () => {
                this.getLocations();
            });
        }
    }

    getLocations = () => {
        Helpers.getListLokasiPengiriman('',this.state.sessEmail, this.state.branchCode).then(res => {
            let sortData = res;
            if(this.state.search !== ''){
                sortData = sortData.filter(item => {
                    const textData = this.state.search.toUpperCase();

                    return Object.keys(item).some(key => ((item[key]) ? item[key] : '').toString().toUpperCase().search(textData) !== -1);
                });
            }
            if(this.state.sortDataValue == 'Name Descending'){
                sortData = sortData.sort(function(a,b) {
                    const val_a = a.Nama.toUpperCase();
                    const val_b = b.Nama.toUpperCase();

                    return (val_a < val_b) ? 1 : ((val_b < val_a) ? -1 : 0);
                });
            }
            if(this.state.sortDataValue == 'Name Ascending'){
                sortData = sortData.sort(function(a,b) {
                    const val_a = a.Nama.toUpperCase();
                    const val_b = b.Nama.toUpperCase();

                    return (val_a > val_b) ? 1 : ((val_b > val_a) ? -1 : 0);
                });
            }

            const slice = sortData.slice(this.state.offset, this.state.offset + this.state.perPage)
            const postData = slice.map((object, i) => {
                return <div className="col-lg-3 col-md-6 col-12 col-sm-6">
                    <div className="vendor-wrap mb-40">
                        <div className="vendor-img-action-wrap" style={{'padding': 'unset'}}>
                            <div className="vendor-img">
                                <table style={{'margin-bottom': 'unset'}}>
                                    <tr>
                                        <td>
                                            <div className="d-flex justify-content-between align-items-end">
                                                <div>
                                                    <h4 style={{'font-size': '15px'}}><a style={{'color': '#000'}}>{object.Nama}</a></h4>
                                                    <p className="mt-2" style={{'font-size': '13px', 'line-height': '1.5'}} align="justify">
                                                        {object.Penerima} <br/>
                                                        {object.NoPonsel} <br/>
                                                        {object.PinPoint}
                                                    </p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <div className="product-badges product-badges-position product-badges-mrg">
                                <span className="hot">
                                    <a onClick={() => {this.handleLocationDelete(object)}}><i className="fi-rs-trash"></i></a>
                                </span>
                            </div>
                        </div>
                        <div className="vendor-content-wrap" style={{'padding': '10px 20px 10px 20px'}}>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <a href={'./location-manage?trxid=' + base64_encode(object.KodeLokasi) + '&action=update'} className="btn btn-xs btn-secondary">Ubah</a>
                                </div>

                                <div>
                                    <a onClick={() => {this.handleLocationClick(object)}} className="btn btn-xs">Pilih Lokasi <i className="fi-rs-arrow-small-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            })

            this.setState({
                pageCount: Math.ceil(sortData.length / this.state.perPage),
                postData
            })
        })
    };

    handleLocationClick = (object) => {
        let check_jarak = (parseFloat(object.Jarak)/1000).toFixed(2);
        if(check_jarak > 200){
            toast.error(Helpers.MSG_ERROR_DISTANCE);

            ReactSession.set("sess_order_kodelokasi", '');
            ReactSession.set("sess_order_jarak", '0');
            ReactSession.set("sess_order_penerima", '');
            ReactSession.set("sess_order_noponsel", '');
            ReactSession.set("sess_order_latitude", '0');
            ReactSession.set("sess_order_longitude", '0');
            ReactSession.set("sess_order_pinpoint", '');
            ReactSession.set("sess_order_jasakirim", '0');
        }else{
            ReactSession.set("sess_order_kodelokasi", object.KodeLokasi);
            ReactSession.set("sess_order_jarak", object.Jarak);
            ReactSession.set("sess_order_penerima", object.Penerima);
            ReactSession.set("sess_order_noponsel", object.NoPonsel);
            ReactSession.set("sess_order_latitude", object.Latitude);
            ReactSession.set("sess_order_longitude", object.Longitude);
            ReactSession.set("sess_order_pinpoint", object.PinPoint);
            ReactSession.set("sess_order_jasakirim", object.EstimasiJasaKirim2);

            if(this.state.type){
                if(this.state.type === 'change_delivery'){
                    window.location.href = './change-delivery-method?trxid=' + base64_encode(this.state.kodeTransaksiOrder);
                }else{
                    window.location.href = './checkout';
                }
            }else{
                window.location.href = './checkout';
            }
        }
    };

    handleLocationDelete = (object) => {
        if (window.confirm('Apakah anda yakin akan menghapus alamat ini?')) {
            Helpers.saveLokasiPengiriman(
                this.state.sessEmail,
                object.KodeLokasi,
                this.state.sessStore,
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                'delete'
            ).then(res => {
                if(res.length > 0){
                    if(res[0].alert == 'success'){
                        this.setState({
                            offset: 0,
                            currentPage: 0,
                            search: ''
                        });

                        this.getLocations();
                    }

                    toast[res[0].alert](res[0].message);
                }else{
                    toast.error(Helpers.MSG_ERROR);
                }
            })
        } else {
            console.log('Cancel.');
        }
    };

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.getLocations()
        });

    };

    handlePageNumClick = (num) => {
        this.setState({
            currentPage: 0,
            offset: 0,
            perPage: num,
        }, () => {
            this.getLocations()
        });
    };

    handleSortByClick = (sortBy) => {
        this.setState({
            currentPage: 0,
            offset: 0,
            sortDataValue: sortBy
        }, () => {
            this.getLocations()
        });
    };

    handleSearch = (e) => {
        e.preventDefault();

        this.setState({
            currentPage: 0,
            offset: 0
        }, () => {
            this.getLocations()
        });
    };

    render() {
        return (
            <>
                <main className="main pages mb-80">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <a href="./" rel="nofollow"><i className="fi-rs-home mr-5"></i>Beranda</a>
                                <span></span> Lokasi
                            </div>
                        </div>
                    </div>
                    <div className="page-content pt-20">
                        <div className="container">
                            <div className="archive-header-2 text-center">
                                <div className="row">
                                    <div className="col-lg-5 mx-auto">
                                        <div className="sidebar-widget-2 widget_search mb-50">
                                            <div className="search-form">
                                                <form method="post" onSubmit={(e) => {this.handleSearch(e)}}>
                                                    <input type="text" placeholder="Cari lokasi ..." value={this.state.search} onChange={e => this.setState({search: e.target.value})}/>
                                                    <button type="submit"><i className="fi-rs-search"></i></button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-lg-8 mx-auto">
                                    <div className="shop-product-fillter">
                                        <div className="totall-product">
                                            <a href={'./location-manage?trxid=&action=insert'} className="btn btn-sm">Buat Baru</a>
                                        </div>
                                        <div className="sort-by-product-area">
                                            <div className="sort-by-cover mr-10">
                                                <div className="sort-by-product-wrap">
                                                    <div className="sort-by">
                                                        <span><i className="fi-rs-apps"></i>Show:</span>
                                                    </div>
                                                    <div className="sort-by-dropdown-wrap">
                                                        <span> {this.state.perPage} <i className="fi-rs-angle-small-down"></i></span>
                                                    </div>
                                                </div>
                                                <div className="sort-by-dropdown">
                                                    <ul>
                                                        {
                                                            this.state.pageNumList.map((object, i) => {
                                                                return <li><a className={(object == this.state.perPage) ? 'active': ''} onClick={() => {this.handlePageNumClick(object)}}>{object}</a></li>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="sort-by-cover">
                                                <div className="sort-by-product-wrap">
                                                    <div className="sort-by">
                                                        <span><i className="fi-rs-apps-sort"></i>Sort by:</span>
                                                    </div>
                                                    <div className="sort-by-dropdown-wrap">
                                                        <span> Featured <i className="fi-rs-angle-small-down"></i></span>
                                                    </div>
                                                </div>
                                                <div className="sort-by-dropdown">
                                                    <ul>
                                                        {
                                                            this.state.sortData.map((object, i) => {
                                                                return <li><a className={(object == this.state.sortDataValue) ? 'active': ''} onClick={() => {this.handleSortByClick(object)}}>{object}</a></li>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row vendor-grid">
                                {this.state.postData}
                            </div>

                            <div className="pagination-area mt-20 mb-20">
                                <nav aria-label="Page navigation example">
                                    <ReactPaginate
                                        previousLabel={<i className="fi-rs-arrow-small-left"></i>}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextLabel={<i className="fi-rs-arrow-small-right"></i>}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakLabel={"..."}
                                        breakClassName={"page-link dot"}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={1}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={"pagination justify-content-start"}
                                        activeClassName={"page-item active"}
                                        activeLinkClassName={"page-link"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}/>
                                </nav>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default List;