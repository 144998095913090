import React from "react";
import {ReactSession} from "react-client-session";
import {Helpers} from "../../../config/Helpers";
import Select from 'react-select';
import {toast} from "react-toastify";
import $ from "jquery";
import {Connection} from "../../../config/Connection";
import {decode as base64_decode} from "base-64";
import queryString from "query-string";
import {MDBDataTableV5} from "mdbreact";

class ProductsInventory extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            urlActive: window.location.pathname,
            urlParam: queryString.parse(window.location.search),

            dataSource: [],
            kode_product: '',
            brand: '',
            name: '',
            sku: '',
            unit: '',
            stocks: 0,
            stocks_add: 0,
            expired_date: ''
        }
    }

    componentDidMount() {
        const kode_product = (this.state.urlParam.kode_product) ? this.state.urlParam.kode_product : '';

        this.setState({
            kode_product: kode_product
        }, () => {
            if(this.state.kode_product){
                this.getProductDetail();
                this.getData();
            }
        });
    }

    getProductDetail = () => {
        Helpers.getMPProducts('all','all',this.state.kode_product,'','').then(res => {
            let obj = res[0];
            this.setState({
                brand: obj.brand,
                name: obj.name,
                sku: obj.sku,
                unit: obj.unit_label,
                stocks: obj.stocks
            });
        })
    };

    getData = () => {
        Helpers.getMPProductsInventory(this.state.kode_product).then(res => {
            this.setState({ dataSource: res });
        })
    };

    handleSubmit = (e) => {
        e.preventDefault();

        Helpers.manageMPProductsInventory(
            this.state.sessUserCode,
            '',
            this.state.kode_product,
            this.state.stocks_add,
            this.state.expired_date
        ).then(res => {
            if(res.length > 0){
                if(res[0].alert == 'success'){
                    setTimeout(function() {
                        window.location.href = 'products';
                    }, 1000);
                }

                toast[res[0].alert](res[0].message);
            }else{
                toast.error(Helpers.MSG_ERROR);
            }
        })
    };

    render() {
        return (
            <>
                <section className="content-main">
                    <div className="content-header">
                        <div>
                            <h2 className="content-title card-title">Product Inventory</h2>
                            <p>Manage your stock here</p>
                        </div>
                    </div>
                    <div className="row mb-15">
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <div className="card-header">
                                    <h4>Basic</h4>
                                </div>
                                <div className="card-body">
                                    <form method="post" onSubmit={(e) => {this.handleSubmit(e)}}>
                                    <div className="mb-4">
                                        <label htmlFor="product_name" className="form-label">Product title</label>
                                        <input type="text" placeholder="Type here" className="form-control" readOnly={true}
                                               value={this.state.name} onChange={e => this.setState({name: e.target.value})}/>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="mb-4">
                                                <label htmlFor="product_name" className="form-label">Product Brand</label>
                                                <input type="text" placeholder="Type here" className="form-control" readOnly={true}
                                                       value={this.state.brand} onChange={e => this.setState({brand: e.target.value})}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="mb-4">
                                                <label htmlFor="product_name" className="form-label">SKU</label>
                                                <input type="text" placeholder="Type here" className="form-control" readOnly={true}
                                                       value={this.state.sku} onChange={e => this.setState({sku: e.target.value})}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="mb-4">
                                                <label htmlFor="product_name" className="form-label">Unit</label>
                                                <input type="text" placeholder="Type here" className="form-control" readOnly={true}
                                                       value={this.state.unit} onChange={e => this.setState({unit: e.target.value})}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="mb-4">
                                                <label className="form-label">Current Stock</label>
                                                <div className="row gx-2">
                                                    <input type="number" className="form-control" readOnly={true}
                                                           value={this.state.stocks} onChange={e => this.setState({stocks: e.target.value})}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="mb-4">
                                                <label className="form-label">Adjust Stock</label>
                                                <input type="number" className="form-control" required
                                                       value={this.state.stocks_add} onChange={e => this.setState({stocks_add: e.target.value})}/>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="mb-4">
                                                <label className="form-label">Expired Date</label>
                                                <input type="date" className="form-control" required
                                                       value={this.state.expired_date} onChange={e => this.setState({expired_date: e.target.value})}/>
                                            </div>
                                        </div>
                                    </div>

                                    <button className="btn btn-primary mt-15" type="submit">Submit</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <MDBDataTableV5
                                        hover
                                        fullPagination
                                        entriesOptions={[10, 25, 50, 100]}
                                        entries={10}
                                        data={
                                            {
                                                columns: [
                                                    {
                                                        label: '#ID',
                                                        field: 'kode_inventory',
                                                    },
                                                    {
                                                        label: 'Last Stock',
                                                        field: 'last_stock',
                                                    },
                                                    {
                                                        label: 'Adjust Stock',
                                                        field: 'add_stock',
                                                    },
                                                    {
                                                        label: 'Final Stock',
                                                        field: 'final_stock',
                                                    },
                                                    {
                                                        label: 'Expired Date',
                                                        field: 'expired_date_format',
                                                    },
                                                    {
                                                        label: 'Created Date',
                                                        field: 'co_format'
                                                    }
                                                ],
                                                rows: this.state.dataSource.map((row) => {
                                                    return {
                                                        ...row
                                                    };
                                                }),
                                            }
                                        } />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default ProductsInventory;