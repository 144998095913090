import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import {ReactSession} from "react-client-session";
import {decode as base64_decode, encode as base64_encode} from "base-64";

import { Helpers } from "../config/Helpers";
import ModalProduct from "../pages/Home";
import queryString from "query-string";

class DropdownCart extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            orderData: [],
            orderTotal: 0,
            orderType: (ReactSession.get("order_type")) ? ReactSession.get("order_type") : 'Grosir',
            branchCode: (ReactSession.get("branch_code")) ? ReactSession.get("branch_code") : 'CBNG-18-020001',
            urlParam: queryString.parse(window.location.search),
            kodeMarketPlaceDetail: '',
            patunganRequired: '',
            patunganPercentage: '',
            patunganTotal: '',
            patunganMinimal: ''
        }
    }

    componentDidMount() {
        if(this.state.sessUserCode){
            this.setupDropdownCart();
        }
    }

    setupDropdownCart = () => {
        Helpers.getProductDetails(this.state.sessEmail, '', this.state.branchCode, 'yes').then(res => {
            this.setState({
                kodeMarketPlaceDetail: (this.state.urlParam.joinid) ? base64_decode(this.state.urlParam.joinid) : '',
                orderData: res.filter(item => {
                    const qtyOrder = parseInt(item.QtyOrder);

                    return qtyOrder > 0;
                })
            }, () => {
                this.setState({
                    orderTotal: res.filter(item => {
                        const qtyOrder = parseInt(item.QtyOrder);

                        return qtyOrder > 0;
                    }).reduce((a,v) =>  a = a + ((this.state.orderType == 'Grosir') ? v.TotalHargaGrosirTerkecil : v.TotalHargaRetailTerkecil) , 0 ).toLocaleString(undefined, {maximumFractionDigits: 0}).toString().replaceAll(',','.')
                })

                if(this.state.kodeMarketPlaceDetail){
                    this.getMarketList();
                }
            });
        })
    };

    getMarketList = () => {
        Helpers.getMarketList(
            'yes',
            this.state.sessEmail,
            this.state.sessUserCode,
            this.state.orderType,
            this.state.branchCode,
            this.state.kodeMarketPlaceDetail
        ).then(res => {
            if(res.length > 0){
                this.setState({
                    patunganRequired: res[0].KurangBelanjaFormat,
                    patunganPercentage: res[0].Percentage,
                    patunganTotal: res[0].TotalTerpenuhiFormat,
                    patunganMinimal: res[0].MinimalOrderFormat
                });
            }
        })
    };

    handleCheckout = () => {
        if(this.state.sessUserCode){
            if(this.state.kodeMarketPlaceDetail){
                window.location.href = './checkout?joinid=' + this.state.urlParam.joinid;
            }else{
                window.location.href = './checkout';
            }
        }else{
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }
    };

    render() {
        return (
            <div className="header-action-icon-2">
                <a className="mini-cart-icon">
                    <img alt="Nest" src="./assets/imgs/theme/icons/icon-cart.svg" />
                    <span className="pro-count blue">{this.state.orderData.length}</span>
                </a>
                <a href="#"><span className="lable">Keranjang</span></a>
                <div className="cart-dropdown-wrap cart-dropdown-hm2">
                    <ul>
                        {
                            this.state.orderData.sort((a,b) => b-a).slice(0,3).map((object, i) => {
                                return <li>
                                    <div className="shopping-cart-title">
                                        <h4><a href="#">{object.NamaBarang}</a></h4>
                                        {
                                            this.state.orderType == 'Grosir' && (
                                                <h4><span>{object.QtyOrder} × </span>{object.HargaGrosirFormatTerkecil} = {object.TotalHargaGrosirFormatTerkecil}</h4>
                                            )
                                        }
                                        {
                                            this.state.orderType == 'Retail' && (
                                                <h4><span>{object.QtyOrder} × </span>{object.HargaRetailFormatTerkecil} = {object.TotalHargaRetailFormatTerkecil}</h4>
                                            )
                                        }

                                    </div>
                                </li>
                            })
                        }
                    </ul>
                    <div className="shopping-cart-footer">
                        {
                            this.state.kodeMarketPlaceDetail && (
                                <>
                                    <div className="shopping-cart-total">
                                        <h4>Kurang Belanja <span>{this.state.patunganRequired}</span></h4>
                                    </div>
                                    <div className="progress">
                                        <div className="progress-bar" role="progressbar" style={{width: this.state.patunganPercentage + '%'}}
                                             aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                    <div className="shopping-cart-total" style={{'border-top': 'unset', 'margin-top': '10px'}}>
                                        <h4>{this.state.patunganPercentage}% <span>{this.state.patunganTotal + ' / ' + this.state.patunganMinimal}</span></h4>
                                    </div>
                                </>
                            )
                        }
                        <div className="shopping-cart-total">
                            <h4>Total <span>{this.state.orderTotal}</span></h4>
                        </div>
                        <div className="shopping-cart-button">
                            <a onClick={() => {this.handleCheckout()}}>Checkout</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DropdownCart;