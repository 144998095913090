import React from "react";
import 'react-toastify/dist/ReactToastify.css';

class Footer extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (
            <>
                <footer className="main-footer font-xs">
                    <div className="row pb-30 pt-15">
                        <div className="col-sm-6">
                            2023 &copy; <strong className="text-brand">Waroq</strong> - Warung Baroqah Online
                        </div>
                        <div className="col-sm-6">
                            <div className="text-sm-end">All rights reserved</div>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}

export default Footer;