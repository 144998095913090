import React from "react";

import queryString from "query-string";
import {isMobile} from "react-device-detect";
import {Helpers} from "../../config/Helpers";
import $ from "jquery";
import Select from 'react-select';
import moment from "moment";
import 'react-html5-camera-photo/build/css/index.css';
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import {encode as base64_encode} from "base-64";
import {toast} from "react-toastify";
import OtpInput from 'react-otp-input';

class KoinWorksApplyOTP extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            urlParam: queryString.parse(window.location.search),
            kodeMarketing: '',
            otp: '',
            contract: ''
        }
    }

    componentDidMount() {
        window.location.href = './404';
        /*this.setState({
            kodeMarketing: (this.state.urlParam.kode) ? this.state.urlParam.kode : '',
        }, () => {
            this.getData();
        });

        if(isMobile) {
            $("#mobile-header").css("display", "none");
            $("#mobile-footer").css("display", "none");
        }*/
    }

    getData = () => {
        $("#preloader-active-x").css("display", "");
        Helpers.getSummary(this.state.kodeMarketing).then(res => {
            this.setState({
                contract: res[0].KoinWorksContract
            });
            $("#preloader-active-x").css("display", "none");
        })
    };

    generateToken = () => {
        $("#preloader-active-x").css("display", "");
        Helpers.koinWorksRequestGenerateToken(this.state.kodeMarketing).then(res => {
            this.handleOTP();
        })
    };

    handleOTP = () => {
        Helpers.koinWorksRequestOTP(this.state.kodeMarketing).then(res => {
            toast[res[0].alert](res[0].message);
            $("#preloader-active-x").css("display", "none");

            if(res[0].alert == 'error'){
                setTimeout(() => {
                    window.location.href = './account';
                }, 2000);
            }
        })
    };

    handleSubmit = (e) => {
        e.preventDefault();

        $("#preloader-active-x").css("display", "");
        Helpers.koinWorksValidateOTP(
            this.state.kodeMarketing,
            this.state.otp
        ).then(res => {
            if(res.length > 0){
                if(res[0].alert == 'success'){
                    setTimeout(function() {
                        window.location.href = './response-page';
                    }, 2000);
                }

                toast[res[0].alert](res[0].message);
            }else{
                toast.error(Helpers.MSG_ERROR);
            }

            $("#preloader-active-x").css("display", "none");
        })
    };

    handleViewLink = (url) => {
        window.location.href = './web-viewer?urlload=' + base64_encode(url) + '&urlback=' + base64_encode(window.location.href);
    }

    render() {
        return (
            <>
                <main className="main pages mb-80" style={{'display': 'none'}}>
                    <div className="page-content pt-10">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 m-auto">
                                    <div className="login_wrap widget-taber-content background-white">
                                        <div className="padding_eight_all bg-white">
                                            <div className="heading_s1">
                                                <h5 className="mb-5">Request OTP</h5>
                                                <div className="divider-text-center mb-15"></div>
                                                <p className="mb-10" align="justify">Dengan melakukan input OTP, Anda setuju memberikan data pribadi untuk keperluan penilaian pinjaman sesuai dengan S&K. Anda dapat mengajukan pinjaman hingga Rp 50.000.000.</p>

                                                <p className="mb-5">Syarat & Ketentuan: <a href="javascript:void(0);" onClick={() => {this.handleViewLink('https://koinworks.com/syarat-dan-ketentuan')}}>Lihat disini.</a></p>
                                                <p className="mb-30">Dokumen Kontrak: <a href="javascript:void(0);" onClick={() => {this.handleViewLink(this.state.contract)}}>Lihat disini.</a></p>

                                            </div>
                                            <form method="post" onSubmit={(e) => {this.handleSubmit(e)}}>
                                                <div className="form-group mb-30">
                                                    <OtpInput
                                                        inputStyle={{'width': '100%', 'padding-left': '0px'}}
                                                        value={this.state.otp}
                                                        onChange={otp => this.setState({otp})}
                                                        numInputs={6}
                                                        isInputSecure={true}
                                                        separator={<span>&nbsp;</span>}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <button type="submit"
                                                            className="btn btn-heading btn-block hover-up"
                                                            name="login">Submit
                                                    </button>
                                                    &nbsp;
                                                    <button type="button"
                                                            style={{'padding': '15px 40px', 'border-radius': '10px'}}
                                                            className="btn btn-heading btn-block hover-up"
                                                            onClick={() => {this.generateToken()}}>Resend OTP
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default KoinWorksApplyOTP;