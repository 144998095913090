import React from "react";
import {ReactSession} from "react-client-session";
import {Helpers} from "../../../config/Helpers";
import Select from 'react-select';
import {toast} from "react-toastify";
import $ from "jquery";
import { MDBDataTableV5, MDBBtn, MDBIcon } from 'mdbreact';
import {Connection} from "../../../config/Connection";

class Products extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            urlActive: window.location.pathname,
            sessMPSupplierId: (ReactSession.get("sess_mp_supplierid")) ? ReactSession.get("sess_mp_supplierid") : '',
            sessMPSupplierStatus: (ReactSession.get("sess_mp_supplierstatus")) ? ReactSession.get("sess_mp_supplierstatus") : '',
            dataSource: [],
            dataStatus: [
                {value: 'all', label: 'All Status'},
                {value: 'yes', label: 'Aktif'},
                {value: 'no', label: 'Tidak Aktif'}
            ],
            dataJenisBarang: [],
            isActive: 'all',
            jenis: ''
        }
    }

    componentDidMount() {
        this.getGoodsType();
        this.getData('all','','');
    }

    getData = (active, kode_product, jenis) => {
        $(".loader-wrapper").css("display", "");
        Helpers.getMPProducts(active,kode_product,jenis,this.state.sessMPSupplierId).then(res => {
            $(".loader-wrapper").css("display", "none");
            this.setState({ dataSource: res });
        });
    };

    getGoodsType = () => {
        Helpers.getGoodsType().then(res => {
            var arr = res.map(function(a) {
                return {value: a.KodeJenisBarang, label: a.JenisBarang};
            });

            this.setState({ dataJenisBarang: arr });
        })
    };

    handleChange = (event,state) => {
        this.setState({ [state]: event.value }, () => {
            this.getData(this.state.isActive,'',this.state.jenis);
        });
    };

    selectData = (id) => {
        window.location.href = 'products-form?kode_product=' + id;
    };

    restock = (id) => {
        window.location.href = 'products-inventory?kode_product=' + id;
    };

    handleReset = () => {
        this.setState({
            isActive: 'all',
            jenis: ''
        }, () => {
            this.getData(this.state.isActive,'',this.state.jenis);
        });
    };

    setArchive = (id,command) => {
        let message = (command === 'delete') ? 'Are you sure you want to archive?' : 'Are you sure you want to publish?';
        if (window.confirm(message) == true) {
            $(".loader-wrapper").css("display", "");
            Helpers.manageMPProducts(
                this.state.sessUserCode,
                id,
                this.state.sessMPSupplierId,
                '',
                '',
                '',
                command
            ).then(res => {
                $(".loader-wrapper").css("display", "none");
                if(res.length > 0){
                    if(res[0].alert == 'success'){
                        this.getData(this.state.isActive,'',this.state.jenis);
                    }

                    toast[res[0].alert](res[0].message);
                }else{
                    toast.error(Helpers.MSG_ERROR);
                }
            })
        }
    };

    render() {
        return (
            <>
                <section className="content-main">
                    <div className="content-header">
                        <div>
                            <h2 className="content-title card-title">Products List</h2>
                            <p>Find and manage your products here</p>
                        </div>
                        <div>
                            <a href="/marketplace/supplier/products-form" className="btn btn-primary btn-sm rounded">Create new</a>
                        </div>
                    </div>
                    <div className="card mb-4">
                        <header className="card-header">
                            <div className="row align-items-center">
                                <div className="col-md-3">
                                    <Select
                                        options={this.state.dataJenisBarang}
                                        value={this.state.dataJenisBarang.filter((item) => item.value === this.state.jenis)}
                                        onChange={event => this.handleChange(event,'jenis')} />
                                </div>
                                <div className="col-md-2">
                                    <Select
                                        options={this.state.dataStatus}
                                        value={this.state.dataStatus.filter((item) => item.value === this.state.isActive)}
                                        onChange={event => this.handleChange(event,'isActive')} />
                                </div>
                                <div className="col-md-2">
                                    <a className="btn btn-orange btn-sm rounded" onClick={() => {this.handleReset()}}>Reset Filter</a>
                                </div>
                            </div>
                        </header>
                        <div className="card-body">
                            <MDBDataTableV5
                                hover
                                fullPagination
                                entriesOptions={[10, 25, 50, 100]}
                                entries={10}
                                data={
                                    {
                                        columns: [
                                            {
                                                label: 'SKU',
                                                field: 'sku',
                                            },
                                            {
                                                label: 'Image',
                                                field: 'image',
                                            },
                                            {
                                                label: 'Name',
                                                field: 'name',
                                            },
                                            {
                                                label: 'Unit',
                                                field: 'unit',
                                            },
                                            {
                                                label: 'Waroq Price',
                                                field: 'waroq_price',
                                            },
                                            {
                                                label: 'Company Price',
                                                field: 'price_format',
                                            },
                                            {
                                                label: 'Type',
                                                field: 'product_type',
                                            },
                                            {
                                                label: 'Category',
                                                field: 'product_category',
                                            },
                                            {
                                                label: 'Status',
                                                field: 'status_view',
                                            },
                                            {
                                                label: 'Action',
                                                field: 'action',
                                            }
                                        ],
                                        rows: this.state.dataSource.map((row) => {
                                            return {
                                                ...row,
                                                image: (
                                                    <img src={row.url_photo} className="img-sm img-thumbnail"
                                                         alt="Item"/>
                                                ),
                                                status_view: (
                                                    <span className={'badge rounded-pill alert-'+((row.status === 'Aktif') ? 'success' : 'danger')}>{row.status_label}</span>
                                                ),
                                                action: (
                                                    <div className="dropdown">
                                                        <a href="#" data-bs-toggle="dropdown"
                                                           className="btn btn-brand rounded btn-sm font-sm"> <i
                                                            className="material-icons md-more_horiz"></i> </a>
                                                        <div className="dropdown-menu">
                                                            <a className="dropdown-item" onClick={() => {this.selectData(row.kode_product)}}>Edit Info</a>
                                                            {/*<a className="dropdown-item" onClick={() => {this.restock(row.kode_product)}}>Update Stock</a>*/}
                                                            {
                                                                row.status === 'Aktif' && (
                                                                    <a className="dropdown-item text-danger" onClick={() => {this.setArchive(row.kode_product,'delete')}}>Archive</a>
                                                                )
                                                            }
                                                            {
                                                                row.status === 'Tidak Aktif' && (
                                                                    <a className="dropdown-item text-success" onClick={() => {this.setArchive(row.kode_product,'active')}}>Publish</a>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                ),
                                            };
                                        }),
                                    }
                                } />
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Products;