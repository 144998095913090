import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Header from "./Header";
import Footer from "./Footer";
import Landing from "./marketplace/Landing";
import RegisterBuyer from "./marketplace/RegisterBuyer";
import RegisterSupplier from "./marketplace/RegisterSupplier";
import HeaderSupplier from "./marketplace/supplier/Header";
import HeaderBuyer from "./marketplace/buyer/Header";

const urlorigin =  window.location.origin;
const urlmp = urlorigin+'/marketplace';
const urlsupplier = urlmp+'/supplier';
const urlbuyer = urlmp+'/buyer';
const urlpath = window.location.protocol + '//' + window.location.host + '/' + window.location.pathname.split('/')[1];
const s_urlpath2 = window.location.pathname.split('/')[2];
const urlpath2 = urlpath + '/' + s_urlpath2;

console.log(urlpath);
console.log(urlpath2);

ReactDOM.render(
    <React.StrictMode>
        {
            urlpath !== urlmp && (
                <>
                    <Header/>
                    <App style={{'margin-bottom': '53px'}}/>
                    <Footer/>
                </>
            )
        }
        {
            urlpath === urlmp && (
                <>
                    <link rel="stylesheet" href="/assets_admin/css/main.css?v=1.1"/>
                    {
                        (s_urlpath2 === undefined || s_urlpath2 === '') && (
                            <>
                                <Landing/>
                            </>
                        )
                    }
                    {
                        (s_urlpath2 !== undefined && s_urlpath2 !== '' && s_urlpath2 === 'supplier') && (
                            <HeaderSupplier/>
                        )
                    }
                    {
                        (s_urlpath2 !== undefined && s_urlpath2 !== '' && s_urlpath2 === 'buyer') && (
                            <HeaderBuyer/>
                        )
                    }
                    {
                        (s_urlpath2 !== undefined && s_urlpath2 !== '' && s_urlpath2 === 'register_supplier') && (
                            <RegisterSupplier/>
                        )
                    }
                    {
                        (s_urlpath2 !== undefined && s_urlpath2 !== '' && s_urlpath2 === 'register_buyer') && (
                            <RegisterBuyer/>
                        )
                    }
                </>
            )
        }
    </React.StrictMode>,
    document.getElementById('root')
);
