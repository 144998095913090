import React from "react";
import {ReactSession} from "react-client-session";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import queryString from "query-string";
import {toast} from "react-toastify";
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import {isMobile, isAndroid, isIOS} from 'react-device-detect';
import OpenApp from "react-open-app";

import { Helpers } from "../../config/Helpers";

class OrderPayment extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            paymentDetails: '',
            kodeTransaksiOrder: '',
            kodePaymentGateway: '',
            isModalOpen: false,
            metodePembayaran: '',
            totalPembayaran: '',
            bayarSebelum: '',
            sisaWaktuPembayaran: '',
            virtualAccount: '',
            OVOPhoneNumber: '',
            urlParam: queryString.parse(window.location.search),

            time: {},
            seconds: 0,
            openTime: false
        }
        this.timer = 0;
    }

    componentDidMount() {
        if(!this.state.sessUserCode){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            const kodeOrder = (this.state.urlParam.trxid) ? base64_decode(this.state.urlParam.trxid) : '';
            const kodePayment = (this.state.urlParam.payment) ? base64_decode(this.state.urlParam.payment) : '';
            const type = (this.state.urlParam.type) ? this.state.urlParam.type : '';

            this.setState({
                kodeTransaksiOrder: kodeOrder,
                kodePaymentGateway: kodePayment,
                type: type
            }, () => {
                this.getDetailPayment();
            });
        }
    }

    getDetailPayment = () => {
        if(this.state.kodePaymentGateway == 'KPG-0014'){
            Helpers.getListOrder('yes',this.state.kodeTransaksiOrder,'','','').then(res => {
                if(res[0].SisaWaktuPembayaranSeconds > 0){
                    this.setState({
                        paymentDetails: res[0],
                        metodePembayaran: 'OVO',
                        totalPembayaran: 'Rp ' + res[0].TotalHargaOrder,
                        bayarSebelum: '',
                        virtualAccount: '',
                        openTime: true,
                        seconds: parseInt(res[0].SisaWaktuPembayaranSeconds)/1000,
                    }, () => {
                        let timeLeftVar = this.secondsToTime(this.state.seconds);
                        this.setState({
                            time: timeLeftVar
                        }, () => {
                            this.startTimer();
                        });
                    });
                }else{
                    this.setState({
                        paymentDetails: res[0],
                        metodePembayaran: 'OVO',
                        totalPembayaran: 'Rp ' + res[0].TotalHargaOrder,
                        bayarSebelum: '',
                        virtualAccount: '',
                        sisaWaktuPembayaran: 'Waktu pembayaran habis, order telah dibatalkan atau dalam proses menunggu konfirmasi pembayaran.',
                        openTime: false,
                        seconds: 0,
                    });
                }
            })
        }else{
            Helpers.getDetailPayment(this.state.kodeTransaksiOrder,this.state.kodePaymentGateway).then(res => {
                if(res[0].ExpiredSeconds > 0){
                    this.setState({
                        paymentDetails: res[0],
                        metodePembayaran: res[0].Bank,
                        totalPembayaran: 'Rp ' + res[0].TotalPembayaran,
                        bayarSebelum: res[0].ExpiredFormatView,
                        virtualAccount: res[0].AccountNo,
                        openTime: true,
                        seconds: parseInt(res[0].ExpiredSeconds)/1000,
                    }, () => {
                        let timeLeftVar = this.secondsToTime(this.state.seconds);
                        this.setState({
                            time: timeLeftVar
                        }, () => {
                            this.startTimer();
                        });
                    });
                }else{
                    this.setState({
                        paymentDetails: res[0],
                        metodePembayaran: res[0].Bank,
                        totalPembayaran: 'Rp ' + res[0].TotalPembayaran,
                        bayarSebelum: res[0].ExpiredFormatView,
                        virtualAccount: res[0].AccountNo,
                        sisaWaktuPembayaran: 'Waktu pembayaran habis, order telah dibatalkan atau dalam proses menunggu konfirmasi pembayaran.',
                        openTime: false,
                        seconds: 0,
                    });
                }
            })
        }
    };

    checkPaymentStatus = () => {
        Helpers.getDetailPaymentCheckNew(this.state.kodeTransaksiOrder).then(res => {
            if(res.length > 0){
                if(res[0].status == 'paid'){
                    clearInterval(this.timer);

                    this.setState({
                        sisaWaktuPembayaran: 'Pembayaran berhasil diterima.',
                        openTime: false
                    });
                }
            }
        })
    };

    handleOVORequest = () => {
        this.handleModal();

        Helpers.createVA(this.state.kodeTransaksiOrder, this.state.kodePaymentGateway, this.state.OVOPhoneNumber).then(res => {
            if(res.length > 0){
                if(res[0].alert == 'success'){
                    this.setState({
                        OVOPhoneNumber: ''
                    });
                }

                toast[res[0].alert](res[0].message);
            }else{
                toast.error(Helpers.MSG_ERROR);
            }
        });

        setTimeout(() => {
            if(isMobile) {
                if (isAndroid) {
                    const url = "intent://ovo.id/#Intent;scheme=https;package=ovo.id;end";
                    window.location.replace(url);
                } else if (isIOS) {
                    window.location.replace("ovo://");

                    setTimeout(() => {
                        window.location.replace(
                            "https://apps.apple.com/ID/app/id1142114207?mt=8"
                        );
                    }, 10000);
                } else {
                    toast.error(Helpers.MSG_ERROR_OVO);
                }
            }else{
                toast.error(Helpers.MSG_ERROR_OVO);
            }
        }, 1000);
    };

    secondsToTime = (secs) => {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    startTimer = () => {
        if (this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown = () => {
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
            sisaWaktuPembayaran: ((this.state.time.h.toString().length < 2) ? '0'+this.state.time.h : this.state.time.h) + ':' + ((this.state.time.m.toString().length < 2) ? '0'+this.state.time.m : this.state.time.m) + ':' + ((this.state.time.s.toString().length < 2) ? '0'+this.state.time.s : this.state.time.s)
        });

        if(parseInt(seconds)%3 == 0){
            this.checkPaymentStatus();
        }

        if (seconds == 0) {
            clearInterval(this.timer);
            this.setState({
                openTime: false
            });
            window.location.reload();
        }
    }

    handleModal = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    handleShopee = () => {
        if(isMobile) {
            var win = window.open(this.state.virtualAccount, '_blank');
            win.focus();
        }else{
            toast.error(Helpers.MSG_ERROR_SHOPEEPAY);
        }
    }

    handleCC = () => {
        window.location.href = this.state.virtualAccount;
    }

    handleKoinWorks = () => {
        window.location.href = './koinworks-loan-otp?user='+this.state.sessUserCode+'&loanid='+this.state.virtualAccount+'&payment='+this.state.kodePaymentGateway+'&order='+this.state.kodeTransaksiOrder;
    }

    handleCopyVA = () => {
        navigator.clipboard.writeText(this.state.virtualAccount);
        toast.success("Virtual Account berhasil di copy");
    };

    render() {
        return (
            <>
                <Modal
                    size={'md'}
                    isOpen={this.state.isModalOpen}
                    toggle={() => {this.handleModal()}}>
                    <ModalBody style={{'padding': 'unset'}}>
                        <div className="row mt-15" style={{'margin': 'unset'}}>
                            <div className="col-lg-12">
                                <div className="input-style mb-20">
                                    <label>Masukkan Nomor OVO</label>
                                    <input value={this.state.OVOPhoneNumber} onChange={e => this.setState({OVOPhoneNumber: e.target.value})}
                                           placeholder="e.g. 081234567890"
                                           min={0}
                                           type="number"/>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => {this.handleOVORequest()}}>
                            Submit
                        </Button>
                        {' '}
                        <Button onClick={() => {this.handleModal()}}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
                <main className="main pages mb-80">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <a href="./" rel="nofollow"><i className="fi-rs-home mr-5"></i>Beranda</a>
                                <span></span> Order Aktif <span></span> Detail Order<span></span> Pembayaran
                            </div>
                        </div>
                    </div>
                    <div className="page-content pt-10">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-5 m-auto">
                                    {
                                        this.state.kodePaymentGateway == 'KPG-0014' && (
                                            <div className="primary-sidebar">
                                                <div className="sidebar-widget widget-store-info mb-30 bg-2 border-0">
                                                    <div className="vendor-info">
                                                        <h4 className="mb-5">
                                                            <a href="#" className="text-heading">
                                                                Informasi Metode Pembayaran OVO</a>
                                                        </h4>

                                                        <div className="vendor-des">
                                                            <p className="font-sm text-heading">
                                                                <ol style={{'list-style': 'list-number'}}>
                                                                    <li>Harap mengunduh aplikasi OVO terlebih dahulu</li>
                                                                    <li>Login ke aplikasi OVO</li>
                                                                    <li>Jika sudah mempunyai aplikasi OVO, pastikan telah diupdate ke versi terakhir</li>
                                                                    <li>Tekan tombol (Send Request) lalu masukkan nomor handphone anda yang telah terdaftar di OVO</li>
                                                                    <li>Setelah submit anda akan langsung diarahkan ke aplikasi OVO dan masa aktif request pembayaran OVO selama 60 detik</li>
                                                                    <li>Jika terjadi kegagalan atau waktu pembayaran di aplikasi OVO sudah habis, anda dapat melakukan request ulang di halaman berikut selama sisa waktu pembayaran Order anda masih aktif.</li>
                                                                </ol>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        this.state.kodePaymentGateway == 'KPG-0015' && (
                                            <div className="primary-sidebar">
                                                <div className="sidebar-widget widget-store-info mb-30 bg-2 border-0">
                                                    <div className="vendor-info">
                                                        <h4 className="mb-5">
                                                            <a href="#" className="text-heading">
                                                                Informasi Metode Pembayaran Shopeepay</a>
                                                        </h4>

                                                        <div className="vendor-des">
                                                            <p className="font-sm text-heading">
                                                                <ol style={{'list-style': 'list-number'}}>
                                                                    <li>Harap mengunduh aplikasi Shopee terlebih dahulu</li>
                                                                    <li>Login ke aplikasi Shopee</li>
                                                                    <li>Jika sudah mempunyai aplikasi Shopee, pastikan telah diupdate ke versi terakhir</li>
                                                                    <li>Tekan tombol (Open Shopee) untuk melanjutkan pembayaran di aplikasi Shopee</li>
                                                                    <li>Untuk mengakses aplikasi Shopee, harap buka halaman berikut melalui browser handphone anda.</li>
                                                                </ol>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    <form className="contact-form-style mt-30 mb-30">
                                        <div className="input-style mb-20">
                                            <label>Metode Pembayaran</label>
                                            <input value={this.state.metodePembayaran}
                                                   placeholder="Metode Pembayaran"
                                                   type="text" readOnly={true}/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Total Pembayaran</label>
                                            <input value={this.state.totalPembayaran}
                                                   placeholder="Total Pembayaran"
                                                   type="text" readOnly={true}/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Bayar Sebelum</label>
                                            <input value={this.state.bayarSebelum}
                                                   placeholder="Bayar Sebelum"
                                                   type="text" readOnly={true}/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Sisa Waktu Pembayaran</label>
                                            <input value={this.state.sisaWaktuPembayaran}
                                                   placeholder="Sisa Waktu Pembayaran"
                                                   type="text" readOnly={true}/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Virtual Account</label>
                                            <input value={(this.state.kodePaymentGateway == 'KPG-0015' || this.state.kodePaymentGateway == 'KPG-0016') ? '' : this.state.virtualAccount}
                                                   placeholder="Virtual Account"
                                                   type="text" readOnly={true}/>
                                        </div>
                                        {
                                            (this.state.kodePaymentGateway == 'KPG-0014' && this.state.openTime) && (
                                                <button className="submit submit-auto-width" onClick={() => {this.handleModal()}}
                                                        type="button">Send Request
                                                </button>
                                            )
                                        }
                                        {
                                            (this.state.kodePaymentGateway == 'KPG-0015' && this.state.openTime) && (
                                                <button className="submit submit-auto-width" onClick={() => {this.handleShopee()}}
                                                        type="button">Buka Aplikasi Shopee
                                                </button>
                                            )
                                        }
                                        {
                                            (this.state.kodePaymentGateway == 'KPG-0016' && this.state.openTime) && (
                                                <button className="submit submit-auto-width" onClick={() => {this.handleCC()}}
                                                        type="button">Proses Pembayaran
                                                </button>
                                            )
                                        }
                                        {
                                            (this.state.kodePaymentGateway == 'KPG-0017' && this.state.openTime) && (
                                                <button className="submit submit-auto-width" onClick={() => {this.handleKoinWorks()}}
                                                        type="button">Konfirmasi OTP
                                                </button>
                                            )
                                        }
                                        {
                                            ((this.state.kodePaymentGateway !== 'KPG-0014' && this.state.kodePaymentGateway !== 'KPG-0015' && this.state.kodePaymentGateway !== 'KPG-0016' && this.state.kodePaymentGateway !== 'KPG-0017') && this.state.openTime) && (
                                                <button className="submit submit-auto-width" onClick={() => {this.handleCopyVA()}}
                                                        type="button">Copy VA
                                                </button>
                                            )
                                        }

                                        <a className="btn btn-outline" style={{'color': '#F47626'}} href={'./'}>Kembali
                                        </a>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </>
        );
    }
}

export default OrderPayment;