import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import {ReactSession} from "react-client-session";
import { Whatsapp } from 'react-social-sharing'

import { Helpers } from "../config/Helpers";

class ModalProduct extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            isModalOpen: this.props.modalRef,
            productId: '',
            productName: '',
            productCategory: '',
            productImage: '',
            productWishlist: '',
            productDetailData: [],
            detailId: '',
            stokPerUnit: '',
            StokPerUnitReal: '',
            qtyOrder: '0',
            priceOrder: '0',
            priceOrderTotal: '0',
            orderType: (ReactSession.get("order_type")) ? ReactSession.get("order_type") : 'Grosir',
            branchCode: (ReactSession.get("branch_code")) ? ReactSession.get("branch_code") : 'CBNG-18-020001',
            shareMessage: '',
            infinity: false
        }
    }

    componentDidMount() {
        if(this.state.isModalOpen){
            this.setupModalProduct();
        }
    }

    setupModalProduct = () => {
        let checkProductId = ReactSession.get("product_id");
        if(checkProductId){
            if(checkProductId !== this.state.productId){
                this.setState({
                    productId: ReactSession.get("product_id"),
                    productName: ReactSession.get("product_name"),
                    productCategory: ReactSession.get("product_category"),
                    productImage: ReactSession.get("product_image").replaceAll(" ","%20")
                }, () => {
                    Helpers.getProductDetails(this.state.sessEmail, this.state.productId, this.state.branchCode, 'yes').then(res => {
                        this.setState({
                            productDetailData: res,
                            productWishlist: (res[0].KodeWishlist == 'null') ? '' : res[0].KodeWishlist,
                            detailId: res[0].DetailId,
                            stokPerUnit: res[0].StokPerUnit,
                            StokPerUnitReal: res[0].StokPerUnitReal,
                            qtyOrder: res[0].QtyOrder,
                            priceOrder: (this.state.orderType == 'Grosir') ? res[0].HargaGrosirFormatTerkecilRaw : res[0].HargaRetailFormatTerkecilRaw,
                            priceOrderTotal: (this.state.orderType == 'Grosir') ? res[0].TotalHargaGrosirFormatTerkecil : res[0].TotalHargaRetailFormatTerkecil,
                            infinity: (res[0].StokPerUnit > 0) ? false : true
                        }, () => {
                            this.setupShareMessage(res[0].UnitBarang,res[0].PcsPerUnit,res[0].HargaGrosirFormatTerkecil);
                        });
                    })
                });
            }
        }
    };

    setupShareMessage = (unit,pcs,harga) => {
        this.setState({
            shareMessage: 'Hallo! \n' +
                'Ada yang spesial kebangetan nih!!! \n' +
                '*' + this.state.productName + '* ' + unit + ' (' + pcs + ' Pcs) cuma seharga *Rp ' + harga + '*!!!\n' +
                'Tuh murahnya kebangetan kan?!?!?\uD83E\uDD73\n\n' +
                'Mau belanja barang supermarket dengan harga warung? Di Waroq aja!\n\n' +
                '\uD83D\uDC47 Yuk!! langsung klik di sini \uD83D\uDC47\n'
        });
    }

    handleModal = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    handleRadioChange = (object) => {
        this.setState({
            detailId: object.DetailId,
            stokPerUnit: object.StokPerUnit,
            StokPerUnitReal: object.StokPerUnitReal,
            qtyOrder: object.QtyOrder,
            priceOrder: (this.state.orderType == 'Grosir') ? object.HargaGrosirFormatTerkecilRaw : object.HargaRetailFormatTerkecilRaw,
            priceOrderTotal: (this.state.orderType == 'Grosir') ? object.TotalHargaGrosirFormatTerkecil : object.TotalHargaRetailFormatTerkecil
        }, () => {
            this.setupShareMessage(object.UnitBarang,object.PcsPerUnit,object.HargaGrosirFormatTerkecil);
        });
    };

    handleQtyChange = (object) => {
        if(this.state.detailId){
            let countQty = 0;
            if (object == 'plus') {
                countQty = this.state.qtyOrder + 1;

                if(this.state.StokPerUnitReal > 0){
                    if(countQty >= this.state.StokPerUnitReal){
                        countQty = this.state.StokPerUnitReal;
                    }
                }
            }else{
                countQty = this.state.qtyOrder - 1;

                if(countQty < 0){
                    countQty = 0;
                }
            }

            this.setState({
                stokPerUnit: this.state.StokPerUnitReal - countQty,
                qtyOrder: countQty,
                priceOrderTotal: (countQty * this.state.priceOrder).toLocaleString(undefined, {maximumFractionDigits: 0}).toString().replaceAll(',','.')
            });
        }else{
            toast.error("Anda belum memilih jenis unit");
        }
    };

    handleSubmitOrderTemp = () => {
        if(this.state.detailId){
            if(parseInt(this.state.qtyOrder) > 0){
                Helpers.createOrderTemp(this.state.sessEmail, this.state.detailId, this.state.qtyOrder, this.state.branchCode, 'yes').then(res => {
                    if(res[0].alert == 'success'){
                        this.setState({
                            isModalOpen: false,
                            productDetailData: [],
                            detailId: '',
                            stokPerUnit: '',
                            StokPerUnitReal: '',
                            qtyOrder: '0',
                            priceOrder: '0',
                            priceOrderTotal: '0'
                        });

                        ReactSession.set("update_dropdown_cart", "t");
                    }

                    toast[res[0].alert](res[0].message);
                })
            }else{
                toast.error("Anda belum mengisi quantity");
            }
        }else{
            toast.error("Anda belum memilih jenis unit");
        }
    };

    handleWishlist = (command) => {
        Helpers.manageWishlist(
            this.state.productWishlist,
            this.state.sessUserCode,
            this.state.productId,
            command
        ).then(res => {
            if(res[0].alert == 'success'){
                Helpers.getProductDetails(this.state.sessEmail, this.state.productId, this.state.branchCode, 'yes').then(res => {
                    this.setState({
                        productWishlist: (res[0].KodeWishlist == 'null') ? '' : res[0].KodeWishlist,
                    });
                })
            }

            toast[res[0].alert](res[0].message);
        })
    };

    handleOrderBesar = () => {

    };

    render() {
        return (
            <Modal
                size={'lg'}
                isOpen={this.state.isModalOpen}
                toggle={() => {this.handleModal()}}>
                <ModalBody style={{'padding': 'unset'}}>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="container d-flex h-100">
                                <center style={{'width': '100%'}}>
                                    <div className="row justify-content-center align-self-center">
                                        <img src={this.state.productImage} style={{'width': '70%'}} />
                                    </div>
                                </center>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="deal" style={{'min-height': 'unset'}}>
                                <div className="deal-top">
                                    <h6 className="mb-10 mt-10 text-brand-2">{this.state.productCategory}</h6>
                                </div>
                                <div className="deal-content detail-info">
                                    <h4 className="product-title" style={{'max-width': 'unset', 'font-size': '25px', 'margin-bottom': 'unset'}}><a href="#" className="text-heading">{this.state.productName}</a></h4>
                                    <div className="clearfix product-price-cover">
                                        <div className="product-price primary-color float-left" style={{'margin': '10px 0 10px 0'}}>
                                            <div>
                                                {
                                                    this.state.productDetailData.map((object, i) => {
                                                        return <div className="mb-10">
                                                            <input type="radio" style={{'height': '11px', width: '11px', 'margin-right': '10px'}} value={object.DetailId} name="productdetails" checked={((this.state.detailId == object.DetailId) ? 'checked' : '')} onChange={() => {this.handleRadioChange(object)}}/>
                                                            <h6 className="text-brand text-brand-custom">{object.UnitBarang}</h6> ({object.PcsPerUnit} Pcs - {object.BeratPerUnit} Gram) - <h6 className="text-brand text-brand-custom">
                                                            {
                                                                this.state.orderType == 'Grosir' && (
                                                                    object.HargaGrosirFormatTerkecil
                                                                )
                                                            }
                                                            {
                                                                this.state.orderType == 'Retail' && (
                                                                    object.HargaRetailFormatTerkecil
                                                                )
                                                            }
                                                            </h6>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="deal-bottom">
                                    {
                                        !this.state.infinity && (
                                            <p className="mb-10">Stock: <a className="text-brand-custom" href="#">{this.state.stokPerUnit}</a></p>
                                        )
                                    }
                                    {
                                        this.state.infinity && (
                                            <p className="mb-10">Stock: <a className="text-brand-custom" href="#">∞</a></p>
                                        )
                                    }

                                    <p className="mb-20">Total: <a className="text-brand-custom" href="#">{this.state.priceOrderTotal}</a></p>
                                    <div style={{'display': 'inline'}}>
                                        <button className="btn btn-small" style={{'display': 'unset', marginBottom: '7px'}} onClick={() => {this.handleQtyChange('minus')}}>-</button>
                                        <input className="" type={'number'} value={this.state.qtyOrder} style={{'height': '36px', width: '75px', marginLeft: '5px', marginRight: '5px'}}/>
                                        <button className="btn btn-small" style={{'display': 'unset', marginBottom: '7px'}} onClick={() => {this.handleQtyChange('plus')}}>+</button>

                                        {
                                            this.state.productWishlist && (
                                                <button className="btn btn-small" style={{'display': 'unset', marginBottom: '7px', marginLeft: '7px', 'background-color': '#F47626'}} onClick={() => {this.handleWishlist('delete')}}><i className="fi-rs-heart" style={{'color': '#FFFFFF', 'margin-left': '0px'}}></i></button>
                                            )
                                        }
                                        {
                                            !this.state.productWishlist && (
                                                <button className="btn btn-small" style={{'display': 'unset', marginBottom: '7px', marginLeft: '7px', 'background-color': '#E7E8EA'}} onClick={() => {this.handleWishlist('insert')}}><i className="fi-rs-heart" style={{'color': '#F47626', 'margin-left': '0px'}}></i></button>
                                            )
                                        }

                                        <Whatsapp solid small message={this.state.shareMessage} link={'https://waroq.com/'}/>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => {this.handleSubmitOrderTemp()}}>
                        Add to cart
                    </Button>
                    {' '}
                    {/*<Button*/}
                        {/*color="primary"*/}
                        {/*onClick={() => {this.handleOrderBesar()}}>*/}
                        {/*Order Besar*/}
                    {/*</Button>*/}
                    {' '}
                    <Button onClick={() => {this.handleModal()}}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default ModalProduct;