import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import {ReactSession} from "react-client-session";

import { Helpers } from "../config/Helpers";
import {encode as base64_encode} from "base-64";
import {Connection} from "../config/Connection";

class ModalTracking extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            sessTrackingCode: (ReactSession.get("sess_tracking_code")) ? ReactSession.get("sess_tracking_code") : '',
            isModalOpen: this.props.modalRef
        }
    }

    componentDidMount() {
        if(this.state.isModalOpen){
            this.setupModal();
        }
    }

    setupModal = () => {

    };

    handleModal = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    render() {
        return (
            <Modal
                size={'md'}
                isOpen={this.state.isModalOpen}
                toggle={() => {this.handleModal()}}>
                <ModalBody>
                    <iframe src={Connection.SVC_URL + 'Pages/tracking.html?kode=' + this.state.sessTrackingCode} width="100%" height="450"></iframe>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => {this.handleModal()}}>Close</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default ModalTracking;