import React, { useRef } from "react";
import $ from "jquery";
import {Helpers} from "../../../config/Helpers";
import {ReactSession} from "react-client-session";
import queryString from "query-string";
import {toast} from "react-toastify";
import {Connection} from "../../../config/Connection";
import ReactToPrint from 'react-to-print';
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";

class OrderDetails extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            urlActive: window.location.pathname,
            urlParam: queryString.parse(window.location.search),
            sessMPSupplierId: (ReactSession.get("sess_mp_supplierid")) ? ReactSession.get("sess_mp_supplierid") : '',
            kode_order: '',
            kode_supplier: '',
            total_items_supplier: '',
            total_order_supplier: '',
            total_order_supplier_format: '',
            total_order_waroq_format: '',
            date_created: '',
            status_upper: '',
            coverage: '',
            company_name: '',
            company_email: '',
            phone: '',
            address: '',
            postal_code: '',
            maps: '',
            isModalOpen: false
        };
        this.componentRef = React.createRef();
    }

    componentDidMount() {
        const kode_order = (this.state.urlParam.kode_order) ? this.state.urlParam.kode_order : '';

        this.setState({
            kode_order: kode_order
        }, () => {
            this.getOrders();
        });
    }

    getOrders = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getMPSupplierOrder(this.state.kode_order,this.state.sessMPSupplierId,'all').then(res => {
            if(res){
                let r = res[0];
                this.setState({
                    kode_order: r.kode_order,
                    kode_supplier: r.kode_supplier,
                    total_items_supplier: r.total_items_supplier,
                    total_order_supplier: r.total_order_supplier,
                    total_order_supplier_format: r.total_order_supplier_format,
                    total_order_waroq_format: r.total_order_waroq_format,
                    date_created: r.date_created,
                    status_upper: r.status_upper,
                    coverage: r.coverage,
                    company_name: r.company_name,
                    company_email: r.company_email,
                    phone: r.phone,
                    address: r.address,
                    postal_code: r.postal_code,
                    maps: r.maps
                });
            }

            this.getData();
        })
    };

    getData = () => {
        Helpers.getMPBuyerOrderDetails(this.state.kode_order,'').then(res => {
            $(".loader-wrapper").css("display", "none");
            const postData = res.map((object, i) => {
                return <tr>
                    <td>
                        <a className="itemside" href="#">
                            <div className="left">
                                <img src={object.img_url} width="40" height="40"
                                     className="img-xs" alt="Item"/>
                            </div>
                            <div className="info">{object.product}</div>
                        </a>
                    </td>
                    <td>{object.status_overall}</td>
                    <td>{object.unit}</td>
                    <td className="text-end">{object.price_supplier_format}</td>
                    <td className="text-end">{object.qty}</td>
                    <td className="text-end">{object.total_price_supplier_format}</td>
                </tr>
            });

            const postDataRaw = res.map((object, i) => {
                return <tr>
                    <td>{i+1}</td>
                    <td>{object.product}</td>
                    <td>{object.unit}</td>
                    <td className="text-end">{object.price_waroq_format}</td>
                    <td className="text-end">{object.qty}</td>
                    <td className="text-end">{object.total_waroq_format}</td>
                </tr>
            });

            this.setState({
                postData,
                postDataRaw
            });
        })
    };

    handlePickup = () => {
        this.saveMPStatusOrder('delivery');
    };

    handleSent = () => {
        this.saveMPStatusOrder('sent');
    };

    handleModal = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    saveMPStatusOrder = (status) => {
        $(".loader-wrapper").css("display", "");

        var now = new Date();
        var time_epoch = now.getTime();

        var data_post = new FormData();

        if(status === 'sent'){
            if($("#doc_order").get(0) !== undefined){
                var doc_order = $("#doc_order").get(0).files;
                if (doc_order.length > 0) {
                    data_post.append("doc_order", doc_order[0]);
                }
            }
        }

        const param =
            "kode_user=" + this.state.sessMPSupplierId +
            "&kode_order=" + this.state.kode_order +
            "&status=" + status +
            "&epoch=" + time_epoch;

        fetch(Connection.API_URL + 'SaveMPStatusOrder?' + param, {
            method: 'POST',
            body: data_post
        }).then((res) => res.json()).then((res) => {
            $(".loader-wrapper").css("display", "none");
            if(res.length > 0){
                if(res[0].alert == 'success'){
                    this.getOrders();
                }

                toast[res[0].alert](res[0].message);
            }else{
                toast.error(Helpers.MSG_ERROR);
            }
        });
    };

    render() {
        return (
            <>
                <Modal
                    size={'lg'}
                    isOpen={this.state.isModalOpen}
                    toggle={() => {this.handleModal()}}>
                    <ModalBody>
                        <ReactToPrint
                            trigger={() => {
                                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                // to the root node of the returned component as it will be overwritten.
                                return <a className="btn btn-secondary print ms-2 mb-3" href="#">
                                    <i className="icon material-icons md-print mr-5"></i> Download
                                </a>;
                            }}
                            content={() => this.componentRef}
                        />
                        <div ref={el => (this.componentRef = el)}>
                            <table className="table">
                                <tr>
                                    <td colSpan={3}>
                                        <div style={{'display': 'flex'}}>
                                            <h3 style={{'flex': 'auto'}}>Surat Jalan</h3>
                                            <img src="/assets/imgs/theme/waroq-logo.png" className="logo" alt="Nest Dashboard" style={{'width': '125px'}}/>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="text">
                                            <h6 className="mb-1">Order info</h6>
                                            <p className="mb-1">
                                                ID Order: {this.state.kode_order} <br/>
                                                Order Status: {this.state.status_upper} <br/>
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text">
                                            <h6 className="mb-1">Customer</h6>
                                            <p className="mb-1">
                                                {this.state.company_name} <br/>
                                                {this.state.company_email} <br/>
                                                {this.state.phone}
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text">
                                            <h6 className="mb-1">Deliver to</h6>
                                            <p className="mb-1">
                                                City: {this.state.coverage} <br/>
                                                {this.state.address} <br/>
                                                Kodepos {this.state.postal_code}
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th width="5%">No</th>
                                    <th width="35%">Product</th>
                                    <th width="10%">Unit</th>
                                    <th width="20%">Price</th>
                                    <th width="10%">Qty</th>
                                    <th width="20%" className="text-end">Total</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.postDataRaw}
                                <tr>
                                    <td colSpan="6">
                                        <article className="float-end">
                                            <dl className="dlist">
                                                <dt>Grand total:</dt>
                                                <dd><b className="h5">{this.state.total_order_waroq_format}</b></dd>
                                            </dl>
                                        </article>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {this.handleModal()}}>Close</Button>
                    </ModalFooter>
                </Modal>

                <section className="content-main">
                    <div className="content-header">
                        <div>
                            <h2 className="content-title card-title">Order detail</h2>
                            <p>Details for Order ID: {this.state.kode_order}</p>
                        </div>
                    </div>
                    <div className="card">
                        <header className="card-header">
                            <div className="row align-items-center">
                                <div className="col-lg-4 col-md-4 mb-lg-0 mb-15">
                                    <div style={{'display': 'flex'}}>
                                        <i className="material-icons md-calendar_today mr-5"></i> <b>{this.state.date_created}</b>
                                    </div>
                                    <small className="text-muted">Order ID: {this.state.kode_order}</small>
                                </div>
                                <div className="col-lg-8 col-md-8 ms-auto text-md-end">
                                    {
                                        (this.state.status_upper === 'waiting pickup') && (
                                            <>
                                                <a className="btn btn-primary" onClick={() => {this.handlePickup()}}>Send Product</a>
                                            </>
                                        )
                                    }
                                    {
                                        (this.state.status_upper === 'on delivery') && (
                                            <>
                                                <input type="file" name="doc_order" id="doc_order" className="form-control mr-5" accept="image/png, image/jpeg"
                                                       style={{'width': '225px', 'display': 'inline'}} />
                                                <a className="btn btn-primary" onClick={() => {this.handleSent()}}>Confirm Delivery</a>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </header>
                        <div className="card-body">
                            <div className="row mb-50 mt-20 order-info-wrap">
                                <div className="col-md-4">
                                    <article className="icontext align-items-start">
                                    <span className="icon icon-sm rounded-circle bg-primary-light">
                                        <i className="text-primary material-icons md-person"></i>
                                    </span>
                                        <div className="text">
                                            <h6 className="mb-1">Customer</h6>
                                            <p className="mb-1">
                                                {this.state.company_name} <br/>
                                                {this.state.company_email} <br/>
                                                {this.state.phone}
                                            </p>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-4">
                                    <article className="icontext align-items-start">
                                    <span className="icon icon-sm rounded-circle bg-primary-light">
                                        <i className="text-primary material-icons md-local_shipping"></i>
                                    </span>
                                        <div className="text">
                                            <h6 className="mb-1">Order info</h6>
                                            <p className="mb-1">
                                                Order Status: <span className="badge rounded-pill alert-success"
                                                                    style={{'font-size': 'unset', 'display': 'unset'}}>{this.state.status_upper}</span>
                                            </p>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-4">
                                    <article className="icontext align-items-start">
                                    <span className="icon icon-sm rounded-circle bg-primary-light">
                                        <i className="text-primary material-icons md-place"></i>
                                    </span>
                                        <div className="text">
                                            <h6 className="mb-1">Deliver to</h6>
                                            <p className="mb-1">
                                                City: {this.state.coverage} <br/>
                                                {this.state.address} <br/>
                                                Kodepos {this.state.postal_code}
                                            </p>
                                            <a href={this.state.maps} target="_blank">View in maps</a>
                                        </div>
                                    </article>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th width="40%">Product</th>
                                                <th width="15%">Status</th>
                                                <th width="10%">Unit</th>
                                                <th width="15%">Price</th>
                                                <th width="5%">Qty</th>
                                                <th width="15%" className="text-end">Total</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.postData}
                                            <tr>
                                                <td colSpan="7">
                                                    <article className="float-end">
                                                        <dl className="dlist">
                                                            <dt>Grand total:</dt>
                                                            <dd><b className="h5">{this.state.total_order_supplier_format}</b></dd>
                                                        </dl>
                                                    </article>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>

                                        <div className="divider mb-3"></div>

                                        <button className="btn btn-primary" onClick={() => {this.handleModal()}}>Cetak Surat Jalan</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default OrderDetails;