import React from "react";
import {encode as base64_encode} from 'base-64';
import { ReactSession } from 'react-client-session';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from "query-string";
import {Helpers} from "../config/Helpers";
import $ from "jquery";

class Landing extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            urlParam: queryString.parse(window.location.search)
        }
    }

    componentDidMount() {
        if(!this.state.sessUserCode){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }
    }

    checkSupplier = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getMPSupplier(this.state.sessUserCode,'', 'Aktif').then(res => {
            $(".loader-wrapper").css("display", "none");
            if(res.length > 0){
                window.location.href = 'marketplace/supplier';
            }else{
                window.location.href = 'marketplace/register_supplier';
            }
        })
    };

    checkBuyer = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getMPBuyer(this.state.sessUserCode,'','Aktif').then(res => {
            $(".loader-wrapper").css("display", "none");
            if(res.length > 0){
                window.location.href = 'marketplace/buyer';
            }else{
                window.location.href = 'marketplace/register_buyer';
            }
        })
    };

    render() {
        return (
            <>
                <ToastContainer />

                <div className="loader-wrapper" style={{'display': 'none'}}>
                    <div className="loader"></div>
                </div>

                <main>
                    <header className="main-header style-2 navbar">
                        <div className="col-brand">
                            <a href="/" className="brand-wrap">
                                <img src="/assets/imgs/theme/waroq-logo.png" className="logo" alt="Nest Dashboard"/>
                            </a>
                        </div>
                    </header>
                    <section className="content-main">
                        <div className="card mx-auto card-login" style={{'max-width': '580px'}}>
                            <div className="card-body" style={{'padding': 'unset'}}>
                                <p className="text-center mb-4">Masuk sebagai</p>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="card card-product-grid">
                                            <a href="#" className="img-wrap" style={{
                                                backgroundImage: "url(" + "/assets_admin/imgs/items/1.jpg" + ")",
                                                backgroundPosition: 'center',
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat'
                                            }}>
                                                <img src="/assets_admin/imgs/items/supplier.png" alt="Product" style={{'width': '150px', 'margin': '40px 0px'}}/>
                                            </a>
                                            <div className="info-wrap">
                                                <a onClick={() => {this.checkSupplier()}} className="btn btn-sm font-sm rounded btn-brand">
                                                    Supplier
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="card card-product-grid">
                                            <a href="#" className="img-wrap" style={{
                                                backgroundImage: "url(" + "/assets_admin/imgs/items/1.jpg" + ")",
                                                backgroundPosition: 'center',
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat'
                                            }}>
                                                <img src="/assets_admin/imgs/items/customer.png" alt="Product" style={{'width': '150px', 'margin': '40px 0px'}}/>
                                            </a>
                                            <div className="info-wrap">
                                                <a onClick={() => {this.checkBuyer()}} className="btn btn-sm font-sm rounded btn-brand">
                                                    Buyer
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </>
        );
    }
}

export default Landing;