import React from "react";

import { Helpers } from "../../config/Helpers"
import {ReactSession} from "react-client-session";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import queryString from "query-string";

class HistoryOrderDetails extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            orderData: '',
            orderDetailsData: [],
            urlParam: queryString.parse(window.location.search)
        }
    }

    componentDidMount() {
        if(!this.state.sessUserCode){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.getOrderDetail();
            this.getListOrderDetail();
        }
    }

    getOrderDetail = () => {
        const kodeOrder = (this.state.urlParam.trxid) ? base64_decode(this.state.urlParam.trxid) : '';

        Helpers.getListOrder('no',kodeOrder,'','','').then(res => {
            this.setState({ orderData: res[0] });
        })
    };

    getListOrderDetail = () => {
        const kodeOrder = (this.state.urlParam.trxid) ? base64_decode(this.state.urlParam.trxid) : '';

        Helpers.getListOrderDetail(kodeOrder).then(res => {
            this.setState({ orderDetailsData: res });
        })
    };

    render() {
        return (
            <>
                <main className="main pages mb-80">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <a href="./" rel="nofollow"><i className="fi-rs-home mr-5"></i>Beranda</a>
                                <span></span> Akun <span></span> History Order <span></span> Detail Order
                            </div>
                        </div>
                    </div>
                    <div className="page-content pt-10">
                        <div className="container">
                            <div className="invoice-inner mb-30">
                                <div className="invoice-info" id="invoice_wrapper">
                                    <div className="invoice-header">
                                        <div className="row">
                                            <div className="col-lg-4 col-12">
                                                <div className="invoice-number">
                                                    <h4 className="invoice-header-1 mb-10 mt-20" style={{'font-size': '18px'}}>No Invoice: <span
                                                        className="text-brand">#{this.state.orderData.KodeTransaksiOrder}</span></h4>
                                                    <p className="invoice-addr-1" style={{'font-size': '14px'}}>
                                                        <strong>Tanggal Transaksi: {this.state.orderData.date_d + ' ' + this.state.orderData.date_m + ' ' + this.state.orderData.date_y + ' ' + this.state.orderData.date_t}</strong> <br/>
                                                        {this.state.orderData.NamaWarung} <br/>
                                                        {this.state.orderData.NamaPemilikWarung}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12 mt-20">
                                                <div className="invoice-number">
                                                    <h4 className="invoice-title-1 mb-10" style={{'font-size': '18px'}}>Informasi Pengiriman</h4>
                                                    <p className="invoice-addr-1" style={{'font-size': '14px'}}>
                                                        <strong>{this.state.orderData.TipePengiriman}</strong> <br/>
                                                        {this.state.orderData.Alamat}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12 mt-20">
                                                <div className="invoice-number">
                                                    <h4 className="invoice-title-1 mb-10" style={{'font-size': '18px'}}>Status Pembayaran</h4>
                                                    <p className="invoice-addr-1" style={{'font-size': '14px'}}>
                                                        <strong>{this.state.orderData.MetodePembayaran + ' - ' + this.state.orderData.NamaPaymentChannel}</strong> <br/>
                                                        {this.state.orderData.StatusPembayaranLabelNew}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead className="bg-active">
                                    <tr>
                                        <th>Produk</th>
                                        <th className="text-center">Unit</th>
                                        <th className="text-center">Quantity</th>
                                        <th className="text-center">Harga</th>
                                        <th className="text-right">Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.orderDetailsData.map((object, i) => {
                                            return <tr>
                                                <td>
                                                    <div className="item-desc-1">
                                                        <span>{object.NamaBarang}</span>
                                                    </div>
                                                </td>
                                                <td className="text-center">{object.UnitBarang}</td>
                                                <td className="text-center">{object.Qty}</td>
                                                <td align="right">{object.HargaSatuan}</td>
                                                <td align="right">{object.Harga}</td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td colSpan="4" className="text-end f-w-600">Grand Total</td>
                                        <td align="right">{this.state.orderData.GrandTotal}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="4" className="text-end f-w-600">Point Digunakan</td>
                                        <td align="right">- {this.state.orderData.PotonganKomisi}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="4" className="text-end f-w-600">Biaya Pengiriman</td>
                                        <td align="right">{this.state.orderData.JasaKirim}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="4" className="text-end f-w-600">Total Bayar</td>
                                        <td align="right"><h6 className="text-brand">{this.state.orderData.TotalHargaOrder}</h6></td>
                                    </tr>
                                    <tr>
                                        <td colSpan="4" className="text-end f-w-600">Estimasi Komisi yang akan didapatkan</td>
                                        <td align="right">{this.state.orderData.EstimasiKomisi}</td>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>

                    </div>
                </main>
            </>
        );
    }
}

export default HistoryOrderDetails;