import React from "react";

import {isMobile} from "react-device-detect";
import $ from "jquery";
import 'react-html5-camera-photo/build/css/index.css';
import 'react-html5-camera-photo/build/css/index.css';

class ResponsePage extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if(isMobile) {
            $("#mobile-header").css("display", "none");
            $("#mobile-footer").css("display", "none");
        }
    }

    render() {
        return (
            <>
                <main className="main pages mb-80">
                    <div className="page-content pt-10">
                        <div className="container">
                            <div className="row mt-15">
                                <div className="col-xl-8 col-lg-10 col-md-12 m-auto text-center">
                                    <h4 className="mb-30">Data has been submit!</h4>
                                    <p className="font-lg text-grey-700 mb-20">
                                        Click this link below to go home or close this window if you're using Waroq Mobile.
                                    </p>
                                    <a className="btn btn-default submit-auto-width font-xs hover-up mt-30"
                                       href={'./'}>Back To Home</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default ResponsePage;