import React from "react";
import {Helpers} from "../../../config/Helpers";
import {ReactSession} from "react-client-session";
import $ from "jquery";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import {toast} from "react-toastify";
import queryString from "query-string";

class ShoppingDetails extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            urlActive: window.location.pathname,
            sessMPBuyerId: (ReactSession.get("sess_mp_buyerid")) ? ReactSession.get("sess_mp_buyerid") : '',
            buyer_name: '',
            company_email: '',
            company_phone: '',
            address: '',
            postal_code: '',
            total_items: 0,
            total_orders: 'Rp 0',
            note: '',

            summaryData: '',
            paymentData: [],
            warungData: [],
            orderData: [],
            orderTotal: 0,
            orderGrandTotal: 0,
            orderTotalKomisi: 0,
            orderTotalBerat: 0,
            orderKomisiDigunakan: 0,
            orderDeliveryType: (ReactSession.get("sess_order_delivery")) ? ReactSession.get("sess_order_delivery") : 'Ambil Sendiri',
            orderDeliveryLocation: (ReactSession.get("sess_order_kodelokasi")) ? ReactSession.get("sess_order_kodelokasi") : '',
            orderPaymentMethod: '',
            orderPaymentMethodType: '',
            orderPaymentCharge: 0,
            orderPaymentFee: '',
            orderWarung: '',
            orderType: (ReactSession.get("order_type")) ? ReactSession.get("order_type") : 'Grosir',
            branchCode: (ReactSession.get("branch_code")) ? ReactSession.get("branch_code") : 'CBNG-18-020001',
            orderLocPinPoint: (ReactSession.get("sess_order_pinpoint")) ? ReactSession.get("sess_order_pinpoint") : '',
            orderLocJarak: (ReactSession.get("sess_order_jarak")) ? ReactSession.get("sess_order_jarak") : '0',
            orderLocJasaKirim: (ReactSession.get("sess_order_jasakirim")) ? ReactSession.get("sess_order_jasakirim") : '0',
            isCommissionChecked: false,
            saldoCommission: 0,
            typeCommission: 'Pilih',
            urlParam: queryString.parse(window.location.search),
            kodeMarketPlaceDetail: '',
            orderShipmentMethod: '',
            koinWorksTenor: '30',
        }
    }

    componentDidMount() {
        this.checkBuyer();
        this.getSummary();
        this.getData();
        this.getCart();
        this.callPaymentMethod();
    }

    checkBuyer = () => {
        Helpers.getMPBuyer('',this.state.sessMPBuyerId,'Aktif').then(res => {
            let obj = res[0];
            this.setState({
                buyer_name: obj.buyer_name,
                company_email: obj.company_email,
                company_phone: obj.company_phone,
                address: obj.address,
                postal_code: obj.postal_code
            });
        })
    };

    getCart = () => {
        Helpers.getMPOrderTempSummary(this.state.sessMPBuyerId,'','','','').then(res => {
            let obj = res[0];
            this.setState({
                total_items: obj.total_items,
                total_orders: obj.total_orders_format,
                orderTotal: obj.total_orders,
                orderGrandTotal: obj.total_orders
            });
        })
    };

    getSummary = () => {
        Helpers.getSummary(this.state.sessUserCode).then(res => {
            this.setState({
                summaryData: res[0],
                saldoCommission: res[0].commission_now_raw
            }, () => {
                this.callPaymentMethod();
            });
        })
    };

    getData = () => {
        Helpers.getMPProductsDisplay(this.state.sessMPBuyerId,'','','', '').then(res => {
            let arr = res.filter(function(e){
                if(parseInt(e.qty_order) > 0){
                    return e;
                }
            });

            const postData = arr.map((object, i) => {
                return <tr>
                    <td>
                        <a className="itemside" href="#">
                            <div className="left">
                                <img src={object.url_photo} width="40" height="40"
                                     className="img-xs" alt="Item"/>
                            </div>
                            <div className="info">{object.name}</div>
                        </a>
                    </td>
                    <td>{object.unit_label}</td>
                    <td className="text-end">{object.price_active_format}</td>
                    <td className="text-end">{object.qty_order}</td>
                    <td className="text-end">Rp {object.price_total.toLocaleString(undefined, {maximumFractionDigits: 0}).toString().replaceAll(',','.')}</td>
                </tr>
            });

            this.setState({
                postData
            });
        })
    };

    callPaymentMethod = () => {
        Helpers.getListPaymentChannel('marketplace').then(res => {
            const generatePaymentMethod = res.map((object, i) => {
                return <tr>
                    <td>
                        <input type="radio" style={{'height': '11px', width: '11px'}} value={object.KodePaymentGateway} name="paymentmethod" checked={((this.state.orderPaymentMethod == object.KodePaymentGateway) ? 'checked' : '')} onChange={() => {this.handleRadioPaymentChange(object)}}/>
                    </td>
                    <td className="image product-thumbnail"><img
                        src={object.Logo} alt="#"/></td>
                    <td>
                        <h6 className="text-brand text-align-left" style={{'vertical-align': 'unset'}}>{object.Shortname}</h6>
                        <h6 className="w-160 mb-5 text-align-left">
                            {
                                object.KodePaymentGateway == 'Limit' && (
                                    <a href="#" className="text-heading" style={{'font-size': '11px'}}>Saldo: Rp {this.state.summaryData.credit_limit}</a>
                                )
                            }
                            {
                                object.KodePaymentGateway == 'Saldo' && (
                                    <a href="#" className="text-heading" style={{'font-size': '11px'}}>Saldo: Rp {this.state.summaryData.saldo_waroq}</a>
                                )
                            }
                            {
                                object.KodePaymentGateway == 'KPG-0017' && (
                                    <a href="#" className="text-heading" style={{'font-size': '11px'}}>Saldo: Rp {this.state.summaryData.KoinWorksLimitFormat}</a>
                                )
                            }
                            {
                                (object.KodePaymentGateway !== 'Limit' && object.KodePaymentGateway !== 'Saldo' && object.KodePaymentGateway !== 'KPG-0017') && (
                                    <a href="#" className="text-heading" style={{'font-size': '11px'}}>{object.Fullname}</a>
                                )
                            }
                        </h6>
                    </td>
                </tr>
            });

            this.setState({
                paymentData: res,
                generatePaymentMethod
            })
        })
    };

    calculateTotal = () => {
        let jasa_kirim = 0;
        let check_total_komisi = parseInt(this.state.summaryData.commission_now_raw);
        let total_harga_kj = parseInt(this.state.orderTotal) + jasa_kirim;
        let sisa_komisi = 0;
        let payment_fee = 0;
        let jenis_komisi = '';
        let MetodePembayaran = '';
        let IDPayment = '';
        let komisi_digunakan = 0;
        let sum_total_payment = 0;

        if(this.state.isCommissionChecked){
            jenis_komisi = "Potongan";

            if(check_total_komisi>total_harga_kj) {
                MetodePembayaran = "Saldo";
                IDPayment = "Saldo";

                sisa_komisi = check_total_komisi - total_harga_kj;
                komisi_digunakan = total_harga_kj;
                payment_fee = 0;

                this.callPaymentMethod();
            }else if(check_total_komisi<total_harga_kj){
                if(this.state.orderPaymentFee == ''){
                    MetodePembayaran = "";
                    IDPayment = "";
                    sisa_komisi = 0;
                    komisi_digunakan = check_total_komisi;
                    payment_fee = 0;
                }else{
                    MetodePembayaran = this.state.orderPaymentMethodType;
                    IDPayment = this.state.orderPaymentMethod;

                    sisa_komisi = 0;
                    komisi_digunakan = check_total_komisi;

                    sum_total_payment = total_harga_kj - komisi_digunakan;
                    if(this.state.orderPaymentMethodType == 'e-Money') {
                        payment_fee = (parseFloat(this.state.orderPaymentFee) / 100) * parseInt(sum_total_payment);
                    }else if(this.state.orderPaymentMethodType == 'Credit Card'){
                        payment_fee = ((parseFloat(this.state.orderPaymentFee)/100) * (parseInt(sum_total_payment)+5000)) + 6500;
                    }else{
                        payment_fee = parseInt(this.state.orderPaymentFee);
                    }
                }
            }else{
                MetodePembayaran = "Saldo";
                IDPayment = "Saldo";

                sisa_komisi = 0;
                komisi_digunakan = total_harga_kj;
                payment_fee = 0;
            }
        }else{
            jenis_komisi = "Pilih";

            if(this.state.orderPaymentFee == ''){
                MetodePembayaran = "";
                IDPayment = "";
                sisa_komisi = check_total_komisi;
                komisi_digunakan = 0;
                payment_fee = 0;
            }else{
                MetodePembayaran = this.state.orderPaymentMethodType;
                IDPayment = this.state.orderPaymentMethod;

                sisa_komisi = check_total_komisi;
                komisi_digunakan = 0;

                sum_total_payment = total_harga_kj - komisi_digunakan;
                if(this.state.orderPaymentMethodType == 'e-Money'){
                    payment_fee = (parseFloat(this.state.orderPaymentFee)/100)*parseInt(sum_total_payment);
                }else if(this.state.orderPaymentMethodType == 'Credit Card'){
                    payment_fee = ((parseFloat(this.state.orderPaymentFee)/100) * (parseInt(sum_total_payment)+5000)) + 6500;
                }else{
                    payment_fee = parseInt(this.state.orderPaymentFee);
                }
            }
        }

        let total_harga_by_flow = total_harga_kj + payment_fee - komisi_digunakan;

        this.setState({
            typeCommission: jenis_komisi,
            orderPaymentMethodType: MetodePembayaran,
            orderPaymentMethod: IDPayment,
            orderGrandTotal: total_harga_by_flow,
            orderPaymentCharge: payment_fee,
            orderKomisiDigunakan: komisi_digunakan,
            saldoCommission: sisa_komisi,
            orderLocJasaKirim: jasa_kirim
        });
    };

    handleRadioPaymentChange = (object) => {
        this.setState({
            orderPaymentMethod: object.KodePaymentGateway,
            orderPaymentFee: object.Fee,
            orderPaymentMethodType: object.JenisBank
        }, () => {
            this.callPaymentMethod();
            this.calculateTotal();
        });

        if(object.KodePaymentGateway === 'KPG-0017'){
            $("#koinworks-tenor").css("display", "");
        }else{
            $("#koinworks-tenor").css("display", "none");
        }
    };

    handleCommissionChecked = () => {
        this.setState({
            isCommissionChecked: !this.state.isCommissionChecked
        }, () => {
            this.calculateTotal();
        });
    };

    handleCheckPayment = () => {
        if(this.state.orderPaymentMethodType == 'Limit'){
            if(parseInt(this.state.orderGrandTotal) <= parseInt(this.state.summaryData.credit_limit_raw)){
                this.handleSendUpload();
            }else{
                toast.error("Total order di keranjang tidak boleh melebihi limit yang tersedia");
            }
        }else if(this.state.orderPaymentMethodType == 'Saldo'){
            if(parseInt(this.state.orderGrandTotal) <= parseInt(this.state.summaryData.saldo_waroq_raw)){
                this.handleSendUpload();
            }else{
                toast.error("Total order di keranjang tidak boleh melebihi saldo yang tersedia");
            }
        }else if(this.state.orderPaymentMethodType == 'Bank'){
            this.handleSendUpload();
        }else if(this.state.orderPaymentMethodType == 'e-Money'){
            this.handleSendUpload();
        }else if(this.state.orderPaymentMethodType == 'Credit Card'){
            this.handleSendUpload();
        }else if(this.state.orderPaymentMethodType == 'Fintech'){
            this.handleSendUpload();
        }else{
            toast.error("Metode pembayaran belum dipilih");
        }
    };

    handleSendUpload = () => {
        if(parseInt(this.state.orderTotal) > 0){
            if (window.confirm('Apakah order anda sudah sesuai? Klik ok untuk melanjutkan')) {
                $(".loader-wrapper").css("display", "");
                Helpers.manageMPOrder(
                    this.state.sessUserCode,
                    this.state.sessMPBuyerId,
                    this.state.orderPaymentMethod,
                    this.state.orderPaymentMethodType,
                    this.state.note
                ).then(res => {
                    $(".loader-wrapper").css("display", "none");

                    if(res.length > 0){
                        if(res[0].alert == 'success'){
                            setTimeout(function() {
                                window.location.href = 'shopping';
                            }, 1000);
                        }

                        toast[res[0].alert](res[0].message);
                    }else{
                        toast.error(Helpers.MSG_ERROR);
                    }
                })
            }else{
                console.log('Cancel.');
            }
        }else{
            toast.error("Anda belum memiliki item di keranjang");
        }
    };

    render() {
        return (
            <>
                <section className="content-main">
                    <div className="content-header">
                        <div>
                            <h2 className="content-title card-title">Your Cart</h2>
                            <p>Check your order here</p>
                        </div>
                        <div>
                            <a href="shopping" className="btn btn-primary btn-sm rounded">Back to shopping</a>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-50 mt-20 order-info-wrap">
                                <div className="col-md-6">
                                    <article className="icontext align-items-start">
                                    <span className="icon icon-sm rounded-circle bg-primary-light">
                                        <i className="text-primary material-icons md-person"></i>
                                    </span>
                                        <div className="text">
                                            <h6 className="mb-1">Customer</h6>
                                            <p className="mb-1">
                                                {this.state.buyer_name} <br/>
                                                {this.state.company_email} <br/>
                                                {this.state.company_phone}
                                            </p>
                                        </div>
                                    </article>
                                </div>
                                <div className="col-md-6">
                                    <article className="icontext align-items-start">
                                    <span className="icon icon-sm rounded-circle bg-primary-light">
                                        <i className="text-primary material-icons md-place"></i>
                                    </span>
                                        <div className="text">
                                            <h6 className="mb-1">Deliver to</h6>
                                            <p className="mb-1">
                                                {this.state.address} <br/>
                                                Kodepos: {this.state.postal_code}
                                            </p>
                                        </div>
                                    </article>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th width="40%">Product</th>
                                                <th width="10%">Unit</th>
                                                <th width="20%">Unit Price</th>
                                                <th width="10%">Qty</th>
                                                <th width="20%" className="text-end">Total</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.postData}
                                            <tr>
                                                <td colSpan="5">
                                                    <article className="float-end">
                                                        <dl className="dlist">
                                                            <dt>Subtotal:</dt>
                                                            <dd>{this.state.total_orders}</dd>
                                                        </dl>
                                                        <dl className="dlist">
                                                            <dt>Payment Charge:</dt>
                                                            <dd>Rp {parseInt(this.state.orderPaymentCharge).toLocaleString(undefined, {maximumFractionDigits: 0}).toString().replaceAll(',','.')}</dd>
                                                        </dl>
                                                        <dl className="dlist">
                                                            <dt>Grand total:</dt>
                                                            <dd><b className="h5">Rp {parseFloat(this.state.orderGrandTotal).toLocaleString(undefined, {maximumFractionDigits: 0}).toString().replaceAll(',','.')}</b></dd>
                                                        </dl>
                                                    </article>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="box shadow-sm bg-light">
                                        <h6 className="mb-15">Payment Method</h6>
                                        <div className="table-responsive">
                                            <table className="table no-border" style={{'margin-bottom': 'unset'}}>
                                                <tbody>
                                                {
                                                    this.state.generatePaymentMethod
                                                }
                                                <tr id="koinworks-tenor" style={{'display': 'none'}}>
                                                    <td scope="col" colSpan={3}>
                                                        <div className="input-style">
                                                            <label>Pilih Tenor</label>
                                                            <div className="custom_select">
                                                                <select
                                                                    className="select-style" onChange={e => this.setState({koinWorksTenor: e.target.value})}>
                                                                    {
                                                                        Helpers.arrKoinWorksTenor.map((object, i) => {
                                                                            return <option value={object.value}>{object.label}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="h-25 pt-4">
                                        <div className="mb-3">
                                            <label>Notes</label>
                                            <textarea className="form-control" value={this.state.note} onChange={e => this.setState({note: e.target.value})}
                                                      placeholder="Type some note"></textarea>
                                        </div>
                                        <button className="btn btn-primary" onClick={() => {this.handleCheckPayment()}}>Checkout</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default ShoppingDetails;