import React from "react";
import {ReactSession} from "react-client-session";
import {Helpers} from "../../../config/Helpers";
import Select from 'react-select';
import {toast} from "react-toastify";
import $ from "jquery";
import { MDBDataTableV5, MDBBtn, MDBIcon } from 'mdbreact';
import {Connection} from "../../../config/Connection";

class Coverage extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            urlActive: window.location.pathname,
            sessMPSupplierId: (ReactSession.get("sess_mp_supplierid")) ? ReactSession.get("sess_mp_supplierid") : '',
            sessMPSupplierStatus: (ReactSession.get("sess_mp_supplierstatus")) ? ReactSession.get("sess_mp_supplierstatus") : '',
            dataSource: [],
            dataProvince: [],
            dataCity: [],

            kode_coverage: '',
            province: '',
            city: ''
        }
    }

    componentDidMount() {
        this.prepareData();
    }

    prepareData = () => {
        this.getMasterData('Master_Wilayah_Indonesia','','dataTerritory','kode','name','jenis');
    };

    getData = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getMPSupplierCoverage('',this.state.sessMPSupplierId,'','').then(res => {
            $(".loader-wrapper").css("display", "none");
            this.setState({ dataSource: res });
        })
    };

    getMasterData = (table,id,state,value,label,additional) => {
        $(".loader-wrapper").css("display", "");
        Helpers.getMasterData(table,id).then(res => {
            if(res){
                this.setState({
                    [state]: res.map(function(item) {
                        return {
                            additional: (additional) ? item[additional] : null,
                            value: item[value],
                            label: (label instanceof Array) ? label.map(function(val, index) {
                                let setVal = (val.includes("|")) ? item[val.split("|")[0]][val.split("|")[1]].trim() : item[val].trim();
                                return (
                                    index === label.length-1
                                ) ? setVal : setVal + ' - ';
                            }) : item[label].trim()
                        };
                    })
                }, () => {
                    if(table === 'Master_Wilayah_Indonesia'){
                        this.setState({
                            dataProvince: this.state[state].filter(item => {
                                return item.additional === 'provinsi';
                            }),
                        });
                    }
                });
            }

            this.getData();
        })
    };

    handleChange = (event,state) => {
        this.setState({ [state]: event.value, [state+'Label']: event.label });

        if(state === 'province'){
            this.setState({
                dataCity: this.state.dataTerritory.filter(item => {
                    return item.additional === 'kota' && item.value.substring(0,2) === event.value;
                }),
            });
        }else if(state === 'city'){
            this.setState({
                dataDistrict: this.state.dataTerritory.filter(item => {
                    return item.additional === 'kecamatan' && item.value.substring(0,5) === event.value;
                }),
            });
        }
    };

    setArchive = (id,command) => {
        let message = (command === 'delete') ? 'Are you sure you want to archive?' : 'Are you sure you want to publish?';
        if (window.confirm(message) == true) {
            $(".loader-wrapper").css("display", "");
            Helpers.manageMPCoverage(
                this.state.sessUserCode,
                id,
                this.state.sessMPSupplierId,
                '',
                command
            ).then(res => {
                $(".loader-wrapper").css("display", "none");
                if(res.length > 0){
                    if(res[0].alert == 'success'){
                        this.getData();
                    }

                    toast[res[0].alert](res[0].message);
                }else{
                    toast.error(Helpers.MSG_ERROR);
                }
            })
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();

        $(".loader-wrapper").css("display", "");
        Helpers.manageMPCoverage(
            this.state.sessUserCode,
            this.state.kode_coverage,
            this.state.sessMPSupplierId,
            this.state.city,
            'insert'
        ).then(res => {
            $(".loader-wrapper").css("display", "none");
            if(res.length > 0){
                if(res[0].alert == 'success'){
                    this.setState({
                        kode_coverage: '',
                        province: '',
                        city: ''
                    }, () => {
                        this.getData();
                    });
                }

                toast[res[0].alert](res[0].message);
            }else{
                toast.error(Helpers.MSG_ERROR);
            }
        })
    };

    render() {
        return (
            <>
                <section className="content-main">
                    <div className="content-header">
                        <div>
                            <h2 className="content-title card-title">Coverage List</h2>
                            <p>Find and manage your coverage here</p>
                        </div>
                    </div>
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <form method="post" onSubmit={(e) => {this.handleSubmit(e)}}>
                                        {
                                            this.state.kode_coverage && (
                                                <div className="mb-4">
                                                    <label className="form-label">ID Coverage</label>
                                                    <input type="text" placeholder="" className="form-control" readOnly={true}
                                                           value={this.state.kode_coverage} onChange={e => this.setState({kode_coverage: e.target.value})}/>
                                                </div>
                                            )
                                        }
                                        <div className="mb-4">
                                            <label className="form-label">Province</label>
                                            <Select
                                                options={this.state.dataProvince}
                                                value={this.state.dataProvince.filter((item) => item.value === this.state.province)}
                                                onChange={event => this.handleChange(event,'province')} />
                                        </div>
                                        <div className="mb-4">
                                            <label className="form-label">City</label>
                                            <Select
                                                options={this.state.dataCity}
                                                value={this.state.dataCity.filter((item) => item.value === this.state.city)}
                                                onChange={event => this.handleChange(event,'city')} />
                                        </div>
                                        <button className="btn btn-primary" type="submit">Submit</button>
                                    </form>
                                </div>
                                <div className="col-md-9">
                                    <MDBDataTableV5
                                        hover
                                        fullPagination
                                        entriesOptions={[10, 25, 50, 100]}
                                        entries={10}
                                        data={
                                            {
                                                columns: [
                                                    {
                                                        label: '#ID',
                                                        field: 'kode_coverage',
                                                    },
                                                    {
                                                        label: 'Coverage',
                                                        field: 'coverage',
                                                    },
                                                    {
                                                        label: 'Status',
                                                        field: 'status_view',
                                                    },
                                                    {
                                                        label: 'Action',
                                                        field: 'action',
                                                    }
                                                ],
                                                rows: this.state.dataSource.map((row) => {
                                                    return {
                                                        ...row,
                                                        status_view: (
                                                            <span className={'badge rounded-pill alert-'+((row.status === 'Aktif') ? 'success' : 'danger')}>{row.status_label}</span>
                                                        ),
                                                        action: (
                                                            <div className="dropdown">
                                                                <a href="#" data-bs-toggle="dropdown"
                                                                   className="btn btn-brand rounded btn-sm font-sm"> <i
                                                                    className="material-icons md-more_horiz"></i> </a>
                                                                <div className="dropdown-menu">
                                                                    {
                                                                        row.status === 'Aktif' && (
                                                                            <a className="dropdown-item text-danger" onClick={() => {this.setArchive(row.kode_coverage,'delete')}}>Archive</a>
                                                                        )
                                                                    }
                                                                    {
                                                                        row.status === 'Tidak Aktif' && (
                                                                            <a className="dropdown-item text-success" onClick={() => {this.setArchive(row.kode_coverage,'active')}}>Publish</a>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        ),
                                                    };
                                                }),
                                            }
                                        } />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Coverage;