import React from "react";

import queryString from "query-string";
import {isMobile} from "react-device-detect";
import $ from "jquery";
import 'react-html5-camera-photo/build/css/index.css';
import 'react-html5-camera-photo/build/css/index.css';
import {decode as base64_decode} from "base-64";
import {ReactSession} from "react-client-session";
import DropdownCart from "../../Header";

class WebViewer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            urlParam: queryString.parse(window.location.search),
            urlLoad: '',
            urlBack: '',
            loadStatus: false
        }
    }

    componentDidMount() {
        this.setState({
            urlLoad: (this.state.urlParam.urlload) ? base64_decode(this.state.urlParam.urlload) : '',
            urlBack: (this.state.urlParam.urlback) ? base64_decode(this.state.urlParam.urlback) : ''
        }, () => {
            if(this.state.urlLoad.slice(-4) !== '.pdf'){
                setTimeout(function() {
                    $("#iframe").addClass("iframe");
                }, 1000);
            }
        });

        if(isMobile) {
            $("#mobile-header").css("display", "none");
            $("#mobile-footer").css("display", "none");
        }
    }

    render() {
        return (
            <>
                <main className="main pages mb-80">
                    <div className="container mb-80 mt-5">
                        <div className="row">
                            <div className="col-lg-12">
                                <a href={this.state.urlBack} className="btn btn-sm">Kembali</a>
                            </div>
                            <div className="col-lg-12">
                                {
                                    (this.state.urlLoad.slice(-4) === '.pdf') && (
                                        <iframe src={this.state.urlLoad} className="iframe">
                                            Your browser doesn't support iframes
                                        </iframe>
                                    )
                                }
                                {
                                    (this.state.urlLoad.slice(-4) !== '.pdf') && (
                                        <iframe src={this.state.urlLoad} id="iframe" is="x-frame-bypass">
                                            Your browser doesn't support iframes
                                        </iframe>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default WebViewer;