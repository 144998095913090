import React from "react";
import {ReactSession} from "react-client-session";
import {toast} from "react-toastify";
import OtpInput from 'react-otp-input';

import { Helpers } from "../config/Helpers";
import queryString from "query-string";

class Register extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            urlParam: queryString.parse(window.location.search),
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            phone: '',
            otp: ''
        }
    }

    componentDidMount() {
        if(this.state.sessUserCode){
            window.location.href = './';
        }
    }

    handleOTP = () => {
        if(this.state.phone){
            Helpers.requestOTP(
                'request',
                this.state.phone,
                ''
            ).then(res => {
                if(res.length > 0){
                    toast[res[0].alert](res[0].message);
                }else{
                    toast.error(Helpers.MSG_ERROR);
                }
            })
        }else{
            toast.error(Helpers.MSG_ERROR_FORM);
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();

        Helpers.register(
            this.state.firstName + ' ' + this.state.lastName,
            this.state.phone,
            this.state.email,
            this.state.password,
            this.state.otp
        ).then(res => {
            if(res.length > 0){
                if(res[0].alert == 'success'){
                    this.setState({
                        email: '',
                        password: '',
                        firstName: '',
                        lastName: '',
                        phone: '',
                        otp: ''
                    });

                    setTimeout(() => {
                        if(this.state.urlParam.voucher){
                            window.location.href = '/login?voucher='+this.state.urlParam.voucher;
                        }else{
                            window.location.href = './login';
                        }
                    }, 1000);
                }

                toast[res[0].alert](res[0].message);
            }else{
                toast.error(Helpers.MSG_ERROR);
            }
        })
    };

    render() {
        return (
            <>
                <main className="main pages">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <a href="./" rel="nofollow"><i className="fi-rs-home mr-5"></i>Beranda</a>
                                <span></span> Daftar
                            </div>
                        </div>
                    </div>
                    <div className="page-content pt-30 pb-150">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
                                    <div className="row">
                                        <div className="col-lg-6 pr-30 d-none d-lg-block">
                                            <img className="border-radius-15" src="assets/imgs/page/bg-auth.jpg"
                                                 alt=""/>
                                        </div>
                                        <div className="col-lg-6 col-md-8">
                                            <div className="login_wrap widget-taber-content background-white">
                                                <div className="padding_eight_all bg-white">
                                                    <div className="heading_s1">
                                                        <h1 className="mb-5">Buat Akun</h1>
                                                        <p className="mb-30">Sudah memiliki akun? <a
                                                            href="./login">Login disini</a></p>
                                                    </div>
                                                    <form method="post" onSubmit={(e) => {this.handleSubmit(e)}}>
                                                        <div className="form-group">
                                                            <input required type="text" value={this.state.firstName} onChange={e => this.setState({firstName: e.target.value})}
                                                                   placeholder="Nama Depan *"/>
                                                        </div>
                                                        <div className="form-group">
                                                            <input required type="text" value={this.state.lastName} onChange={e => this.setState({lastName: e.target.value})}
                                                                   placeholder="Nama Belakang *"/>
                                                        </div>
                                                        <div className="login_footer form-group">
                                                            <div className="chek-form">
                                                                <input required type="number" value={this.state.phone} onChange={e => this.setState({phone: e.target.value})}
                                                                       min={0}
                                                                       placeholder="Nomor HP *"/>
                                                            </div>
                                                            <button type="button"
                                                                    className="btn btn-heading btn-block hover-up"
                                                                    onClick={() => {this.handleOTP()}}>Kirim OTP
                                                            </button>
                                                        </div>
                                                        <div className="form-group">
                                                            <OtpInput
                                                                inputStyle={{'width': '100%', 'padding-left': '0px'}}
                                                                value={this.state.otp}
                                                                onChange={otp => this.setState({otp})}
                                                                numInputs={6}
                                                                isInputSecure={true}
                                                                separator={<span>&nbsp;</span>}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <input required type="email" value={this.state.email} onChange={e => this.setState({email: e.target.value})}
                                                                   placeholder="Email *"/>
                                                        </div>
                                                        <div className="form-group">
                                                            <input required type="password" value={this.state.password} onChange={e => this.setState({password: e.target.value})}
                                                                   placeholder="Password *"/>
                                                        </div>
                                                        <div className="form-group">
                                                            <button type="submit"
                                                                    className="btn btn-heading btn-block hover-up"
                                                                    name="login">Daftar
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default Register;