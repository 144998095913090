import React from "react";

import { Helpers } from "../../config/Helpers"
import {ReactSession} from "react-client-session";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import queryString from "query-string";
import {toast} from "react-toastify";
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import QRCode from "react-qr-code";
import ModalTracking from "../../components/ModalTracking";

class OrderActiveDetails extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            sessUserType: (ReactSession.get("sess_usertype")) ? ReactSession.get("sess_usertype") : '',
            orderData: '',
            orderDetailsData: [],
            kodeTransaksiOrder: '',
            isModalOutOpen: false,
            isModalReceiveOpen: false,
            urlParam: queryString.parse(window.location.search),
            branchCode: '',
            setModal: '',
            time: {},
            seconds: 0
        }
        this.timer = 0;
    }

    componentDidMount() {
        if(!this.state.sessUserCode){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            const kodeOrder = (this.state.urlParam.trxid) ? base64_decode(this.state.urlParam.trxid) : '';

            this.setState({
                kodeTransaksiOrder: kodeOrder
            }, () => {
                this.getOrderDetail();
            });
        }
    }

    getOrderDetail = () => {
        Helpers.getListOrder('yes',this.state.kodeTransaksiOrder,'','','').then(res => {
            const status_overall = res[0].status_overall;
            const StatusDirectPayment = res[0].StatusDirectPayment;
            const MetodePembayaran = res[0].MetodePembayaran;
            const SisaWaktuPembayaran = res[0].SisaWaktuPembayaran;
            const SisaWaktuPembayaranSeconds = res[0].SisaWaktuPembayaranSeconds;
            const KodeCabang = res[0].KodeCabang;
            const PickupOut = res[0].PickupOut;
            const PickupReceive = res[0].PickupReceive;
            const TipePengiriman = res[0].TipePengiriman;

            let btn_payment = false;
            let tv_sisa_waktu_bayar = false;
            let tv_sisa_waktu_bayar_view = '';
            let ll_information = false;
            let btn_cancel = false;
            let btn_qrcode = false;
            let ll_information_qrcode = false;
            let ll_qrcode = false;
            let cv_qrcode_out = false;
            let btn_qrcode_out = '';
            let btn_qrcode_receive = '';
            let btn_tracking = <button className="btn btn-fill-out btn-block mr-15" onClick={() => {this.handleTrackingClick()}}>
                Lihat Detail Status
            </button>;
            let btn_change_delivery = '';
            let btn_change_payment = '';

            if(TipePengiriman === 'Ambil Sendiri' && PickupReceive == "Proses" && status_overall !== "Order Canceled" && parseInt(SisaWaktuPembayaranSeconds) > 0){
                btn_change_delivery = <button className="btn btn-fill-out btn-block mr-15" onClick={() => {this.handleChangeDeliveryMethod()}}>
                    Ubah Pengiriman
                </button>;
            }

            if(status_overall == "Waiting Payment"){
                btn_payment = true;
            }else{
                if(status_overall == "Order Processed" && StatusDirectPayment == "Pending" && (MetodePembayaran == "Bank" || MetodePembayaran == "e-Money" || MetodePembayaran == "Credit Card" || MetodePembayaran == "Fintech") && parseInt(SisaWaktuPembayaranSeconds) > 0){
                    btn_payment = true;
                    tv_sisa_waktu_bayar = true;

                    this.setState({
                        seconds: parseInt(res[0].SisaWaktuPembayaranSeconds)/1000
                    }, () => {
                        let timeLeftVar = this.secondsToTime(this.state.seconds);
                        this.setState({
                            time: timeLeftVar
                        }, () => {
                            this.startTimer();
                        });
                    });
                }else if(status_overall == "Order Processed" && StatusDirectPayment == "Pending" && (MetodePembayaran == "Bank" || MetodePembayaran == "e-Money" || MetodePembayaran == "Credit Card" || MetodePembayaran == "Fintech") && parseInt(SisaWaktuPembayaranSeconds) == 0){
                    btn_payment = false;
                    tv_sisa_waktu_bayar = true;
                    tv_sisa_waktu_bayar_view = <div>Waktu pembayaran habis, order telah dibatalkan atau dalam proses menunggu konfirmasi pembayaran.</div>
                }else{
                    btn_payment = false;
                    tv_sisa_waktu_bayar = false;
                }
            }

            if(status_overall == "Order Processed" && MetodePembayaran == "Limit"){
                ll_information = true;
            }else{
                ll_information = false;
            }

            if(status_overall == "Order Processed"){
                if(MetodePembayaran == "Bank" || MetodePembayaran == "e-Money" || MetodePembayaran == "Credit Card" || MetodePembayaran == "Fintech"){
                    if(StatusDirectPayment == "Pending" && parseInt(SisaWaktuPembayaranSeconds) > 0){
                        btn_cancel = true;
                        btn_change_payment = <button className="btn btn-fill-out btn-block mr-15" onClick={() => {this.handleChangePaymentMethod()}}>
                            Ubah Pembayaran
                        </button>;
                    }else{
                        btn_cancel = false;
                    }
                }else if(MetodePembayaran == "Saldo"){
                    btn_cancel = false;
                }else{
                    btn_cancel = true;
                }
            }else{
                btn_cancel = false;
            }

            if(status_overall == "Waiting Pickup"){
                btn_qrcode = false;
                ll_information_qrcode = true;
                ll_qrcode = true;

                if(this.state.sessUserType == "logistik"){
                    cv_qrcode_out = true;

                    if(PickupOut == "Done"){
                        btn_qrcode_out = <button className="btn btn-fill-out btn-block btn-secondary mr-15">
                            Pengambilan barang telah dilakukan
                        </button>
                    }else{
                        btn_qrcode_out = <button className="btn btn-fill-out btn-block mr-15" onClick={() => {this.handleModalQROut()}}>
                            QRCode Pengambilan Barang
                        </button>
                    }

                    if(PickupReceive == "Done"){
                        btn_qrcode_receive = <button className="btn btn-fill-out btn-block btn-secondary mr-15">
                            Barang telah diterima customer
                        </button>
                    }else{
                        btn_qrcode_receive = <button className="btn btn-fill-out btn-block mr-15" onClick={() => {this.handleModalQRReceive()}}>
                            Scan QRCode Penerimaan Barang
                        </button>
                    }
                }else{
                    cv_qrcode_out = false;

                    if(PickupReceive == "Done"){
                        btn_qrcode_receive = <button className="btn btn-fill-out btn-block btn-secondary mr-15">
                            Barang telah diterima customer
                        </button>
                    }else{
                        if(TipePengiriman == 'Biteship'){
                            btn_qrcode_receive = '';

                            /*btn_tracking = <button className="btn btn-fill-out btn-block mr-15" onClick={() => {this.handleTrackingClick()}}>
                                Tracking Pengiriman
                            </button>*/
                        }else{
                            btn_qrcode_receive = <button className="btn btn-fill-out btn-block mr-15" onClick={() => {this.handleModalQRReceive()}}>
                                QRCode Penerimaan Barang
                            </button>

                            //btn_tracking = ''
                        }
                    }
                }
            }else{
                btn_qrcode = false;
                ll_information_qrcode = false;
                ll_qrcode = false;
            }

            this.setState({
                orderData: res[0],
                btn_payment: btn_payment,
                tv_sisa_waktu_bayar: tv_sisa_waktu_bayar,
                tv_sisa_waktu_bayar_view: tv_sisa_waktu_bayar_view,
                ll_information: ll_information,
                btn_cancel: btn_cancel,
                btn_qrcode: btn_qrcode,
                ll_information_qrcode: ll_information_qrcode,
                ll_qrcode: ll_qrcode,
                cv_qrcode_out: cv_qrcode_out,
                btn_qrcode_out: btn_qrcode_out,
                btn_qrcode_receive: btn_qrcode_receive,
                btn_tracking: btn_tracking,
                btn_change_delivery: btn_change_delivery,
                btn_change_payment: btn_change_payment,
                branchCode: KodeCabang
            }, () => {
                this.getListOrderDetail();
            });
        })
    };

    handleModalQROut = () => {
        this.setState({ isModalOutOpen: !this.state.isModalOutOpen });
    };

    handleModalQRReceive = () => {
        this.setState({ isModalReceiveOpen: !this.state.isModalReceiveOpen });
    };

    getListOrderDetail = () => {
        Helpers.getListOrderDetail(this.state.kodeTransaksiOrder).then(res => {
            this.setState({ orderDetailsData: res });
        })
    };

    handleCancel = () => {
        if (window.confirm('Apakah anda yakin akan membatalkan order ini?')) {
            Helpers.cancelOrder(this.state.kodeTransaksiOrder, this.state.sessEmail).then(res => {
                if(res.length > 0){
                    if(res[0].alert == 'success'){
                        setTimeout(function() {
                            window.location.href = './account-orders';
                        }, 1000);
                    }

                    toast[res[0].alert](res[0].message);
                }else{
                    toast.error(Helpers.MSG_ERROR);
                }
            })
        }else{
            console.log('Cancel.');
        }
    };

    handlePayment = () => {
        const MetodePembayaran = this.state.orderData.MetodePembayaran;
        const KodePaymentGateway = this.state.orderData.KodePaymentGateway;
        const KodeTransaksiOrder = this.state.orderData.KodeTransaksiOrder;

        if(MetodePembayaran == "Limit"){
            //dialogFormUploadPayment();
        }else if(MetodePembayaran == "Bank" || MetodePembayaran == "e-Money" || MetodePembayaran == "Credit Card" || MetodePembayaran == "Fintech"){
            if(KodePaymentGateway == ""){
                console.log('Empty Payment');
            }else{
                window.location.href = './account-orders-payment?trxid=' + base64_encode(KodeTransaksiOrder) + '&payment=' + base64_encode(KodePaymentGateway);
            }
        }
    };

    handleChangeDeliveryMethod = () => {
        Helpers.getChangeDeliveryMethod(this.state.kodeTransaksiOrder).then(res => {
            if(res.length > 0){
                window.location.href = './account-orders-payment?trxid=' + base64_encode(res[0].KodeChangeDelivery) + '&payment=' + base64_encode(res[0].KodePaymentGateway) + '&type=change_delivery';
            }else{
                window.location.href = './change-delivery-method?trxid=' + base64_encode(this.state.kodeTransaksiOrder);
            }
        })
    };

    handleChangePaymentMethod = () => {
        window.location.href = './change-payment-method?trxid=' + base64_encode(this.state.kodeTransaksiOrder);
    };

    secondsToTime = (secs) => {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    startTimer = () => {
        if (this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown = () => {
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
            tv_sisa_waktu_bayar_view: <div>Sisa Waktu Pembayaran: {((this.state.time.h.toString().length < 2) ? '0'+this.state.time.h : this.state.time.h)}:{((this.state.time.m.toString().length < 2) ? '0'+this.state.time.m : this.state.time.m)}:{((this.state.time.s.toString().length < 2) ? '0'+this.state.time.s : this.state.time.s)}</div>
        });

        if (seconds == 0) {
            clearInterval(this.timer);
            window.location.reload();
        }
    }

    handleTrackingClick = () => {
        ReactSession.set("sess_tracking_code", this.state.kodeTransaksiOrder);

        this.setState({
            setModal: ''
        }, () => {
            this.setState({
                setModal: <ModalTracking modalRef={true}/>
            })
        })
    };

    render() {
        return (
            <>
                { this.state.setModal }

                <Modal
                    size={'md'}
                    isOpen={this.state.isModalOutOpen}
                    toggle={() => {this.handleModalQROut()}}>
                    <ModalBody style={{'padding': 'unset'}}>
                        <div className="row mt-15 mb-15" style={{'margin': 'unset'}}>
                            <div className="col-lg-12">
                                <center><QRCode value={'out'+this.state.kodeTransaksiOrder} /></center>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {this.handleModalQROut()}}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal
                    size={'md'}
                    isOpen={this.state.isModalReceiveOpen}
                    toggle={() => {this.handleModalQRReceive()}}>
                    <ModalBody style={{'padding': 'unset'}}>
                        <div className="row mt-15 mb-15" style={{'margin': 'unset'}}>
                            <div className="col-lg-12">
                                <center><QRCode value={'rcv'+this.state.kodeTransaksiOrder} /></center>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {this.handleModalQRReceive()}}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
                <main className="main pages mb-80">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <a href="./" rel="nofollow"><i className="fi-rs-home mr-5"></i>Beranda</a>
                                <span></span> Akun <span></span> Order Aktif <span></span> Detail Order
                            </div>
                        </div>
                    </div>
                    <div className="page-content pt-10">
                        <div className="container">
                            <div className="invoice-inner mb-30">
                                <div className="invoice-info" id="invoice_wrapper">
                                    <div className="invoice-header">
                                        <div className="row">
                                            <div className="col-lg-4 col-12">
                                                <div className="invoice-number">
                                                    <h4 className="invoice-header-1 mb-10 mt-20" style={{'font-size': '18px'}}>No Invoice: <span
                                                        className="text-brand">#{this.state.orderData.KodeTransaksiOrder}</span></h4>
                                                    <p className="invoice-addr-1" style={{'font-size': '14px'}}>
                                                        <strong>Tanggal Transaksi: {this.state.orderData.date_d + ' ' + this.state.orderData.date_m + ' ' + this.state.orderData.date_y + ', ' + this.state.orderData.date_t + ' WIB'}</strong> <br/>
                                                        {this.state.orderData.NamaWarung} <br/>
                                                        {this.state.orderData.NamaPemilikWarung}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12 mt-20">
                                                <div className="invoice-number">
                                                    <h4 className="invoice-title-1 mb-10" style={{'font-size': '18px'}}>Informasi Pengiriman</h4>
                                                    <p className="invoice-addr-1" style={{'font-size': '14px'}}>
                                                        <strong>{(this.state.orderData.Kurir) ? this.state.orderData.Kurir : this.state.orderData.TipePengiriman}</strong> <br/>
                                                        {this.state.orderData.Alamat}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-12 mt-20">
                                                <div className="invoice-number">
                                                    <h4 className="invoice-title-1 mb-10" style={{'font-size': '18px'}}>Status Pembayaran</h4>
                                                    <p className="invoice-addr-1" style={{'font-size': '14px'}}>
                                                        <strong>{this.state.orderData.StatusPembayaranLabelNew}</strong> <br/>
                                                        Metode Pembayaran: {this.state.orderData.MetodePembayaran}{(this.state.orderData.NamaPaymentChannel) ? ' - '+this.state.orderData.NamaPaymentChannel : ''} <br/>
                                                        {
                                                            this.state.tv_sisa_waktu_bayar && (
                                                                this.state.tv_sisa_waktu_bayar_view
                                                            )
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead className="bg-active">
                                    <tr>
                                        <th>Produk</th>
                                        <th className="text-center">Unit</th>
                                        <th className="text-center">Quantity</th>
                                        <th className="text-center">Harga</th>
                                        <th className="text-right">Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.orderDetailsData.map((object, i) => {
                                            return <tr>
                                                <td>
                                                    <div className="item-desc-1">
                                                        <span>{object.NamaBarang}</span>
                                                    </div>
                                                </td>
                                                <td className="text-center">{object.UnitBarang}</td>
                                                <td className="text-center">{object.Qty}</td>
                                                <td align="right">Rp {object.HargaSatuan}</td>
                                                <td align="right">Rp {object.Harga}</td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td colSpan="4" className="text-end f-w-600">Total</td>
                                        <td align="right">Rp {this.state.orderData.GrandTotal}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="4" className="text-end f-w-600">Point Digunakan</td>
                                        <td align="right">Rp - {this.state.orderData.PotonganKomisi}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="4" className="text-end f-w-600">Biaya Pengiriman</td>
                                        <td align="right">Rp {this.state.orderData.JasaKirim}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="4" className="text-end f-w-600">Biaya Admin</td>
                                        <td align="right">Rp {this.state.orderData.BiayaAdmin}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="4" className="text-end f-w-600">Total Bayar</td>
                                        <td align="right"><h6 className="text-brand">Rp {this.state.orderData.TotalHargaOrder}</h6></td>
                                    </tr>
                                    <tr>
                                        <td colSpan="4" className="text-end f-w-600">Estimasi Komisi yang akan didapatkan</td>
                                        <td align="right">Rp {this.state.orderData.EstimasiKomisi}</td>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                            {
                                this.state.ll_information && (
                                    <div className="primary-sidebar">
                                        <div className="sidebar-widget widget-store-info mb-30 bg-2 border-0">
                                            <div className="vendor-info">
                                                <div className="vendor-des">
                                                    <p className="font-sm text-heading">
                                                        Mohon menunggu, order sedang dipersiapkan. Estimasi pengiriman order dalam 3 - 7 hari kerja.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                this.state.ll_information_qrcode && (
                                    <div className="primary-sidebar">
                                        <div className="sidebar-widget widget-store-info mb-30 bg-2 border-0 info-custom-caramel">
                                            <div className="vendor-info">
                                                <div className="vendor-des">
                                                    <p className="font-sm text-heading" align="justify">
                                                        <strong>Perhatian:</strong><br/>
                                                        Pengambilan barang wajib menggunakan QR Code, tunjukkan QR Code berikut kepada tim logistik waroq saat pengambilan barang di gudang dan setelah menerima barang, klik tombol dibawah ini untuk menampilkan.<br/>
                                                        Untuk pengiriman menggunakan metode delivery, estimasi pengiriman order dalam <strong>3 - 7 hari kerja.</strong><br/>
                                                        <br/>

                                                        <strong>Alamat pengambilan barang:</strong><br/>
                                                        {
                                                            this.state.branchCode=='CBNG-18-020001' && (
                                                                <>
                                                                    Mayapada Tower 2<br/>
                                                                    (Gudang Waroq/Gudang BBX Mart)<br/>
                                                                    Jl. Jend. Sudirman No.Kav. 27, RT.4/RW.2, Kuningan, Karet, Kecamatan Setiabudi, Kota Jakarta Selatan, DKI Jakarta 12920<br/><br/>
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            this.state.branchCode=='CBNG-18-020002' && (
                                                                <>
                                                                    RUKO MAYAPADA<br/>
                                                                    Jl. Wibawa Mukti II RT.006/RW.001, Jatiluhur, Kec. Jatiasih (Ruko mayapada), KOTA BEKASI, JATIASIH, JAWA BARAT, ID, 17425<br/><br/>
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            this.state.branchCode=='CBNG-18-020003' && (
                                                                <>
                                                                    Mayapada Hospital Surabaya (MHSB)<br/>
                                                                    Jl. Mayjen Sungkono No.20, Pakis, Kec. Sawahan, Kota SBY, Jawa Timur 60256<br/><br/>
                                                                </>
                                                            )
                                                        }

                                                        <strong>Jam operasional gudang untuk pick up</strong>:<br/>
                                                        - Senin-Jumat: <strong>Jam 08.00 sd 18.00</strong><br/>
                                                        - Sabtu: <strong>Jam 10.00 sd 17.00</strong><br/>
                                                        - Minggu: <font color='#F60000'><strong>Tidak beroperasi</strong></font>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            <div className="mt-30">
                                {
                                    this.state.btn_cancel && (
                                        <button className="btn btn-fill-out btn-block btn-secondary mr-15" onClick={() => {this.handleCancel()}}>
                                            Batalkan Order
                                        </button>
                                    )
                                }
                                {
                                    this.state.btn_payment && (
                                        <button className="btn btn-fill-out btn-block mr-15" onClick={() => {this.handlePayment()}}>
                                            Lanjutkan Pembayaran
                                        </button>
                                    )
                                }
                                {
                                    (this.state.ll_qrcode && this.state.cv_qrcode_out) && (
                                        <>
                                            {this.state.btn_qrcode_out}
                                            {this.state.btn_qrcode_receive}
                                        </>
                                    )
                                }
                                {
                                    (this.state.ll_qrcode && !this.state.cv_qrcode_out) && (
                                        this.state.btn_qrcode_receive
                                    )
                                }
                                { this.state.btn_tracking }
                                { this.state.btn_change_delivery }
                                { this.state.btn_change_payment }
                            </div>
                        </div>

                    </div>
                </main>
            </>
        );
    }
}

export default OrderActiveDetails;