import React from "react";
import ReactPaginate from 'react-paginate';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { ReactSession } from 'react-client-session';

import { Helpers } from "../../config/Helpers";
import {toast} from "react-toastify";
import queryString from "query-string";
import ModalPatungan from "./Dashboard";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Whatsapp} from "react-social-sharing/dist-es6";

class List extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            sessStore: (ReactSession.get("sess_order_store")) ? ReactSession.get("sess_order_store") : '',
            dataMarketPlace: [],
            dataMarketPlaceDetails: [],
            offset: 0,
            perPage: 10,
            currentPage: 0,
            pageNumList: [10,25,50,100,150,200],
            sortData: ['Featured','ID Ascending','ID Descending'],
            search: '',
            urlParam: queryString.parse(window.location.search),
            viewListMode: '',
            breadcumbs: '',
            isModalOpen: false,
            isModalChildOpen: false,
            isModalRefundOpen: false,
            selectStatusUser: '',
            selectStatusMarket: '',
            selectKodeMarketPlace: '',
            selectKodeMarketPlaceDetail: '',
            selectOrderType: '',
            shareMessage: '',
            branchCode: (ReactSession.get("branch_code")) ? ReactSession.get("branch_code") : 'CBNG-18-020001',
        };
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    componentDidMount() {
        if(!this.state.sessUserCode){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.setState({
                viewListMode: (this.state.urlParam.v) ? this.state.urlParam.v : '',
            }, () => {
                if(!this.state.viewListMode){
                    window.location.href = './patungan';
                }else{
                    this.getData();
                }
            });
        }
    }

    getData = () => {
        if(this.state.viewListMode == "user") {
            this.getMarketListByUser();
        }else if(this.state.viewListMode == "cancel"){
            this.getMarketListByUserCancel();
        }else{
            this.getMarketList();
        }
    };

    getMarketListByUser = () => {
        Helpers.getMarketListByUser(this.state.sessEmail,this.state.sessUserCode).then(res => {
            this.setState({
                dataMarketPlace: res,
                breadcumbs: 'Patungan Belanja Anda'
            }, () => {
                this.setData();
            });
        })
    };

    getMarketListByUserCancel = () => {
        Helpers.getMarketListByUserCancel(this.state.sessEmail,this.state.sessUserCode).then(res => {
            this.setState({
                dataMarketPlace: res,
                breadcumbs: 'Patungan Belanja Refund'
            }, () => {
                this.setData();
            });
        })
    };

    getMarketList = () => {
        Helpers.getMarketList(
            'yes',
            this.state.sessEmail,
            this.state.sessUserCode,
            this.state.orderType,
            this.state.branchCode,
            ''
        ).then(res => {
            this.setState({
                dataMarketPlace: res,
                breadcumbs: 'Patungan Belanja'
            }, () => {
                this.setData();
            });
        })
    };

    getMarketListDetails = () => {
        Helpers.getMarketListDetails(this.state.selectKodeMarketPlace).then(res => {
            this.setState({
                dataMarketPlaceDetails: res
            });
        })
    };

    setupShareMessage = (unique) => {
        this.setState({
            shareMessage: 'Halloo!! Aku lagi belanja di Waroq nih, barangnya asli murah banget..nget...ngetttt!!\n\n' +
                'Patungan Yuk! dengan menggabungkan belanja kita sehingga harganya semakin murah!! \uD83E\uDD73\n\n' +
                'Yuk tunggu apa lagi!! cari berdasarkan kode berikut *#' + unique + '* atau' +
                '\uD83D\uDC47 langsung klik di sini ya \uD83D\uDC47\n'
        });
    }

    setData = () => {
        let sortData;
        if(this.state.viewListMode == "user") {
            sortData = this.state.dataMarketPlace.filter(item => parseInt(item.PercentageReal) > 0 );
        }else if(this.state.viewListMode == "cancel"){
            sortData = this.state.dataMarketPlace;
        }else{
            sortData = this.state.dataMarketPlace.filter(item => parseInt(item.PercentageReal) > 0 );
        }

        if(this.state.search !== ''){
            sortData = sortData.filter(item => {
                const textData = this.state.search.toUpperCase();

                return Object.keys(item).some(key => ((item[key]) ? item[key] : '').toString().toUpperCase().search(textData) !== -1);
            });
        }
        if(this.state.sortDataValue == 'ID Descending'){
            sortData = sortData.sort(function(a,b) {
                const val_a = a.Nama.toUpperCase();
                const val_b = b.Nama.toUpperCase();

                return (val_a < val_b) ? 1 : ((val_b < val_a) ? -1 : 0);
            });
        }
        if(this.state.sortDataValue == 'ID Ascending'){
            sortData = sortData.sort(function(a,b) {
                const val_a = a.Nama.toUpperCase();
                const val_b = b.Nama.toUpperCase();

                return (val_a > val_b) ? 1 : ((val_b > val_a) ? -1 : 0);
            });
        }

        const slice = sortData.slice(this.state.offset, this.state.offset + this.state.perPage)
        const postData = slice.map((object, i) => {
            return <div className="col-lg-3 col-md-6 col-12 col-sm-6">
                <div className="vendor-wrap mb-40">
                    <div className="vendor-img-action-wrap" style={{'padding': 'unset'}}>
                        <div className="vendor-img">
                            <table style={{'margin-bottom': 'unset'}}>
                                <tr>
                                    <td>
                                        <div className="justify-content-between align-items-end">
                                            <div>
                                                <h4 style={{'font-size': '15px'}}><a style={{'color': '#000'}}>{object.KodeMarketPlace}</a></h4>
                                                <p className="mt-2" style={{'font-size': '13px', 'line-height': '1.5'}} align="justify">
                                                    {object.NamaMarketingMasking} <br/>
                                                    <div className="dropdown-divider"></div>
                                                    <table style={{'margin-bottom': '0px', 'border-color': 'unset'}}>
                                                        <tr>
                                                            <td style={{'border': 'unset', 'padding': '5px 0px'}}>{object.PercentageReal}%</td>
                                                            <td style={{'border': 'unset', 'padding': '5px 0px'}} align="right">{object.TotalTerpenuhiRealFormat} / {object.MinimalOrderFormat}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{'border': 'unset', 'padding': '5px 0px'}} colSpan={2}>
                                                                <div className="progress">
                                                                    <div className="progress-bar" role="progressbar" style={{width: object.PercentageReal + '%'}}
                                                                         aria-valuemin="0" aria-valuemax="100"></div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{'border': 'unset', 'padding': '5px 0px'}}>Aktif Sampai</td>
                                                            <td style={{'border': 'unset', 'padding': '5px 0px'}} align="right">{object.TanggalExpiredFormat}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{'border': 'unset', 'padding': '0px 0px'}}>Status Anda</td>
                                                            <td style={{'border': 'unset', 'padding': '0px 0px'}} align="right"><strong>{object.StatusUser}</strong></td>
                                                        </tr>
                                                    </table>
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="product-badges product-badges-position product-badges-mrg">
                                <span className="hot">
                                    <a>#{object.UniqueCode}</a>
                                </span>
                        </div>
                    </div>
                    <div className="vendor-content-wrap" style={{'padding': '10px 20px 10px 20px'}}>
                        <div className="d-flex justify-content-between align-items-center">
                            {
                                object.Status == 'Cancel' && (
                                    <div>
                                        <a onClick={() => {this.handlePatunganRefundClick(object)}} className="btn btn-xs btn-secondary">Refund </a>
                                    </div>
                                )
                            }

                            {
                                (object.Status == 'Active' && object.StatusUser !== 'Payment') && (
                                    <div>
                                        <a onClick={() => {this.handlePatunganClick(object)}} className="btn btn-xs">Ikut Patungan <i className="fi-rs-arrow-small-right"></i></a>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        })

        this.setState({
            pageCount: Math.ceil(sortData.length / this.state.perPage),
            postData
        })
    };

    handlePatunganClick = (object) => {
        this.handleModal();

        this.setState({
            selectStatusUser: object.StatusUser,
            selectStatusMarket: object.Status,
            selectKodeMarketPlace: object.KodeMarketPlace,
            selectKodeMarketPlaceDetail: object.KodeMarketPlaceDetail,
            selectOrderType: object.Jenis
        }, () => {
            this.setupShareMessage(object.UniqueCode)
        });
    };

    handlePatunganRefundClick = (object) => {
        this.handleModalRefund();

        this.setState({
            selectStatusUser: object.StatusUser,
            selectStatusMarket: object.Status,
            selectKodeMarketPlace: object.KodeMarketPlace,
            selectKodeMarketPlaceDetail: object.KodeMarketPlaceDetail,
            selectOrderType: object.Jenis
        });
    };

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.getData()
        });
    };

    handlePageNumClick = (num) => {
        this.setState({
            currentPage: 0,
            offset: 0,
            perPage: num,
        }, () => {
            this.getData()
        });
    };

    handleSortByClick = (sortBy) => {
        this.setState({
            currentPage: 0,
            offset: 0,
            sortDataValue: sortBy
        }, () => {
            this.getData()
        });
    };

    handleSearch = (e) => {
        e.preventDefault();

        this.setState({
            currentPage: 0,
            offset: 0
        }, () => {
            this.getData()
        });
    };

    handleModal = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    handleChildModal = () => {
        this.getMarketListDetails();
        this.handleModal();
        this.setState({ isModalChildOpen: !this.state.isModalChildOpen });
    };

    handleModalRefund = () => {
        this.setState({ isModalRefundOpen: !this.state.isModalRefundOpen });
    };

    handleMulaiBelanja = () => {
        ReactSession.set("order_type", this.state.selectOrderType);

        window.location.href = './shop?joinid=' + base64_encode(this.state.selectKodeMarketPlaceDetail);
    };

    handleJoin = () => {
        if (window.confirm('Apakah anda yakin akan bergabung dengan patungan belanja berikut ini?')) {
            Helpers.joinMarketOrder(
                this.state.sessUserCode,
                this.state.selectKodeMarketPlace
            ).then(res => {
                if(res.length > 0){
                    if(res[0].alert == 'success'){
                        ReactSession.set("order_type", this.state.selectOrderType);
                        this.handleModal();

                        setTimeout(function() {
                            window.location.href = './shop?joinid=' + base64_encode(res[0].kode);
                        }, 1000);
                    }

                    toast[res[0].alert](res[0].message);
                }else{
                    toast.error(Helpers.MSG_ERROR);
                }
            })
        } else {
            console.log('Cancel.');
        }
    };

    handleRefund = (type) => {
        if (window.confirm('Apakah anda yakin akan melakukan refund ke pilihan tersebut?')) {
            Helpers.saveRefundPatungan(
                this.state.selectKodeMarketPlaceDetail,
                this.state.sessUserCode,
                type
            ).then(res => {
                if(res.length > 0){
                    if(res[0].alert == 'success'){
                        this.handleModalRefund();

                        setTimeout(function() {
                            window.location.reload();
                        }, 1000);
                    }

                    toast[res[0].alert](res[0].message);
                }else{
                    toast.error(Helpers.MSG_ERROR);
                }
            })
        } else {
            console.log('Cancel.');
        }
    };

    render() {
        return (
            <>
                <Modal
                    size={'md'}
                    isOpen={this.state.isModalOpen}
                    toggle={() => {this.handleModal()}}>
                    <ModalBody style={{'padding': 'unset'}}>
                        <div className="row p-15">
                            <div className="col-lg-12">
                                <div className="dashboard-menu">
                                    <ul className="nav flex-column" role="tablist">
                                        {
                                            (this.state.selectStatusMarket !== 'Cancel' && this.state.selectStatusUser == 'Active') && (
                                                <li className="nav-item">
                                                    <a className="nav-link" id="dashboard-tab" data-bs-toggle="tab"
                                                       role="tab" aria-controls="dashboard"
                                                       aria-selected="false"
                                                       onClick={() => {this.handleMulaiBelanja()}}><i className="fi-rs-shopping-cart mr-10"></i>Mulai Belanja</a>
                                                </li>
                                            )
                                        }
                                        {
                                            (this.state.selectStatusMarket !== 'Cancel' && (this.state.selectStatusUser !== 'Active' && this.state.selectStatusUser !== 'Payment')) && (
                                                <li className="nav-item">
                                                    <a className="nav-link" id="orders-tab" data-bs-toggle="tab"
                                                       role="tab" aria-controls="orders" aria-selected="false"
                                                       onClick={() => {this.handleJoin()}}><i className="fi-rs-book mr-10"></i>Gabung</a>
                                                </li>
                                            )
                                        }
                                        {
                                            (this.state.selectStatusMarket !== 'Cancel' && (this.state.selectStatusUser == 'Active' || this.state.selectStatusUser == 'Payment' || this.state.selectStatusUser == 'Done')) && (
                                                <li className="nav-item">
                                                    <a className="nav-link" id="track-orders-tab" data-bs-toggle="tab"
                                                       role="tab" aria-controls="track-orders"
                                                       aria-selected="false"
                                                       onClick={() => {this.handleChildModal()}}><i className="fi-rs-users mr-10"></i>Lihat Data Peserta</a>
                                                </li>
                                            )
                                        }
                                        {/*{*/}
                                            {/*(this.state.selectStatusMarket !== 'Cancel') && (*/}
                                                {/*<li className="nav-item">*/}
                                                    {/*<a className="nav-link" id="address-tab" data-bs-toggle="tab"*/}
                                                       {/*role="tab" aria-controls="address"*/}
                                                       {/*aria-selected="true"><i className="fi-rs-share mr-10"></i>Bagikan</a>*/}
                                                {/*</li>*/}
                                            {/*)*/}
                                        {/*}*/}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {
                            (this.state.selectStatusMarket !== 'Cancel') && (
                                <Whatsapp solidcircle big message={this.state.shareMessage} link={'https://waroq.com/patungan?trxid='+base64_encode(this.state.selectKodeMarketPlace)}/>
                            )
                        }

                        <Button onClick={() => {this.handleModal()}}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>

                <Modal
                    size={'md'}
                    isOpen={this.state.isModalChildOpen}
                    toggle={() => {this.handleChildModal()}}>
                    <ModalHeader>
                        Data Peserta
                    </ModalHeader>
                    <ModalBody style={{'padding': 'unset'}}>
                        <div className="row p-15">
                            <div className="col-lg-12">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Nama</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.dataMarketPlaceDetails.map((object, i) => {
                                            return <tr>
                                                <td>{i+1}</td>
                                                <td>{object.NamaMarketing}</td>
                                                <td align="right">{object.Total}</td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {this.handleChildModal()}}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>

                <Modal
                    size={'md'}
                    isOpen={this.state.isModalRefundOpen}
                    toggle={() => {this.handleModalRefund()}}>
                    <ModalBody style={{'padding': 'unset'}}>
                        <div className="row p-15">
                            <div className="col-lg-12">
                                <div className="dashboard-menu">
                                    <ul className="nav flex-column" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link" id="address-tab" data-bs-toggle="tab"
                                               role="tab" aria-controls="address"
                                               aria-selected="true"
                                               onClick={() => {this.handleRefund('rekening')}}><i className="fi-rs-credit-card mr-10"></i>Refund Ke Rekening</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="address-tab" data-bs-toggle="tab"
                                               role="tab" aria-controls="address"
                                               aria-selected="true"
                                               onClick={() => {this.handleRefund('voucher')}}><i className="fi-rs-money mr-10"></i>Refund Ke Voucher</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {this.handleModalRefund()}}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>

                <main className="main pages mb-80">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <a href="./" rel="nofollow"><i className="fi-rs-home mr-5"></i>Beranda</a>
                                <span></span> Patungan<span></span> {this.state.breadcumbs}
                            </div>
                        </div>
                    </div>
                    <div className="page-content pt-20">
                        <div className="container">
                            <div className="archive-header-2 text-center">
                                <div className="row">
                                    <div className="col-lg-5 mx-auto">
                                        <div className="sidebar-widget-2 widget_search mb-50">
                                            <div className="search-form">
                                                <form method="post" onSubmit={(e) => {this.handleSearch(e)}}>
                                                    <input type="text" placeholder="Cari patungan ..." value={this.state.search} onChange={e => this.setState({search: e.target.value})}/>
                                                    <button type="submit"><i className="fi-rs-search"></i></button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-lg-8 mx-auto">
                                    <div className="shop-product-fillter">
                                        <div className="totall-product">
                                            <a href={'./patungan'} className="btn btn-sm">Kembali</a>
                                        </div>
                                        <div className="sort-by-product-area">
                                            <div className="sort-by-cover mr-10">
                                                <div className="sort-by-product-wrap">
                                                    <div className="sort-by">
                                                        <span><i className="fi-rs-apps"></i>Show:</span>
                                                    </div>
                                                    <div className="sort-by-dropdown-wrap">
                                                        <span> {this.state.perPage} <i className="fi-rs-angle-small-down"></i></span>
                                                    </div>
                                                </div>
                                                <div className="sort-by-dropdown">
                                                    <ul>
                                                        {
                                                            this.state.pageNumList.map((object, i) => {
                                                                return <li><a className={(object == this.state.perPage) ? 'active': ''} onClick={() => {this.handlePageNumClick(object)}}>{object}</a></li>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="sort-by-cover">
                                                <div className="sort-by-product-wrap">
                                                    <div className="sort-by">
                                                        <span><i className="fi-rs-apps-sort"></i>Sort by:</span>
                                                    </div>
                                                    <div className="sort-by-dropdown-wrap">
                                                        <span> Featured <i className="fi-rs-angle-small-down"></i></span>
                                                    </div>
                                                </div>
                                                <div className="sort-by-dropdown">
                                                    <ul>
                                                        {
                                                            this.state.sortData.map((object, i) => {
                                                                return <li><a className={(object == this.state.sortDataValue) ? 'active': ''} onClick={() => {this.handleSortByClick(object)}}>{object}</a></li>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row vendor-grid">
                                {this.state.postData}
                            </div>

                            <div className="pagination-area mt-20 mb-20">
                                <nav aria-label="Page navigation example">
                                    <ReactPaginate
                                        previousLabel={<i className="fi-rs-arrow-small-left"></i>}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextLabel={<i className="fi-rs-arrow-small-right"></i>}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakLabel={"..."}
                                        breakClassName={"page-link dot"}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={1}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={"pagination justify-content-start"}
                                        activeClassName={"page-item active"}
                                        activeLinkClassName={"page-link"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}/>
                                </nav>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default List;