import React from "react";

import { Helpers } from "../../config/Helpers"
import {Connection} from "../../config/Connection";

import ModalPatungan from "../../components/ModalPatungan";
import {ReactSession} from "react-client-session";
import {decode as base64_decode, encode as base64_encode} from "base-64";
import queryString from "query-string";
import {toast} from "react-toastify";

class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            setModal: '',
            urlParam: queryString.parse(window.location.search),
            kodeMarketPlace: ''
        };
    }

    componentDidMount() {
        if(!this.state.sessUserCode){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.setState({
                kodeMarketPlace: (this.state.urlParam.trxid) ? base64_decode(this.state.urlParam.trxid) : '',
            });
        }
    }

    handleCreateClick = () => {
        this.setState({
            setModal: ''
        }, () => {
            this.setState({
                setModal: <ModalPatungan modalRef={true}/>
            })
        })
    };

    handleContinueJoin = () => {
        if (window.confirm('Apakah anda yakin akan bergabung dengan patungan belanja berikut ini?')) {
            Helpers.joinMarketOrder(
                this.state.sessUserCode,
                this.state.kodeMarketPlace
            ).then(res => {
                if(res.length > 0){
                    if(res[0].alert == 'success'){
                        ReactSession.set("order_type", res[0].jenis);

                        setTimeout(function() {
                            window.location.href = './shop?joinid=' + base64_encode(res[0].kode);
                        }, 1000);
                    }

                    toast[res[0].alert](res[0].message);
                }else{
                    toast.error(Helpers.MSG_ERROR);
                }
            })
        } else {
            console.log('Cancel.');
        }
    };

    render() {
        return (
            <>
                { this.state.setModal }

                <main className="main pages mb-80">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <a href="./" rel="nofollow"><i className="fi-rs-home mr-5"></i>Beranda</a>
                                <span></span> Patungan
                            </div>
                        </div>
                    </div>
                    <div className="page-content pt-10">

                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 m-auto">
                                    <div className="row">
                                        <div className="col-12 mb-15">
                                            <img src={'./assets/imgs/patungan/v2_img_patungan_all.png'} />
                                        </div>
                                        <div className="col-4">
                                            <a href={'./patungan-list?v=all'}><img src={'./assets/imgs/patungan/v2_img_market_join.png'} /></a>
                                        </div>
                                        <div className="col-4">
                                            <a onClick={() => {this.handleCreateClick()}}><img src={'./assets/imgs/patungan/v2_img_market_create.png'} /></a>
                                        </div>
                                        <div className="col-4">
                                            <a href={'./patungan-list?v=user'}><img src={'./assets/imgs/patungan/v2_img_market_pantau.png'} /></a>
                                        </div>
                                        {
                                            this.state.kodeMarketPlace && (
                                                <div className="col-12 mt-10">
                                                    <a
                                                        className="banner-left-icon d-flex align-items-center wow animate__animated animate__fadeInUp"
                                                        data-wow-delay=".2s"
                                                        onClick={() => {this.handleContinueJoin()}}>
                                                        <div className="banner-icon">
                                                            <img src="./assets/imgs/shop/v2_ic_patungan.png" alt=""/>
                                                        </div>
                                                        <div className="banner-text">
                                                            <h3 className="icon-box-title">Kode Patungan: {this.state.kodeMarketPlace}</h3>
                                                            <p>
                                                                Klik disini untuk melanjutkan ikut patungan<br/>
                                                                Pastikan anda telah membaca dan memahami syarat &amp; ketentuan dibawah ini
                                                            </p>
                                                        </div>
                                                    </a>
                                                </div>
                                            )
                                        }
                                    </div>

                                    <div className="primary-sidebar mt-15">
                                        <div className="sidebar-widget widget-store-info mb-30 bg-2 border-0 info-custom">
                                            <div className="vendor-info">
                                                <h4 className="mb-5">
                                                    <a href="#" className="text-heading">
                                                        Syarat & Ketentuan</a>
                                                </h4>

                                                <div className="product-rate-cover mb-15">
                                                </div>

                                                <div className="vendor-des">
                                                    <p className="font-sm text-heading">
                                                        <ol style={{'list-style': 'list-number'}}>
                                                            <li>Masa aktif patungan belanja adalah 24 jam</li>
                                                            <li>Maksimal waktu join adalah satu jam sebelum patungan berakhir</li>
                                                            <li>Pembayaran menggunakan virtual account dan e-Money akan dikenakan biaya admin sesuai dengan channel yang dipilih</li>
                                                            <li>Jika patungan tidak terpenuhi selama 24 jam, maka order akan otomatis dibatalkan, dan payment akan di-refund</li>
                                                            <ul>
                                                                <li>4.1 Untuk pembayaran menggunakan virtual account atau e-money, anda dapat memilih apakah dana akan di-refund ke nomor rekening atau ke saldo voucher</li>
                                                                <li>4.2 Jika anda memilih refund ke nomor rekening, maka dana akan ditransfer ke no rekening yang anda daftarkan di profil dan dikenakan biaya admin sesuai dengan bank tujuan. Dana akan anda terima maksimal dalam 60 menit setelah anda melakukan konfirmasi refund</li>
                                                                <li>4.3 Jika anda memilih refund ke saldo voucher, maka dana akan langsung ditransfer ke saldo voucher anda tanpa dikenakan biaya, proses transfer maksimal dalam 15 menit setelah anda melakukan konfirmasi refund</li>
                                                                <li>4.4 Untuk pembayaran menggunakan saldo voucher, maka refund akan otomatis dikembalikan ke saldo voucher anda tanpa dikenakan biaya</li>
                                                                <li>4.5 Untuk penggunaan komisi, baik sebagai pembayaran penuh maupun potongan belanja, komisi akan otomatis dikembalikan ke saldo komisi anda tanpa dikenakan biaya.</li>
                                                            </ul>
                                                        </ol>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>
            </>
        );
    }
}

export default Dashboard;