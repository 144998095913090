import React from "react";
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import {ReactSession} from "react-client-session";
import { v4 as uuidv4 } from 'uuid';

import Home from './pages/Home'
import Shop from './pages/Shop'
import History from "./pages/account/History";
import OrderActive from "./pages/account/OrderActive";
import Profile from "./pages/account/Profile";
import TransferVoucher from "./pages/account/TransferVoucher";
import WithdrawalCommission from "./pages/account/WithdrawalCommission";
import New from "./pages/store/New";
import List from "./pages/store/List";
import Dashboard from "./pages/patungan/Dashboard";
import ListPatungan from "./pages/patungan/List";
import Page404 from "./404";
import Checkout from "./pages/Checkout";
import About from "./pages/About";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import OrderActiveDetails from "./pages/account/OrderActiveDetails";
import OrderPayment from "./pages/account/OrderPayment";
import OrderPaymentResponse from "./pages/account/OrderPaymentResponse";
import Login from "./pages/Login";
import Register from "./pages/Register";
import HistoryOrderDetails from "./pages/account/HistoryOrderDetails";
import LocationNew from "./pages/location/New";
import LocationList from "./pages/location/List";
import Chat from "./pages/help/Chat";
import ChatDetails from "./pages/help/ChatDetails";
import HistoryOrder from "./pages/account/HistoryOrder";
import HistoryWithdrawal from "./pages/account/HistoryWithdrawal";
import HistoryTransferVoucher from "./pages/account/HistoryTransferVoucher";
import HistoryOrderReceipt from "./pages/account/HistoryOrderReceipt";
import Wishlist from "./pages/account/Wishlist";
import MutasiKomisi from "./pages/account/MutasiKomisi";
import MutasiVoucher from "./pages/account/MutasiVoucher";
import KoinWorksApply from "./pages/account/KoinWorksApply";
import KoinWorksApplyOTP from "./pages/account/KoinWorksApplyOTP";
import KoinWorksLoanOTP from "./pages/account/KoinWorksLoanOTP";
import KoinWorksLoan from "./pages/account/KoinWorksLoan";
import KoinWorksLoanDetails from "./pages/account/KoinWorksLoanDetails";
import ResponsePage from "./pages/others/ResponsePage";
import WebViewer from "./pages/others/WebViewer";
import ChangeDeliveryMethod from "./pages/account/ChangeDeliveryMethod";
import ChangePaymentMethod from "./pages/account/ChangePaymentMethod";

import RegisterSupplier from "./marketplace/RegisterSupplier";
import RegisterBuyer from "./marketplace/RegisterBuyer";

import DashboardSupplier from "./marketplace/supplier/pages/Dashboard";
import SupplierAccount from "./marketplace/supplier/pages/Account";
import SupplierOrderDetails from "./marketplace/supplier/pages/OrderDetails";
import SupplierOrders from "./marketplace/supplier/pages/Orders";
import SupplierProducts from "./marketplace/supplier/pages/Products";
import SupplierProductsForm from "./marketplace/supplier/pages/ProductsForm";
import SupplierProductsInventory from "./marketplace/supplier/pages/ProductsInventory";
import SupplierCoverage from "./marketplace/supplier/pages/Coverage";

import DashboardBuyer from "./marketplace/buyer/pages/Dashboard";
import BuyerAccount from "./marketplace/buyer/pages/Account";
import BuyerOrderDetails from "./marketplace/buyer/pages/OrderDetails";
import BuyerOrders from "./marketplace/buyer/pages/Orders";
import BuyerShopping from "./marketplace/buyer/pages/Shopping";
import BuyerShoppingDetails from "./marketplace/buyer/pages/ShoppingDetails";

class App extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
    }

    componentDidMount() {
        if(!ReactSession.get("sess_email")){
            ReactSession.set("sess_email", uuidv4());
        }
    }

    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="login" element={<Login />} />
                    <Route path="register" element={<Register />} />
                    <Route path="about" element={<About />} />
                    <Route path="privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="shop" element={<Shop />} />
                    <Route path="checkout" element={<Checkout />} />
                    <Route path="chat" element={<Chat />} />
                    <Route path="chat-details" element={<ChatDetails />} />
                    <Route path="account" element={<Profile />} />
                    <Route path="account-mutasi-komisi" element={<MutasiKomisi />} />
                    <Route path="account-mutasi-voucher" element={<MutasiVoucher />} />
                    <Route path="account-history" element={<History />} />
                    <Route path="account-history-oder" element={<HistoryOrder />} />
                    <Route path="account-history-order-details" element={<HistoryOrderDetails />} />
                    <Route path="account-history-order-receipt" element={<HistoryOrderReceipt />} />
                    <Route path="account-history-withdrawal" element={<HistoryWithdrawal />} />
                    <Route path="account-history-voucher" element={<HistoryTransferVoucher />} />
                    <Route path="account-orders" element={<OrderActive />} />
                    <Route path="account-orders-details" element={<OrderActiveDetails />} />
                    <Route path="account-orders-payment" element={<OrderPayment />} />
                    <Route path="account-orders-payment-response" element={<OrderPaymentResponse />} />
                    <Route path="account-voucher" element={<TransferVoucher />} />
                    <Route path="account-withdrawal" element={<WithdrawalCommission />} />
                    <Route path="account-wishlist" element={<Wishlist />} />
                    <Route path="store" element={<List />} />
                    <Route path="store-manage" element={<New />} />
                    <Route path="patungan" element={<Dashboard />} />
                    <Route path="patungan-list" element={<ListPatungan />} />
                    <Route path="location" element={<LocationList />} />
                    <Route path="location-manage" element={<LocationNew />} />
                    <Route path="koinworks-apply" element={<KoinWorksApply />} />
                    <Route path="koinworks-apply-otp" element={<KoinWorksApplyOTP />} />
                    <Route path="koinworks-loan-otp" element={<KoinWorksLoanOTP />} />
                    <Route path="koinworks-loan" element={<KoinWorksLoan />} />
                    <Route path="koinworks-loan-details" element={<KoinWorksLoanDetails />} />
                    <Route path="response-page" element={<ResponsePage />} />
                    <Route path="web-viewer" element={<WebViewer />} />
                    <Route path="change-delivery-method" element={<ChangeDeliveryMethod />} />
                    <Route path="change-payment-method" element={<ChangePaymentMethod />} />
                    <Route path="*" element={<Page404 />} />

                    <Route path="marketplace">
                        <Route path="register_supplier" element={<RegisterSupplier />} />
                        <Route path="register_buyer" element={<RegisterBuyer />} />
                        <Route path="supplier">
                            <Route path="" element={<DashboardSupplier />} />
                            <Route path="dashboard" element={<DashboardSupplier />} />
                            <Route path="account" element={<SupplierAccount />} />
                            <Route path="coverage" element={<SupplierCoverage />} />
                            <Route path="products" element={<SupplierProducts />} />
                            <Route path="products-form" element={<SupplierProductsForm />} />
                            <Route path="products-inventory" element={<SupplierProductsInventory />} />
                            <Route path="orders" element={<SupplierOrders />} />
                            <Route path="orders-details" element={<SupplierOrderDetails />} />
                        </Route>
                        <Route path="buyer">
                            <Route path="" element={<DashboardBuyer />} />
                            <Route path="dashboard" element={<DashboardBuyer />} />
                            <Route path="account" element={<BuyerAccount />} />
                            <Route path="shopping" element={<BuyerShopping />} />
                            <Route path="shopping-details" element={<BuyerShoppingDetails />} />
                            <Route path="orders" element={<BuyerOrders />} />
                            <Route path="orders-details" element={<BuyerOrderDetails />} />
                        </Route>
                    </Route>
                </Routes>
            </BrowserRouter>
        );
    }
}

export default App;