import React from "react";
import {ReactSession} from "react-client-session";
import {Helpers} from "../../../config/Helpers";
import Select from 'react-select';
import {toast} from "react-toastify";
import $ from "jquery";
import {Connection} from "../../../config/Connection";
import {decode as base64_decode} from "base-64";
import queryString from "query-string";

class ProductsForm extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            urlActive: window.location.pathname,
            urlParam: queryString.parse(window.location.search),
            sessMPSupplierId: (ReactSession.get("sess_mp_supplierid")) ? ReactSession.get("sess_mp_supplierid") : '',
            sessMPSupplierStatus: (ReactSession.get("sess_mp_supplierstatus")) ? ReactSession.get("sess_mp_supplierstatus") : '',

            productData: [],
            dataJenisBarang: [],
            dataKategoriBarang: [],

            kode_jenis: '',
            kode_category: '',
            kode_product_unit: '',
            kode_product: '',
            price: 0,
            waroq_price: '0',
            brand: '-',
            tags: '-',
            sku: '',
            command: 'insert'
        }
    }

    componentDidMount() {
        const kode_product = (this.state.urlParam.kode_product) ? this.state.urlParam.kode_product : '';

        this.setState({
            kode_product: kode_product
        }, () => {
            this.getGoodsType();
        });
    }

    getProduct = (jenis) => {
        $(".loader-wrapper").css("display", "");
        Helpers.getProduct(jenis, '', 'yes').then(res => {
            if(res){
                this.setState({
                    productData: res.map(function(item) {
                        return {
                            value: item.KodeUnitBarangTerbesar,
                            label: item.NamaBarang
                        };
                    })
                });
            }

            if(this.state.urlParam.kode_product){
                this.getProductInfo();
            }else{
                $(".loader-wrapper").css("display", "none");
            }
        })
    };

    getProductDetails = (kode_unit) => {
        $(".loader-wrapper").css("display", "");
        Helpers.getProduct('', '', 'yes',kode_unit).then(res => {
            $(".loader-wrapper").css("display", "none");
            if(res){
                const r = res[0];

                this.setState({
                    unit: r.UnitBarangTerbesar,
                    tags: r.Tag,
                    waroq_price: r.HargaGrosirFormatTerbesar,
                    photo: r.img_url,
                    brand: r.NamaProdusen
                });
            }
        })
    };

    getProductInfo = () => {
        Helpers.getMPProducts('all',this.state.kode_product,'','','').then(res => {
            $(".loader-wrapper").css("display", "none");
            if(res){
                let r = res[0];
                this.setState({
                    unit: r.unit,
                    tags: r.tags,
                    waroq_price: r.waroq_price,
                    photo: r.url_photo,
                    brand: r.brand,
                    kode_product_unit: r.kode_product_unit,
                    sku: r.sku,
                    price: r.price,
                    kode_jenis: r.code_product_type,
                    kode_category: r.code_product_category,
                    command: 'update'
                });
            }
        })
    };

    getGoodsType = () => {
        $(".loader-wrapper").css("display", "");
        Helpers.getGoodsType().then(res => {
            var arr = res.map(function(a) {
                return {value: a.KodeJenisBarang, label: a.JenisBarang};
            });

            this.setState({ dataJenisBarang: arr });

            if(this.state.urlParam.kode_product){
                this.getGoodsTypeDetails('');
            }
        })
    };

    getGoodsTypeDetails = (kode) => {
        $(".loader-wrapper").css("display", "");
        Helpers.getGoodsTypeDetails(kode).then(res => {
            var arr = res.map(function(a) {
                return {value: a.KodeKategoriBarang, label: a.KategoriBarang};
            });

            this.setState({ dataKategoriBarang: arr });

            this.getProduct(kode);
        });
    };

    handleChange = (event,state) => {
        this.setState({ [state]: event.value });

        if(state === 'kode_jenis'){
            this.getGoodsTypeDetails(event.value);
        }
        if(state === 'kode_category'){
            this.getProduct(event.value);
        }
        if(state === 'kode_product_unit'){
            this.getProductDetails(event.value);
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();

        $(".loader-wrapper").css("display", "");
        Helpers.manageMPProducts(
            this.state.sessUserCode,
            this.state.kode_product,
            this.state.sessMPSupplierId,
            this.state.kode_product_unit,
            this.state.price,
            this.state.sku,
            this.state.command
        ).then(res => {
            $(".loader-wrapper").css("display", "none");
            if(res.length > 0){
                if(res[0].alert == 'success'){
                    setTimeout(function() {
                        window.location.href = 'products';
                    }, 1000);
                }

                toast[res[0].alert](res[0].message);
            }else{
                toast.error(Helpers.MSG_ERROR);
            }
        })
    };

    render() {
        return (
            <>
                <form className="content-main" method="post" onSubmit={(e) => {this.handleSubmit(e)}}>
                    <div className="content-header">
                        <div>
                            <h2 className="content-title card-title">Product Form</h2>
                            <p>Manage your product here</p>
                        </div>
                        <div>
                            <button className="btn btn-primary" type="submit">Submit</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card mb-4">
                                <div className="card-header">
                                    <h4>Basic</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row gx-2">
                                        <div className="col-sm-6 mb-4">
                                            <label className="form-label">Category</label>
                                            <Select
                                                isDisabled={(this.state.urlParam.kode_product) ? true : false}
                                                options={this.state.dataJenisBarang}
                                                value={this.state.dataJenisBarang.filter((item) => item.value === this.state.kode_jenis)}
                                                onChange={event => this.handleChange(event,'kode_jenis')} />
                                        </div>
                                        <div className="col-sm-6 mb-4">
                                            <label className="form-label">Sub-category</label>
                                            <Select
                                                isDisabled={(this.state.urlParam.kode_product) ? true : false}
                                                options={this.state.dataKategoriBarang}
                                                value={this.state.dataKategoriBarang.filter((item) => item.value === this.state.kode_category)}
                                                onChange={event => this.handleChange(event,'kode_category')} />
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="product_name" className="form-label">Product title</label>
                                        <Select
                                            isDisabled={(this.state.urlParam.kode_product) ? true : false}
                                            options={this.state.productData}
                                            value={this.state.productData.filter((item) => item.value === this.state.kode_product_unit)}
                                            onChange={event => this.handleChange(event,'kode_product_unit')} />
                                    </div>
                                    <div className="mb-4">
                                        <label className="form-label">Brand</label>
                                        <input type="text" placeholder="Type here" className="form-control" readOnly={true}
                                               value={this.state.brand} onChange={e => this.setState({brand: e.target.value})}/>
                                    </div>
                                    <div className="mb-4">
                                        <label className="form-label">Unit</label>
                                        <input type="text" placeholder="Type here" className="form-control" readOnly={true}
                                               value={this.state.unit} onChange={e => this.setState({unit: e.target.value})}/>
                                    </div>
                                    <div className="mb-4">
                                        <label className="form-label">Waroq Price (By Unit)</label>
                                        <div className="row gx-2">
                                            <input placeholder="Type here" type="text" className="form-control" readOnly={true}
                                                   value={this.state.waroq_price} onChange={e => this.setState({waroq_price: e.target.value})}/>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="product_name" className="form-label">Tags</label>
                                        <input type="text" placeholder="Type here" className="form-control" readOnly={true}
                                               value={this.state.tags} onChange={e => this.setState({tags: e.target.value})}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="card mb-4">
                                <div className="card-header">
                                    <h4>Image</h4>
                                </div>
                                <div className="card-body">
                                    <div className="input-upload">
                                        {
                                            this.state.photo && (
                                                <img src={this.state.photo} alt="" style={{'max-width': '200px'}}/>
                                            )
                                        }
                                        {
                                            !this.state.photo && (
                                                <img src="/assets_admin/imgs/theme/upload.svg" alt="" style={{'max-width': '200px'}}/>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-4">
                                <div className="card-header">
                                    <h4>Input your details here</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row gx-2">
                                        <div className="col-sm-6 mb-4">
                                            <label htmlFor="product_name" className="form-label">SKU</label>
                                            <input type="text" placeholder="Type here" className="form-control" required
                                                   value={this.state.sku} onChange={e => this.setState({sku: e.target.value})}/>
                                        </div>
                                        <div className="col-sm-6 mb-4">
                                            <label className="form-label">Price</label>
                                            <input placeholder="$" type="number" className="form-control" required
                                                   value={this.state.price} onChange={e => this.setState({price: e.target.value})}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        );
    }
}

export default ProductsForm;