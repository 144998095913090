import React from "react";
import ReactPaginate from 'react-paginate';
import {encode as base64_encode} from 'base-64';
import { ReactSession } from 'react-client-session';

import { Helpers } from "../../config/Helpers";
import { Connection } from "../../config/Connection";
import {toast} from "react-toastify";

class List extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            offset: 0,
            perPage: 10,
            currentPage: 0,
            pageNumList: [10,25,50,100,150,200],
            sortData: ['Featured','Name Ascending','Name Descending'],
            search: ''
        };
        this.handlePageClick = this.handlePageClick.bind(this);
    }

    componentDidMount() {
        if(!this.state.sessUserCode){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.getWarung();
        }
    }

    getWarung = () => {
        Helpers.getWarung('',this.state.sessUserCode).then(res => {
            let sortData = res;
            if(this.state.search !== ''){
                sortData = sortData.filter(item => {
                    const textData = this.state.search.toUpperCase();

                    return Object.keys(item).some(key => ((item[key]) ? item[key] : '').toString().toUpperCase().search(textData) !== -1);
                });
            }
            if(this.state.sortDataValue == 'Name Descending'){
                sortData = sortData.sort(function(a,b) {
                    const val_a = a.NamaWarung.toUpperCase();
                    const val_b = b.NamaWarung.toUpperCase();

                    return (val_a < val_b) ? 1 : ((val_b < val_a) ? -1 : 0);
                });
            }
            if(this.state.sortDataValue == 'Name Ascending'){
                sortData = sortData.sort(function(a,b) {
                    const val_a = a.NamaWarung.toUpperCase();
                    const val_b = b.NamaWarung.toUpperCase();

                    return (val_a > val_b) ? 1 : ((val_b > val_a) ? -1 : 0);
                });
            }

            const slice = sortData.slice(this.state.offset, this.state.offset + this.state.perPage)
            const postData = slice.map((object, i) => {
                return <div className="col-lg-4 col-md-4 col-12 col-sm-4">
                    <div className="vendor-wrap style-2 mb-40">
                        <div className="product-badges product-badges-position product-badges-mrg">
                            <span className="hot">{object.KodeWarung}</span>
                        </div>
                        <div className="vendor-content-wrap">
                            <div>
                                <h4 className="mb-5"><a href="#">{object.NamaWarung}</a>
                                </h4>

                                <div className="product-rate-cover">
                                    <span className="font-small ml-5 text-muted">Pemilik: {object.NamaPemilikWarung}</span>
                                </div>
                                <div
                                    className="vendor-info d-flex justify-content-between align-items-end mt-30">
                                    <ul className="contact-infor text-muted">
                                        <li><img src="assets/imgs/theme/icons/icon-location.svg"
                                                 alt=""/><strong>Alamat: </strong> <span>{object.Alamat}</span>
                                        </li>
                                        <li><img src="assets/imgs/theme/icons/icon-contact.svg" alt=""/><strong>Telepon:</strong><span> {object.NoTelepon}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            })

            this.setState({
                pageCount: Math.ceil(sortData.length / this.state.perPage),
                postData
            })
        })
    };

    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState({
            currentPage: selectedPage,
            offset: offset
        }, () => {
            this.getWarung()
        });

    };

    handlePageNumClick = (num) => {
        this.setState({
            currentPage: 0,
            offset: 0,
            perPage: num,
        }, () => {
            this.getWarung()
        });
    };

    handleSortByClick = (sortBy) => {
        this.setState({
            currentPage: 0,
            offset: 0,
            sortDataValue: sortBy
        }, () => {
            this.getWarung()
        });
    };

    handleSearch = (e) => {
        e.preventDefault();

        this.setState({
            currentPage: 0,
            offset: 0
        }, () => {
            this.getWarung()
        });
    };

    render() {
        return (
            <>
                <main className="main pages mb-80">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <a href="./" rel="nofollow"><i className="fi-rs-home mr-5"></i>Beranda</a>
                                <span></span> Warung
                            </div>
                        </div>
                    </div>
                    <div className="page-content pt-20">
                        <div className="container">
                            <div className="archive-header-2 text-center">
                                <div className="row">
                                    <div className="col-lg-5 mx-auto">
                                        <div className="sidebar-widget-2 widget_search mb-50">
                                            <div className="search-form">
                                                <form method="post" onSubmit={(e) => {this.handleSearch(e)}}>
                                                    <input type="text" placeholder="Cari warung ..." value={this.state.search} onChange={e => this.setState({search: e.target.value})}/>
                                                    <button type="submit"><i className="fi-rs-search"></i></button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 col-lg-8 mx-auto">
                                    <div className="shop-product-fillter">
                                        <div className="totall-product">
                                            <a href={'./store-manage?trxid=&action=insert'} className="btn btn-sm">Buat Baru</a>
                                        </div>
                                        <div className="sort-by-product-area">
                                            <div className="sort-by-cover mr-10">
                                                <div className="sort-by-product-wrap">
                                                    <div className="sort-by">
                                                        <span><i className="fi-rs-apps"></i>Show:</span>
                                                    </div>
                                                    <div className="sort-by-dropdown-wrap">
                                                        <span> {this.state.perPage} <i className="fi-rs-angle-small-down"></i></span>
                                                    </div>
                                                </div>
                                                <div className="sort-by-dropdown">
                                                    <ul>
                                                        {
                                                            this.state.pageNumList.map((object, i) => {
                                                                return <li><a className={(object == this.state.perPage) ? 'active': ''} onClick={() => {this.handlePageNumClick(object)}}>{object}</a></li>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="sort-by-cover">
                                                <div className="sort-by-product-wrap">
                                                    <div className="sort-by">
                                                        <span><i className="fi-rs-apps-sort"></i>Sort by:</span>
                                                    </div>
                                                    <div className="sort-by-dropdown-wrap">
                                                        <span> Featured <i className="fi-rs-angle-small-down"></i></span>
                                                    </div>
                                                </div>
                                                <div className="sort-by-dropdown">
                                                    <ul>
                                                        {
                                                            this.state.sortData.map((object, i) => {
                                                                return <li><a className={(object == this.state.sortDataValue) ? 'active': ''} onClick={() => {this.handleSortByClick(object)}}>{object}</a></li>
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row vendor-grid">
                                {this.state.postData}
                            </div>

                            <div className="pagination-area mt-20 mb-20">
                                <nav aria-label="Page navigation example">
                                    <ReactPaginate
                                        previousLabel={<i className="fi-rs-arrow-small-left"></i>}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextLabel={<i className="fi-rs-arrow-small-right"></i>}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakLabel={"..."}
                                        breakClassName={"page-link dot"}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={1}
                                        pageRangeDisplayed={1}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={"pagination justify-content-start"}
                                        activeClassName={"page-item active"}
                                        activeLinkClassName={"page-link"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}/>
                                </nav>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default List;