import { Connection } from './Connection';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set, get, child, push, update, remove, onValue, query, orderByChild, equalTo } from "firebase/database";
import { getStorage, ref as refStorage, getDownloadURL, uploadBytesResumable, uploadString } from "firebase/storage";

export const firebaseConfig = {
    apiKey: 'AIzaSyDzLLn_QmzlWz2v8gCyUhLUkAnUvZxeOvE',
    authDomain: 'waroq-web.firebaseapp.com',
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: 'waroq-web',
    storageBucket: 'waroq-web.appspot.com',
    messagingSenderId: '397923545734',
    appId: '1:397923545734:web:cd805768842f1bf5f9d707',
    measurementId: 'G-JJSB77NJEZ'
}

export const firebase = initializeApp(firebaseConfig);
//export const database = getDatabase(firebase);
export const storage = getStorage(firebase);

export const CONF_HEADERS = {
    'Access-Control-Allow-Origin': '*'
}

export class Helpers {
    static TITLE_SUCCESS = 'Success';
    static TITLE_ERROR = 'Alert';

    static MSG_SUCCESS = 'Success';
    static MSG_ERROR = 'Error, please contact administrator for more information.';

    static MSG_ERROR_FORM = 'Form tidak boleh kosong';
    static MSG_ERROR_SHOPEEPAY = 'Untuk mengakses aplikasi Shopee, harap buka halaman berikut melalui browser handphone anda.';
    static MSG_ERROR_OVO = 'Tidak dapat membuka aplikasi OVO, akses halaman berikut melalui browser handphone anda atau buka aplikasi OVO secara manual.';
    static MSG_ERROR_DISTANCE = 'Jarak pengiriman dengan gudang tidak boleh lebih dari 200km.';

    static MSG_SUCCESS_LOGIN = 'Login berhasil';
    static MSG_ERROR_LOGIN = 'Akun tidak ditemukan atau username/password tidak sesuai';

    static OBJ_STATUS = 'status';
    static OBJ_ERROR = 'errors';

    /* MASTER */

    static arrKoinWorksTenor = [
        {label: '30 Hari', value: '30'},
        {label: '60 Hari', value: '60'},
        {label: '90 Hari', value: '90'}
    ];

    static getPromoBanner(user,cabang){
        const param =
            "user=" + user +
            "&cabang=" + cabang;

        return fetch(Connection.API_URL + 'GetPromoBanner?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getMasterData(table,id){
        const param =
            "table=" + table +
            "&id=" + id;

        return fetch(Connection.API_URL + 'GetMasterData?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getLoginData(username,password,fcm_token,uuid){
        const param =
            "username=" + username +
            "&password=" + password +
            "&fcm_token=" + fcm_token +
            "&uuid=" + uuid;

        return fetch(Connection.API_URL + 'Authentication?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static requestOTP(type, nomor, otp){
        const param =
            "type=" + type +
            "&nomor=" + nomor +
            "&otp=" + otp;

        return fetch(Connection.API_URL + 'RequestOTP?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static register(fullname, phone, email, password, otp){
        const param =
            "fullname=" + fullname +
            "&phone=" + phone +
            "&email=" + email +
            "&password=" + password +
            "&otp=" + otp +
            "&address=" + '' +
            "&bank=" + '' +
            "&holder=" + '' +
            "&number=" + '' +
            "&provinsi=" + '' +
            "&kota=" + '' +
            "&kecamatan=" + '' +
            "&desa=" + '' +
            "&lat=" + '' +
            "&lng=" + '';

        return fetch(Connection.API_URL + 'Register?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getBranch(){
        return fetch(Connection.API_URL + 'GetBranch', {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getGoodsUnit(){
        return fetch(Connection.API_URL + 'GetGoodsUnit', {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getGoodsType(){
        return fetch(Connection.API_URL + 'GetGoodsType', {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getGoodsTypeDetails(kode){
        const param =
            "kode=" + kode;

        return fetch(Connection.API_URL + 'GetGoodsTypeDetails?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getProductInfo(page, perpage, jenis, cabang){
        const param =
            "page=" + page +
            "&perpage=" + perpage +
            "&jenis=" + jenis +
            "&cabang=" + cabang;

        return fetch(Connection.API_URL + 'GetProductInfo?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getListReorder(kode){
        const param =
            "kode=" + kode;

        return fetch(Connection.API_URL + 'GetListReorder?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getProduct(jenis,cabang,all,kode_unit){
        const param =
            "jenis=" + jenis +
            "&cabang=" + cabang +
            "&all=" + all +
            "&kode_unit=" + kode_unit;

        return fetch(Connection.API_URL + 'GetProduct?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getProductDetails(email, kode, cabang, all){
        const param =
            "email=" + email +
            "&kode=" + kode +
            "&cabang=" + cabang +
            "&all=" + all;

        return fetch(Connection.API_URL + 'GetProductDetails?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getBank(){
        return fetch(Connection.API_URL + 'GetBank', {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getWarung(kode, marketing){
        const param =
            "kode=" + kode +
            "&marketing=" + marketing;

        return fetch(Connection.API_URL + 'GetWarung?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getListWithdrawal(email, kode){
        const param =
            "email=" + email +
            "&kode=" + kode;

        return fetch(Connection.API_URL + 'GetListWithdrawal?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getListTransferVoucher(email, kode){
        const param =
            "email=" + email +
            "&kode=" + kode;

        return fetch(Connection.API_URL + 'GetListTransferVoucher?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getCabang(cabang){
        const param =
            "cabang=" + cabang;

        return fetch(Connection.API_URL + 'GetCabang?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getListPaymentChannel(jenis){
        const param =
            "jenis=" + jenis;

        return fetch(Connection.API_URL + 'GetListPaymentChannel?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getDetailPayment(kode,payment){
        const param =
            "kode=" + kode +
            "&payment=" + payment;

        return fetch(Connection.API_URL + 'GetDetailPayment?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getDetailPaymentCheckNew(kode){
        const param =
            "kode=" + kode;

        return fetch(Connection.API_URL + 'GetDetailPaymentCheckNew?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getDetailPaymentInfo(kode){
        const param =
            "kode=" + kode;

        return fetch(Connection.API_URL + 'GetDetailPaymentInfo?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getListOrderPickupInfo(kode){
        const param =
            "id=" + kode;

        return fetch(Connection.API_URL + 'GetListOrderPickupInfo?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getListOrder(type,kode,marketing,start,end){
        const param =
            "type=" + type +
            "&kode=" + kode +
            "&marketing=" + marketing +
            "&start=" + start +
            "&end=" + end;

        return fetch(Connection.API_URL + 'GetListOrder?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getListOrderDetail(id){
        const param =
            "id=" + id;

        return fetch(Connection.API_URL + 'GetListOrderDetail?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getListLokasiPengiriman(lokasi,user,cabang){
        const param =
            "lokasi=" + lokasi +
            "&user=" + user +
            "&cabang=" + cabang;

        return fetch(Connection.API_URL + 'GetListLokasiPengiriman?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getKomplain(kode){
        const param =
            "kode=" + kode;

        return fetch(Connection.API_URL + 'GetKomplain?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getKomplainDetail(kode){
        const param =
            "kode=" + kode;

        return fetch(Connection.API_URL + 'GetKomplainDetail?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getMarketList(aktif,email,kode,jenis,cabang,joinid){
        const param =
            "aktif=" + aktif +
            "&email=" + email +
            "&kode=" + kode +
            "&jenis=" + jenis +
            "&cabang=" + cabang +
            "&joinid=" + joinid;

        return fetch(Connection.API_URL + 'GetMarketList?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getMarketListDetails(kode){
        const param =
            "kode=" + kode;

        return fetch(Connection.API_URL + 'GetMarketListDetails?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getMarketListByUser(email,kode){
        const param =
            "email=" + email +
            "&kode=" + kode;

        return fetch(Connection.API_URL + 'GetMarketListByUser?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getMarketListByUserCancel(email,kode){
        const param =
            "email=" + email +
            "&kode=" + kode;

        return fetch(Connection.API_URL + 'GetMarketListByUserCancel?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getMarketListCheck(email,kode){
        const param =
            "email=" + email +
            "&kode=" + kode;

        return fetch(Connection.API_URL + 'GetMarketListCheck?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getMarketListCancelCheck(email,kode){
        const param =
            "email=" + email +
            "&kode=" + kode;

        return fetch(Connection.API_URL + 'GetMarketListCancelCheck?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getSummary(kode){
        const param =
            "kode=" + kode;

        return fetch(Connection.API_URL + 'GetSummary?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getAccountInfo(email){
        const param =
            "email=" + email;

        return fetch(Connection.API_URL + 'GetAccountInfo?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getWishlist(user,categories,cabang){
        const param =
            "user=" + user +
            "&categories=" + categories +
            "&cabang=" + cabang;

        return fetch(Connection.API_URL + 'GetWishlist?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getMutasiKomisi(user){
        const param =
            "user=" + user;

        return fetch(Connection.API_URL + 'GetMutasiKomisi?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getMutasiVoucher(user){
        const param =
            "user=" + user;

        return fetch(Connection.API_URL + 'GetMutasiVoucher?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static manageWishlist(kode,user,barang,command){
        const param =
            "kode=" + kode +
            "&user=" + user +
            "&barang=" + barang +
            "&command=" + command;

        return fetch(Connection.API_URL + 'ManageWishlist?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static changePassword(kode,old,newest,confirm){
        const param =
            "kode=" + kode +
            "&old=" + old +
            "&new=" + newest +
            "&confirm=" + confirm;

        return fetch(Connection.API_URL + 'ChangePassword?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static updateProfileMarketing(kode,nama,alamat,telepon,email,bank,holder,number,user){
        const param =
            "kode=" + kode +
            "&nama=" + nama +
            "&alamat=" + alamat +
            "&telepon=" + telepon +
            "&email=" + email +
            "&bank=" + bank +
            "&holder=" + holder +
            "&number=" + number +
            "&user=" + user;

        return fetch(Connection.API_URL + 'UpdateProfileMarketing?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static transferVoucher(kode,total,handphone,password){
        const param =
            "kode=" + kode +
            "&total=" + total +
            "&handphone=" + handphone +
            "&password=" + password;

        return fetch(Connection.API_URL + 'TransferVoucher?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static saveWithdrawal(type,kode,jumlah,bank_name,bank_account,bank_number,email){
        const param =
            "type=" + type +
            "&kode=" + kode +
            "&jumlah=" + jumlah +
            "&bank_name=" + bank_name +
            "&bank_account=" + bank_account +
            "&bank_number=" + bank_number +
            "&email=" + email;

        return fetch(Connection.API_URL + 'SaveWithdrawal?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static createOrderTemp(email, kode, qty, cabang, po){
        const param =
            "email=" + email +
            "&kode=" + kode +
            "&qty=" + qty +
            "&cabang=" + cabang +
            "&po=" + po;

        return fetch(Connection.API_URL + 'CreateOrderTemp?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static countDistance(destination,cabang){
        // return fetch(Connection.MAPS_DISTANCE_URL + destination, {
        //     method: 'GET'
        // }).then((res) => res.json()).then((json) => {
        //     return json;
        // }).catch((e) => {
        //     console.log(e);
        // });

        const param =
            "destination=" + destination +
            "&cabang=" + cabang;

        return fetch(Connection.API_URL + 'GetGoogleMapsDistance?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static googleLocationDetails(latlng){
        return fetch(Connection.MAPS_DETAILS_URL + latlng, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static saveLokasiPengiriman(id_user, kode, user, jenis, nama, penerima, ponsel, detail, pinpoint, provinsi, kota, kecamatan, desa, lat, lng, distance, setdefault, command){
        const param =
            "id_user=" + id_user +
            "&kode=" + kode +
            "&user=" + user +
            "&jenis=" + jenis +
            "&nama=" + nama +
            "&penerima=" + penerima +
            "&ponsel=" + ponsel +
            "&detail=" + detail +
            "&pinpoint=" + pinpoint +
            "&provinsi=" + provinsi +
            "&kota=" + kota +
            "&kecamatan=" + kecamatan +
            "&desa=" + desa +
            "&lat=" + lat +
            "&lng=" + lng +
            "&distance=" + distance +
            "&setdefault=" + setdefault +
            "&command=" + command;

        return fetch(Connection.API_URL + 'SaveLokasiPengiriman?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static createKomplain(kode,user,penerima,jenis,question,command){
        const param =
            "kode=" + kode +
            "&user=" + user +
            "&penerima=" + penerima +
            "&jenis=" + jenis +
            "&question=" + question +
            "&command=" + command;

        return fetch(Connection.API_URL + 'CreateKomplain?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static createOrder(kode,email,list,point,jenis,pickup,lokasi,payment,jenis_order,id_payment,market,cabang,shipment,shipment_price,koinworks_tenor){
        const param =
            "kode=" + kode +
            "&email=" + email +
            "&list=" + list +
            "&point=" + point +
            "&jenis=" + jenis +
            "&pickup=" + pickup +
            "&lokasi=" + lokasi +
            "&payment=" + payment +
            "&jenis_order=" + jenis_order +
            "&id_payment=" + id_payment +
            "&market=" + market +
            "&cabang=" + cabang +
            "&shipment=" + shipment +
            "&shipment_price=" + shipment_price +
            "&koinworks_tenor=" + koinworks_tenor;

        return fetch(Connection.API_URL + 'CreateOrderNew?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static cancelOrder(kode,email){
        const param =
            "kode=" + kode +
            "&email=" + email;

        return fetch(Connection.API_URL + 'CancelOrder?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getChangeDeliveryMethod(kode){
        const param =
            "kode=" + kode;

        return fetch(Connection.API_URL + 'GetChangeDeliveryMethod?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static changeDeliveryMethod(kode,user,lokasi,payment,id_payment){
        const param =
            "kode=" + kode +
            "&user=" + user +
            "&lokasi=" + lokasi +
            "&payment=" + payment +
            "&id_payment=" + id_payment;

        return fetch(Connection.API_URL + 'ChangeDeliveryMethod?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static changePaymentMethod(kode,user,payment,id_payment){
        const param =
            "kode=" + kode +
            "&user=" + user +
            "&payment=" + payment +
            "&id_payment=" + id_payment;

        return fetch(Connection.API_URL + 'ChangePaymentMethod?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static createVA(kode,payment,phone){
        const param =
            "kode=" + kode +
            "&payment=" + payment +
            "&phone=" + phone;

        return fetch(Connection.API_URL + 'CreateVA?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static createMarketOrder(kode,jenis){
        const param =
            "kode=" + kode +
            "&jenis=" + jenis;

        return fetch(Connection.API_URL + 'CreateMarketOrder?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static joinMarketOrder(kode,market){
        const param =
            "kode=" + kode +
            "&market=" + market;

        return fetch(Connection.API_URL + 'JoinMarketOrder?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static saveRefundPatungan(kode,user,jenis){
        const param =
            "kode=" + kode +
            "&user=" + user +
            "&jenis=" + jenis;

        return fetch(Connection.API_URL + 'SaveRefundPatungan?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getBiteshipRates(email,cabang,lokasi,jenis){
        const param =
            "email=" + email +
            "&cabang=" + cabang +
            "&lokasi=" + lokasi +
            "&jenis=" + jenis;

        return fetch(Connection.API_URL + 'GetBiteshipRates?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static koinWorksRequestLimit(kode,raw){
        let data_post = new FormData();
        data_post.append("kode", kode);
        data_post.append("raw", raw);

        return fetch(Connection.API_URL + 'KoinWorksRequestLimit', {
            method: 'POST',
            body: data_post
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static koinWorksRequestGenerateToken(kode){
        let data_post = new FormData();
        data_post.append("kode", kode);

        return fetch(Connection.API_URL + 'KoinWorksRequestGenerateToken', {
            method: 'POST',
            body: data_post
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static koinWorksRequestOTP(kode){
        let data_post = new FormData();
        data_post.append("kode", kode);

        return fetch(Connection.API_URL + 'KoinWorksRequestOTP', {
            method: 'POST',
            body: data_post
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static koinWorksValidateOTP(kode,otp){
        let data_post = new FormData();
        data_post.append("kode", kode);
        data_post.append("otp", otp);

        return fetch(Connection.API_URL + 'KoinWorksValidateOTP', {
            method: 'POST',
            body: data_post
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static koinWorksRequestOTPLoan(kode,loan){
        let data_post = new FormData();
        data_post.append("kode", kode);
        data_post.append("loan", loan);

        return fetch(Connection.API_URL + 'KoinWorksRequestOTPLoan', {
            method: 'POST',
            body: data_post
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static koinWorksValidateOTPLoan(kode,otp,loan){
        let data_post = new FormData();
        data_post.append("kode", kode);
        data_post.append("otp", otp);
        data_post.append("loan", loan);

        return fetch(Connection.API_URL + 'KoinWorksValidateOTPLoan', {
            method: 'POST',
            body: data_post
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static koinWorksGetListLoan(kode){
        const param =
            "kode=" + kode;

        return fetch(Connection.API_URL + 'KoinWorksGetListLoan?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static koinWorksGetListLoanDetails(kode){
        const param =
            "kode=" + kode;

        return fetch(Connection.API_URL + 'KoinWorksGetListLoanDetails?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static koinWorksGetListRevenue(kode){
        const param =
            "kode=" + kode;

        return fetch(Connection.API_URL + 'KoinWorksGetListRevenue?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static koinWorksGetListTransaction(kode){
        const param =
            "kode=" + kode;

        return fetch(Connection.API_URL + 'KoinWorksGetListTransaction?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static applyVoucher(kode,user){
        const param =
            "kode=" + kode +
            "&user=" + user;

        return fetch(Connection.API_URL + 'ApplyVoucher?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    /* MARKETPLACE */

    static getMPSupplier(kode,kode_supplier,status){
        const param =
            "kode=" + kode +
            "&kode_supplier=" + kode_supplier +
            "&status=" + status;

        return fetch(Connection.API_URL + 'GetMPSupplier?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getMPSupplierCoverage(kode_coverage,kode_supplier,kode_wilayah,status){
        const param =
            "kode_coverage=" + kode_coverage +
            "&kode_supplier=" + kode_supplier +
            "&kode_wilayah=" + kode_wilayah +
            "&status=" + status;

        return fetch(Connection.API_URL + 'GetMPSupplierCoverage?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getMPBuyer(kode,kode_buyer,status){
        const param =
            "kode=" + kode +
            "&kode_buyer=" + kode_buyer +
            "&status=" + status;

        return fetch(Connection.API_URL + 'GetMPBuyer?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getMPProducts(active,kode_product,jenis,kode_user){
        const param =
            "&active=" + active +
            "&kode_product=" + kode_product +
            "&jenis=" + jenis +
            "&kode_user=" + kode_user;

        return fetch(Connection.API_URL + 'GetMPProducts?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getMPProductsDisplay(kode_buyer,kode_product,kode_supplier,kode_wilayah,jenis){
        const param =
            "&kode_buyer=" + kode_buyer +
            "&kode_product=" + kode_product +
            "&kode_supplier=" + kode_supplier +
            "&kode_wilayah=" + kode_wilayah +
            "&jenis=" + jenis;

        return fetch(Connection.API_URL + 'GetMPProductsDisplay?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getMPProductsInventory(kode_product){
        const param =
            "kode_product=" + kode_product;

        return fetch(Connection.API_URL + 'GetMPProductsInventory?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getMPOrderTempSummary(kode_buyer,kode_product,kode_supplier,kode_wilayah,jenis){
        const param =
            "&kode_buyer=" + kode_buyer +
            "&kode_product=" + kode_product +
            "&kode_supplier=" + kode_supplier +
            "&kode_wilayah=" + kode_wilayah +
            "&jenis=" + jenis;

        return fetch(Connection.API_URL + 'GetMPOrderTempSummary?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static manageMPSupplier(kode_user_created, kode_user, kode_supplier, supplier_type, supplier_name, business_type, company_name, company_email, company_phone, company_website, fullname, position, phone, products, others_products, description, postal_code, district, subcity, city, province, address, pinpoint, coordinates, command){
        const param =
            "kode_user_created=" + kode_user_created +
            "&kode_user=" + kode_user +
            "&kode_supplier=" + kode_supplier +
            "&supplier_type=" + supplier_type +
            "&supplier_name=" + supplier_name +
            "&business_type=" + business_type +
            "&company_name=" + company_name +
            "&company_email=" + company_email +
            "&company_phone=" + company_phone +
            "&company_website=" + company_website +
            "&fullname=" + fullname +
            "&position=" + position +
            "&phone=" + phone +
            "&products=" + products +
            "&others_products=" + others_products +
            "&description=" + description +
            "&postal_code=" + postal_code +
            "&district=" + district +
            "&subcity=" + subcity +
            "&city=" + city +
            "&province=" + province +
            "&address=" + address +
            "&pinpoint=" + pinpoint +
            "&coordinates=" + coordinates +
            "&command=" + command;

        return fetch(Connection.API_URL + 'ManageMPSupplier?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static manageMPCoverage(kode_user, kode_coverage, kode_supplier, kode_wilayah, command){
        const param =
            "&kode_user=" + kode_user +
            "&kode_coverage=" + kode_coverage +
            "&kode_supplier=" + kode_supplier +
            "&kode_wilayah=" + kode_wilayah +
            "&command=" + command;

        return fetch(Connection.API_URL + 'ManageMPCoverage?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static manageMPBuyer(kode_user_created, kode_user, kode_buyer, buyer_type, buyer_name, business_type, company_name, company_email, company_phone, company_website, fullname, position, phone, finance_email, finance_npwp, finance_phone, products, others_products, description, postal_code, district, subcity, city, province, address, pinpoint, coordinates, kode_wilayah, command){
        const param =
            "kode_user_created=" + kode_user_created +
            "&kode_user=" + kode_user +
            "&kode_buyer=" + kode_buyer +
            "&buyer_type=" + buyer_type +
            "&buyer_name=" + buyer_name +
            "&business_type=" + business_type +
            "&company_name=" + company_name +
            "&company_email=" + company_email +
            "&company_phone=" + company_phone +
            "&company_website=" + company_website +
            "&fullname=" + fullname +
            "&position=" + position +
            "&phone=" + phone +
            "&finance_email=" + finance_email +
            "&finance_npwp=" + finance_npwp +
            "&finance_phone=" + finance_phone +
            "&products=" + products +
            "&others_products=" + others_products +
            "&description=" + description +
            "&postal_code=" + postal_code +
            "&district=" + district +
            "&subcity=" + subcity +
            "&city=" + city +
            "&province=" + province +
            "&address=" + address +
            "&pinpoint=" + pinpoint +
            "&coordinates=" + coordinates +
            "&kode_wilayah=" + kode_wilayah +
            "&command=" + command;

        return fetch(Connection.API_URL + 'ManageMPBuyer?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static manageMPProducts(kode, kode_product, kode_supplier, kode_product_unit, price, sku, command){
        const param =
            "kode=" + kode +
            "&kode_product=" + kode_product +
            "&kode_supplier=" + kode_supplier +
            "&kode_product_unit=" + kode_product_unit +
            "&price=" + price +
            "&sku=" + sku +
            "&command=" + command;

        return fetch(Connection.API_URL + 'ManageMPProducts?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static manageMPProductsInventory(kode, kode_inventory, kode_product, add_stock, expired_date){
        const param =
            "kode=" + kode +
            "&kode_inventory=" + kode_inventory +
            "&kode_product=" + kode_product +
            "&add_stock=" + add_stock +
            "&expired_date=" + expired_date;

        return fetch(Connection.API_URL + 'ManageMPProductsInventory?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static manageMPOrderTemp(kode_user, kode_buyer, kode_unit, qty){
        const param =
            "kode_user=" + kode_user +
            "&kode_buyer=" + kode_buyer +
            "&kode_unit=" + kode_unit +
            "&qty=" + qty;

        return fetch(Connection.API_URL + 'ManageMPOrderTemp?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static manageMPOrder(kode_user, kode_buyer, kode_payment_gateway, metode_pembayaran, note){
        const param =
            "kode_user=" + kode_user +
            "&kode_buyer=" + kode_buyer +
            "&kode_payment_gateway=" + kode_payment_gateway +
            "&metode_pembayaran=" + metode_pembayaran +
            "&note=" + note;

        return fetch(Connection.API_URL + 'ManageMPOrder?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getMPSupplierSummary(kode_supplier){
        const param =
            "kode_supplier=" + kode_supplier;

        return fetch(Connection.API_URL + 'GetMPSupplierSummary?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getMPBuyerOrder(kode_order,kode_buyer,status){
        const param =
            "kode_order=" + kode_order +
            "&kode_buyer=" + kode_buyer +
            "&status=" + status;

        return fetch(Connection.API_URL + 'GetMPBuyerOrder?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getMPSupplierOrder(kode_order,kode_supplier,status){
        const param =
            "kode_order=" + kode_order +
            "&kode_supplier=" + kode_supplier +
            "&status=" + status;

        return fetch(Connection.API_URL + 'GetMPSupplierOrder?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    static getMPBuyerOrderDetails(kode_order,status){
        const param =
            "kode_order=" + kode_order +
            "&status=" + status;

        return fetch(Connection.API_URL + 'GetMPBuyerOrderDetails?' + param, {
            method: 'GET'
        }).then((res) => res.json()).then((json) => {
            return json;
        }).catch((e) => {
            console.log(e);
        });
    }

    /* FIREBASE */

    static firebaseUploadFile(file,name,path){
        const storageRef = refStorage(storage, `files/${path}/${name}`);
        return uploadString(storageRef, file, 'data_url').then((snapshot) => {
            return getDownloadURL(snapshot.ref).then((downloadURL) => {
                return downloadURL;
            }).catch(() => {
                return false;
            });
        }).catch((e) => {
            return e;
        });
    }
}