import React from "react";
import {Button, Modal, ModalBody, ModalFooter, Nav, NavItem, NavLink} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faHome, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import {ReactSession} from "react-client-session";
import {encode as base64_encode} from "base-64";
import {Helpers} from "./config/Helpers";

const tabs = [{
    route: "/",
    icon: faHome,
    label: "Beranda",
    img: "./assets/imgs/navbar/v3_ic_home.png",
    img_active: "./assets/imgs/navbar/v3_ic_home_active.png"
},{
    route: "/store",
    icon: faSearch,
    label: "Warung",
    img: "./assets/imgs/navbar/v3_ic_category.png",
    img_active: "./assets/imgs/navbar/v3_ic_category_active.png"
},{
    route: "/shop",
    icon: faUserCircle,
    label: "Telusuri",
    img: "./assets/imgs/navbar/v3_ic_cart.png",
    img_active: "./assets/imgs/navbar/v3_ic_cart_active.png"
},{
    route: "/patungan",
    icon: faUserCircle,
    label: "Patungan",
    img: "./assets/imgs/navbar/v3_ic_fav.png",
    img_active: "./assets/imgs/navbar/v3_ic_fav_active.png"
},{
    route: "/account",
    icon: faUserCircle,
    label: "Akun",
    img: "./assets/imgs/navbar/v3_ic_history.png",
    img_active: "./assets/imgs/navbar/v3_ic_history_active.png"
}];

class Footer extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            urlActive: window.location.pathname,
            orderType: (ReactSession.get("order_type")) ? ReactSession.get("order_type") : 'Grosir',
            isModalOpen: false,
            totalOrderActive: '0',
        }
    }

    componentDidMount() {
        if(this.state.sessUserCode){
            this.getSummary();
        }
    }

    getSummary = () => {
        Helpers.getSummary(this.state.sessUserCode).then(res => {
            if(res.length > 0){
                this.setState({
                    totalOrderActive: res[0].total_active_order
                });
            }
        })
    };

    handleModal = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    handleLogout = () => {
        if (window.confirm('Apakah anda yakin akan keluar dari aplikasi?')) {
            ReactSession.set("sess_userid", "");
            ReactSession.set("sess_fullname", "");
            ReactSession.set("sess_branchcode", "");
            ReactSession.set("sess_employeecode", "");
            ReactSession.set("sess_profileid", "");
            ReactSession.set("sess_email", "");
            ReactSession.set("sess_usercode", "");
            ReactSession.set("sess_usertype", "");
            ReactSession.set("sess_uuid", "");

            window.location.href = './';
        } else {
            console.log('Cancel.');
        }
    };

    render() {
        return (
            <>
                <Modal
                    size={'md'}
                    isOpen={this.state.isModalOpen}
                    toggle={() => {this.handleModal()}}
                    modalClassName={'bottomed'}
                    contentClassName={'bottomed-content'}>
                    <ModalBody>
                        {
                            this.state.sessUserCode !== '' && (
                                <ul>
                                    <li className={'mb-5'}>
                                        <a onClick={() => {this.handleLogout()}} style={{'color': '#FFFFFF'}}><i className="fi fi-rs-sign-out mr-10"></i>Keluar</a>
                                    </li>
                                    <li className={'mb-5'}>
                                        <a href="./account-orders" style={{'display': 'flex', 'color': '#FFFFFF'}}>
                                            <i className="fi fi-rs-shopping-cart mr-10"></i>Order Aktif
                                            <div className="badge-circle-white">{this.state.totalOrderActive}</div>
                                        </a>
                                    </li>
                                    <li className={'mb-5'}>
                                        <a href="./account-history" style={{'color': '#FFFFFF'}}><i className="fi fi-rs-archive mr-10"></i>Riwayat Transaksi</a>
                                    </li>
                                    <li className={'mb-5'}>
                                        <a href="./account-withdrawal" style={{'color': '#FFFFFF'}}><i className="fi fi-rs-money mr-10"></i>Penarikan Komisi</a>
                                    </li>
                                    <li className={'mb-5'}>
                                        <a href="./account-voucher" style={{'color': '#FFFFFF'}}><i className="fi fi-rs-label mr-10"></i>Transfer Voucher</a>
                                    </li>
                                    <li className={'mb-5'}>
                                        <a href="./account" style={{'color': '#FFFFFF'}}><i className="fi fi-rs-user mr-10"></i>Profil & Saldo</a>
                                    </li>
                                </ul>
                            )
                        }
                        {
                            this.state.sessUserCode == '' && (
                                <ul>
                                    <li>
                                        <a href={'./login?redirectUrl=' + base64_encode(window.location.href)} style={{'color': '#FFFFFF'}}><i className="fi fi-rs-user mr-10"></i>Login</a>
                                    </li>
                                </ul>
                            )
                        }
                    </ModalBody>
                </Modal>

                <nav id="mobile-footer" className="navbar fixed-bottom navbar-light content-mobile" role="navigation" style={{'background-color': '#FFF', 'padding-top': '0px', 'padding-bottom': '0px'}}>
                    <Nav className="w-100">
                        <div style={{'width': '100%', 'height': '1px', 'background-color': 'lightgrey'}}></div>
                        <div className=" d-flex flex-row justify-content-around w-100">
                            {
                                tabs.map((tab, index) =>(
                                    <NavItem key={`tab-${index}`}>
                                        {
                                            tab.route === '/account' && (
                                                <NavLink onClick={() => {this.handleModal()}} className="nav-link" activeClassName="active" style={{'padding': '.5rem 1rem .1rem 1rem'}}>
                                                    <div className="row d-flex flex-column justify-content-center align-items-center">
                                                        {
                                                            (this.state.urlActive.indexOf(tab.route) <= -1 || (this.state.urlActive.indexOf(tab.route) > -1 && tab.route === '/' && this.state.urlActive !== tab.route)) && (
                                                                <>
                                                                    <img src={tab.img} width="24" height="24" style={{'margin-bottom': '15px', 'margin-top': '9px'}}/>
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            (
                                                                index > 0 && this.state.urlActive.indexOf(tab.route) > -1
                                                                ||
                                                                this.state.urlActive.indexOf(tab.route) > -1 && this.state.urlActive === tab.route
                                                            ) && (
                                                                <>
                                                                    <img src={tab.img_active} width="24" height="24" style={{'margin-bottom': '15px', 'margin-top': '9px'}}/>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </NavLink>
                                            )
                                        }
                                        {
                                            tab.route !== '/account' && (
                                                <NavLink href={tab.route} className="nav-link" activeClassName="active" style={{'padding': '.5rem 1rem .1rem 1rem'}}>
                                                    <div className="row d-flex flex-column justify-content-center align-items-center">
                                                        {
                                                            (this.state.urlActive.indexOf(tab.route) <= -1 || (this.state.urlActive.indexOf(tab.route) > -1 && tab.route === '/' && this.state.urlActive !== tab.route)) && (
                                                                <>
                                                                    <img src={tab.img} width="24" height="24" style={{'margin-bottom': '15px', 'margin-top': '9px'}}/>
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            (
                                                                index > 0 && this.state.urlActive.indexOf(tab.route) > -1
                                                                ||
                                                                this.state.urlActive.indexOf(tab.route) > -1 && this.state.urlActive === tab.route
                                                            ) && (
                                                                <>
                                                                    <img src={tab.img_active} width="24" height="24" style={{'margin-bottom': '15px', 'margin-top': '9px'}}/>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </NavLink>
                                            )
                                        }
                                    </NavItem>
                                ))
                            }
                        </div>
                    </Nav>
                </nav>
                <footer className="main content-desktop">
                    <section className="newsletter mb-15 wow animate__animated animate__fadeIn">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="position-relative newsletter-inner">
                                        <div className="newsletter-content">
                                            <h2 className="mb-20">
                                                Tetap di rumah & dapatkan kebutuhan sehari-hari <br/>
                                                Anda dari aplikasi kami
                                            </h2>
                                            <p className="mb-15">
                                                Mulai Belanja Harian Anda dengan <span className="text-brand">Waroq</span>,
                                                download aplikasi disini:
                                            </p>
                                            <a href="https://play.google.com/store/apps/details?id=waroq.waroqmobileapp"
                                               target="_blank">
                                                <img src="./assets/imgs/vendor/playstore.png" width="150"
                                                     style={{position: 'unset'}}/>
                                            </a>
                                            <img src="./assets/imgs/banner/banner-9.png" alt="newsletter"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="featured section-padding">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6 mb-md-4 mb-xl-0">
                                    <div
                                        className="banner-left-icon d-flex align-items-center wow animate__animated animate__fadeInUp"
                                        data-wow-delay="0">
                                        <div className="banner-icon">
                                            <img src="./assets/imgs/theme/icons/icon-1.svg" alt=""/>
                                        </div>
                                        <div className="banner-text">
                                            <h3 className="icon-box-title">Harga terbaik</h3>
                                            <p>Belanja ≥ 500.000</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div
                                        className="banner-left-icon d-flex align-items-center wow animate__animated animate__fadeInUp"
                                        data-wow-delay=".1s">
                                        <div className="banner-icon">
                                            <img src="./assets/imgs/theme/icons/icon-2.svg" alt=""/>
                                        </div>
                                        <div className="banner-text">
                                            <h3 className="icon-box-title">Free delivery</h3>
                                            <p>Belanja ≥ 1.000.000</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div
                                        className="banner-left-icon d-flex align-items-center wow animate__animated animate__fadeInUp"
                                        data-wow-delay=".2s">
                                        <div className="banner-icon">
                                            <img src="./assets/imgs/theme/icons/icon-3.svg" alt=""/>
                                        </div>
                                        <div className="banner-text">
                                            <h3 className="icon-box-title">Promo menarik</h3>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div
                                        className="banner-left-icon d-flex align-items-center wow animate__animated animate__fadeInUp"
                                        data-wow-delay=".3s">
                                        <div className="banner-icon">
                                            <img src="./assets/imgs/theme/icons/icon-4.svg" alt=""/>
                                        </div>
                                        <div className="banner-text">
                                            <h3 className="icon-box-title">Banyak Pilihan</h3>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                    <div
                                        className="banner-left-icon d-flex align-items-center wow animate__animated animate__fadeInUp"
                                        data-wow-delay=".4s">
                                        <div className="banner-icon">
                                            <img src="./assets/imgs/theme/icons/icon-5.svg" alt=""/>
                                        </div>
                                        <div className="banner-text">
                                            <h3 className="icon-box-title">Pengiriman cepat</h3>
                                            <p>&nbsp;</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="container pb-30 wow animate__animated animate__fadeInUp" data-wow-delay="0">
                        <div className="row align-items-center">
                            <div className="col-12 mb-30">
                                <div className="footer-bottom"></div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6">
                                <p className="font-sm mb-0">&copy; 2024, <strong className="text-brand">Waroq</strong> -
                                    Warung Baroqah Online <br/>All rights reserved</p>
                            </div>
                            <div className="col-xl-4 col-lg-6 text-center d-none d-xl-block">
                                Mayapada Tower 1 Lt 5, Jl. Jend Sudirman Kav 28, Jakarta Selatan<br/>
                                Telp: 0878-1987-8386, Email: admin@waroq.com<br/>
                                PT BINA ANGGOTA ORGANIK
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 text-end d-none d-md-block">
                                <div className="mobile-social-icon">
                                    <h6>Ikuti Kami</h6>
                                    <a href="https://www.facebook.com/waroqonline/" target={'_blank'}><img src="./assets/imgs/theme/icons/icon-facebook-white.svg" alt=""/></a>
                                    <a href="https://twitter.com/waroqonline" target={'_blank'}><img src="./assets/imgs/theme/icons/icon-twitter-white.svg" alt=""/></a>
                                    <a href="https://www.instagram.com/waroqonline/" target={'_blank'}><img src="./assets/imgs/theme/icons/icon-instagram-white.svg" alt=""/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <div id="preloader-active">
                    <div className="preloader d-flex align-items-center justify-content-center">
                        <div className="preloader-inner position-relative">
                            <div className="text-center">
                                <img src="./assets/imgs/theme/loading.gif" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="preloader-active-x" style={{'display': 'none'}}>
                    <div className="preloader d-flex align-items-center justify-content-center">
                        <div className="preloader-inner position-relative">
                            <div className="text-center">
                                <img src="./assets/imgs/theme/loading.gif" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Footer;