import React from "react";
import {Connection} from "../config/Connection";

class PrivacyPolicy extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (
            <>
                <main className="main pages">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <a href="./" rel="nofollow"><i className="fi-rs-home mr-5"></i> Beranda</a>
                                <span></span> Kebijakan Privasi
                            </div>
                        </div>
                    </div>
                    <section className="hero-3 position-relative align-items" style={{backgroundImage: `url(https://waroq.co.id/WRQBACKEND/Images/default/back4.png)`, height: 'auto'}}>
                        <h2 className="mb-30 mt-30 text-center" style={{color: 'white'}}>Kebijakan Privasi</h2>
                        <div className="row" style={{'--bs-gutter-x': 'unset'}}>
                            <div className="col-xl-10 col-lg-12 m-auto">
                                <div className="row">
                                    <div className="col-sm-12 mb-15" align="center">
                                        <p className="pr-15 pl-15 mb-150" align="justify" style={{color: 'white'}}>
                                            <ol style={{'list-style': 'auto'}}>
                                                <li>Selamat datang di aplikasi Waroq (Warung Baroqah), aplikasi ini dikelola oleh PT. Bina Anggota Organik.</li>
                                                <li>Informasi yang Kami kumpulkan berhubungan dengan penyelenggaraan transaksi Kami dengan Anda. Kami akan menjaga informasi yang telah diperoleh dari Anda.</li>
                                                <li>Kami hanya akan menyimpan informasi Anda sepanjang tidak bertentangan dengan hukum atau selama informasi tersebut masih digunakan sesuai dengan tujuan awal pengumpulan informasi yaitu transaksi Kami dengan Anda.</li>
                                                <li>
                                                    Informasi yang Kami kumpulkan terkait informasi pribadi Anda adalah sebagai berikut:
                                                    <ol>
                                                        <li>-	Nama</li>
                                                        <li>-	Nomor Telepon</li>
                                                        <li>-	Alamat Email</li>
                                                        <li>-	Alamat Pengiriman</li>
                                                        <li>-	Rekening Bank</li>
                                                    </ol>
                                                </li>
                                                <li>Informasi terkait alamat email Anda akan Kami gunakan sebagai identitas Anda saat menggunakan aplikasi.</li>
                                                <li>Informasi tentang Anda akan Kami gunakan untuk kepentingan Anda dalam menggunakan aplikasi Kami, seperti nama, nomor telepon dan alamat Anda untuk tujuan pengiriman produk kepada Anda.</li>
                                                <li>Informasi rekening bank Anda hanya diperlukan jika Anda ingin melakukan penarikan komisi.</li>
                                                <li>
                                                    Informasi lain terkait penggunaan fungsionalitas aplikasi adalah sebagai berikut:
                                                    <ol>
                                                        <li>-	Kamera</li>
                                                        <label className="pl-10">Diperlukan untuk melakukan pengambilan gambar produk dan mengunggah bukti pembayaran transaksi Anda.</label>
                                                        <li>-	Koordinat Lokasi</li>
                                                        <label className="pl-10">Diperlukan untuk melakukan pengambilan lokasi terkait pengiriman produk berdasarkan transaksi Anda.</label>
                                                        <li>-	Penyimpanan</li>
                                                        <label className="pl-10">Diperlukan untuk menyimpan foto produk yang Anda inginkan.</label>
                                                    </ol>
                                                </li>
                                                <li>Dalam menjalankan aplikasi Kami, Anda dapat terhubung kepada Pihak Ketiga yang mana Kami tidak bertanggung jawab terhadap Informasi tentang Anda yang Anda berikan kepada Pihak Ketiga.</li>
                                                <li>
                                                    Apabila Anda mempunyai pertanyaan mengenai penanganan data pribadi Anda, atau pertanyaan lain yang berkaitan dengan aplikasi, maka Anda dapat menghubungi Kami di:
                                                    <div>
                                                        PT. Bina Anggota Organik<br/>
                                                        Mayapada Tower 1 Lt 5,<br/>
                                                        Jl. Jend Sudirman Kav 28,<br/>
                                                        Jakarta Selatan<br/>
                                                        Telp: 0878-1987-8386<br/>
                                                        Email: admin@waroq.com
                                                    </div>
                                                </li>
                                                <li>Kami selaku penyelenggara dapat mengubah atau memperbarui Perjanjian ini sewaktu-waktu dan menampilkan perubahan atau perbaharuan tersebut di aplikasi Kami dan penerapannya dapat berlaku segera setelah ditampilkan. Anda wajib membaca kebijakan privasi setiap saat dan dianggap menyetujui perubahan dan / atau pembaharuan tersebut setelah dimasukkan ke dalam aplikasi ketika pengguna tetap melanjutkan menggunakan aplikasi Kami.</li>
                                            </ol>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </>
        );
    }
}

export default PrivacyPolicy;