import React from "react";
import {ReactSession} from "react-client-session";
import queryString from "query-string";
import {toast} from "react-toastify";
import {decode as base64_decode, encode as base64_encode} from "base-64";

import { Connection } from "../../config/Connection";
import { Helpers } from "../../config/Helpers";
import GoogleMap from '../../components/GoogleMap';
import AutoComplete from "../../components/AutoComplete";
import OtpInput from 'react-otp-input';

const Marker = props => {
    return <>
        <div className="pin"></div>
        <div className="pulse"></div>
    </>
}

class New extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            sessUserType: (ReactSession.get("sess_usertype")) ? ReactSession.get("sess_usertype") : '',
            urlParam: queryString.parse(window.location.search),

            mapCenter: {
                lat: -6.213110657838351,
                lng: 106.82010690652227
            },
            mapZoom: 15,
            mapApiLoaded: false,
            mapInstance: null,
            mapApi: null,
            places: [],

            wilayahData: [],

            storeID: '',
            storeWilayah: '',
            storeNama: '',
            storePemilik: '',
            storeEmail: '',
            storeHandphone: '',
            storeOTP: '',
            storePinPoint: '',
            storeDistance: '',
            storeDistanceDisplay: '',
            storeProvinsi: '',
            storeKota: '',
            storeKecamatan: '',
            storeDesa: '',
            storeKodepos: '',
            storeFasilitas: '',
            storeChiller: '',
            storeUkuranChiller: '',
            storeEtalase: '',
            storeSembako: '',
            storeKepemilikan: '',
            storeBangunan: '',
            storeOmset: '',
            storeImage: '',
            storeImageName: '',
            storeCommand: ''
        }
    }

    componentDidMount() {
        if(!this.state.sessUserCode){
            window.location.href = './login?redirectUrl=' + base64_encode(window.location.href);
        }else{
            this.getBranch();
        }
    }

    getBranch = () => {
        Helpers.getBranch().then(res => {
            this.setState({
                wilayahData: res
            }, () => {
                this.getWarungDetail();
            });
        })
    };

    getWarungDetail = () => {
        this.setState({
            storeID: (this.state.urlParam.trxid) ? base64_decode(this.state.urlParam.trxid) : '',
            storeCommand: (this.state.urlParam.action) ? this.state.urlParam.action : ''
        }, () => {
            if(this.state.storeID && this.state.storeCommand == 'update'){
                console.log('update');
            }else{
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        this.setState({
                            mapCenter: {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude
                            }
                        }, () => {
                            this.googleLocationDetails();
                        });
                    });
                }
            }
        });
    };

    googleLocationDetails = () => {
        const latlng = this.state.mapCenter.lat + ',' + this.state.mapCenter.lng;

        Helpers.googleLocationDetails(latlng).then(res => {
            this.defaultPlace(res.results[0]);
        });
    };

    handleGenerateMaps = () => {
        const generateMaps = <GoogleMap
            defaultZoom={this.state.mapZoom}
            defaultCenter={this.state.mapCenter}
            bootstrapURLKeys={{
                key: Connection.MAPS_API_KEY,
                libraries: ['places', 'geometry'],
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}>
            <Marker lat={parseFloat(this.state.mapCenter.lat)} lng={parseFloat(this.state.mapCenter.lng)} />
        </GoogleMap>

        this.setState({
            generateMaps
        });
    };

    apiHasLoaded = (map, maps) => {
        this.setState({
            mapApiLoaded: true,
            mapInstance: map,
            mapApi: maps,
        });
    };

    defaultPlace = (place) => {
        this.setState({
            places: [place],
            mapCenter: {
                lat: place.geometry.location.lat,
                lng: place.geometry.location.lng
            },
            storePinPoint: place.formatted_address,
            storeProvinsi: place.address_components.find(x => x.types[0] === 'administrative_area_level_1').long_name,
            storeKota: place.address_components.find(x => x.types[0] === 'administrative_area_level_2').long_name,
            storeKecamatan: place.address_components.find(x => x.types[0] === 'administrative_area_level_3').long_name,
            storeDesa: place.address_components.find(x => x.types[0] === 'administrative_area_level_4').long_name
        }, () => {
            this.countDistance();
        });
    };

    addPlace = (place) => {
        this.setState({
            places: [place],
            mapCenter: {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            },
            storePinPoint: place.formatted_address,
            storeProvinsi: place.address_components.find(x => x.types[0] === 'administrative_area_level_1').long_name,
            storeKota: place.address_components.find(x => x.types[0] === 'administrative_area_level_2').long_name,
            storeKecamatan: place.address_components.find(x => x.types[0] === 'administrative_area_level_3').long_name,
            storeDesa: place.address_components.find(x => x.types[0] === 'administrative_area_level_4').long_name
        }, () => {
            this.countDistance();
        });
    };

    countDistance = () => {
        const destination = this.state.mapCenter.lat + ',' + this.state.mapCenter.lng
        Helpers.countDistance(destination,'').then(res => {
            this.setState({
                //storeDistance: res.rows[0].elements[0].distance.value,
                //storeDistanceDisplay: res.rows[0].elements[0].distance.text + ' / ' + res.rows[0].elements[0].duration.text,
                storeDistance: res[0].distanceValue,
                storeDistanceDisplay: res[0].distanceText + ' / ' + res[0].durationText,
            }, () => {
                this.handleGenerateMaps();
            });
        })
    };

    handleOTP = () => {
        if(this.state.storeHandphone){
            Helpers.requestOTP(
                'request',
                this.state.storeHandphone,
                ''
            ).then(res => {
                if(res.length > 0){
                    toast[res[0].alert](res[0].message);
                }else{
                    toast.error(Helpers.MSG_ERROR);
                }
            })
        }else{
            toast.error(Helpers.MSG_ERROR_FORM);
        }
    };

    handleSubmit = (e) => {
        e.preventDefault();

        var formdata = new FormData();
        formdata.append("img", this.state.storeImage);
        formdata.append("BranchCode", this.state.storeWilayah);
        formdata.append("KodeMarketing", this.state.sessUserCode);
        formdata.append("NamaWarung", this.state.storeNama);
        formdata.append("NamaPemilikWarung", this.state.storePemilik);
        formdata.append("Alamat", this.state.storePinPoint);
        formdata.append("Kelurahan", this.state.storeDesa);
        formdata.append("Kecamatan", this.state.storeKecamatan);
        formdata.append("KodePos", this.state.storeKodepos);
        formdata.append("NoTelepon", this.state.storeHandphone);
        formdata.append("FotoWarung", this.state.storeImageName);
        formdata.append("Keterangan", this.state.storeFasilitas);
        formdata.append("Chiler", this.state.storeChiller);
        formdata.append("UkuranCaseChiller", this.state.storeUkuranChiller);
        formdata.append("UkuranEtalase", this.state.storeEtalase);
        formdata.append("Sembako", this.state.storeSembako);
        formdata.append("Kepemilikan", this.state.storeKepemilikan);
        formdata.append("JenisBangunan", this.state.storeBangunan);
        formdata.append("OmsetPerHari", this.state.storeOmset);
        formdata.append("Email", this.state.storeEmail);
        formdata.append("Password", '');
        formdata.append("Coordinate", this.state.mapCenter.lat + ');' + this.state.mapCenter.lng);
        formdata.append("Akurasi", this.state.storeDistance);
        formdata.append("OTP", this.state.storeOTP);
        formdata.append("Provinsi", this.state.storeProvinsi);
        formdata.append("Kota", this.state.storeKota);

        fetch(Connection.SOAP_SaveMitraUpload, {
            method: 'POST',
            body: formdata
        }).then((res) => res.json()).then((res) => {
            console.log(res);
            if(res.length > 0){
                if(res[0].alert == 'success'){
                    setTimeout(function() {
                        window.location.href = './store';
                    }, 1000);
                }

                toast[res[0].alert](res[0].message);
            }else{
                toast.error(Helpers.MSG_ERROR);
            }
        }).catch((error) => {
            toast.error(error);
        });
    };

    render() {
        return (
            <>
                <main className="main pages mb-80">
                    <div className="page-header breadcrumb-wrap">
                        <div className="container">
                            <div className="breadcrumb">
                                <a href="./" rel="nofollow"><i className="fi-rs-home mr-5"></i>Beranda</a>
                                <span></span> Warung<span></span> Manage
                            </div>
                        </div>
                    </div>
                    <div className="page-content pt-20">
                        <div className="container">
                            {
                                this.state.mapApiLoaded && (
                                    <AutoComplete map={this.state.mapInstance} mapApi={this.state.mapApi} addplace={this.addPlace} />
                                )
                            }
                            <div style={{ height: '100vh', width: '100%' }}>
                                {this.state.generateMaps}
                            </div>
                            <div className="row">
                                <div className="col-lg-5 m-auto">
                                    <form className="contact-form-style mt-30 mb-30"
                                          method="post" onSubmit={(e) => {this.handleSubmit(e)}}>
                                        <div className="input-style mb-20">
                                            <label>Wilayah</label>
                                            <div className="custom_select">
                                                <select
                                                    className="select-style" required onChange={e => this.setState({storeWilayah: e.target.value})}>
                                                    <option value={''}>Pilih Wilayah</option>
                                                    {
                                                        this.state.wilayahData.map((object, i) => {
                                                            return <option value={object.BranchCode} selected={(this.state.storeWilayah == object.BranchCode) ? 'selected' : ''}>{object.BranchName}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Nama Mitra</label>
                                            <input value={this.state.storeNama} onChange={e => this.setState({storeNama: e.target.value})}
                                                   placeholder=""
                                                   type="text" required/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Nama Pemilik (Sesuai KTP)</label>
                                            <input value={this.state.storePemilik} onChange={e => this.setState({storePemilik: e.target.value})}
                                                   placeholder=""
                                                   type="text" required/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Email Pemilik</label>
                                            <input value={this.state.storeEmail} onChange={e => this.setState({storeEmail: e.target.value})}
                                                   placeholder=""
                                                   type="email" required/>
                                        </div>
                                        <div className="login_footer form-group">
                                            <div className="chek-form">
                                                <input required type="number" value={this.state.storeHandphone} onChange={e => this.setState({storeHandphone: e.target.value})}
                                                       min={0}
                                                       placeholder="Nomor HP *"/>
                                            </div>
                                            <button type="button"
                                                    className="btn btn-heading btn-block hover-up"
                                                    onClick={() => {this.handleOTP()}}>Kirim OTP
                                            </button>
                                        </div>
                                        <div className="form-group">
                                            <OtpInput
                                                inputStyle={{'width': '100%', 'padding-left': '0px'}}
                                                value={this.state.storeOTP}
                                                onChange={storeOTP => this.setState({storeOTP})}
                                                numInputs={6}
                                                isInputSecure={true}
                                                separator={<span>&nbsp;</span>}
                                            />
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Alamat (Berdasarkan Pin Point)</label>
                                            <textarea value={this.state.storePinPoint} onChange={e => this.setState({storePinPoint: e.target.value})}
                                                      placeholder="Cari atau arahkan pin sesuai lokasi anda"
                                                      required />
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Estimasi Jarak & Waktu Tempuh Dari Gudang</label>
                                            <input value={this.state.storeDistanceDisplay} onChange={e => this.setState({storeDistanceDisplay: e.target.value})}
                                                   placeholder=""
                                                   type="text" readOnly={true} required/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Provinsi</label>
                                            <input value={this.state.storeProvinsi} onChange={e => this.setState({storeProvinsi: e.target.value})}
                                                   placeholder=""
                                                   type="text" readOnly={true} required/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Kota</label>
                                            <input value={this.state.storeKota} onChange={e => this.setState({storeKota: e.target.value})}
                                                   placeholder=""
                                                   type="text" readOnly={true} required/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Kecamatan</label>
                                            <input value={this.state.storeKecamatan} onChange={e => this.setState({storeKecamatan: e.target.value})}
                                                   placeholder=""
                                                   type="text" readOnly={true} required/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Desa</label>
                                            <input value={this.state.storeDesa} onChange={e => this.setState({storeDesa: e.target.value})}
                                                   placeholder=""
                                                   type="text" readOnly={true} required/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Kode Pos</label>
                                            <input value={this.state.storeKodepos} onChange={e => this.setState({storeKodepos: e.target.value})}
                                                   placeholder="e.g. 12920"
                                                   type="number" required/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Fasilitas Umum Terdekat</label>
                                            <input value={this.state.storeFasilitas} onChange={e => this.setState({storeFasilitas: e.target.value})}
                                                   placeholder="e.g. SD Sukasenang 2"
                                                   type="text" required/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Chiller</label>
                                            <div className="custom_select">
                                                <select
                                                    className="select-style" required onChange={e => this.setState({storeChiller: e.target.value})}>
                                                    <option value={''}>Pilih Chiller</option>
                                                    <option value={'Yes'}>Yes</option>
                                                    <option value={'No'}>No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Ukuran Chiller</label>
                                            <input value={this.state.storeUkuranChiller} onChange={e => this.setState({storeUkuranChiller: e.target.value})}
                                                   placeholder="Masukkan Ukuran Chiller"
                                                   type="text" required/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Ukuran Etalase</label>
                                            <input value={this.state.storeEtalase} onChange={e => this.setState({storeEtalase: e.target.value})}
                                                   placeholder="Ukuran Etalase"
                                                   type="text" required/>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Sembako</label>
                                            <div className="custom_select">
                                                <select
                                                    className="select-style" required onChange={e => this.setState({storeSembako: e.target.value})}>
                                                    <option value={''}>Pilih Sembako</option>
                                                    <option value={'Yes'}>Yes</option>
                                                    <option value={'No'}>No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Kepemilikan</label>
                                            <div className="custom_select">
                                                <select
                                                    className="select-style" required onChange={e => this.setState({storeKepemilikan: e.target.value})}>
                                                    <option value={''}>Pilih Kepemilikan</option>
                                                    <option value={'Milih Sendiri'}>Milih Sendiri</option>
                                                    <option value={'Kontrak'}>Kontrak</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Jenis Bangunan</label>
                                            <div className="custom_select">
                                                <select
                                                    className="select-style" required onChange={e => this.setState({storeBangunan: e.target.value})}>
                                                    <option value={''}>Pilih Jenis Bangunan</option>
                                                    <option value={'Permanen'}>Permanen</option>
                                                    <option value={'Semi Permanen'}>Semi Permanen</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="input-style mb-20">
                                            <label>Omset Per Hari (Dalam Rupiah)</label>
                                            <input value={this.state.storeOmset} onChange={e => this.setState({storeOmset: e.target.value})}
                                                   placeholder="Masukkan Omset Per Hari"
                                                   min={0}
                                                   type="number" required/>
                                        </div>
                                        <button className="submit submit-auto-width"
                                                type="submit">Submit
                                        </button>
                                        <a className="btn btn-outline" style={{'color': '#F47626'}} href={'./store'}>Kembali
                                        </a>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default New;