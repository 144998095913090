import React from "react";

import {encode as base64_encode} from "base-64";
import queryString from "query-string";
import {isMobile} from "react-device-detect";
import $ from "jquery";

class OrderPaymentResponse extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            kodeTransaksiOrder: '',
            kodePayment: '',
            responsePayment: '',
            urlParam: queryString.parse(window.location.search)
        }
    }

    componentDidMount() {
        const kodeOrder = (this.state.urlParam.trxid) ? this.state.urlParam.trxid : '';
        const kodePayment = (this.state.urlParam.payment_code) ? this.state.urlParam.payment_code : '';
        const response = (this.state.urlParam.response) ? this.state.urlParam.response : '';

        this.setState({
            kodeTransaksiOrder: kodeOrder,
            kodePayment: kodePayment,
            responsePayment: response
        });

        if(isMobile) {
            $("#mobile-header").css("display", "none");
            $("#mobile-footer").css("display", "none");
        }
    }

    render() {
        return (
            <>
                <main className="main pages mb-80">
                    <div className="page-content pt-10">
                        <div className="container">
                            <div className="row mt-15">
                                <div className="col-xl-8 col-lg-10 col-md-12 m-auto text-center">
                                    {
                                        (this.state.responsePayment == 'error') && (
                                            <>
                                                <h1 className="display-2 mb-30">Payment Failed</h1>
                                                <p className="font-lg text-grey-700 mb-20">
                                                    Click this link below to go back to your payment area or close this window if you're using Waroq Mobile.
                                                </p>
                                                <a className="btn btn-default submit-auto-width font-xs hover-up mt-30"
                                                   href={'./account-orders-payment?trxid='+base64_encode(this.state.kodeTransaksiOrder)+'&payment='+base64_encode(this.state.kodePayment)}>Back To Payment</a>
                                            </>
                                        )
                                    }
                                    {
                                        (this.state.responsePayment == 'success') && (
                                            <>
                                                <h1 className="display-2 mb-30">Payment Success</h1>
                                                <p className="font-lg text-grey-700 mb-20">
                                                    Click this link below to go back to your order or close this window if you're using Waroq Mobile.
                                                </p>
                                                <a className="btn btn-default submit-auto-width font-xs hover-up mt-30"
                                                   href={'./account-orders-details?trxid='+base64_encode(this.state.kodeTransaksiOrder)}>Back To Order Details</a>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default OrderPaymentResponse;