import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import {ReactSession} from "react-client-session";

import { Helpers } from "../config/Helpers";
import {encode as base64_encode} from "base-64";

class ModalPatungan extends React.Component {

    constructor(props) {
        super(props);
        ReactSession.setStoreType("localStorage");
        this.state = {
            sessUserCode: (ReactSession.get("sess_usercode")) ? ReactSession.get("sess_usercode") : '',
            sessEmail: (ReactSession.get("sess_email")) ? ReactSession.get("sess_email") : '',
            isModalOpen: this.props.modalRef
        }
    }

    componentDidMount() {
        if(this.state.isModalOpen){
            this.setupModal();
        }
    }

    setupModal = () => {

    };

    handleModal = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    handleCreate = (type) => {
        if (window.confirm('Apakah anda yakin akan membuat patungan belanja?')) {
            Helpers.createMarketOrder(
                this.state.sessUserCode,
                type
            ).then(res => {
                if(res.length > 0){
                    if(res[0].alert == 'success'){
                        ReactSession.set("order_type", type);
                        this.handleModal();

                        setTimeout(function() {
                            window.location.href = './shop?joinid=' + base64_encode(res[0].kode);
                        }, 1000);
                    }

                    toast[res[0].alert](res[0].message);
                }else{
                    toast.error(Helpers.MSG_ERROR);
                }
            })
        } else {
            console.log('Cancel.');
        }
    };

    render() {
        return (
            <Modal
                size={'md'}
                isOpen={this.state.isModalOpen}
                toggle={() => {this.handleModal()}}>
                <ModalBody style={{'padding': 'unset', backgroundImage: `url(./assets/imgs/background/v2_bg_orange.jpeg)`}}>
                    <div className="row p-15">
                        <div className="col-lg-12">
                            <center><img src="./assets/imgs/icon/v2_ic_patungan.png" width={100} alt=""/></center>
                        </div>
                        <div className="col-lg-12">
                            <center><h6 className="p-15" style={{'color': '#FFFFFF'}}>Silahkan pilih jenis kategori patungan di bawah ini</h6></center>
                        </div>
                        <div className="col-lg-12">
                            <div className="banner-left-icon d-flex align-items-center" style={{'background-color': '#FFFFFF'}} onClick={() => {this.handleCreate('Grosir')}}>
                                <div className="banner-icon">
                                    <img src="./assets/imgs/patungan/PatunganHargaGrosir.png" alt=""/>
                                </div>
                                <div className="banner-text">
                                    <p style={{'color': '#F47626'}}>Patungan belanja dengan harga grosir, minimum total order keseluruhan senilai 500.000</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 mt-15">
                            <div className="banner-left-icon d-flex align-items-center" style={{'background-color': '#FFFFFF'}} onClick={() => {this.handleCreate('Retail')}}>
                                <div className="banner-icon">
                                    <img src="./assets/imgs/patungan/PatunganHargaRetail.png" alt=""/>
                                </div>
                                <div className="banner-text">
                                    <p style={{'color': '#F47626'}}>Patungan belanja dengan harga retail, minimum total order keseluruhan senilai 300.000</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                {/*<ModalFooter>*/}
                    {/*<Button onClick={() => {this.handleModal()}}>*/}
                        {/*Close*/}
                    {/*</Button>*/}
                {/*</ModalFooter>*/}
            </Modal>
        );
    }
}

export default ModalPatungan;